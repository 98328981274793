/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint eqeqeq: 0 */
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ReactCrop } from 'react-image-crop';
import 'react-image-crop/src/ReactCrop.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import images from '../../assets/images';
import instance from '../../shared/interceptor';
import { setSuccessMsg } from '../../shared/store/slices/successSlice';
import { companyState, setCompany, userState } from '../../shared/store/slices/userSlice';
import { compressImage, globalError, setLoader } from '../../shared/util/util';

let themeColors = [
  { color: '#F2A54A', isChecked: false },
  { color: '#E4456E', isChecked: false },
  { color: '#EA33F4', isChecked: false },
  { color: '#4A68D9', isChecked: false },
  { color: '#52B1F9', isChecked: false },
  { color: '#489E92', isChecked: false },
  { color: '#75737F', isChecked: false },
]

export default function CompanySettings() {
  const dispatch = useDispatch();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const { id, logo, subdomain, color_theme, name } = useSelector(companyState) || { id: null, logo: null, subdomain: null, color_theme: null, name: null };
  const userData = useSelector(userState);

  const [logoUploading, setLogoUploading] = useState(false);
  const [logoUploaded, setLogoUploaded] = useState(false);
  const [uploadLogoDialog, setUploadLogoDialog] = useState(false);
  const [smsCheckboxChecked, setSmsCheckboxChecked] = useState(false);
  const [authAppCheckboxChecked, setAuthAppCheckbox] = useState(false);
  const [switchChecked, setSwitchChecked] = useState(false);
  const [error, setError] = useState('');
  const [isDragOver, setIsDragOver] = useState(false);
  const [crop, setCrop] = useState(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [filename, setFileName]= useState(null);
  const [isCropped, setIsCropped] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const [colorThemes, setColorThemes] = useState(themeColors);
  const [removeLogo, setRemoveLogo] = useState(false);
  const [companyName, setCompanyName] = useState('');

  useEffect(() => {
    if (logo?.logo_url) {
      setIsCropped(false);
      setCroppedImage(null);
      setIsDragOver(false);
      setLogoUploaded(true);
      setFileName(logo?.logo_url);
    }
    if (color_theme) {
      let foundColorIndex = colorThemes.findIndex(color => color?.color.toLowerCase() === color_theme.toLowerCase());
      let temp = [...colorThemes.map(item => ({ ...item, isChecked: false }))];
      if (foundColorIndex > -1) {
        temp[foundColorIndex] = { color: color_theme, isChecked: true };
      } else {
        temp[colorThemes?.length - 1] = { color: color_theme, isChecked: true };
      }
      setColorThemes(temp);
    }
    if (name) {
      setCompanyName(name);
    }
  }, [])

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }
    drawCanvas();
  }, [completedCrop]);

  const drawCanvas = () => {
    try {
      const image = imgRef.current;
      const canvas = previewCanvasRef.current;
      const crop = completedCrop;
  
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      const ctx = canvas.getContext('2d');
      const pixelRatio = window.devicePixelRatio;
  
      canvas.width = crop.width * pixelRatio;
      canvas.height = crop.height * pixelRatio;
  
      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = 'high';
  
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );
    } catch(e) {
      globalError(dispatch, e);
    }
  }

  const smsCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSmsCheckboxChecked(event.target.checked);
  };

  const authAppCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAuthAppCheckbox(event.target.checked);
  };

  const authToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSwitchChecked(event.target.checked);
  };

  const handleImageUpload = async (fileList) => {
    try {
      let file = fileList[0];
      let sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
      if (+sizeInMB > 2) {
        setIsDragOver(false);
        return setError('Use max 2MB of image size to upload company logo.');
      }
      if (file?.type !== 'image/png') {
        setIsDragOver(false);
        return setError('Unsupported format. Use PNG image to upload company logo.');
      }
      setError('');
      setIsCropped(false);
      setCroppedImage(null);
      setIsDragOver(false);
      setLogoUploaded(true);
      const reader = new FileReader();
      reader.addEventListener('load', () => setFileName(reader?.['result'] as any));
      reader.readAsDataURL(file);
    } catch(e) {
      globalError(dispatch, e);
    }
  }

  const generateCroppedImage = (canvas, crop) => {
    if (!crop || !canvas) {
      return;
    }
    try {
      setUploadLogoDialog(false);
      setIsCropped(true);
      setCroppedImage(canvas.toDataURL());
    } catch(e) {
      globalError(dispatch, e);
    }
  }

  const onLoad = useCallback((e) => {
    imgRef.current = e.currentTarget;
  }, []);

  const saveChanges = async () => {
    try {
      const formData = new FormData();
      let selectedColorTheme = colorThemes.find(item => item?.isChecked);

      if (logoUploaded) {
        let res = isCropped ? await fetch(croppedImage) : await fetch(filename);
        const blob = await res.blob();
        const compressed = await compressImage(blob, { quality: 0.3, type: 'image/png' }) as any;
        let file = new File([compressed], 'somename.png', { type: 'image/png' });
        formData.append('company[logo]', file);
        formData.append('company[color_theme]', selectedColorTheme?.color);
      } else {
        let themeColor = colorThemes.find(item => item.isChecked);

        if (themeColor?.color?.toLowerCase() != color_theme?.toLowerCase()) {
          formData.append('company[color_theme]', selectedColorTheme?.color);
        } else if (removeLogo) {
          formData.append('company[destroy_logo]', JSON.stringify(true));
        }
      }
      if (companyName != name) {
        formData.append('company[name]', companyName);
      }
      setLoader(dispatch, true);
      let updatedRes = await instance.patch('/enterprise/companies/' + id, formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      });
      setRemoveLogo(false);
      dispatch(setCompany(updatedRes['company']));
      dispatch(setSuccessMsg('Updated Successfully'));
    } catch(e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  }

  const removeImage = (e) => {
    setLogoUploading(false); 
    setLogoUploaded(false); 
    setFileName(null); 
    setIsCropped(false); 
    setCroppedImage(null);
    setRemoveLogo(true);
  }

  const getTheme = (theme) => {
    let themeIndex = colorThemes.findIndex(item => item?.color?.toLowerCase() === theme?.color?.toLowerCase());
    if (themeIndex > -1) {
      let temp = [...colorThemes.map(item => ({ ...item, isChecked: false }))];
      temp[themeIndex] = { color: theme?.color, isChecked: true };
      setColorThemes(temp);
    }
  }

  const shouldDisable = () => {
    let themeColor = colorThemes.find(item => item.isChecked);

    if (((logo?.logo_url && (logo?.logo_url != filename)) || (logo?.logo_url == filename)) &&
       (themeColor?.color?.toLowerCase() == color_theme?.toLowerCase()) && !removeLogo && !isCropped && companyName == name
    ) {
      return true;
    }
    return false;
  }

  const handleModalClose = (e, reason) => {
    if (reason && reason == "backdropClick") return;
    setUploadLogoDialog(false);
  }

  //=================================================

  return (
    <>
      <section className="compnay-settings-page" aria-label="company-settings">
        <div className="settings-title">
        <div className='main-page-header'>
          <div className='page-breacrumb'>
          <div className="breadcrumb-wrapper">
         
            <ul className="breadcrumb-links">
              <li>Company</li>
              <li>Company settings</li>
            </ul>
            </div>
          </div>
          </div>
          <div className="bottom-title secondary-header">
            <div className="title-left">
              <h2>Company settings</h2>
            </div>
            <div className="title-right">
              <Button disabled={shouldDisable()} data-testid="save-button" variant="contained" className="sm-btn" onClick={saveChanges}>Save changes</Button>
            </div>
          </div>
        </div>

        <div className="settings-wrapper">
          <div className="page-container">
            <div className="settings-outer">
              <div className="title-block company-name">
                <div className='left-title w-100'>                  
                  <div className="form-group">                    
                    <h5>Company Name</h5>  
                    <FormControl>                        
                      <FilledInput disabled={userData?.role?.name != 'CIO' && userData?.role?.name != 'IT Admin'} type="text" name="company-name" placeholder="Enter name of your company" value={companyName} onChange={(e) => setCompanyName(e?.target?.value)} />
                    </FormControl>
                    {/* <span className="form-error"></span> */}
                  </div>
                </div>
              </div>
              <div className="title-block">
                <div className='left-title w-100'>
                  <h5>Company logo</h5>
                  <p>Add logo of your company. Your logo will be visible across the platform and invitation emails.</p>
                </div>
              </div>
              <div className="compnay-photo-block">
                {!logoUploaded ?
                  <div className="photo-upload-box is-horizontal">
                    <div className="upload-info">
                      {!logoUploading ?
                        <div aria-label="drag-over" className={`upload-icon be-upload-img square-placeholder ${isDragOver ? 'bg-transparent' : ''}`}
                          onDragLeave={() => setIsDragOver(false)}
                          onDragOver={(e) => {
                            e.preventDefault();
                            setIsDragOver(true);
                          }}
                          onDrop={(e) => { e.preventDefault(); handleImageUpload(e?.dataTransfer?.files)}}>
                        </div>
                        :
                        <div className="upload-icon">
                          <div className="secondary-loader">
                            <svg className="spinner" viewBox="0 0 50 50" height="50" width="50">
                              <circle cx="25" cy="25" r="20" stroke="#6200ea" strokeOpacity="0.15" fill="none" strokeWidth="5">
                              </circle>
                              <circle className="animated-path" cx="25" cy="25" r="20" stroke="#6200ea" fill="none" strokeWidth="5">
                              </circle>
                            </svg>
                          </div>
                        </div>
                      }
                      <p className="instructions">Drag and drop an image or
                        <span className="browse-files-link">
                          <input aria-label="browse" type="file" className="hidden-input" onChange={(e) => handleImageUpload(e?.target?.files)} accept='image/png'/>browse
                        </span>
                      </p>
                    </div>
                  </div>
                  :
                  <div className="photo-upload-box is-horizontal uploaded">
                    <div className="upload-info">
                      <div className="upload-icon">
                        <img aria-label="open-crop-modal" src={filename} alt="logo"/> {/* src={!isCropped ? filename : croppedImage } onClick={(e) => setUploadLogoDialog(true)} */}
                      </div>
                      <div className="instructions has-btns">
                        <Button variant="outlined" className="xs-btn">
                          <input aria-label="upload-other" type="file" className="hidden-input" onChange={(e) => {setRemoveLogo(true); handleImageUpload(e?.target?.files)}} accept='image/png'/>
                          Upload new image
                        </Button>
                        <Button aria-label="remove" variant="contained" className="xs-btn purple-btn" onClick={removeImage}>Remove</Button>
                      </div>
                    </div>
                  </div>
                }
                { error && <span aria-label="error" className="form-error">{ error }</span> }
                <div className="photo-guidelines">
                  {/* <p>Recommended: 232x48 px with transparent background.</p> */}
                  <p>Supported format PNG of Max. size 2MB</p>
                </div>
              </div>
            </div>

            <div className="settings-outer">
              <div className="title-block">
                <div className='left-title w-100'>
                  <h5>Sub domain</h5>
                  <p>Enter the domain.</p>
                </div>
              </div>

              <div className="domain-wrapper">
                <div className="form-group m-0">
                  <FormControl variant="filled">
                    {/* @ts-ignore */}
                    <FilledInput type="text" className='no-label' variant="filled" defaultValue={ subdomain ?? 'enterprise' } disabled/>
                  </FormControl>
                </div>
                <span className="existing-url">.builder.ai</span>
              </div>
            </div>

            <div className="settings-outer">
              <div className="title-block">
                <div className='left-title w-100'>
                  <h5>Choose a colour theme <span className="custom-chip">Coming soon</span></h5>
                  <p>Morem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </div>
              </div>
              <div className="color-checkbox-wrapper">
                { colorThemes.map((theme, i) => (
                  <div className="checkbox-outer" key={`theme_${i}`}>
                    <input data-testid="radio-input" type="radio" name='theme-color' className="hidden-input" checked={theme?.isChecked} onChange={() => getTheme(theme)}/>
                    <em className='be-tick-square tickmark'></em>
                    <span className='inner-box' style={{ backgroundColor: theme?.color }}></span>
                    <span className="outer-border" style={{ borderColor: theme?.color }}></span>
                  </div>
                )) }
              </div>
            </div>

            <div className="settings-outer">
              <div className="title-block">
                <div className='left-title'>
                  <h5>Two-factor authentication (2FA) <span className="custom-chip">Coming soon</span></h5>
                  <p>Qorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </div>
                <div className="right-title">
                  <Switch inputProps={{'aria-label': "authToggleSwitch"}} onChange={authToggle} />
                </div>
              </div>
              <div className="radio-wrapper">
                <FormGroup>
                  <div className="row">
                    <div className="col-md-6">
                      <FormControlLabel className={'radio-control box-radio top-align ' + (smsCheckboxChecked ? 'checked' : '') + (!switchChecked ? 'disabled' : '')} value="sms" control={<Checkbox
                        icon={<img src={images.checkboxUnchecked} alt='' />}
                        checkedIcon={<img src={images.checkboxChecked} alt='' />}
                        inputProps={{'aria-label': "smsCheck"}}
                        onChange={smsCheckboxChange}
                        checked={smsCheckboxChecked}
                        disabled={!switchChecked}
                      />} label={
                        <div className='radio-content'>
                          <strong>SMS</strong>
                          <div className="sub-content">
                            <p>Morem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                          </div>
                        </div>
                      } />
                    </div>
                    <div className="col-md-6">
                      <FormControlLabel className={'radio-control box-radio top-align ' + (authAppCheckboxChecked ? 'checked' : '') + (!switchChecked ? 'disabled' : '')} value="auth-app" control={<Checkbox
                        icon={<img src={images.checkboxUnchecked} alt='' />}
                        checkedIcon={<img src={images.checkboxChecked} alt='' />}
                        inputProps={{'aria-label': "authCheck"}}
                        onChange={authAppCheckboxChange}
                        checked={authAppCheckboxChecked}
                        disabled={!switchChecked}
                      />} label={
                        <div className='radio-content'>
                          <strong>Authentication App</strong>
                          <div className="sub-content">
                            <p>Dorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                          </div>
                        </div>
                      } />
                    </div>
                  </div>
                </FormGroup>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Logo cropper modal starts */}
      <Dialog
        open={uploadLogoDialog}
        onClose={handleModalClose}
      >
        <DialogContent>
          {/* <span className="modal-close be-close" onClick={(e) => setUploadLogoDialog(false)}></span> */}
          <div className="modal-body" data-testid="close-modal-btn">
            <div className="modal-title">
              <h2 aria-label="modal-heading">Crop your image then click add</h2>
            </div>

            <div className="logo-cropping-block">
              <ReactCrop
                crop={crop}
                onChange={(c) => setCrop(c)}
                onComplete={(c) => setCompletedCrop(c)}
                maxWidth={232}
                maxHeight={48}
                ruleOfThirds
              >
                <img data-testid="react-crop" src={filename} onLoad={onLoad} ref={imgRef} alt="cropped-comp-img" crossOrigin="anonymous" />
              </ReactCrop>
              <div className="cropped-logo">
                <canvas
                  aria-label='canvas'
                  ref={previewCanvasRef}
                  style={{
                    width: Math.round(completedCrop?.width ?? 0),
                    height: Math.round(completedCrop?.height ?? 0)
                  }}
                />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="fixed-footer-wrap">
          <div className="fixed-full-footer">
            <Button data-testid="close-btn" variant="contained" className='grey-btn sm-btn' onClick={(e) => setUploadLogoDialog(false)}>Cancel</Button>
            <Button data-testid="add-btn" variant="contained" className='sm-btn' disabled={!completedCrop?.width || !completedCrop?.height} onClick={() => generateCroppedImage(previewCanvasRef.current, completedCrop)}>Add</Button>
          </div>
        </DialogActions>
      </Dialog>
      {/* Logo cropper modal ends */}
    </>
  )
}
