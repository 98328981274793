/* eslint-disable jsx-a11y/anchor-is-valid */
import Button from '@mui/material/Button';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import { useSelector } from 'react-redux';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useState } from 'react';

import { companyState } from '../../shared/store/slices/userSlice';
import { Tooltip } from '@mui/material';
import images from '../../assets/images';

export default function RolesPermissionsTopNavigation({ roleData }) {
  const location = useLocation();
  const compSel = useSelector(companyState);

  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [random, setRandom] = useState(1);
  const [inputVal, setInputVal] = useState('');

  const searchRole = (e) => {
    setInputVal(e?.target?.value);
    roleData(e?.target?.value);
  }

  const clearInput = () => {
    searchRole('');
    setRandom(prev => prev + 1);
  }

  //=============================================================

  return (
    <>
      <div className="main-page-header">
        <div className="page-breacrumb">
          <div className="breadcrumb-wrapper">
            <ul className="breadcrumb-links">
              <li><Link to="/company">Company</Link></li>
              <li><Link to="/company/members">Members</Link></li>
              <li>Roles & permissions</li>
            </ul>
          </div>
          <div className="compnay-logo">
          { compSel?.logo?.logo_url && <img src={compSel?.logo?.logo_url} alt="company logo" /> }
          </div>
        </div>

        <div className="page-title">
          <h2 aria-label="member-heading">Roles & permissions</h2>
        </div>
      </div>

      <div className="page-filters-wrapper align-items-start secondary-header">
        <div className="left-filter">
          <span aria-label="count" className="count">Add a description for Roles & permissions</span>
        </div>
        <div className="right-filter">
          <div className="form-group">
            <FormControl variant="filled">
              {/* @ts-ignore */}
              <FilledInput type="text" variant="filled" className="xs-input search-input-wrap" placeholder='Search role'
              onChange={searchRole}
                startAdornment={
                  <InputAdornment position="start"><em className='be-search'></em></InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end" className='search-icon-wrap'>
                    {inputVal && <img src={images.iconCrossCircle} alt="" onClick={clearInput}/>}
                  </InputAdornment>                        
                }
                key={random}
              />
            </FormControl>
          </div>
          <Tooltip placement='top' title='Coming soon' arrow classes={{ popper: 'info-tooltip' }}>
            <span>
              <Button disabled={true} data-testid="add-member-to-list" variant="contained" className="xs-btn purple-btn">Add custom role</Button>
            </span>
          </Tooltip>
        </div>
      </div>

      <div className="page-top-navigation">
        <ul>
          <li><NavLink data-testid="default-role" className={splitLocation[1] === "company/roles-permissions/default-roles" ? "active" : ""} to='/company/roles-permissions/default-roles'>Default Roles</NavLink></li>
          <li>{true ? 
            <Tooltip placement="top" arrow title="Coming soon" classes={{ popper: 'info-tooltip' }}><span>Custom roles</span></Tooltip>
           : <NavLink className={splitLocation[1] === "company/roles-permissions/custom-roles" ? "active" : ""} to='/company/roles-permissions/custom-roles'>Custom roles</NavLink>}</li>
        </ul >
      </div >
    </>
  )
}
