/* eslint eqeqeq: 0 */
import Button from '@mui/material/Button';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import images from '../../../assets/images';
import AddMemberToProjectDialog from '../../../components/dialog/project-team-dialog/AddMemberToProjectDialog';
import ChangeProjectLeadDialog from '../../../components/dialog/project-team-dialog/ChangeProjectLeadDialog';
import RemoveMemberFromProject from '../../../components/dialog/project-team-dialog/RemoveMemberFromProject';
import instance from '../../../shared/interceptor';
import { currentProjectState, currentSpaceState } from '../../../shared/store/slices/companySlice';
import { companyState, permissionState, userPermissionState, userState } from '../../../shared/store/slices/userSlice';
import { PROJECTS } from '../../../shared/util/constant';
import { debounce, getFullname, getInitials, globalError, isAllowedTo, setLoader, sortListAcc } from '../../../shared/util/util';
import '../project.scss';
import Summary from '../summary';
import ProjectTopNavigation from './ProjectNavigation';


export default function ProjectTeam() {
  const dispatch = useDispatch();
  const currentProjectSel = useSelector(currentProjectState);
  const currentSpaceSel = useSelector(currentSpaceState);
  const permissions = useSelector(permissionState);
  const userPermissions = useSelector(userPermissionState);
  const compSel = useSelector(companyState);
  const userSel = useSelector(userState);

  const [projectOwner, setProjectOwner] = useState(null);
  const [projectMembers, setProjectMembers] = useState([]);
  const [selectedProjectMember, setSelectedProjectMember] = useState(null);
  const [MakeProjectLead, setMakeProjectLead] = useState(false);
  const [RemoveMember, removeMember] = useState(false);
  const [AddMembers, setAddMembers] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [random, setRandom] = useState(1);
  const [loading, setLoading] = useState(false);
  const [sortAsc, setSortAsc] = useState(false);

  useEffect(() => {
    if (currentSpaceSel && currentProjectSel) {
      getAllProjectMembers();
    }
  }, [currentSpaceSel?.id, currentProjectSel?.id, searchText]);

  // const [anchorEl, setAnchorEl] = useState(null);
  // const open = Boolean(anchorEl);
  const [anchorElMember, setAnchorElMember] = useState(null);
  const [openElem, setOpenElem] = useState(null);

  const AddMembersDialogClose = (e, render = false) => {
    if (render) {
      getAllProjectMembers();
    }
    setAddMembers(false);
  };

  const MakeProjectLeadDialogOpen = (member = null) => {
    setSelectedProjectMember(member);
    setMakeProjectLead(true);
  };

  const MakeProjectLeadDialogClose = (e, render = false) => {
    if (render) {
      getAllProjectMembers();
    }
    setMakeProjectLead(false);
  };

  const RemoveMemberDialogOpen = (member) => {
    setSelectedProjectMember(member);
    removeMember(true);
  };

  const RemoveMemberDialogClose = (e, render = false) => {
    if (render) {
      getAllProjectMembers();
    }
    removeMember(false);
  };

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const handleClickMember = (row) => (event) => {
    setAnchorElMember(event.currentTarget);
    setOpenElem(row);
  };

  const getAllProjectMembers = async () => {
    try {
      setLoading(true);
      if (!searchText){
        setLoader(dispatch, true);
      }
      let url = `${PROJECTS.replace(':id', compSel?.id).replace(':space_id', currentSpaceSel?.id)}/${currentProjectSel?.id}/all_members`;
      if (searchText) {
        url += `?q=${searchText}`;
      }
      let memberListRes = await instance.get(url);
      setProjectOwner(memberListRes['project']['owner']);
      setProjectMembers(memberListRes['project']['members']);
    } catch(e) {
      globalError(dispatch, e);
    } finally {
      setLoading(false);
      setLoader(dispatch, false);
    }
  }

  const searchProjectMember = debounce((e) => {
    setSearchText(e.target.value);
  });

  const isAllowedToAddProjectMembers = () => isAllowedTo(
    'update', 
    'project_member', 
    permissions, 
    { id: currentProjectSel?.id, type: 'Project' }, 
    userPermissions
  );

  const clearInput = () => {
    setSearchText('');
    setRandom(prev => prev + 1);
  }

  const toggleSort = () => {
    setSortAsc(!sortAsc);
    let sortedList = sortListAcc(projectMembers, 'first_name', sortAsc);
    setProjectMembers(sortedList);
  }

  //==========================================================
  return (
    <>
      <div className="project-page-wrap project-overview" aria-label='project-team'>
        <div className="page-container top-space pb-0">
          <ProjectTopNavigation />

          <section>
            <div className='page-content'>
              <div className="page-filters-wrapper">
                <div className="left-filter">
                  <span aria-label="count" className="count">Project Members ({(Object.keys(projectOwner ?? {}).length) ? projectMembers?.length + 1 : projectMembers?.length})</span>
                </div>
                <div className="right-filter">
                  <div className="form-group search-wrap">
                    {/* <span className="dropdown-text">Only active <em className='icon be-chevron-down'></em></span> */}
                    <FormControl variant="filled">
                      {/* @ts-ignore */}
                      <FilledInput onChange={searchProjectMember} inputProps={{ 'data-testid': "searchProjects" }} type="text" variant="filled" className="xs-input search-input-wrap" placeholder='Search members'
                        startAdornment={
                          <InputAdornment position="start"><em className='be-search'></em></InputAdornment>
                        }
                        endAdornment={
                          <InputAdornment position="end" className='search-icon-wrap'>
                            {searchText && <img src={images.iconCrossCircle} alt="" onClick={clearInput}/>}
                          </InputAdornment>                        
                        }
                        key={random}
                      />
                    </FormControl>
                  </div>
                  {isAllowedToAddProjectMembers() && <Button data-testid='add-member-btn' variant="contained" className="xs-btn grey-btn btn-add-members" onClick={() => setAddMembers(true)}>Add members</Button>}
                </div>
              </div>              

              {/* Table content start */}
              <div className="table-wrapper table-responsive">
                {(!projectMembers?.length && !Object.keys(projectOwner ?? {})?.length && !loading) && <div className="p-4">No member found.</div>}
                {(projectMembers?.length > 0 || Object.keys(projectOwner ?? {})?.length > 0) && <TableContainer>
                  <Table>   
                  <TableHead>
                      <TableRow>
                        <TableCell>Name <em data-testid='sort-elem' className="cursor-pointer" onClick={toggleSort}>{sortAsc ? <span className='icon be-left-arrow move-up'></span> : <span className='icon be-left-arrow move-down'></span>}</em></TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Role</TableCell>                        
                        <TableCell>&nbsp;</TableCell>
                      </TableRow>
                    </TableHead>                 
                    <TableBody>
                    { loading && <tr><td><img src={(images as any).buAnim} alt="B" style={{ position: 'fixed', top: '50%', left: '50%', height: '80px', width:'80px', transform: 'translate(-50%, -50%)' }}/></td></tr> }
                      { !loading && projectOwner && Object.keys(projectOwner).length > 0 && <TableRow>
                        <TableCell className='text-nowrap align-left'>
                          <div className='user-link'>
                            <span className='user-avatar'>
                              {projectOwner?.profile_picture?.profile_picture_url ? <img src={ projectOwner?.profile_picture?.profile_picture_url } alt="profile pic" /> : 
                              getInitials(getFullname(projectOwner?.first_name, projectOwner?.last_name))}
                            </span>
                            <span className="user-name">{ getFullname(projectOwner?.first_name, projectOwner?.last_name) }</span>                            
                            {/* TODO: Favourite(star) button to be enabled later
                            <Tooltip enterTouchDelay={0} title={
                              <div className='tooltip-content'>Project lead</div>
                            }>
                              <Button className='xs-btn purple-btn btn-star'><em className='icon be-star'></em></Button>
                            </Tooltip> */}
                          </div>
                        </TableCell>
                        <TableCell className='text-nowrap'>
                          { projectOwner?.email }
                        </TableCell>
                        <TableCell className='text-nowrap'>
                          Project Owner
                        </TableCell>                        
                        <TableCell className='text-nowrap'>
                          {/* <div className='three-dots'>
                            <em className='icon be-vertical-dots' onClick={handleClick}></em>                          
                            <Menu
                              id="basic-menu"
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                              MenuListProps={{
                                'aria-labelledby': 'basic-button',
                              }}
                            >
                              <MenuItem className='toggle-menu-item-row' onClick={MakeProjectLeadDialogOpen}><em className='icon be-star'></em> Make Project Lead</MenuItem>
                            </Menu>
                          </div> */}
                        </TableCell>
                      </TableRow>}

                      { !loading && projectMembers.map(member => {
                        return <TableRow key={member?.id}>
                          <TableCell className='text-nowrap align-left'>
                            <div className='user-link'>
                              <span className='user-avatar'>
                                {member?.profile_picture?.profile_picture_url ? <img src={ member?.profile_picture?.profile_picture_url } alt="profile pic" /> : 
                                getInitials(getFullname(member?.first_name, member?.last_name))}
                              </span>
                              <span className="user-name">{ getFullname(member?.first_name, member?.last_name) }</span>
                            </div>
                          </TableCell>
                          <TableCell className='text-nowrap'>
                            { member?.email }
                          </TableCell>
                          <TableCell className='text-nowrap'>
                            Member
                          </TableCell>                        
                          <TableCell className='text-nowrap'>
                          { (userSel?.role?.name == 'CIO' || currentSpaceSel?.owner?.id == userSel?.id || currentProjectSel?.owner?.id == userSel?.id) && <div className='three-dots'>
                              <em className='icon be-vertical-dots' data-testid="open-menu-items" onClick={handleClickMember(member)}></em>                          
                              <Menu
                                id="basic-menu"
                                anchorEl={anchorElMember}
                                open={ openElem === member }
                                onClose={() => { setAnchorElMember(null); setOpenElem(null) }}
                                MenuListProps={{
                                  'aria-labelledby': 'basic-button',
                                }}
                              >
                                {(userSel?.role?.name == 'CIO' || currentSpaceSel?.owner?.id == userSel?.id) && 
                                  <MenuItem data-testid="open-project-lead-dialog" className='toggle-menu-item-row' onClick={() => MakeProjectLeadDialogOpen(member)}><em className='icon be-star'></em> Make Project Lead</MenuItem>
                                }
                                <MenuItem data-testid="remove-member-dialog" className='toggle-menu-item-row' onClick={() => RemoveMemberDialogOpen(member)}><img src={images.deleteIcon} alt='Delete icon' /> Remove Member</MenuItem>
                              </Menu>
                            </div>
                          }
                          </TableCell>
                        </TableRow>
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>}
              </div>
              {/* Table content end */}

            </div>

            <Summary tab={"Project"}></Summary>

          </section>

        </div>
      </div>

      {/* Add members dialog start */}
      <AddMemberToProjectDialog {...{ AddMembers, AddMembersDialogClose }}/>
      {/* Add members dialog end */}

      {/* change project lead dialog start */}
      <ChangeProjectLeadDialog {...{ MakeProjectLead, MakeProjectLeadDialogClose, selectedProjectMember }}/>
      {/* change project lead dialog end */}

      {/* Remove member dialog start */}
      <RemoveMemberFromProject {...{ RemoveMember, RemoveMemberDialogClose, selectedProjectMember }}/>
      {/* Remove member dialog end */}

    </>
  )
}