/* eslint eqeqeq: 0 */
import Button from '@mui/material/Button';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';

import AddGoalDialog from '../../../components/dialog/space-project-dialog/AddGoalDialog';
import CreateSpaceProjectDialog from '../../../components/dialog/space-project-dialog/CreateSpaceProjectDialog';
import instance from '../../../shared/interceptor';
import { getCurrentSpaceDetail, getProjectDetail } from '../../../shared/services/common.service';
import { currentProjectState, currentSpaceState } from '../../../shared/store/slices/companySlice';
import { companyState, permissionState, userPermissionState, userState } from '../../../shared/store/slices/userSlice';
import { ON_BOARDING_WAITING_PAYMENT, PROJECTS, SPACE_LIST } from '../../../shared/util/constant';
import { globalError, isAllowedTo, setLoader } from '../../../shared/util/util';
import '../project.scss';
import './ProjectNavigation.scss';


export default function ProjectTopNavigation() {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const compSel = useSelector(companyState);
  const userSel = useSelector(userState);
  const currentSpaceSel = useSelector(currentSpaceState);
  const currentProjectSel = useSelector(currentProjectState);
  const permissions = useSelector(permissionState);
  const userPermissions = useSelector(userPermissionState);
  const navigate = useNavigate();
  const params = useParams();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [CreateProject, setCreateProject] = useState(false);
  const [AddNewGoal, setAddNewGoal] = useState(false);
  const [projectMembers, setProjectMembers] = useState([]);

  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  useEffect(() => {
    if ((currentSpaceSel?.id != params?.id) || (currentProjectSel?.id != params?.project_id)) {
      getCurrentSpaceDetail(dispatch, compSel?.id, params?.id);
      getProjectDetail(dispatch, compSel?.id, params?.id, params?.project_id);
    }
  }, [params?.id, params?.project_id]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    getAllProjectMembers(signal);

    return () => {
      controller.abort();
    }
  }, [params?.id, params?.project_id])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openProjectEdit = () => {
    setCreateProject(true);
    setAnchorEl(null);
  }

  const CreateProjectDialogClose = async (e) => {
    setCreateProject(false);
  };

  const AddNewGoalDialogOpen = () => {
    setAddNewGoal(true);
  };

  const AddNewGoalDialogClose = () => {
    setAddNewGoal(false);
  };

  const navigateToPlayWelcome = () => {
    const routeTo = `/company/spaces/${currentSpaceSel?.id}/project/${currentProjectSel?.id}/playground-welcome`;
    navigate(routeTo);
  }

  const getAllProjectMembers = async (signal?) => {
    try {
      setLoader(dispatch, true);
      let url = `${PROJECTS.replace(':id', compSel?.id).replace(':space_id', params?.id)}/${params?.project_id}/all_members`;
      let memberListRes = await instance.get(url, { signal });
      if (memberListRes?.['project']?.['members']) {
        setProjectMembers(memberListRes['project']['members']);
      }
    } catch(e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  }

  //====================================================
  return (
    <>
      <div className="main-page-header">
        <div className="page-breacrumb">
          <div className="breadcrumb-wrapper">
            <ul className="breadcrumb-links">
              <li><Link to={ON_BOARDING_WAITING_PAYMENT}>Building</Link></li>
              <li>{isAllowedTo('update', 'space', permissions) ? <Link to={SPACE_LIST}>Spaces</Link> : 'Spaces'}</li>
              <li><Link to={`/company/spaces/${currentSpaceSel?.id}/overview`}>{currentSpaceSel?.name}</Link></li>
              <li>{currentProjectSel?.name}</li>
            </ul>
          </div>
          <div className="compnay-logo">
            {compSel?.logo?.logo_url && <img src={compSel?.logo?.logo_url} alt="company logo" />}
          </div>
        </div>

        <div className="page-title">
          <h2 aria-label="member-heading">{currentProjectSel?.name}</h2>
        </div>
        <div className="page-filters-wrapper align-items-start">
        <div className="left-filter">
          <span aria-label="count" className="count">{currentProjectSel?.description}</span>
        </div>
      </div>
      </div>

      


      <div className="projects-top-nav-wrapper">
        <div className="page-top-navigation">
          <ul>
            <li><NavLink className={splitLocation[1] === "project/overview" ? "active" : ""} to={`/company/spaces/${currentSpaceSel?.id}/project/${currentProjectSel?.id}/overview`}>Overview</NavLink></li>
            {isAllowedTo('read', 'project_member', permissions, {id: currentProjectSel?.id, type: 'Project'}, userPermissions) && <li><NavLink className={splitLocation[1] === "project/team" ? "active" : ""} to={`/company/spaces/${currentSpaceSel?.id}/project/${currentProjectSel?.id}/team`}>Team</NavLink></li>}
            <li><NavLink className={splitLocation[1] === "project/activity" ? "active" : ""} to={`/company/spaces/${currentSpaceSel?.id}/project/${currentProjectSel?.id}/activity`}>Activity</NavLink></li>
            <li><NavLink className={splitLocation[1] === "project/meetings" ? "active" : ""} to={`/company/spaces/${currentSpaceSel?.id}/project/${currentProjectSel?.id}/meetings`}>Meetings</NavLink></li>
          </ul>
        </div>
          <div className="page-filters-wrapper m-0">
            <div className="right-filter full-width justify-right">
              {/* { isAllowedToAdd() && <Button variant="outlined" className="xs-btn is-icon-btn be-calendar-plus"></Button>} */}              
              {isAllowedTo('update', 'buildcard', permissions, { id: currentSpaceSel?.id, type: 'Space' }, userPermissions) && <Button variant="contained" className="sm-btn purple-btn btn-full" onClick={navigateToPlayWelcome}>New project idea</Button>}
              {isAllowedTo('update', 'project', permissions, { id: currentProjectSel?.id, type: 'Project' }, userPermissions) && <Button data-testid="add-member-to-list" variant="contained" className="xs-btn grey-btn square-btn-three-dots" onClick={handleClick}>
                <em className='icon be-vertical-dots'></em>
              </Button>}
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                className='custom-dropmenu action-menu-dropdown'
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem className='menu-item' onClick={openProjectEdit}><em className='icon be-edit'></em> Edit</MenuItem>
              </Menu>
            </div>
          </div>
      </div>

      {/* Edit project modal start */}
      { CreateProject && <CreateSpaceProjectDialog {...{ CreateProject, CreateProjectDialogClose, AddNewGoalDialogOpen, action: 'Edit' }} />}
      {/* Edit project modal end */}

      {/* Add new goal modal start */}
      {AddNewGoal && <AddGoalDialog {...{ AddNewGoal, AddNewGoalDialogClose }} />}      
      {/* Add new goal modal end */}
    </>
  )
}