import Button from '@mui/material/Button';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useNavigate } from 'react-router';
import images from '../../assets/images';
import { userState } from '../../shared/store/slices/userSlice';
import { getFullname, getInitials, sortListAcc } from '../../shared/util/util';


function SpaceTeamListTable({teamList, spaceOwner, loading}) {
    const [sortAsc, setSortAsc] = useState(false);
    const [spaceTeamList, setSpaceTeamList] = useState(teamList ?? []);
    const userSel = useSelector(userState);
    const navigate = useNavigate();

    const [anchorSl, setAnchorSl] = useState(null);

    useEffect(() => {
        setSpaceTeamList(teamList);
    }, [teamList.length]);

    const handleClick2 = (idx, event) => {
        setAnchorSl({[idx]: event.currentTarget});
    };

    const handleClose = () => {
        setAnchorSl(null);
    };

    const navigateToProject = (spaceId, projectId) => {
        navigate(`/company/spaces/${spaceId}/project/${projectId}/overview`);
    }

    const toggleSort = () => {
        setSortAsc(!sortAsc);
        let sortedList = sortListAcc(teamList, 'first_name', sortAsc);
        setSpaceTeamList(sortedList);
    }

  //=====================================================

    return (
        <TableContainer>
            <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Name <em className="cursor-pointer" onClick={toggleSort}>{sortAsc ? <span className='icon be-left-arrow move-up'></span> : <span className='icon be-left-arrow move-down'></span>}</em></TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell>Project Lead</TableCell>
                    {/* <TableCell>&nbsp;</TableCell> */}
                </TableRow>
            </TableHead>
            <TableBody>
                {!loading && spaceOwner && Object.keys(spaceOwner).length > 0 && <TableRow>
                    <TableCell className='text-nowrap'>
                        <div className='user-link'>
                        {/* if user image not available */}
                        {/* <span className='initials'>
                            AC
                        </span> */}
                        <span className='user-avatar'>
                            {spaceOwner?.profile_picture?.profile_picture_url ? <img src={spaceOwner?.profile_picture?.profile_picture_url} alt="profile url" /> : 
                            getInitials(getFullname(spaceOwner?.first_name, spaceOwner?.last_name))}
                        </span>
                        <span className="user-name">{getFullname(spaceOwner?.first_name, spaceOwner?.last_name) ?? '-'}</span>&nbsp;{userSel?.id == spaceOwner?.id && <span>(You)</span>}
                        </div>
                    </TableCell>
                    <TableCell className='text-nowrap'>
                        {spaceOwner?.email}
                    </TableCell>
                    <TableCell className='text-nowrap'>
                        Space Owner
                    </TableCell>
                    <TableCell className='text-nowrap'>
                        { spaceOwner?.project_lead ? 'Yes' : 'No'}
                        { spaceOwner?.owned_projects?.length > 0 && 
                        <Tooltip 
                            classes={{ popper: 'info-tooltip capt-first-letter stack-menu' }} 
                            enterTouchDelay={0} 
                            placement='bottom-end'
                            title={
                              <Stack direction="row" className='purple-chips' spacing={1}>{ spaceOwner?.owned_projects?.map(item => <Chip key={item?.id} label={ item?.name } />)}</Stack>
                            }                            
                        >
                        <em className='icon be-info'></em>
                        </Tooltip>}
                    </TableCell>

                    {/* <TableCell className='text-nowrap'>
                        { spaceOwner?.project_lead && spaceOwner?.owned_projects?.length > 0 && <div className='three-dots'>
                            <em className='icon be-vertical-dots' onClick={(e) => handleClick2(-1, e)}></em>
                            <Menu
                                id="basic-menu"
                                className='three-dot-menu'
                                anchorEl={anchorSl && anchorSl[-1]}
                                open={Boolean(anchorSl && anchorSl[-1])}
                                onClose={handleClose}
                                anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                                }}
                                transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                                }}
                                MenuListProps={{
                                'aria-labelledby': 'basic-button',
                                }}
                            >
                                { spaceOwner?.owned_projects?.map(item => <MenuItem key={item?.id} className='toggle-menu-item-row' onClick={handleClose}>
                                    <Button onClick={() => navigateToProject(item?.space_id, item?.id)} data-testid="add-space-to-list" variant="contained" className="xs-btn purple-btn">{item?.name}</Button>
                                </MenuItem>)}
                            </Menu>
                        </div>}
                    </TableCell> */}
                </TableRow>}
                { !loading && spaceTeamList?.map((team, index) => {
                    return (
                        <TableRow key={team?.id}>
                            <TableCell className='text-nowrap'>
                                <div className='user-link'>
                                {/* if user image not available */}
                                {/* <span className='initials'>
                                    AC
                                </span> */}
                                <span className='user-avatar'>
                                    {team?.profile_picture?.profile_picture_url ? <img src={team?.profile_picture?.profile_picture_url} alt="" /> : 
                                    getInitials(getFullname(team?.first_name, team.last_name)) }
                                </span>
                                <span className="user-name">{getFullname(team?.first_name, team.last_name) ?? '-'}</span>&nbsp;
                                </div>
                            </TableCell>
                            <TableCell className='text-nowrap'>
                                {team?.email}
                            </TableCell>
                            <TableCell className='text-nowrap'>
                                Member
                            </TableCell>
                            <TableCell className='text-nowrap'>
                                {team?.project_lead ? 'Yes': 'No'}
                                { team?.owned_projects?.length > 0 && <Tooltip placement='bottom-end' enterTouchDelay={0} classes={{ popper: 'info-tooltip capt-first-letter stack-menu' }}  title={
                                 <Stack direction="row" className='purple-chips' spacing={1}>{ team?.owned_projects?.map(item => <Chip key={item?.id} label={ item?.name } />)}</Stack>
                                }>
                                <em className='icon be-info'></em>
                                </Tooltip>}
                            </TableCell>

                            {/* <TableCell className='text-nowrap'>
                                { team?.project_lead && team?.owned_projects?.length > 0 && <div className='three-dots'>
                                    <em className='icon be-vertical-dots' onClick={(e) => handleClick2(index, e)}></em>
                                    <Menu
                                        id="basic-menu"
                                        className='three-dot-menu'
                                        anchorEl={anchorSl && anchorSl[index]}
                                        open={Boolean(anchorSl && anchorSl[index])}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                        }}
                                        MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                        }}
                                    >
                                        {team?.owned_projects?.map( item => <MenuItem key={item?.id} className='toggle-menu-item-row' onClick={handleClose}>
                                            <Button onClick={() => navigateToProject(item?.space_id, item?.id)} data-testid="add-space-to-list" variant="contained" className="xs-btn purple-btn">{ item?.name }</Button>
                                        </MenuItem>)}
                                    </Menu>
                                </div>}
                            </TableCell> */}
                        </TableRow>
                    )
                })}

            </TableBody>
            </Table>
        </TableContainer>
    )
}

export default memo(SpaceTeamListTable);
