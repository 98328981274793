/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint eqeqeq: 0 */
import Tooltip from '@mui/material/Tooltip';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import images from '../../../assets/images';
import AddMemberToBmeetDialog from '../../../components/dialog/bmeet-member-dialog/AddMemberToBmeetDialog';
import instance from '../../../shared/interceptor';
import { getCurrentSpaceDetail, getProjectDetail } from '../../../shared/services/common.service';
import { currentProjectState, currentSpaceState, setBuildcardId } from "../../../shared/store/slices/companySlice";
import { setSuccessMsg } from '../../../shared/store/slices/successSlice';
import { companyState, userState } from '../../../shared/store/slices/userSlice';
import { PROJECTS, SPACES } from '../../../shared/util/constant';
import { globalError, setLoader } from '../../../shared/util/util';
import './playgroundWelcome.scss';

export default function PlaygroundWelcome() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const compSel = useSelector(companyState);
  const currentProjectSel = useSelector(currentProjectState);
  const currentSpaceSel = useSelector(currentSpaceState);
  const userData = useSelector(userState);

  const [frame, setFrame] = useState(false);
  const [callMyTeam, setCallMyTeam] = useState(false);

  useEffect(() => {
    if (!currentSpaceSel || !currentProjectSel) {
      getCurrentSpaceDetail(dispatch, compSel?.id, params?.id);
      if (isProjectIdPresent()) {
        getProjectDetail(dispatch, compSel?.id, params?.id, params?.project_id);
      }
    }
  }, [])

  useEffect(() => {
    const handler = (ev: MessageEvent<{ type: string; buildcard_id: string }>) => {
      if (typeof ev?.data !== "object") return;
      if (!ev?.data?.type) return;
      if (ev?.data?.type !== "buildcard-save") return;
      if (!ev?.data?.buildcard_id) return;
      if (ev?.data?.buildcard_id) {
        console.log("event data", ev?.data);
        dispatch(setBuildcardId(ev.data.buildcard_id));
        console.log(ev.data.buildcard_id);
        saveBuildcardId(ev.data.buildcard_id);
      }
    };

    window.addEventListener("message", handler);

    return () => {
      window.removeEventListener("message", handler);
      setFrame(false);
    };
  }, [currentSpaceSel?.id, currentProjectSel?.id]);

  const saveBuildcardId = async (buildcardId) => {
    try {
      setLoader(dispatch, true);
      const url = `${PROJECTS.replace(':id', compSel?.id).replace(':space_id', currentSpaceSel?.id)}/${currentProjectSel?.id}/build_cards`;
      const payload = {
        build_card: {
          studio_build_card_pid: buildcardId,
          space_id: currentSpaceSel?.id,
        }
      }
      let buildRes = await instance.post(url, payload);
      if (buildRes?.['build_card']) {
        navigate(`/company/spaces/${currentSpaceSel?.id}/project/${currentProjectSel?.id}/playground/${buildRes?.['build_card']['id']}`);
        dispatch(setSuccessMsg('Buildcard saved successfully.'));
      }
    } catch(e) {
      console.log(e)
      let err = e?.['studio_build_card_pid']?.[0] ?? e;
      globalError(dispatch, err);
    } finally {
      setLoader(dispatch, false);
    }
  }

  const callMyTeamDialogClose = () => {
    setCallMyTeam(false);
  };

  const isProjectIdPresent = () => {
    return !!params?.project_id;
  }

  const isOwnerOrMemberSpace = () => {
    let member = currentSpaceSel?.members.some(mem => mem?.id == userData?.id);
    return (member || userData?.id == currentSpaceSel?.owner?.id || userData?.role?.name == 'CIO');
  }

  const getWbUrl = async () => {
    try {
      if (!isOwnerOrMemberSpace()) {
        return globalError(dispatch, {message: 'You are not the owner/member of the space'});
      }
      setLoader(dispatch, true);
      const url = `${SPACES.replace(':id', compSel?.id)}/${currentSpaceSel?.id}/whiteboards`;
      let res = await instance.post(url, null);
      navigate(`/company/spaces/${currentSpaceSel?.id}/whiteboard-playground/${res?.['whiteboard']?.id}`);
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  }

  //================================================================
  return (
    <>
      <section className="natasha-welcome has-header playground-welcome-page" aria-label="playground-welcome-page">
        <div className="inner-content">
          <span className="be-close p-2 m-5 float-end" onClick={() => navigate(-1)}></span>

          <div className={`welcome-content ${isProjectIdPresent() ? '': 'has-two-options'}`}>
            <div className="container xs">
              <div className="flex-wrapper">
                <em className="welcome-logo"><img src={images.natashaLogo} alt="" /></em>
                <div className="option-selection">
                    {isProjectIdPresent() ? <p className='title'>Welcome to the playground! <br />How do you want to work on your idea?</p> : <p className='title'>Welcome to the idea board! <br />How do you want to work on your idea?</p>}
                    <div className="link-button-wrap">
                      <Link to='' className='link-button' onClick={getWbUrl}>
                        <em className="support-avatar"><img src={images.createWhiteboard} alt="" /></em>
                        <div className="title">Create <br/>whiteboard</div>
                      </Link>
                    {isProjectIdPresent() && <Link to='' className="link-button" onClick={()=> setCallMyTeam(true)}>
                      <em className="support-avatar"><img src={images.callTeam} alt="" /></em>
                      <div className="title">Call my <br/>team</div>
                    </Link>}
                    <Link to='' className={`link-button ${isProjectIdPresent() ? '' : 'static'}`} data-testid="open-iframe" onClick={() => isProjectIdPresent() ? setFrame(true) : null}>
                      <em className="support-avatar"><img src={images.openStudio} alt="" /></em>
                      <div className="title">Open <br/>studio</div>
                    </Link>
                    {isProjectIdPresent() && <Tooltip arrow  placement='bottom' title="Coming soon" classes={{ popper: 'info-tooltip' }}>
                      <Link to='' className="link-button static">
                        <em className="support-avatar icon be-upload-document"></em>
                        <div className="title">Upload <br/>document</div>
                      </Link>
                    </Tooltip>}
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
      { callMyTeam && <AddMemberToBmeetDialog {...{ callMyTeam, callMyTeamDialogClose }}/> }

      {frame && <iframe aria-label="iframe-label" title="studio application" src={`${process.env.REACT_APP_STUDIO_URL}/home?is_source_enterprise_surface=true`} className="studio-iframe"/>}
    </>
  )
}
