import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from '@mui/material/Tooltip';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import images from '../../assets/images';
import UnauthorizedAccess from '../../components/UnauthorizedAccess';
import { getSpaceCreditData } from '../../shared/services/common.service';
import { currentSpaceState } from '../../shared/store/slices/companySlice';
import { spaceCreditState } from '../../shared/store/slices/spaceCreditSlice';
import { companyState, permissionState, userPermissionState } from '../../shared/store/slices/userSlice';
import { isAllowedTo } from '../../shared/util/util';
import Summary from '../building/summary';
import '../company/credits/credits.scss';
import { buildcardConsumptionGraphData } from '../dashboard/dashboardGraphData';
import SpaceCreditModals from './SpaceCreditModals';
import SpaceTopNavigation from './SupplyChainDistributionNavigation';

export default function SpaceCredits() {
  const dispatch = useDispatch();
  const compSel = useSelector(companyState);
  const currentSpaceSel = useSelector(currentSpaceState);
  const spaceCreditSel = useSelector(spaceCreditState);
  const permissions = useSelector(permissionState);
  const userPermissions = useSelector(userPermissionState);

  const [openCreditRequestHistoryDialogue, setOpenCreditRequestHistoryDialogue] = useState(false);
  const [openRequestCreditDialogue, setOpenRequestCreditDialogue] = useState(null);
  const [spaceCreditOverview, setSpaceCreditOverview] = useState(null);
  const [buildcardConsumption, setBuildcardConsumption] = useState(null);
  const [buildcardEstimation, setBuildcardEstimation] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const pendingSpaceCreditRequest = spaceCreditSel?.['spaceCredit']?.[0]?.['space_credit']?.['pending_space_credit_request'];

  const entity = { id: currentSpaceSel?.id, type: 'Space' }

  useEffect(() => {
    if (currentSpaceSel?.active_space_credit_id) {
      getSpaceCreditData(compSel, currentSpaceSel, dispatch);
    }
  }, [currentSpaceSel?.active_space_credit_id])
  
  useEffect(() => {
    setSpaceCreditOverview(spaceCreditSel?.['spaceCredit']?.[0]?.['space_credit']);
    setBuildcardConsumption(spaceCreditSel?.['spaceCredit']?.[1]);
    setBuildcardEstimation(spaceCreditSel?.['spaceCredit']?.[2]);
  }, [pendingSpaceCreditRequest?.id, pendingSpaceCreditRequest?.credit_requested, spaceCreditSel?.['spaceCredit']?.length])

  const handleClickOpen = () => {
    setOpenCreditRequestHistoryDialogue(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
};

  //=============================================================================
  return (
    isAllowedTo('read', 'space_credit', permissions, entity, userPermissions) ? <>
      <div className="credits-page spaces-credits-page" aria-label="space-credits">
        <div className="page-container top-space pb-0">
          <SpaceTopNavigation />
          <div className="main-content-wrap full-width">
            <section className="overview-inner-section">
              <div className='page-content'>

                {/* No data wrapper  */}
                {!spaceCreditOverview?.allocated_credits && <div className="no-data-wrapper mt-0">
                  <em className="thumb"><img src={images.emptyStateIllustration} alt="Flag" /></em>
                  <h4 aria-label="no-space">No Credits yet</h4>
                  <p>Your space has not been allocated with any credits yet.</p>
                  <Button variant="contained" className="sm-btn" onClick={() => setOpenRequestCreditDialogue({})}>Request Credits</Button>
                </div>}

                {/* Space credit overview */}
                {spaceCreditOverview?.allocated_credits > 0 && <div className="section-row">
                  <div className="current-allocations card">
                    <div className="title-wrap">
                      <h4>Space credit overview</h4>
                      <div className="button-wrap">
                        {spaceCreditOverview?.request_history ? <Tooltip arrow placement='top' title="Request history" classes={{ popper: 'info-tooltip' }}>
                          <Button variant="outlined" className="xs-btn is-icon-btn icon" onClick={handleClickOpen}><img src={images.iconHistory} alt="Request history" /></Button>
                        </Tooltip> : 
                        <Tooltip arrow placement='top' title="No request history" classes={{ popper: 'info-tooltip' }}>
                          <span>
                            <Button variant="outlined" disabled className="xs-btn is-icon-btn icon"><img src={images.iconHistory} alt="Request history" /></Button>
                          </span>
                        </Tooltip>}
                        
                        <Button variant="contained" className="xs-btn purple-btn" onClick={() => setOpenRequestCreditDialogue(spaceCreditOverview)}>Request Credits</Button>
                      </div>
                    </div>
                    <div className="progress-wrap">
                      <Box sx={{ width: '100%' }}>
                        <LinearProgress variant="determinate" value={(spaceCreditOverview?.consumed_credits / spaceCreditOverview?.allocated_credits) * 100} />
                      </Box>
                    </div>
                    <div className="count-wrap">
                      <div className="left">
                        <span><em className="square-dot purple"></em><strong>{spaceCreditOverview?.consumed_credits}</strong>&nbsp; Credits Consumed</span>
                        <span><em className="square-dot"></em><strong>{spaceCreditOverview?.credit_balance}</strong>&nbsp; Credit balance</span>
                      </div>
                      <div className="right">
                        <span>Total credits: <strong>&nbsp;{spaceCreditOverview?.allocated_credits}</strong></span>
                      </div>
                    </div>
                  </div>
                </div>}

                {/* Active Buildcards consumption */}
                { buildcardConsumption?.total_build_cards_count > 0 && <div className="section-row">
                  <div className="current-allocations card">
                    <div className="title-wrap">
                      <h4>Active Buildcards consumption</h4>
                      <span>{buildcardConsumption?.total_build_cards_count} Buildcards</span>
                    </div>
                    <div className="graph-wrap">
                      <HighchartsReact highcharts={Highcharts} options={buildcardConsumptionGraphData(buildcardConsumption)}/>
                      {/* <img src={(images as any).creditsGrapgh} alt="credits grapgh" /> */}
                    </div>
                  </div>
                </div>}

                {/* Draft Buildcards Estimated consumption */}
                <div className="section-row">
                  <div className="current-allocations card">
                    <div className="title-wrap">
                      <h4>Draft Buildcards Estimated consumption</h4>
                      <span className="credit-consuption">Total estimated credits consumption: <span className="warning">{buildcardEstimation?.total_estimated_credits}</span></span>
                    </div>
                    <div className="credits-list">
                      {buildcardEstimation?.build_cards?.map((item, idx) => <div className="list-content" key={item?.id}>
                        <div className="title-wrap">
                          <h5>{item?.name}</h5>
                          {item?.space_goals?.length > 0 && <div className="tag-wrapper">
                            <span className="custom-tag">{item?.space_goals?.[0]?.name}</span>
                            {item?.space_goals?.length > 1 && <span className="custom-tag" onClick={(event) => setAnchorEl({[idx]: event.currentTarget}) }>+{item?.space_goals?.length - 1}</span>}
                          </div>}
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl?.[idx]}
                            open={Boolean(anchorEl?.[idx])}
                            onClose={handleClose}
                            MenuListProps={{
                            'aria-labelledby': 'basic-button',
                            }}
                            anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                            }}
                            transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                            }}
                          >
                            {item?.space_goals?.slice(1, item?.space_goals?.length)?.map(goal => <MenuItem key={goal?.id} className='custom-tag'>{goal?.name}</MenuItem>)}
                          </Menu>
                        </div>
                        <div className="credit-estimated">
                          <span><strong>{item?.estimated_credits}</strong> Credits usage estimated </span>
                          {item?.over_estimated && <Tooltip placement='top-start' arrow title={'Buildcard estimated credits is going beyond current available space budget'} classes={{ popper: 'info-tooltip estimated-consuption-tooltip' }}>
                            <img className="warning-icon" src={images.iconWarning} alt="" />
                          </Tooltip>}
                        </div>
                        <div className={`${item?.over_estimated ? "warning" : "" } progress-wrap warning`}>
                          <Box sx={{ width: '100%' }}>
                            <LinearProgress variant="determinate" value={item?.over_estimated ? 100 : item?.estimated_credits} />
                          </Box>
                        </div>
                      </div>)}
                    </div>
                  </div>
                </div>

              </div>
              <Summary tab={"Space"}></Summary>
            </section>
          </div>

        </div>
      </div>

      <SpaceCreditModals { ...{ 
        openRequestCreditDialogue, setOpenRequestCreditDialogue,
        openCreditRequestHistoryDialogue, setOpenCreditRequestHistoryDialogue
      } } />
    </> : <UnauthorizedAccess />
  )
}