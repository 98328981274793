/* eslint eqeqeq: 0 */
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import Menu from "@mui/material/Menu";
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import instance from '../interceptor';
import { currentSpaceState } from '../store/slices/companySlice';
import { setSuccessMsg } from '../store/slices/successSlice';
import { companyState, permissionState, userPermissionState } from '../store/slices/userSlice';
import { SPACES } from '../util/constant';
import { globalError, isAllowedTo, setLoader } from '../util/util';


function GoalsTags({ card, idx, spaceGoals, searchSpaceGoalList, updateGoals }) {
  const dispatch = useDispatch();
  const companySel = useSelector(companyState);
  const currentSpace = useSelector(currentSpaceState);
  const permissions = useSelector(permissionState);
  const userPermissions = useSelector(userPermissionState);
  const params = useParams();

  const [anchorGoalEl, setGoalAnchorEl] = useState(null);

  const entity = { id: currentSpace?.id, type: 'Space' }

  const tagBuildcard = async (id, spaceGoal) => {
    try {
      setLoader(dispatch, true);
      const url = `${SPACES.replace(':id', companySel?.id)}/${params?.id}/build_cards/${id}/tag_space_goal`;
      const payload = {
        space_goal_ids: [spaceGoal?.id]
      }
      await instance.post(url, payload);
      updateGoals(spaceGoal, card);
      dispatch(setSuccessMsg('Space goal added to Buildcard successfully.'));
      handleClose();
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  }

  const handleClose = () => {
    setGoalAnchorEl(null);
  }

  const handleClick = (event, idx) => {
    event.preventDefault();
    event.stopPropagation();
    setGoalAnchorEl({[idx]: event.currentTarget});
  };

  //===========================================
  return (
    <div className="icon-btn-wrap">                                            
      <Tooltip placement='top-end' arrow title={"Add goal"} classes={{ popper: 'info-tooltip' }}>
        {isAllowedTo('read', 'space_goal', permissions, entity, userPermissions) && <Button variant="contained" className="btn-goal-rounded" onClick={(e) => handleClick(e, idx)}><em className='icon be-business-goals'></em> {card?.space_goals?.length}</Button>}
      </Tooltip>                                
      <div className="tag-menu-wrapper">
        <Menu
            id="goals-tag-menu"
            className="taglist-menu cards"
            anchorEl={anchorGoalEl?.[idx]}
            open={Boolean(anchorGoalEl?.[idx]) }
            onClose={handleClose}
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
            }}
            transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
            }}
        >
            {card?.space_goals?.length > 0 && <div className='selected-tags'>                                                        
              {card?.space_goals?.map(gol => <Stack key={gol?.id} direction="row" className='purple-chips' spacing={1}>
                <Chip 
                  label={gol?.name}                                          
                  deleteIcon={<span className='icon be-close-dark sm-close'></span>} />
              </Stack>)}
            {/* {!card?.space_goals?.length && <div className="empty-tags-wrapper">
              <p className="empty-text">No goals added yet</p>
            </div>} */}
            </div>}
            <div className="search-container">
              <FormControl variant="filled">                                                        
                <FilledInput type="text" className="xs-input search-tag-input" placeholder='Search space goals...'
                startAdornment={
                    <InputAdornment position="start"></InputAdornment>
                }
                onChange={searchSpaceGoalList}
                onKeyDown={event => {event?.stopPropagation();}}
                />
              </FormControl>
            </div>
            <div style={{maxHeight: '260px'}}>
              <div className='tag-row'>
                {spaceGoals?.map(go => <Stack key={go?.id} direction="row" className='purple-chips' spacing={1}>
                <Chip onClick={() => tagBuildcard(card?.enterprise_id, go)} label={go?.name}/>
                </Stack>)}
              </div>
          </div>
        </Menu>
      </div>
    </div>
  )
}

export default GoalsTags;
