import * as React from 'react';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from 'highcharts/highcharts-more';
import Highcharts from "highcharts/highstock";
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';

import currentDateIndicator from "highcharts/modules/current-date-indicator";
import images from '../../../assets/images';
import ArchiveGoalDialog from '../../../components/dialog/business-goals-dialogue/ArchiveGoalDialog';
import CreateBusinessGoalDialogue from '../../../components/dialog/business-goals-dialogue/CreateBusinessGoalDialog';
import CreateKpiGoalDialog from '../../../components/dialog/business-goals-dialogue/CreateKpiGoalDialog';
import instance from '../../../shared/interceptor';
import GoalDetailCard from '../../../shared/shared-comp/GoalDetailCard';
import { companyState } from '../../../shared/store/slices/userSlice';
import { COMPANY } from '../../../shared/util/constant';
import customPlugin from '../../../shared/util/customPlugin';
import { getColorCodeAndClassForGoals, getFullname, globalError, setLoader, sortListAcc } from '../../../shared/util/util';
import { goalsStatusData, goalsTimeLine } from '../../dashboard/dashboardGraphData';
import '../company.scss';
import './business-goals.scss';

currentDateIndicator(Highcharts);
customPlugin(Highcharts);
HighchartsMore(Highcharts);

export default function BusinessGoalDetails() {
  const params = useParams();
  const dispatch = useDispatch();
  const compSel = useSelector(companyState);
  const navigate = useNavigate();

  const [openDialogueKPI, setOpenDialogueKPI] = useState(false);  
  const [businessDetailsTabValue, setBusinessDetailsTabValue] = useState('Overview');
  const [goalDetail, setGoalDetail] = useState(null);
  const [listKpi, setListKpi] = useState(null);
  const [statusData, setStatusData] = useState(null);
  const [timelineData, setTimelineData] = useState(null);
  const [spaceData, setSpaceData] = useState(null);
  const [openCreateDialogue, setOpenCreateDialogue] = useState(false);
  const [archive, setArchive] = useState(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [expanded, setExpanded] = useState({});
  const [sortAsc, setSortAsc] = useState(false);

  useEffect(() => {
    getGoalDetail();
    getKpis();
    getStatusData();
    getPerformanceData();
    getSpaceList();
  }, []);

  const getGoalDetail = async () => {
    try {
      setLoader(dispatch, true);
      const goalId = params?.goal_id;
      const url = `${COMPANY}/${compSel?.id}/company_goals/${goalId}`;
      let res = await instance.get(url);
      setGoalDetail(res?.['company_goal']);
    } catch(e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  }

  const getKpis = async () => {
    try {
      setLoader(dispatch, true);
      const goalId = params?.goal_id;
      const url = `${COMPANY}/${compSel?.id}/company_goals/${goalId}/kpis`;
      let res = await instance.get(url);
      setListKpi(res?.['kpis']);
    } catch(e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  }

  const getSpaceList = async () => {
    try {
      setLoader(dispatch, true);
      const goalId = params?.goal_id;
      const url = `${COMPANY}/${compSel?.id}/company_goals/${goalId}/spaces`;
      let res = await instance.get(url);
      setSpaceData(res)
    } catch(e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  }

  const getStatusData = async () => {
    try {
      setLoader(dispatch, true);
      const goalId = params?.goal_id;
      const url = `${COMPANY}/${compSel?.id}/company_goals/${goalId}/kpis/status_data`;
      let res = await instance.get(url);
      setStatusData(res);
    } catch(e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  }

  const getPerformanceData = async () => {
    try {
      setLoader(dispatch, true);
      const goalId = params?.goal_id;
      const url = `${COMPANY}/${compSel?.id}/company_goals/${goalId}/kpis/performance_data`;
      let res = await instance.get(url);
      setTimelineData(res?.['kpis'].flat()?.map((kpi, idx) => ({name: getColorCodeAndClassForGoals(kpi?.status, kpi?.archive ?? null)?.class, color: getColorCodeAndClassForGoals(kpi?.status, kpi?.archive ?? null)?.color, low: new Date(kpi?.created_at).getTime(), high: new Date(kpi?.due_date).getTime(), kpi_name: kpi?.name})))
    } catch(e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  }


  const ideaboardTabHandleChange = (event: React.SyntheticEvent, newValue: string) => {
    setBusinessDetailsTabValue(newValue);
  };

  const handleClose = (e, render = null) => {
    setOpenDialogueKPI(false);
    setOpenCreateDialogue(false);
    setArchive(null);
    if (render) {
      getGoalDetail();
      getKpis();
      getStatusData();
      getPerformanceData();
      getSpaceList();
    }
  };

  const handleDotClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const setExpansion = (idx) => {
    if(idx in expanded) {
      setExpanded(prev => ({...prev, [idx]: !prev?.[idx]}));
    } else {
      setExpanded(prev => ({...prev, [idx]: true}));
    }
  }

  const redirect = (space, card) => {
    if ((card?.status == 'ongoing' || card?.status == 'completed' || card?.state == 'ready_for_kickoff') && space?.can_update) {
      window.open(card?.home_url, '_blank');
    } else if ((!card?.status || (card?.status != 'ongoing'  && card?.status != 'completed')) && 
      (card?.state == 'draft' || card?.state == 'pending_approval')) {
      navigate(`/company/spaces/${space?.id}/project/${card?.project_id}/playground/${card.enterprise_id}`);
    }
  }

  const sortSpaceList = () => {
    setSortAsc(!sortAsc);
    setSpaceData({...spaceData, spaces: sortListAcc(spaceData?.spaces, 'name', sortAsc)});
  }

  //================================
  return (
    <>
      <div className="goals-detail-page" aria-label="business-goal-detail-page">
        <div className="page-container top-space">
          <div className="main-page-header">
            <div className="page-breacrumb">
              <div className="breadcrumb-wrapper">
                <ul className="breadcrumb-links">
                  <li><Link to='/company/company-settings'>Company</Link></li>
                  <li><Link to='/company/business-goals'>Business goals</Link></li>
                  <li>{goalDetail?.name}</li>
                </ul>
              </div>
              <div className="compnay-logo">
                { compSel?.logo?.logo_url && <img src={compSel?.logo?.logo_url} alt="company logo" /> }
              </div>
            </div>
            <div className="page-title">
              <div className="title-wrap">
                <h2>{goalDetail?.name}</h2>
                <p className='count'>{goalDetail?.description}</p>
              </div>              
              <div className="right-filter">
                <div className="button-wrap">
                  {/* <Button variant="contained" className="sm-btn purple-btn">Edit</Button> */}
                  <Button variant="contained" className="sm-btn square-btn-three-dots" onClick={(e) => handleDotClick(e)}>
                    <em aria-label="three-dot-btn" className='icon be-vertical-dots' ></em>
                    </Button>
                    <Menu
                      id="basic-goal-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={() => setAnchorEl(null)}
                      MenuListProps={{
                      'aria-labelledby': 'basic-button',
                      }}
                      anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                      }}
                      transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                      }}
                      className={'three-dot-menu-paper'}
                    >
                      <MenuItem data-testid="edit-dialog" className='toggle-menu-item-row' onClick={(e) => {setOpenCreateDialogue(true); setAnchorEl(null)}} ><img src={images.editIcon} alt=""/> Edit</MenuItem>
                      <MenuItem data-testid="archive-dialog" className='toggle-menu-item-row' aria-label="archive-btn" onClick={(e) => {setArchive(goalDetail); setAnchorEl(null)}}><em className='icon be-archive'></em> {goalDetail?.archive ? 'Unarchive' : 'Archive'}</MenuItem>
                    </Menu>
                  
                </div>                
              </div>
            </div>
          </div>
          {/* <div className="page-filters-wrapper align-items-start"></div> */}
          <div className="inner-section-wrap">
            <div className="page-filters-wrapper">
              <div className="left-filter">
                <div className="tab-label-wrapper">
                  <TabContext value={businessDetailsTabValue}>
                    <Box>
                      <TabList onChange={ideaboardTabHandleChange} aria-label="">
                        <Tab label="Overview" value="Overview" />
                        <Tab label="Resources" value="Resources" />
                      </TabList>
                    </Box>
                  </TabContext>
                </div>
              </div>              
            </div>
            <Box className="tab-context-wrapper">
              <TabContext value={businessDetailsTabValue}>
                <TabPanel value="Overview">

                  <GoalDetailCard {...{goalDetail, getGoalDetail}}/>

                  {/* <div className="goal-section">
                    <h4>What's being build for this goal</h4>
                    <div className="no-data-wrapper no-ideas-yet" aria-label="empty-state">
                      <em className="thumb"><img src={images.emptyStateIllustration} alt="Spaces Flag" /></em>
                      <h4 aria-label="no-space">No assigned spaces yet</h4>
                      <p>Assign a space, so your members can work towards this goal.</p>
                      <Button variant="contained" className="sm-btn light-purple-btn">Assign space</Button>
                    </div>
                  </div> */}
                  <div className="goal-section">
                    <div className="title-wrap">
                      <h4>KPIs</h4>
                      <Button variant="contained" className="xs-btn light-purple-btn" onClick={() => setOpenDialogueKPI(true)}>Add KPI</Button>
                    </div>                    
                    
                    {/* No data found - empty state */}
                    {!timelineData?.length && !listKpi?.accomplished?.length &&<div className="no-data-wrapper no-ideas-yet" aria-label="empty-state">
                      <em className="thumb"><img src={images.emptyStateIllustration} alt="Spaces Flag" /></em>
                      <h4 aria-label="no-space">No KPIs yet</h4>
                      <p>Add a KPI to measure your business goal.</p>
                      <Button variant="contained" className="sm-btn light-purple-btn" onClick={() => setOpenDialogueKPI(true)}>Add KPI</Button>
                    </div>}

                    {statusData?.total_count > 0 && <div className="kpi-count">
                      <span>{statusData?.total_count ?? 0}</span> total (<span>{statusData?.accomplished ?? 0}</span> accomplished, <span>{statusData?.on_track ?? 0}</span> on track, <span>{statusData?.progressing ?? 0}</span> progressing, <span>{statusData?.off_track ?? 0}</span> off track, <span>{statusData?.not_started ?? 0}</span> not started)
                    </div>}
                    
                    {timelineData?.length > 0 && <div className="graph-wrapper">
                      <div className="kpi-performance">
                        <div style={{width: '65%'}}>
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={goalsTimeLine(timelineData, '280px')}
                          />
                        </div>
                        <div style={{width: '45%'}}>
                          <HighchartsReact highcharts={Highcharts} options={goalsStatusData(statusData, '280px', true, true)}/>
                        </div>
                        {/* <img src={(images as any).KPIPerformanceGraph} alt="KPI Performance graph"/> */}
                      </div>                                            
                    </div>}
                    <div className="efficiancy-cards-wrapper">
                      <div className="card-section">
                        {listKpi?.not_accomplished?.map(acc => <div key={acc?.id} className={`card ${getColorCodeAndClassForGoals(acc?.status, false)?.class}`}>
                          <h4 onClick={() => navigate(`/company/business-goals/${params?.goal_id}/kpi/${acc?.id}`)}>{acc?.name}</h4>
                          <div className="count">
                            <span className="spaces"><strong>{acc?.spaces?.length ?? 0}</strong>&nbsp;&nbsp;Spaces
                              <ul className="custom-tooltip">
                                {acc?.spaces?.map(sp => <li key={sp?.id}><em className="icon be-multiple-users" style={{backgroundColor: sp?.color}}></em><span>{sp?.name}</span></li>)}
                              </ul>
                            </span>
                            <span className="separator"></span> 
                            <span><strong>{acc?.build_card_count ?? 0}</strong>&nbsp;&nbsp;Buildcards</span>
                          </div>
                          <div className="text-description">
                            {acc?.description}
                          </div>
                          <div className="card-bottom">
                            <span className="target-date">Target {moment(acc?.due_date).format('DD.MM.YYYY')}</span>
                            <span className="status">{acc?.status?.replace('_', ' ')} <em className="dot" style={{backgroundColor: getColorCodeAndClassForGoals(acc?.status, false)?.color}}></em></span>
                          </div>
                        </div>)}
                      </div>
                      {listKpi?.accomplished?.length > 0 && <div className="accomplished-cards">
                        <h3>Accomplished KPIs</h3>
                        <div className="card-section"> 
                          {!listKpi?.accomplished?.length && <p className="epmty-card-text">No accomplished cards yet!</p>}                         
                          {listKpi?.accomplished?.map(acc => <div className="card" key={acc?.id}>
                            <h4 onClick={() => navigate(`/company/business-goals/${params?.goal_id}/kpi/${acc?.id}`)}>{acc?.name}</h4>
                            <div className="count">                                                            
                              <span className="spaces"><strong>{acc?.spaces?.length ?? 0}</strong>&nbsp;&nbsp;Spaces
                                <ul className="custom-tooltip">
                                  {acc?.spaces?.map(sp => <li key={sp?.id}><em className="icon be-multiple-users" style={{backgroundColor: sp?.color}}></em><span>{sp?.name}</span></li>)}
                                </ul>
                              </span>                                                            
                              <span className="separator"></span> 
                              <span><strong>{acc?.build_card_count ?? 0}</strong>&nbsp;&nbsp;Buildcards</span>
                            </div>
                            <div className="text-description">
                              {acc?.description}
                            </div>
                            <div className="card-bottom">
                              <span className="target-date">Target {moment(acc?.due_date).format('DD.MM.YYYY')}</span>
                              <span className="status">Accomplished <img src={images.checkSuccessRounded} alt="icon"/></span>
                            </div>
                          </div>)}
                          
                        </div>
                      </div>}
                    </div>

                    {/* <div className="separator"></div> */}

                    {/* Spaces working toward goal */}
                    {spaceData?.spaces?.length > 0 && <div className="spaces-in-goal-wrapper">
                      <div className="title-wrap">
                        <h3>Spaces working toward this goal</h3>
                        <span className="count">
                          {/* <span className="spaces"><strong>6</strong>&nbsp;&nbsp;Spaces</span> */}
                          <span className="spaces"><strong>{spaceData?.spaces_count}</strong>&nbsp;&nbsp;Spaces
                            <ul className="custom-tooltip">
                              {spaceData?.spaces?.map(space => <li key={space?.id}><em className="icon be-multiple-users" style={{backgroundColor: space?.color ?? '#860DFF'}}></em><span>{space?.name}</span></li>)}
                            </ul>
                          </span>
                          <span className="separator"></span>
                          <span><strong>{spaceData?.build_cards_count}</strong>&nbsp;&nbsp;Buildcards</span>
                        </span>                        
                      </div>
                      <div className="table-wrapper table-responsive accordion-head-table-wrap">
                        <TableContainer>
                          <Table className="outer-table">
                            <TableHead>
                              <TableRow>
                                <TableCell>NAME <em className={`icon ${!sortAsc ? 'be-down-arrow-1' : 'be-down-arrow-1 move-up'}`} aria-label="sort-btn" onClick={sortSpaceList}></em></TableCell>
                                <TableCell className="text-center">KPIS</TableCell>
                                <TableCell className="text-center">BUILDCARDS</TableCell>
                                <TableCell className="text-center">USER CREDITS</TableCell>
                                <TableCell>OWNER</TableCell>
                                <TableCell className="text-center"></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>                              
                              {spaceData?.spaces?.map((space, idx) => <React.Fragment key={space?.id}>
                              <TableRow>
                                <TableCell>
                                  {space?.name?.length > 15 ? <Tooltip placement='top' title={space?.name} style={{'width':'auto'}}>
                                    <>
                                      <em className={`icon be-chevron-down ${expanded?.[idx] ? "toggle-down" : ""} ${!space?.build_cards?.length ? "hidden" : ""}`} onClick={() => space?.build_cards?.length ? setExpansion(idx) : null}></em> <strong className='truncate-name'>{space?.name}</strong>
                                    </>
                                  </Tooltip>
                                  :
                                  <>
                                    <em className={`icon be-chevron-down ${expanded?.[idx] ? "toggle-down" : ""} ${!space?.build_cards?.length ? "hidden" : ""}`} onClick={() => space?.build_cards?.length ? setExpansion(idx) : null}></em> <strong>{space?.name}</strong>
                                  </>
                                  }
                                </TableCell>
                                <TableCell className="text-center">{space?.kpis_count}</TableCell>
                                <TableCell className="text-center">{space?.build_cards_count}</TableCell>
                                <TableCell className="text-center">120</TableCell>
                                <TableCell>
                                {getFullname(space?.owner?.first_name, space?.owner?.last_name)?.length > 15 ? <Tooltip placement='top' title={getFullname(space?.owner?.first_name, space?.owner?.last_name)} style={{'width':'auto'}}>
                                  <span className='truncate-name'>{getFullname(space?.owner?.first_name, space?.owner?.last_name)}</span>
                                </Tooltip>
                                : <span>{getFullname(space?.owner?.first_name, space?.owner?.last_name) ?? '-'}</span>  
                                }                                  
                                </TableCell>
                                <TableCell className="text-center"><Link className="underline" to={`/company/spaces/${space?.id}/overview`}>Details</Link></TableCell>
                              </TableRow>
                              {expanded[idx] ? (
                              <TableRow>
                                <TableCell colSpan={5} className="collapse-table-wrap">                                    
                                  <Table className="inner-table">
                                    <TableBody>
                                      {space?.build_cards?.map(project => <TableRow key={project?.id}>
                                        <TableCell>
                                        {project?.name?.length > 15 ? <Tooltip placement='top' title={project?.name} style={{'width':'auto'}}>
                                          <strong><a className="truncate-name" onClick={() => redirect(space, project)}>{project?.name}</a></strong>
                                          </Tooltip> 
                                          :
                                          <strong><a onClick={() => redirect(space, project)}>{project?.name}</a></strong>
                                        }
                                        </TableCell>
                                        <TableCell className="text-center"></TableCell>
                                        <TableCell className="text-center"></TableCell>
                                        <TableCell className="left-spacing text-center">40</TableCell>
                                        <TableCell className="left-spacing">
                                          {getFullname(project?.owner?.first_name, project?.owner?.last_name)?.length > 15 ? <Tooltip placement='top' title={getFullname(project?.owner?.first_name, project?.owner?.last_name)} style={{'width':'auto'}}>
                                            <span className='truncate-name'>{getFullname(project?.owner?.first_name, project?.owner?.last_name)}</span>
                                          </Tooltip>
                                          :
                                          <span>{getFullname(project?.owner?.first_name, project?.owner?.last_name) ?? '-'}</span>
                                        }
                                        </TableCell>
                                        <TableCell className="text-center"></TableCell>
                                      </TableRow>)}
                                    </TableBody>
                                  </Table>                                    
                                </TableCell>
                              </TableRow> 
                              ) : null}
                              </React.Fragment>)}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </div>}
                    {/* Spaces working toward goal end */}

                  </div>
                </TabPanel>
                {/* Tab content for Resources */}
                <TabPanel value="Resources">                
                  <div className="goal-section resources-tab-section">
                    <h4>Resources</h4>
                    {/* No data found - empty state */}
                    <div className="no-data-wrapper no-ideas-yet" aria-label="empty-state">
                      <em className="thumb"><img src={images.emptyStateIllustration} alt="Spaces Flag" /></em>
                      <h4 aria-label="no-space">No resources yet</h4>
                      <p>Assign a space, so your members can work towards this goal.</p>
                      <Button variant="contained" className="sm-btn grey-btn">Resources</Button>
                    </div>
                  </div>
                </TabPanel>
              </TabContext>
            </Box>
          </div>

        </div>
      </div>

      {/* Create KPI for goal dialogue */}
      {openDialogueKPI && <CreateKpiGoalDialog {...{openDialogueKPI, handleClose}}/>}
      {/* Create KPI for goal dialogue close */}

      {/* Edit business goal dialogue start */}
      {openCreateDialogue && <CreateBusinessGoalDialogue {...{openCreateDialogue, handleClose, goalDetail}}/>}
      {/* Edit business goal dialogue end */}

      {archive && <ArchiveGoalDialog {...{archive, handleClose}}/>}
    </>
  )
}