
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import instance from '../../../shared/interceptor';
import { getCurrentSpaceDetail } from '../../../shared/services/common.service';
import { currentProjectState, currentSpaceState } from '../../../shared/store/slices/companySlice';
import { bmeetUrl, bmeetingUrlState, companyState, permissionState } from '../../../shared/store/slices/userSlice';
import { ON_BOARDING_WAITING_PAYMENT, SPACES, SPACE_LIST } from '../../../shared/util/constant';
import { globalError, isAllowedTo, setLoader } from '../../../shared/util/util';
import '../project.scss';


export default function PlaygroundMeeting() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const compSel = useSelector(companyState);
  const currentSpaceSel = useSelector(currentSpaceState);
  const currentProjectSel = useSelector(currentProjectState);
  const meetingSel = useSelector(bmeetingUrlState);
  const permissions = useSelector(permissionState);

  const [iframeUrl, setiframeUrl] = useState(true);

  useEffect(() => {
    getCurrentSpaceDetail(dispatch, compSel?.id, params?.id);
    if (!meetingSel?.id) {
      getMeetingData();
      // state ? navigate(`/company/spaces/${params?.id}/ideaboard`) : currentProjectSel?.id ? navigate(`/company/spaces/${params?.id}/project/${currentProjectSel?.id}/playground-welcome`) : navigate(-1);
    }
    
  }, [meetingSel?.id, params?.id, params?.meeting_id]);

  useEffect(() => {
    const handler = (ev: MessageEvent<{ type: string; schedule_meeting_id: string }>) => {
      if (typeof ev?.data !== "object") return;
      if (!ev?.data?.type) return;
      if (ev?.data?.type !== "meeting-closed") return;
      if (!ev?.data?.schedule_meeting_id) return;
      if (ev?.data?.schedule_meeting_id) {
        setiframeUrl(null);
        if (state) {
          navigate(`/company/spaces/${params?.id}/ideaboard`);
        } else if (!state && currentProjectSel?.id) {
          navigate(`/company/spaces/${params?.id}/project/${currentProjectSel?.id}/overview`);
        } else {
          navigate(`/company/spaces/${params?.id}/overview`);
        }
      }
    };

    window.addEventListener("message", handler);

    return () => {
      window.removeEventListener("message", handler);
      setiframeUrl(null);
    };
  }, []);

  const getMeetingData = async () => {
    try {
      setLoader(dispatch, true);
      const url = `${SPACES.replace(':id', compSel?.id)}/${params?.id}/meetings/${params?.meeting_id}`;
      let meetingRes = await instance.get(url);
      dispatch(bmeetUrl(meetingRes['meeting']));
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  }

  //===========================================================
  return (
    <div className="meeting-playground-page" aria-label='meeting-playground'>
      <div className="page-container top-space">
        <div className="main-page-header">
          <div className="page-breacrumb">
            <div className="breadcrumb-wrapper">
              <ul className="breadcrumb-links">
                <li><Link to={ON_BOARDING_WAITING_PAYMENT}>Building</Link></li>
                <li>{isAllowedTo('update', 'space', permissions) ? <Link to={SPACE_LIST}>Spaces</Link> : 'Spaces'}</li>
                <li><Link to={`/company/spaces/${currentSpaceSel?.id}/overview`}>{ currentSpaceSel?.name }</Link></li>
                {state ? <li><Link to={`/company/spaces/${currentSpaceSel?.id}/ideaboard`}>Ideaboard</Link></li> : currentProjectSel?.name ? <li>{ currentProjectSel?.name }</li> : null}
              </ul>
            </div>                        
          </div>

          <div className="page-title">
            <h2 aria-label="member-heading">{meetingSel?.title}</h2>
          </div>
        </div>
      </div>


      { iframeUrl && meetingSel?.url && <div className='iframe-wrapper' aria-label="iframe-meeting-label">
        <iframe  allow="camera *; microphone *; display-capture *; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen
          aria-label="iframe-label" title="bmeet application" 
          src={meetingSel?.url + '&is_source_enterprise_surface=true'} className="studio-iframe" style={{'width': '100%', 'height': '100%', 'position': 'relative', 'top': '0', 'right': '0', 'left': '0px', 'backgroundColor': '#ffffff'}}/>
      </div> }
      {false && <div className='footer-section'>
        <div className='assigned-buildcard'>
          <div className="table-wrapper table-responsive assigned-buildcard-table">
            <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Active buildcard</TableCell>
                      <TableCell className='text-right'>Featuresets</TableCell>
                      <TableCell className='text-right'>Credits</TableCell>
                      <TableCell className='text-right'>Duration</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <span className='link-wrap'><span className='title'>{ currentProjectSel?.name ?? currentSpaceSel?.name }</span> <span className="primary-link">Change</span></span>
                      </TableCell>
                      {/* <TableCell><span>{ currentProjectSel?.name }</span> <Link to="/" className="primary-link" target="_blank" >Change</Link></TableCell> */}
                      <TableCell className='text-right'>0</TableCell>
                      <TableCell className='text-right'>13</TableCell>
                      <TableCell className='text-right'>0 days</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
            </TableContainer>
          </div>
        </div>
      </div>}
      
    </div>
  )
}
