/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint eqeqeq: 0 */
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import images from '../../../assets/images';
import instance from '../../../shared/interceptor';
import { getUserListWithoutPermission } from '../../../shared/services/common.service';
import { currentSpaceState } from '../../../shared/store/slices/companySlice';
import { setSuccessMsg } from '../../../shared/store/slices/successSlice';
import { companyState } from '../../../shared/store/slices/userSlice';
import { BULK_CREATE_SPACE_MEMBER } from '../../../shared/util/constant';
import { debounce, getFullname, getInitials, globalError, setLoader } from '../../../shared/util/util';


function AddSpaceMemberDialog({ addSpaceMember, AddSpaceMemberDialogClose, AddSpaceMemberBulkDialogOpen }) {
  const dispatch = useDispatch();
  const companySel = useSelector(companyState);
  const currentSpaceSel = useSelector(currentSpaceState);

  const [memberList, setMemberList] = useState([]);
  const [memberSearchText, setMemberSearchText] = useState('');
  const [selectedMem, setSelectedMem] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (addSpaceMember) {
      getUserList();
    }
  }, [memberSearchText]);

  const getUserList = async () => {
    const userListRes = await getUserListWithoutPermission(companySel, memberSearchText, dispatch, setLoading);
    setMemberList(userListRes?.['users'] ?? []);
  }

  const addMembers = async () => {
    try {
      setLoader(dispatch, true);
      const spaceMembers = selectedMem.map(item => ({ email: item.email }))
      const payload = {
        space_members: spaceMembers
      }
      const url = `${BULK_CREATE_SPACE_MEMBER.replace(':id', companySel.id).replace(':space_id', currentSpaceSel.id)}`;
      let res = await instance.post(url, payload);
      if (res?.['space_members']?.['count']) {
        resetForm();
        AddSpaceMemberDialogClose(null, true);
        dispatch(setSuccessMsg(`${res?.['space_members']?.count} Member/s added successfully.`));
      } else {
        globalError(dispatch, {message: 'Member already exists, No members added'});
      }
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  }

  const resetForm = () => {
    setMemberList([]);
    setSelectedMem([]);
    setMemberSearchText('');
  }

  const removeMember = (index) => {
    let tempArr = [...selectedMem];
    tempArr.splice(index, 1);
    setSelectedMem(tempArr);
  }

  const handleCancel = () => {
    resetForm();
    AddSpaceMemberDialogClose();
  }

  const handleModalClose = (e, reason) => {
    if (reason && reason == "backdropClick") return;
    AddSpaceMemberDialogClose(e, false)
  }

  const handleChange = debounce((e) => {
    setMemberSearchText(e?.target?.value);
  })

  //==============================================
  return (
    <Dialog
      open={addSpaceMember}
      onClose={handleModalClose}
    >
      <DialogContent className='add-members-to-space-dialog'>
        {/* TODO: close button to be enabled later */}
        {/* <span className="modal-close be-close" onClick={AddSpaceMemberDialogClose}></span> */}
        <div className="modal-body" aria-label='add-member-dialog'>
          <div className="modal-title">
            <h2 aria-label='modal-heading'>Add members to your space</h2>
            <div className="right-title">
              <div data-testid="csv-dialog" className="browse-files-link" onClick={AddSpaceMemberBulkDialogOpen}>Bulk add via CSV</div>
            </div>
          </div>
          <div className="modal-form">
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <FormControl>
                    <Autocomplete
                      loading={loading}
                      data-testid="autocomplete-space-search"
                      disableCloseOnSelect
                      multiple
                      freeSolo
                      disableClearable
                      id="searchMembersDropdown"
                      className='search-members-dropdown'
                      value={selectedMem}
                      onChange={(event, value) => typeof value?.[value?.length - 1] == 'object' ? setSelectedMem([...new Map(value.map(item => [item['id'], item])).values()]) : null}
                      options={memberList}
                      filterOptions={(options) => options}
                      autoHighlight
                      renderTags={(value: string[], getTagProps) => ''}
                      getOptionLabel={(option) => getFullname(option?.first_name, option?.last_name) ?? option?.email}
                      popupIcon={<InputAdornment position="start"><em className='be-search'></em></InputAdornment>}
                      renderOption={(props, option, { selected }) => (
                        <Box component="span" {...props} key={option?.id}>
                          <div className='search-members-dropdown'>
                            <div className='left-side'>
                              {option?.profile_picture?.profile_picture_url ? <img className='initials' src={option?.profile_picture?.profile_picture_url} /> : <span className='initials'>{option?.first_name?.[0] ?? '-'}</span>}
                              <span className='user-details'>
                                <span className='name'>{getFullname(option?.first_name, option?.last_name) ?? '-'}</span>
                                <span className='email'>({option?.email})</span>
                              </span>
                            </div>
                            <div className='right-side'>
                              <Checkbox
                                className='square-checkbox'
                                checked={selected}
                                icon={<img src={images.checkboxUnchecked} alt='' />}
                                checkedIcon={<img src={images.checkboxChecked} alt='' />}
                              />
                            </div>
                          </div>
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField  {...params} className='search-input-box' label="Search members" variant="outlined" onChange={handleChange} />
                      )}
                    />
                  </FormControl>
                  <span className="form-error"></span>
                </div>
              </div>
            </div>
          </div>
          <div className='members-to-be-added'>Members to be added <span>({selectedMem.length})</span></div>
          {!selectedMem.length && <p className='no-members-text'>No member selected yet.</p>}

          <div className='members-list-container'>
            <ul>
              {selectedMem.map((item, idx) => {
                return (<li key={item.id}>
                  <div className='user-link'>
                    <span className='initials'>
                      {getInitials(getFullname(item?.first_name, item?.last_name)) ?? '-'}
                    </span>
                    {/* <span className='user-avatar'>
                                <img src={(images as any).userImage2} alt="" />
                                </span> */}
                    <span className='user-detail'>
                      <span className="name">{getFullname(item?.first_name, item?.last_name)}</span>
                      <span className="email">{item.email}</span>
                    </span>
                  </div>
                  <span aria-label="remove-span-btn" className='be-close' onClick={() => removeMember(idx)}></span>
                </li>)
              })}
            </ul>
          </div>

        </div>
      </DialogContent>
      <DialogActions className="fixed-footer-wrap">
        <div className="fixed-full-footer">
          <Button data-testid='cancel-btn' variant="contained" className='grey-btn sm-btn' onClick={handleCancel}>Cancel</Button>
          <Button data-testid="add-mem-btn" variant="contained" className='sm-btn' disabled={!selectedMem.length} onClick={addMembers}>Add members</Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default AddSpaceMemberDialog;
