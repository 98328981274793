import RolesPermissionsTopNavigation from './RolesPermissionsTopNavigation';
import './company.scss';


export default function CompanyCustomRolesPermissions() {
  const roleData = (data) => {
    // console.log(data, 'in custom');
  }

  //================================
  return (
    <>
      <div className="company-roles-permissions-page" aria-label="custom-role-page">
        <div className="page-container top-space">
          <RolesPermissionsTopNavigation roleData={roleData}/>

          
        </div>
      </div>
    </>
  )
}