/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint eqeqeq: 0 */
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import fileDownload from 'js-file-download';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import images from '../../../assets/images';
import AddMemberToBmeetDialog from '../../../components/dialog/bmeet-member-dialog/AddMemberToBmeetDialog';
import DeleteMeetingDialog from '../../../components/dialog/bmeet-member-dialog/DeleteMeetingDialog';
import instance from '../../../shared/interceptor';
import { bmeetUrl, companyState, userState } from '../../../shared/store/slices/userSlice';
import { PROJECTS } from '../../../shared/util/constant';
import { getFullname, globalError, setLoader } from '../../../shared/util/util';
import '../project.scss';
import Summary from '../summary';
import ProjectTopNavigation from './ProjectNavigation';
import './projectMeetings.scss';

export default function ProjectMeetings() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams();
	const compSel = useSelector(companyState);
	const userSel = useSelector(userState);

	const [tabContentVal, setValue] = useState('upcomingMeetingsSection');
	const [meetingList, setMeetingList] = useState(null);
	const [callMyTeam, setCallMyTeam] = useState(false);
	const [selectedMeeting, setSelectedMeeting] = useState(null);
	const [deleteMeeting, setDeleteMeeting] = useState(null);

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setValue(newValue);
	};

	useEffect(() => {
		getMeetings();
	}, [])

	const getMeetings = async () => {
		try {
			setLoader(dispatch, true);
			const url = `${PROJECTS.replace(':id', compSel?.id).replace(':space_id', params?.id)}/${params?.project_id}/meetings`;
			let meetingsRes = await instance.get(url);
			setMeetingList(meetingsRes);
		} catch (err) {
			globalError(dispatch, err);
		} finally {
			setLoader(dispatch, false);
		}
	}

	const joinMeeting = (meet) => {
		dispatch(bmeetUrl(meet));
		navigate(`/company/spaces/${params?.id}/meeting-playground/${meet?.id}`);
	}

	const downloadMeeting = (url, title) => {
		axios.get(url, {
			responseType: 'blob',
		})
			.then((res) => {
				fileDownload(res.data, `${title}.mp4`)
			}).catch(err => {
				globalError(dispatch, err);
			})
	}

	const editMeeting = (meet) => {
		setSelectedMeeting(meet);
		setCallMyTeam(true);
	}

	const callMyTeamDialogClose = (e, render = false) => {
		if (render) {
			getMeetings();
		}
		setCallMyTeam(false);
	}

	const handleModalClose = (e, reason, render = false) => {
		if (reason && reason === "backdropClick") return;
		if (render) {
			getMeetings();
		}
		setDeleteMeeting(null);
	}

	//===========================================================
	return (
		<div className="project-overview tab-page-section" aria-label='project-meetings-page'>
			<div className="page-container pb-0 top-space">
				<ProjectTopNavigation />

				<section className='timeline-wrapper'>
					<div className='page-content project-meetings-page'>
						{/* Upcoming and past meeting tabs */}
						<div className='tab-content-wrapper'>
							<TabContext value={tabContentVal}>
								<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
									<TabList onChange={handleChange} aria-label="">
										<Tab label="Upcoming meetings" value="upcomingMeetingsSection" className='me-0' />
										<Tab label="Past meetings" value="pastMeetingsSection" />
									</TabList>
								</Box>
								<TabPanel value="upcomingMeetingsSection">
									<div className='tab-content-section'>
										{meetingList?.upcoming_meetings?.sort((a, b) => moment(a?.start_time)?.valueOf() - moment(b?.start_time)?.valueOf())?.map(meet => {
											return <div className="tab-content-row" key={meet?.id}>
												<div className='col1'>
													<div className='badge' style={{ backgroundColor: '#00C853' }}>
														{meet?.instant ? 'Now' :
															`${moment(meet?.start_time).format('DD MMM, YYYY')} | ${moment(meet?.start_time).format('hh:mm a')} ${meet?.end_time && '-'} ${meet?.end_time && moment(meet?.end_time).format('hh:mm a')}`}
													</div>
													<div className='title'>{meet?.title} {meet?.instant && moment(meet?.start_time).format('DD MMM, YYYY hh:mm a')}</div>
												</div>
												<div className='col2'>
													<div className='info-title'>HOST:
														<Tooltip enterTouchDelay={0} title={<div className='tooltip-content'>{getFullname(meet?.host?.first_name, meet?.host?.last_name)}</div>}>
															<span className='avatar overlapped' style={{ backgroundColor: '#7C4DFF' }}>{meet?.host?.first_name?.[0]}</span>
														</Tooltip>
													</div>
													{meet?.users?.length > 0 && <div className='info-title'>INVITEE:
														{meet?.users?.slice(0, 3).map(item => (
															<Tooltip key={item.id} enterTouchDelay={0} title={getFullname(item?.first_name, item?.last_name)}>
																<span className='avatar overlapped' style={{ backgroundColor: '#7C4DFF' }}>
																	{item?.first_name?.[0] ?? '-'}
																</span>
															</Tooltip>
														))}
														{meet.users.length > 3 && <Tooltip arrow className='tooltip-holder' placement='bottom' classes={{ popper: 'info-tooltip has-list-items' }} enterTouchDelay={0} title={
															<div className='tooltip-content multiple-content'>
																{meet?.users?.slice(3).map(us => <span key={us?.id}>{getFullname(us?.first_name, us?.last_name) ?? '-'}</span>)}
															</div>
														}>
															<span className='avatar overlapped' style={{ backgroundColor: '#DFE0E6', color: '#3C3E49' }}>+{meet.users.length - 3}</span>
														</Tooltip>}
													</div>}
												</div>
												<div className='col3'>
													{!meet?.instant && (userSel?.id === meet?.host?.id || userSel?.role?.name == 'CIO') && <span className='icon-buttons-wrap'>
														<Tooltip placement='bottom-end' title="Edit" classes={{ popper: 'info-tooltip bottom-end' }} arrow>
															<Button data-testid="edit-btn" variant="outlined" className="xs-btn is-icon-btn" onClick={() => editMeeting(meet)}>
																<img src={images.editIcon} alt="Edit" />
															</Button>
														</Tooltip>
														<Tooltip placement='bottom-start' title="Delete" classes={{ popper: 'info-tooltip' }} arrow>
															<Button data-testid="delete-btn" variant="outlined" className="xs-btn is-icon-btn" onClick={() => setDeleteMeeting(meet)}>
																<img src={images.deleteIcon} alt="Delete" />
															</Button>
														</Tooltip>
													</span>}
													{(meet?.is_host || meet?.is_invitee) && <Button data-testid="join-btn" className='xs-btn purple-btn' variant="contained" onClick={() => joinMeeting(meet)}>{meet?.is_host ? 'Start' : 'Join'}</Button>}
												</div>
											</div>
										})}
										{/* No data wrapper  */}
										{!meetingList?.upcoming_meetings?.length &&
											<div className="no-data-wrapper meetings">
												<em className="thumb"><img src={images.emptyStateIllustration} alt="Spaces Flag" /></em>
												<h4 aria-label="no-space">No meetings</h4>
												<p>Your upcoming meetings will appear here.</p>
											</div>}
										{/* No data wrapper end */}
									</div>
								</TabPanel>
								<TabPanel value="pastMeetingsSection">
									{meetingList?.past_meetings?.map(meet => {
										return <div className='tab-content-section' key={meet?.id}>
											<div className="tab-content-row">
												<div className='col1'>
													<div className='badge' style={{ backgroundColor: '#676b7e' }}>{moment(meet?.start_time).format('DD MMM, YYYY')} | {moment(meet?.start_time).format('hh:mm a')} {meet?.end_time && '-'} {meet?.end_time && moment(meet?.end_time).format('hh:mm a')}</div>
													<div className='title'>{meet?.title}</div>
												</div>
												<div className='col2'>
													<div className='info-title'>HOST:
														<Tooltip enterTouchDelay={0} title={<div className='tooltip-content'>{getFullname(meet?.host?.first_name, meet?.host?.last_name)}</div>}>
															<span className='avatar' style={{ backgroundColor: '#7C4DFF' }}>{meet?.host?.first_name?.[0]}</span>
														</Tooltip>
													</div>
													{meet?.users?.length > 0 && <div className='info-title'>INVITEE:
														{meet?.users?.slice(0, 3).map(us => {
															return <Tooltip key={us.id} enterTouchDelay={0} title={getFullname(us?.first_name, us?.last_name)}>
																<span key={us?.id} className='avatar overlapped' style={{ backgroundColor: '#7C4DFF' }}>{us?.first_name?.[0] ?? '-'}</span>
															</Tooltip>
														})}
														{meet.users.length > 3 && <Tooltip className='tooltip-holder' arrow placement='bottom' classes={{ popper: 'info-tooltip has-list-items' }} enterTouchDelay={0} title={
															<div className='tooltip-content multiple-content'>
																{meet?.users?.slice(3).map(us => <span key={us?.id}>{getFullname(us?.first_name, us?.last_name) ?? '-'}</span>)}
															</div>
														}>
															<span className='avatar overlapped' style={{ backgroundColor: '#DFE0E6', color: '#3C3E49' }}>+{meet.users.length - 3}</span>
														</Tooltip>}
													</div>}
												</div>
												<div className='col3'>
													{/* <Button className='xs-btn purple-btn btn-join' variant="contained" onClick={() => downloadMeeting(meet?.url, meet?.title)}><em className='icon be-box-arrow-down btn-download'></em>
											Download Recording</Button> */}
												</div>
											</div>
										</div>
									})}
									{/* No data wrapper  */}
									{!meetingList?.past_meetings?.length &&
										<div className="no-data-wrapper meetings">
											<em className="thumb"><img src={images.emptyStateIllustration} alt="Spaces flag" /></em>
											<h4 aria-label="no-space">No meetings</h4>
											<p>Your past meetings will appear here.</p>
										</div>}
									{/* No data wrapper end */}
								</TabPanel>
							</TabContext>
						</div>

					</div>
					<Summary tab={"Project"}></Summary>

					{callMyTeam && <AddMemberToBmeetDialog {...{ callMyTeam, callMyTeamDialogClose, selectedMeeting }} />}
					{!!deleteMeeting && <DeleteMeetingDialog {...{ deleteMeeting, handleModalClose }} />}
				</section>
			</div>
		</div>
	)
}