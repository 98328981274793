import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import instance from "../../../shared/interceptor";
import CheckoutNavigation from "./CheckoutNavigation";
import { PLANS } from "../../../shared/util/constant";
import PlanPayment from "../../plan-checkout/PlanPayment";
import { globalError, setLoader } from "../../../shared/util/util";
import PlanBillingDetails from "../../plan-checkout/PlanBillingDetails";
import CheckoutPlanSelection from "../../plan-checkout/CheckoutPlanSelection";
import { companyState, userDataState } from "../../../shared/store/slices/userSlice";

export default function Checkout() {
  const [CheckoutSection, setCheckoutSection] = useState('Plan Details');
  const [selectedPlan, setSelectedPlan] = useState(null);

  const companyData = useSelector(companyState);
  const userData = useSelector(userDataState);

  const location = useLocation();
  const { state } = location;
  const dispatch = useDispatch();

  const getPlan = async (id) => {
    if (userData?.user?.role?.name !== 'Finance Admin') {
      try {
        setLoader(dispatch, true);
        let resp = await instance.get(`${PLANS}/${id}`);
        setSelectedPlan(resp?.['plan'])
      } catch (e) {
        globalError(dispatch, e);
      } finally {
        setLoader(dispatch, false);
      }
    }
  }

  useEffect(() => {
    let id = state?.planId ?? companyData?.plan?.id;
    if (id){
      (async () => await getPlan(id))();
    }

    if (state?.section) {
      setCheckoutSection(state.section);
    }
  }, [])

  return (
    <div aria-label='checkout-page'>
      <CheckoutNavigation checkoutSection={CheckoutSection} setCheckoutSection={setCheckoutSection} />
      {CheckoutSection === 'Plan Details' ? 
        <CheckoutPlanSelection selectedPlan={selectedPlan} setCheckoutSection={setCheckoutSection} /> : 
        CheckoutSection === 'Billing Details' ?
          <PlanBillingDetails selectedPlan={selectedPlan} setCheckoutSection={setCheckoutSection} /> : 
          <PlanPayment selectedPlan={selectedPlan} />
      }
    </div>
  )
}