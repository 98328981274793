import { configureStore } from "@reduxjs/toolkit";

import userSlice from "./slices/userSlice";
import errorSlice from "./slices/errorSlice";
import successSlice from "./slices/successSlice";
import spaceSlice from "./slices/companySlice";
import loadingSlice from "./slices/loadingSlice";
import spaceCreditSlice from "./slices/spaceCreditSlice";

const store = configureStore({
    reducer: {
        userData: userSlice,
        error: errorSlice,
        success: successSlice,
        spaces: spaceSlice,
        loading: loadingSlice,
        spaceCredit: spaceCreditSlice
    }
});

export default store;
