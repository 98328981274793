/* eslint eqeqeq: 0 */
import Checkbox from "@mui/material/Checkbox";
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";

import { companyState } from "../../shared/store/slices/userSlice";
import { MEMBER_ROLES } from "../../shared/util/constant";
import { getRandom } from "../../shared/util/util";

const idb = window.indexedDB;

const CsvTable = ({ images, uploadedCSV }) => {
    const { email_domain } = useSelector(companyState);

    const [tableData, setTableData] = useState([]);
    const [isChecked, setIsChecked] = useState(true);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [error, setError] = useState('');
    const num = 10;

    useEffect(() => {
        createCollection(storeCsvData);
        setPage(1);
        setIsChecked(true);
    }, [uploadedCSV])

    const slicedArray = (array = [], m, n) => array.slice(m, n);

    const createCollection = (cb) => {
        setError('');
        if (!idb) {
            console.log('does not support indexedDB');
            return;
        }
        setLoading(true);
        const request = idb.open('csvData', 1);
        request.onerror = (event) => {
            console.log(event, 'error while creating collection');
            setError(event?.srcElement?.['error']['message'])
            setLoading(false);
        }

        request.onupgradeneeded = (event) => {
            const db = request?.result;
            if (!db.objectStoreNames.contains('memberCsv')) {
                db.createObjectStore('memberCsv', {
                    keyPath: 'id',
                    autoIncrement: true
                })
            }
        }

        request.onsuccess = (result) => {
            console.log('database opened successfully');
            cb();
        }
    }

    const storeCsvData = (data = uploadedCSV, getAll = true) => {
        const dbPromise = idb.open('csvData', 1);
        dbPromise.onsuccess = () => {
            const db = dbPromise?.result;
            const tx = db.transaction('memberCsv', 'readwrite');
            const csvData = tx.objectStore('memberCsv').put({ id: 1, 'uploadedCSV': data });
            csvData.onsuccess = () => {
                tx.oncomplete = () => {
                    db.close();
                }
                if (getAll) {
                    getAllData(true);
                }
            }
            csvData.onerror = (err) => {
                console.log(err);
                setLoading(false);
            }
        }
    }

    const getAllData = (load = false, m = 0, n = num) => {
        if (load) {
            setLoading(true);
        }
        getAllFromDB((err, arr) => {
            setLoading(false);
            if (err) {
                console.log(err, 'error here')
                return setError(err?.srcElement?.['error']['message']);
            }
            setTableData(slicedArray(arr, m, n));
        });
    }

    const toggleChecked = (idx) => {
        let data = [...tableData];
        data[idx]['checked'] = !data[idx]['checked'];
        setTableData(data);
        getAllFromDB((err, arr) => {
            if (err) {
                return setError(err?.srcElement?.['error']['message']);
            }
            let index = arr.findIndex(item => item.id == data[idx].id);
            arr[index] = index > -1 ? data[idx] : arr[index];

            let dataIndex = data.findIndex(item => !item.checked);
            let notCheckedIndex = arr.findIndex(item => !item.checked);
            (dataIndex > -1 || notCheckedIndex > -1) ? setIsChecked(false) : setIsChecked(true);
            storeCsvData(arr, false);
        })
    }

    const createTableDataIfNotRole = (i, row, item) => {
        if (checkId(item) || item === 'role') return null;
        return (<TableCell key={`${i + getRandom()}`}>
            <div className="tooltip-wrapper">
                <div className="tooltip-trigger">
                    <span className="trigger-text">{row[item]}</span>
                    {item === 'email' && !row[item]?.endsWith(email_domain) &&
                        <Tooltip enterTouchDelay={0} title="This member does not belong to your organisation. Are you sure you want to invite to your account?">
                            <em className="be-tooltip tooltip-icon"></em>
                        </Tooltip>
                    }
                </div>
            </div>
        </TableCell>)
    }

    const createTableDataForRole = (idx, row) => {
        return (<TableCell align="right" key={`${idx + getRandom()}`} width={150}>
            <FormControl>
                <Select value={row.role.toLowerCase()} className="borderless-dropdown sm-height no-label" onChange={(e) => updateRole(e, row)}>
                    {MEMBER_ROLES.map(role => <MenuItem disabled={role?.value?.toLowerCase() === 'cio'} key={role.key} value={role.value.toLowerCase()}>{role.label}</MenuItem>)}
                </Select>
            </FormControl>
        </TableCell>)
    }

    const updateRole = (e, row) => {
        getAllFromDB((err, arr) => {
            if (err) {
                return setError(err?.srcElement?.['error']['message']);
            }
            let index = arr.findIndex(item => item.id == row.id);
            arr[index] = index > -1 ? { ...row, role: e.target.value } : arr[index];
            storeCsvData(arr, false);
            getAllData(false, (page * num) - num, page * num);
        });
    }

    const toggleAllCheckBox = () => {
        getAllFromDB((err, arr) => {
            if (err) {
                return setError(err?.srcElement?.['error']['message']);
            }
            let toggledCheckboxes = arr.map(item => ({ ...item, checked: !isChecked }));
            setIsChecked(!isChecked);
            storeCsvData(toggledCheckboxes, false);
            getAllData(false, (page * num) - num, page * num);
        });
    }

    const getAllFromDB = (cb) => {
        setError('');
        const dbPromise = idb.open('csvData', 1);
        dbPromise.onsuccess = () => {
            const db = dbPromise?.result;
            const tx = db.transaction('memberCsv', 'readonly');
            const csvData = tx.objectStore('memberCsv');
            const members = csvData.getAll();
            members.onsuccess = (query) => {
                tx.oncomplete = () => {
                    db.close();
                }
                const arr = query?.srcElement?.['result'][0]?.uploadedCSV ?? query?.target?.['result'][0]?.uploadedCSV;
                cb(null, arr);
            }
            members.onerror = (err) => {
                setLoading(false);
                cb(err, null);
            }
        };
    }

    const handlePaginationChange = (e, val) => {
        setPage(val);
        getAllData(true, (val * num) - num, val * num);
    }

    const checkId = (item) => {
        return item === 'checked' || item === 'id';
    }

    // ============================================

    return <>
        <div className="table-wrapper table-responsive">
            <span aria-label="error" className="form-error">{error}</span>
            {loading &&
                <div className="photo-upload-box lg-height secondary grey-bg">
                    <div className="upload-info">
                        <div className="secondary-loader">
                            <svg className="spinner" viewBox="0 0 50 50" height="50" width="50">
                                <circle cx="25" cy="25" r="20" stroke="#6200ea" strokeOpacity="0.15" fill="none" strokeWidth="5">
                                </circle>
                                <circle className="animated-path" cx="25" cy="25" r="20" stroke="#6200ea" fill="none" strokeWidth="5">
                                </circle>
                            </svg>
                        </div>
                        <h6>{tableData.length > 0 ? 'Loading' : 'Uploading'} your data...</h6>
                    </div>
                </div>
            }
            {tableData.length > 0 && !loading &&
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell width={48}>
                                    <Checkbox
                                        data-testid='checkbox'
                                        checked={isChecked}
                                        icon={<img src={images.checkboxUnchecked} alt='' />}
                                        checkedIcon={<img src={images.checkboxChecked} alt='' />}
                                        onClick={toggleAllCheckBox}
                                    />
                                </TableCell>
                                {Object.keys(tableData[0]).map((item, idx) => (
                                    checkId(item) ? null : <TableCell key={`${idx + getRandom()}`} align={`${item === 'role' ? 'right' : 'justify'}`}>{item}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData.map((row, i) => (
                                <TableRow key={`table3_${i + getRandom()}`}>
                                    <TableCell width={48}>
                                        <Checkbox
                                            checked={row['checked']}
                                            onClick={() => toggleChecked(i)}
                                            icon={<img src={images.checkboxUnchecked} alt='' />}
                                            checkedIcon={<img src={images.checkboxChecked} alt='' />}
                                        />
                                    </TableCell>
                                    {Object.keys(tableData[0]).map(item => item !== 'role' ? createTableDataIfNotRole(i, row, item) : createTableDataForRole(i, row))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>}
        </div>
        <div className="custom-pagination-wrapper">
            <Pagination page={page} count={Math.ceil(uploadedCSV.length / num)} shape="rounded" size="large" siblingCount={2} onChange={handlePaginationChange} />
        </div>
    </>;
}

export default CsvTable;
