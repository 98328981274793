import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import instance from '../../../shared/interceptor';
import { getUserListWithoutPermission } from '../../../shared/services/common.service';
import { updateSpaceList } from '../../../shared/store/slices/companySlice';
import { setSuccessMsg } from '../../../shared/store/slices/successSlice';
import { companyState } from '../../../shared/store/slices/userSlice';
import { SPACES } from '../../../shared/util/constant';
import { debounce, getFullname, getInitials, globalError, setLoader, validateEmail } from '../../../shared/util/util';

function EditSpaceDialog({EditSpace, EditSpaceDialogClose, selectedSpace}) {
    const dispatch = useDispatch();
    const companySel = useSelector(companyState);

    const [formVal, setFormVal] = useState({name: '', email: '', description: ''});
    const [defaultVal, setDefaultVal] = useState(null);
    const [spaceOwnerList, setSpaceOwnerList] = useState([]);
    const [error, setError] = useState({
        name: '',
        email: ''
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setFormVal({
            name: selectedSpace?.name, 
            email: selectedSpace?.owner?.email, 
            description: selectedSpace?.description
        })

        return () => {
            setFormVal({name: '', email: '', description: ''});
        }
    }, [])

    const searchSpaces = debounce(async (ownerVal) => {
        setFormVal({ ...formVal, email: ownerVal });
        if (ownerVal) {
            try {
                setLoading(true);
                let res = await getUserListWithoutPermission(companySel, ownerVal, dispatch, setLoading);
                setSpaceOwnerList(res?.['users'] ?? []);
                (!res?.['users'].length) ? setDefaultVal(ownerVal) : setDefaultVal(null);
            } catch (e) {
                globalError(dispatch, e);
            } finally {
                setLoading(false);
            }
        } else {
            setSpaceOwnerList([]);
            setDefaultVal(null);
        }
    })

    const editSpace = async () => {
        try {
            const payload = {
                name: formVal?.name ? formVal.name : selectedSpace.name,
                email: formVal.email,
                description: formVal?.description,
                archive: selectedSpace?.archive
            }
            if (payload?.email && !validateEmail(payload?.email)) {
                return setError({ ...error, email: 'Provide a valid email address.' });
            }
            if (!payload?.name) {
                return setError({ ...error, name: 'Provide the Space name.' });
            }
            setLoader(dispatch, true);
            resetErrorObj();
            let url = `${SPACES.replace(':id', companySel?.id)}/${selectedSpace?.id}`;
            await instance.patch(url, payload);
            dispatch(updateSpaceList(true));
            EditSpaceDialogClose({}, true);
            resetErrorObj();
            dispatch(setSuccessMsg('Space updated successfully.'));
        } catch(e) {
            globalError(dispatch, e);
        } finally {
            setLoader(dispatch, false);
        }
    }

    const checkTextLength = (val) => {
        if (!val.trim().length) {
            setError({ ...error, name: 'Space name should not be empty.' });
        }
        if (val.trim().length > 50) {
            setError({ ...error, name: 'Space name should not be greater than 50' });
        }
    }

    const handleModalClose = (e, reason) => {
        if (reason && reason == "backdropClick") return;
        EditSpaceDialogClose(e, false)
    }

    const resetErrorObj = () => {
        setError({
            name: '',
            email: ''
        })
    }

    //==========================================================

    return (<Dialog
        open={EditSpace}
        onClose={handleModalClose}
        >
        <DialogContent>
        <span className="modal-close be-close" onClick={EditSpaceDialogClose}></span>
        <div className="modal-body" aria-label="editModal">
            <div className="modal-title has-right-data">
            <h2>Edit space</h2>
            </div>

            <div className="modal-form">
            <div className="row">
                <div className="col-sm-12">
                <div className="form-group">
                    <h6>Space details</h6>
                    <FormControl variant="filled" className={error?.['name'] ? 'has-error' : ''}>
                        <InputLabel>Space name</InputLabel>
                        {/* @ts-ignore */}
                        <FilledInput data-testid="edit-space-name" type="text" variant="filled"  name="space-name" onKeyUp={() => setError('')} onBlur={e => checkTextLength(e.target.value)} value={formVal?.name} onChange={(e) => {setFormVal({...formVal, name: e.target.value}); resetErrorObj()}}/>
                    </FormControl>
                    <span className="form-error">{error?.['name']}</span>
                </div>
                </div>
            </div>
            <div className='row'>
                <div className="col-sm-12">
                <div className="form-group has-add-email">
                    <FormControl>
                    <Autocomplete
                        loading={loading}
                        freeSolo
                        data-testid="autocomplete-search"
                        value={{name: '', email: formVal?.email ?? '' }}
                        id="spaceOwnerEmail"
                        options={spaceOwnerList}
                        autoHighlight
                        disableClearable
                        getOptionLabel={(option: any) => option.email}
                        filterOptions={(options) => options}
                        onChange={(event, value) => setFormVal({ ...formVal, email: value?.['email'] })}
                        renderOption={(props, option) => (
                            <Box component="span" {...props} key={option?.id}>
                                <div className='search-members-dropdown search-space-owner'>
                                    <div className='left-side'>
                                        {option?.profile_picture ? <img className='initials' src={option?.profile_picture?.profile_picture_url} /> : <span className='initials'>{getInitials(option?.name) ?? '-'}</span>}
                                        <span className='user-details'>
                                            <span className='name'>{getFullname(option?.first_name, option?.last_name)}</span>
                                            <span className='email'>{option.email}</span>
                                        </span>
                                    </div>
                                    {/* <div className='right-side'>
                                        <Checkbox
                                            className='square-checkbox'
                                            checked={selected}
                                            icon={<img src={images.checkboxUnchecked} alt='' />}
                                            checkedIcon={<img src={images.checkboxChecked} alt='' />}
                                        />
                                    </div> */}
                                </div>
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} className='search-input-box' label="Space owner (email)" variant="outlined" onChange={(e) => searchSpaces(e.target.value)}/>
                        )}
                    />
                    </FormControl>
                    <span className="form-error">{error?.['email']}</span>
                    { defaultVal && validateEmail(defaultVal) && <div className="add-email-wrapper invite" >
                        <div className="add-email-box" onClick={() => {setFormVal({...formVal, email: defaultVal}); setDefaultVal('')}}><strong className="primary-link">Invite {defaultVal}</strong> to Builder Enterprise and the space “{selectedSpace?.name}”.</div>
                    </div> }
                    
                </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="form-group">
                        <h6>Description</h6>
                        <TextField
                        inputProps={{ className: "custom-textarea description" }}
                        id="outlined-multiline-static"
                        label=""
                        multiline
                        rows={4}
                        value={formVal?.description ?? ''}
                        variant="outlined"
                        onChange={(e) => setFormVal({...formVal, description: e.target.value})}
                        />
                    </div>
                </div>
            </div>

            </div>
        </div>
        </DialogContent>
        <DialogActions className="fixed-footer-wrap">
            <div className="fixed-full-footer">
                <Button aria-label="edit-btn" variant="contained" disabled={!formVal['name']} className='btn-save sm-btn w-100' onClick={editSpace}>Save</Button>
            </div>
        </DialogActions>
    </Dialog>)
}

export default EditSpaceDialog;
