import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useDispatch, useSelector } from 'react-redux';

import instance from '../../../shared/interceptor';
import { currentProjectState, currentSpaceState } from '../../../shared/store/slices/companySlice';
import { setSuccessMsg } from '../../../shared/store/slices/successSlice';
import { companyState } from '../../../shared/store/slices/userSlice';
import { PROJECTS } from '../../../shared/util/constant';
import { getFullname, globalError, setLoader } from '../../../shared/util/util';


function RemoveMemberFromProject({ RemoveMember, RemoveMemberDialogClose, selectedProjectMember }) {
    const dispatch = useDispatch();
    const currentProjectSel = useSelector(currentProjectState);
    const currentSpaceSel = useSelector(currentSpaceState);
    const compSel = useSelector(companyState);
    
    const removeMemberFromProject = async () => {
        try {
            setLoader(dispatch, true);
            const url = `${PROJECTS.replace(':id', compSel?.id).replace(':space_id', currentSpaceSel?.id)}/${currentProjectSel?.id}/project_members/${selectedProjectMember?.id}`;
            await instance.delete(url);
            RemoveMemberDialogClose(null, true);
            dispatch(setSuccessMsg('Member removed successfully.'));
        } catch (e) {
            globalError(dispatch, e);
        } finally {
            setLoader(dispatch, false);
        }
    }

    const handleModalClose = (e, reason) => {
        if (reason && reason == "backdropClick") return;
        RemoveMemberDialogClose(e, false);
    }

    //===================================================
    return (
        <Dialog
            open={RemoveMember}
            onClose={handleModalClose}
        >
            <DialogContent className='add-members-to-space-dialog'>
                <span className="modal-close be-close" onClick={RemoveMemberDialogClose}></span>
                <div aria-label="remove-project-member" className="modal-body">
                    <div className="modal-title">
                    <h2 aria-label='modal-heading'>Remove member</h2>              
                    </div>            
                    <p>Member will not be able to make any changes to this project’s Buildcards. Are you sure you want to remove { getFullname(selectedProjectMember?.first_name, selectedProjectMember?.last_name) } from this project? </p>

                </div>
            </DialogContent>
            <DialogActions className="fixed-footer-wrap">
                <div className="fixed-full-footer">
                    <Button data-testid="remove-member-close" variant="contained" className='grey-btn sm-btn' onClick={RemoveMemberDialogClose}>Cancel</Button>
                    <Button data-testid="remove-member" variant="contained" className='orange-btn sm-btn' onClick={removeMemberFromProject}>Remove</Button>
                </div>
            </DialogActions>
        </Dialog>
    )
}

export default RemoveMemberFromProject;
