/* eslint eqeqeq: 0 */
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import Menu from "@mui/material/Menu";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import images from '../../assets/images';
import AddGoalDialog from '../../components/dialog/space-project-dialog/AddGoalDialog';
import CreateSpaceProjectDialog from '../../components/dialog/space-project-dialog/CreateSpaceProjectDialog';
import instance from '../../shared/interceptor';
import { addTag, getAllSpaceGoals, getAllTags, getProjectDetail, getSpaceMemberList, handleDelete } from '../../shared/services/common.service';
import CardEntityTags from '../../shared/shared-comp/CardEntityTags';
import GoalsTags from '../../shared/shared-comp/GoalsTags';
import { currentSpaceState } from '../../shared/store/slices/companySlice';
import { loadingState } from '../../shared/store/slices/loadingSlice';
import { companyState, permissionState, userPermissionState, userState } from '../../shared/store/slices/userSlice';
import { PROJECTS } from '../../shared/util/constant';
import { debounce, getBcState, getCardLable, getCookie, globalError, isAllowedTo, setCookie, setLoader, sortProject } from '../../shared/util/util';
import Summary from '../building/summary';
import SpaceTopNavigation from './SupplyChainDistributionNavigation';
import TagResultSection from './TagResultSection';
import './project.scss';
import './spaces-overview.scss';
import UnauthorizedAccess from '../../components/UnauthorizedAccess';


export default function SpaceOverview() {
  const dispatch = useDispatch();
  const companySel = useSelector(companyState);
  const userSel = useSelector(userState);
  const loadingSel = useSelector(loadingState);
  const currentSpace = useSelector(currentSpaceState);
  const permissions = useSelector(permissionState);
  const userPermissions = useSelector(userPermissionState);
  const navigate = useNavigate();
  const params = useParams();

  const [CreateProject, setCreateProject] = useState(false);
  const [AddNewGoal, setAddNewGoal] = useState(false);
  const [projectList, setProjectList] = useState(null);
  const [projectSearchText, setProjectSearchText] = useState('');
  const [random, setRandom] = useState(1);
  const [loading, setLoading] = useState(false);
  const [teamList, setTeamList] = useState([]);
  const [shouldVisible, setShouldVisible] = useState(false);
  const [expanded, setExpanded] = useState({0: true});
  const [tagList, setTagList] = useState(null);
  const [showSearchTags, setShowSearchTags] = useState(null);
  const [overviewTabValue, setOverviewTabValue] = useState('tabListView');
  const [spaceGoals, setSpaceGoals] = useState([]);
  const [searchSpaceGoals, setSearchSpaceGoals] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);

  const entity = { id: currentSpace?.id, type: 'Space' }
  const isAllowedToUpdateEntityTag = isAllowedTo('update', 'entity_tag', permissions, entity, userPermissions);
  const isAllowedToAddProject = isAllowedTo('update', 'project', permissions, entity, userPermissions);
  
  const moreTagsDropdownClick = (id, event, elem) => {
    event.preventDefault();
    event.stopPropagation();
    elem({ [id]: event.currentTarget });
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (currentSpace) {
      if (!currentSpace?.private || (currentSpace?.private && currentSpace?.owner?.email === userSel?.email)) {
        getListOfProjects(signal);
        window.scrollTo(0, 0);
        (async () => {
          if (isAllowedTo('read', 'space_goal', permissions, entity, userPermissions)) {
            let res = await getAllSpaceGoals(dispatch, companySel, params);
            setSpaceGoals(res?.['space_goals']);
            setSearchSpaceGoals(res?.['space_goals']);
          }
        })()
      } else {
        navigate(-1);
      }
    }

    return () => {
      controller.abort();
      setShouldVisible(false);
    }
  }, [currentSpace?.id, projectSearchText]);


  const CreateProjectDialogOpen = () => {
    setCreateProject(true);
  };

  const CreateProjectDialogClose = async (e, render = false) => {
    if (render) {
      await getListOfProjects();
    }
    setCreateProject(false);
  };

  const AddNewGoalDialogOpen = () => {
    setAddNewGoal(true);
  };

  const AddNewGoalDialogClose = () => {
    setAddNewGoal(false);
  };

  const getListOfProjects = async (signal?) => {
    try {
      setLoading(true);
      if (!projectSearchText){
        setLoader(dispatch, true);
      }
      let url = `${PROJECTS.replace(':id', companySel?.id).replace(':space_id', params?.id)}`;
      if (projectSearchText) {
        url += `?q=${projectSearchText}`;
      }
      const projectRes = await instance.get(url, { signal });
      //==================================
      const userCookie = getCookie(process.env.REACT_APP_USER);
      let tokenCookie = userCookie ? JSON.parse(userCookie) : {};
      setCookie(process.env.REACT_APP_USER, JSON.stringify({ ...tokenCookie, pm_dashboard_token: projectRes?.['pm_dashboard_token'] }));
      //====================================
      if (projectRes?.['projects']) {
        let sortedByLatest = projectRes['projects'].sort((a, b) => moment.utc(b.created_at).diff(moment.utc(a.created_at)));
        setProjectList(sortedByLatest);
        getTeamList(signal);
      }
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoading(false);
      if (!projectSearchText){
        setLoader(dispatch, false);
      }
      setShouldVisible(true);
    }
  }

  const searchProjects = debounce((e) => {
    setProjectSearchText(e.target.value);
  });


  const navigateToProjectDetail = async (e, project) => {
    e.stopPropagation();
    e.preventDefault();
    let navigateTo = `/company/spaces/${params?.id}/project/${project?.id}/overview`;
    getProjectDetail(dispatch, companySel?.id, params?.id, project?.id, navigate, navigateTo);
  }

  const tabHandleChange = (event: React.SyntheticEvent, newValue: string) => {
    setOverviewTabValue(newValue);
  };

  const sortProjectList = (e) => {
    setProjectList(sortProject(e, projectList));
    setExpanded({0: true});
  }

  // sorting of buildcards from same options, for future if this functionality comes
  // const selectChangeHandler = (e) => {
  //   let seletedVal = e.target.value;
  //   for(let i = 0; i < projectList.length; i++) {
  //     projectList[i]?.['build_cards']?.sort(function(a, b) {
  //       let textA = a.name.toUpperCase();
  //       let textB = b.name.toUpperCase();
  //       let timeA = a.created_at;
  //       let timeB = b.created_at;
  //       let cond = (textA > textB) ? 1 : 0;

  //       if (seletedVal == 'A-Z') {
  //         return (textA < textB) ? -1 : cond;
  //       } else if (seletedVal == 'Z-A') {
  //         return (textA < textB) ? cond : -1;
  //       } else if (seletedVal == 'Latest') {
  //         return moment.utc(timeA).diff(moment.utc(timeB));
  //       } else if (seletedVal == 'Oldest') {
  //         return moment.utc(timeB).diff(moment.utc(timeA));
  //       }
  //     });
  //   }
  //   let sortedArray = [...projectList];
  //   setProjectList(sortedArray);
  // }

  const redirect = (card, projectId) => {
    if (card?.status == 'ongoing' || card?.status == 'completed' || card?.state == 'ready_for_kickoff') {
      window.open(card?.home_url, '_blank');
    } else if ((!card?.status || (card?.status != 'ongoing'  && card?.status != 'completed')) && 
    (card?.state == 'draft' || card?.state == 'pending_approval')) {
      navigate(`/company/spaces/${params?.id}/project/${projectId}/playground/${card.enterprise_id}`);
    }
  }

  const clearInput = () => {
    setProjectSearchText('');
    setRandom(prev => prev + 1);
  }

  const getTeamList = async (signal?) => {
    let teamListRes = await getSpaceMemberList(companySel, currentSpace, params, signal, dispatch);
    if (teamListRes) {
      setTeamList(teamListRes?.['space']?.['members']);
    }
  }

  const emptyState = () => {
    return (<div className="no-data-wrapper">
      <em className="thumb"><img src={images.emptyStateIllustration} alt="Spaces Flag" /></em>
      <h4 aria-label="no-space">No projects yet</h4>
      <p>Add a project and start building apps for your Enterprise.</p>
      {isAllowedToAddProject && <Button variant="contained" className="xs-btn grey-btn" onClick={CreateProjectDialogOpen}>Add project</Button>}
    </div>)
  }

  const handleClose = () => {
    setAnchorEl(null);
    setTagList(null);
  }

  const tagChangeHandler = debounce((e, card) => {
    if (e.key === 'Enter'){
      addTags(e.target.value, card);
      handleClose();
    } else {
      getTagList(e.target['value']);
    }
  })

  const getTagList = async (query?) => {
    let tagsRes = await getAllTags(companySel, dispatch, query);
    setTagList(tagsRes['tags']);
  }
  
  const mutateProjectList = (response, toAdd) => {
    return projectList.map(project => ({...project, build_cards: project.build_cards.map(bc => {
      if (bc?.enterprise_id == response?.['entity_tag']?.taggable_id) {
        if (toAdd) {
          return {...bc, entity_tags: [...bc?.entity_tags, {id: response?.['entity_tag']?.['id'], name: response?.['entity_tag']?.['name']}]};
        } else {
          return {...bc, entity_tags: bc?.entity_tags?.filter(item => item?.id !== response?.['entity_tag']?.id)};
        }
      } else {
        return bc;
      }
    })}));
  }

  const addTags = async (tagName, card) => {
    let response = await addTag(companySel, dispatch, tagName, card);
    let filteredProject = mutateProjectList(response, true);
    setProjectList(filteredProject);
    handleClose();
  }

  const deleteTag = async (tagId) => {
    let response = await handleDelete(dispatch, companySel, tagId);
    let filteredProject = mutateProjectList(response, false);
    setProjectList(filteredProject);
  }

  const tagInput = (card) => {
    return <div>
      <FormControl variant="filled" onKeyDown={e => e.stopPropagation()}>                                                        
        <FilledInput type="text" className="xs-input search-tag-input" placeholder='Search or create new'
          autoFocus
          startAdornment={
            <InputAdornment position="start"></InputAdornment>
          }
          onKeyDown={(e) => tagChangeHandler(e, card)}
          inputProps={{ 'data-testid': 'tag-input'}}
        />
      </FormControl>
    </div>
  }

  const searchSpaceGoalList = (event) => {
    let val = event?.target?.value;
    if(val) {
      setSpaceGoals(searchSpaceGoals.filter(item => item?.name?.toLowerCase()?.includes(val?.toLowerCase())));
    } else {
      setSpaceGoals(searchSpaceGoals);
    }
  }

  const updateGoals = (goal, card) => {
    let mappedList =  projectList.map(project => ({...project, build_cards: project.build_cards.map(bc => {
      if (bc?.enterprise_id == card?.enterprise_id) {
        return {...bc, space_goals: [...bc?.space_goals, {id: goal?.id, name: goal?.['name']}]};
      } else {
        return bc;
      }
    })}));
    setProjectList(mappedList);
  }

  const canDelete = () => isAllowedTo('destroy', 'entity_tag', permissions, { id: currentSpace?.id, type: 'Space'}, userPermissions)
  
  //=============================================================
  return (
    isAllowedTo('read', 'space', permissions, entity, userPermissions) ? <>
      <div className="space-overview-page" aria-label="space-overview">
        <div className="page-container top-space pb-0">
          <SpaceTopNavigation />

          <section className='overview-inner-section'>
            <div className='page-content'>
              {/* TODO: to be enable later
              <div className='apps-list'>
                <div className='section-title'>
                  App (12)
                </div>
                <div className='data-container'>
                  <div className='row'>
                    <div className="col-xs-12 col-sm-4">
                      <div className='app-card'>
                        <div className='data-content'>
                          <div className='thumbnail'>
                            <span>
                              <em className='icon be-multiple-users'></em>
                            </span>
                          </div>
                          <div className='description'>
                            <div className='sub-title'>IOS</div>
                            <div className='title'>Makro NextGen CRM</div>
                          </div>
                        </div>
                        <div className='right-arrow rotate-down'>
                          <em className='icon be-down-arrow'></em>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-4">
                      <div className='app-card'>
                        <div className='data-content'>
                          <div className='thumbnail'>
                            <span>
                              <em className='icon be-multiple-users'></em>
                            </span>
                          </div>
                          <div className='description'>
                            <div className='sub-title'>Android</div>
                            <div className='title'>Makro NextGen CRM</div>
                          </div>
                        </div>
                        <div className='right-arrow rotate-down'>
                          <em className='icon be-down-arrow'></em>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-4">
                      <div className='app-card'>
                        <div className='data-content'>
                          <div className='thumbnail'>
                            <span>
                              <em className='icon be-multiple-users'></em>
                            </span>
                          </div>
                          <div className='description'>
                            <div className='sub-title'>IOS</div>
                            <div className='title'>Makro NextGen CRM</div>
                          </div>
                        </div>
                        <div className='right-arrow rotate-down'>
                          <em className='icon be-down-arrow'></em>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="page-filters-wrapper">
                <div className="left-filter btn-wrapper">
                  <span aria-label="count" className="count filter-title">Projects ({projectList?.length ?? 0})</span>
                  {isAllowedToAddProject && <Button data-testid="add-btn-dialog" variant="contained" className="xs-btn is-icon-btn purple-btn has-border" onClick={CreateProjectDialogOpen}><em className='icon be-plus'></em></Button>}
                </div>
                <div className="right-filter">
                  <div className="form-group sort-by">
                    <span className="dropdown-text">Sort by</span>
                    <Select 
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "right"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "right"
                        }
                      }} 
                      className="borderless-dropdown sm-height no-label" 
                      defaultValue={'Latest'} 
                      onChange={sortProjectList}
                    >
                      <MenuItem className='sorting-menu-item' value="Number of BCs">Number of BCs</MenuItem>
                      <MenuItem className='sorting-menu-item' value="A-Z">A-Z</MenuItem>
                      <MenuItem className='sorting-menu-item' value="Z-A">Z-A</MenuItem>
                      <MenuItem className='sorting-menu-item' value="Latest">Latest</MenuItem>
                      <MenuItem className='sorting-menu-item' value="Oldest">Oldest</MenuItem>
                    </Select>
                  </div>
                  <FormControl variant="filled" className='search-input'>
                    {/* @ts-ignore */}
                    <FilledInput inputProps={{ 'data-testid': "searchProjects" }} type="text" variant="filled" className="xs-input search-input-wrap" placeholder='Search projects'
                      startAdornment={
                        <InputAdornment position="start"><em className='be-search'></em></InputAdornment>
                      }
                      key={random}
                      endAdornment={
                        <InputAdornment position="end" className='search-icon-wrap'>
                          {projectSearchText && <img src={images.iconCrossCircle} alt="" onClick={clearInput}/>}
                        </InputAdornment>                        
                      }
                      onChange={searchProjects}
                    />
                  </FormControl>
                  <div className="button-grouping">
                    <Tooltip placement='bottom' title='Grid View' style={{'width':'auto'}}>
                      <Button data-testid="tab-grid-view" variant="contained" className={`inner-btn ${overviewTabValue === 'tabGridView' ? "active" : ""}`} onClick={() => setOverviewTabValue('tabGridView')}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M2.5 3.99805C2.5 3.16962 3.17157 2.49805 4 2.49805H7C7.82843 2.49805 8.5 3.16962 8.5 3.99805V6.99805C8.5 7.82647 7.82843 8.49805 7 8.49805H4C3.17157 8.49805 2.5 7.82647 2.5 6.99805V3.99805Z" className='stroke' strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path fillRule="evenodd" clipRule="evenodd" d="M2.5 12.998C2.5 12.1696 3.17157 11.498 4 11.498H7C7.82843 11.498 8.5 12.1696 8.5 12.998V15.998C8.5 16.8265 7.82843 17.498 7 17.498H4C3.17157 17.498 2.5 16.8265 2.5 15.998V12.998Z" className='stroke' strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path fillRule="evenodd" clipRule="evenodd" d="M11.5 3.99805C11.5 3.16962 12.1716 2.49805 13 2.49805H16C16.8284 2.49805 17.5 3.16962 17.5 3.99805V6.99805C17.5 7.82647 16.8284 8.49805 16 8.49805H13C12.1716 8.49805 11.5 7.82647 11.5 6.99805V3.99805Z" className='stroke' strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path fillRule="evenodd" clipRule="evenodd" d="M11.5 12.998C11.5 12.1696 12.1716 11.498 13 11.498H16C16.8284 11.498 17.5 12.1696 17.5 12.998V15.998C17.5 16.8265 16.8284 17.498 16 17.498H13C12.1716 17.498 11.5 16.8265 11.5 15.998V12.998Z" className='stroke' strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </Button>
                    </Tooltip>
                    <Tooltip placement='bottom' title='List View' style={{'width':'auto'}}>
                      <Button variant="contained" className={`inner-btn ${overviewTabValue === 'tabListView' ? "active" : ""}`} onClick={() => setOverviewTabValue('tabListView')}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M3 4.49805C3 3.66962 3.67157 2.99805 4.5 2.99805H15.5C16.3284 2.99805 17 3.66962 17 4.49805V15.498C17 16.3265 16.3284 16.998 15.5 16.998H4.5C3.67157 16.998 3 16.3265 3 15.498V4.49805Z" className='stroke' strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M3 6.99805H17" className='stroke' strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M10 16.998V6.99805" className='stroke' strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M3 11.998H17" className='stroke' strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              </div>

              {(!loading) && !projectList?.length && emptyState()}

              {/* Accordion wrapper: list and grid view */}
              { (loading && !loadingSel?.loading) && <div><img src={(images as any).buAnim} alt="B" style={{ position: 'fixed', top: '60%', left: '50%', height: '80px', width:'80px', transform: 'translate(-50%, -50%)' }}/></div> }
              {shouldVisible && <div className='accordion-table-holder'>

                {/* Table content start */}
                {projectList?.length > 0 && <div className="table-wrapper table-responsive accordion-head-table-wrap">
                  <TableContainer>
                    <Table>
                      <TableBody>
                        {!loading && projectList?.map((project, idx) => {
                          return <TableRow key={project?.id}>
                            <TableCell colSpan={5}>
                              <div className='accordion-wrapper'>
                                <Accordion expanded={expanded[idx] == true} onChange={(e, isExpanded) => setExpanded(isExpanded ? {...expanded, [idx]: true} : {...expanded, [idx]: false})}>
                                  <AccordionSummary
                                    //expandIcon={<span>more...</span>}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                    <div className="space-ac-wrapper">
                                      <div className='ac-left'>
                                        {project?.build_cards?.length > 0 && <em className='arrow-icon be-right-arrow'></em>}
                                        <span className='text-lable'>{project?.name}</span>
                                        {/* <em className="rating">
                                          <img src={images.starRatingGold} alt="rating" />
                                        </em> */}
                                      </div>
                                      <div className="ac-right">
                                        <div className="inner-data">
                                          <div className="counts">BuildCards: <span className="value">{project?.build_cards?.length}</span></div>
                                        </div>
                                        <div className="inner-data">
                                          <div className="counts">Members: <span className="value">{(project?.owner?.id ? 1 : 0) + project?.members?.length}</span></div>
                                        </div>
                                        {/* TODO : to be enabled later
                                        <div className="inner-data">
                                          <div className="tag-wrapper">
                                            <span className="custom-tag is-purple">Reduce delivery time by 2 days</span>
                                            <span className="custom-tag is-purple" onClick={(e) => moreTagsDropdownClick(idx, e)}>+3</span>
                                            <Menu
                                              className='taglist-menu'
                                              anchorEl={anchorEl && anchorEl[idx]}
                                              open={ Boolean(anchorEl && anchorEl[idx]) }
                                              onClose={() => setAnchorEl(null)}
                                              anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                              }}
                                              transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                              }}
                                            >
                                              <div className='tag-row'><span className="custom-tag is-purple">Reduce shipping cost by 10%</span></div>
                                              <div className='tag-row'><span className="custom-tag is-purple">Reduce shipping cost by 10%</span></div>
                                              <div className='tag-row'><span className="custom-tag is-purple">Reduce shipping cost by 10%</span></div>
                                            </Menu>
                                          </div>
                                        </div> */}
                                        <div className="inner-data">
                                          <a data-testid="navigate-btn" className='primary-link' onClick={(e) => navigateToProjectDetail(e, project)}>Details</a>
                                        </div>
                                      </div>
                                    </div>
                                  </AccordionSummary>

                                  {/* Accordion expanded */}
                                  <AccordionDetails>
                                    {/* List table wrapper */}
                                    {overviewTabValue == 'tabListView' && <div className="table-wrapper table-responsive expended-list-table-wrap">
                                      {project?.['build_cards']?.length > 0 && <TableContainer>
                                        <Table>
                                          <TableHead>
                                            <TableRow>
                                              <TableCell width='28%'>Name <em className='icon be-down-arrow-1' aria-label="sort-btn"></em></TableCell>
                                              <TableCell>Tags</TableCell>
                                              <TableCell>Status</TableCell>
                                              <TableCell>Delivery</TableCell>
                                              <TableCell>Features</TableCell>
                                              <TableCell>Platforms</TableCell>
                                              <TableCell>Progress</TableCell>
                                              <TableCell>Credits</TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>

                                            {project?.['build_cards']?.map(card => <TableRow key={card?.id}>
                                              <TableCell width='28%'>
                                                <span data-testid="navigate-heading" style={{ 'cursor': 'pointer' }} onClick={() => (project?.['can_update'] || card?.state == 'draft' || card?.state == 'pending_approval') ? redirect(card, project?.id) : null}>{card?.name}</span>
                                              </TableCell>
                                              <TableCell>
                                                {/* will be hidden once clicked and added tags */}
                                                <div className="card-tags-wrap list-view">
                                                  {!card?.entity_tags?.length && <Tooltip placement='top-end' arrow title={isAllowedToUpdateEntityTag ? "Add tag" : "Cannot add tag"} classes={{ popper: 'info-tooltip' }}>
                                                    <Button variant="contained" className="xs-btn is-icon-btn purple-btn has-border" onClick={(e) => moreTagsDropdownClick(card?.id, e, setAnchorEl)}><em className='icon be-tag-pin'></em></Button>
                                                  </Tooltip>}
                                                </div>

                                                {/* wll be shown once tags added */}
                                                <div className="card-tags-wrap list-view">
                                                  {card?.entity_tags?.length > 0 && <Button data-testid="open-menu-btn" variant="contained" className="xs-btn is-icon-btn purple-btn has-border whitebg"  onClick={(e) => moreTagsDropdownClick(card?.id, e, setAnchorEl)}>+{card?.entity_tags?.length}</Button>}
                                                  <Menu
                                                      className='taglist-menu cards'
                                                      disableRestoreFocus
                                                      anchorEl={anchorEl?.[card?.id]}
                                                      open={ Boolean(anchorEl?.[card?.id]) }
                                                      onClose={handleClose}
                                                      anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                      }}
                                                      transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                      }}
                                                    >
                                                      {card?.entity_tags?.length > 0 ? <div className='selected-tags'>                                                        
                                                        {card?.entity_tags?.map(tag => <Stack key={tag?.id} direction="row" className='purple-chips' spacing={1}>
                                                        <Chip label={tag?.name} onClick={() => {setShowSearchTags(tag); handleClose()}} onDelete={canDelete() ? () => {deleteTag(tag?.id); handleClose()} : null} deleteIcon={<span data-testid="delete-tag" className='icon be-close-dark sm-close'></span>} />
                                                        </Stack>)}
                                                      </div> : 
                                                      <div className="empty-tags-wrapper">
                                                        <p className="empty-text">No tag added yet</p>
                                                      </div>}
                                                      {isAllowedToUpdateEntityTag && tagInput(card)}
                                                      <div style={{maxHeight: '260px'}}>
                                                        {tagList?.map(item => <div key={item?.id} className='tag-row'>
                                                          <Stack direction="row" className='purple-chips' spacing={1}>
                                                            <Chip label={item?.name} onClick={() => addTags(item?.name, card)}/>
                                                          </Stack>
                                                        </div>)}
                                                      </div>
                                                    </Menu>
                                                </div>
                                              </TableCell>
                                              <TableCell>
                                                <Stack direction="row" spacing={1}>
                                                  <Chip label={getCardLable(card?.state, card?.status)} className={getBcState(card?.state, card?.status)} />
                                                </Stack>
                                              </TableCell>
                                              <TableCell>{card?.delivery_date ? moment(card?.delivery_date).format('DD.MM.YYYY') : '-'}</TableCell>
                                              <TableCell>{card?.number_of_features}</TableCell>
                                              <TableCell>{card?.number_of_platforms}</TableCell>
                                              <TableCell>
                                                <span>{!['draft', 'pending_approval', 'ready_for_kickoff'].includes(card?.state) ? 
                                                  <div className='progress-circle'>
                                                    <Tooltip className='progress-tooltip-popover' placement="bottom" enterTouchDelay={0} title={
                                                      <div className='credits'>
                                                        <span>{card?.completion ?? 0}%</span>
                                                      </div>
                                                    }>
                                                      <div className="progress-with-bg">
                                                        <CircularProgress size={24} variant="determinate" thickness={4} className='bg-progress' value={100} />
                                                        <CircularProgress size={24} variant="determinate" thickness={4} value={card?.completion ?? 0} />
                                                      </div>
                                                    </Tooltip>
                                                  </div> : 'N/A'
                                                }</span>
                                              </TableCell>
                                              <TableCell>120</TableCell>
                                            </TableRow>)}

                                          </TableBody>
                                        </Table>
                                      </TableContainer>}
                                    </div>}
                                    {/* List table wrapper end */}

                                    {/* Grid view cards wrapper */}
                                    {overviewTabValue == 'tabGridView' && project?.['build_cards']?.length > 0 && <div className='grid-view-cards-wrapper'>
                                      <div className='data-container'>
                                        <div className='row'>
                                          {project?.['build_cards']?.map((card, idx) => <div key={card?.id} className="col-xs-12 col-sm-4">
                                            <div className='card-template'>
                                              <div className='card-body'>
                                                <div className='tag-container has-right-content'>
                                                  <Stack direction="row" spacing={1}>
                                                    <Chip label={getCardLable(card?.state, card?.status)} className={getBcState(card?.state, card?.status)} />
                                                  </Stack>
                                                  {isAllowedTo('read', 'space_goal', permissions, entity, userPermissions) && <GoalsTags {...{card, idx, spaceGoals, searchSpaceGoalList, updateGoals}} />}
                                                </div>

                                                <div className='inner-container'>
                                                  <div className='left'>
                                                    {card?.state == 'draft' && <div className='date-container'>
                                                      <span className='title'> </span><span>{card?.weeks ? `${card?.weeks} weeks` : ''}</span>
                                                    </div>}
                                                    <div className='date-container'>
                                                      <span className='title'>DELIVERY </span><span>{card?.delivery_date? moment(card?.delivery_date).format('DD.MM.YYYY') : '-'}</span>
                                                    </div>
                                                    <div className='card-title-container'>
                                                      <span className='card-name' onClick={() => (project?.['can_update'] || card?.state == 'draft' || card?.state == 'pending_approval') ? redirect(card, project?.id) : null}>{card?.name}</span>
                                                      <div className='sub-heading'>
                                                        Lead: Aakash Kumar
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className='right'>
                                                    <div className='progress-circle'>
                                                      <Tooltip className='progress-tooltip-popover' placement="bottom" enterTouchDelay={0} title={
                                                        <div className='credits'>
                                                          <span>{card?.completion ?? 0}%</span>
                                                        </div>
                                                      }>
                                                        <span>{!['draft', 'pending_approval', 'ready_for_kickoff'].includes(card?.state) && 
                                                          <div className="progress-with-bg">
                                                            <CircularProgress size={40} variant="determinate" className='bg-progress' value={100} />
                                                            <CircularProgress size={40} variant="determinate" value={card?.completion ?? 0} />
                                                            <span className="progress-val">{Math.ceil(card?.completion ?? 0)}%</span>
                                                          </div>
                                                        }</span>
                                                      </Tooltip>
                                                    </div>
                                                  </div>
                                                </div>
                                                {isAllowedTo('read', 'tag', permissions) && <div className='card-tags-wrap'>
                                                  <CardEntityTags {...{card, setShowSearchTags, deleteTag, tagList, addTags, tagChangeHandler}}/>
                                                </div>}
                                                
                                              </div>
                                              <div className='card-footer'>
                                                <div className='content-box'>
                                                  <span className='title'>Features</span>
                                                  <div className='count'>{card?.number_of_features}</div>
                                                </div>
                                                <div className='content-box'>
                                                  <span className='title'>Platforms</span>
                                                  <div className='count'>{card?.number_of_platforms}</div>
                                                </div>
                                                <div className='content-box'>
                                                  <span className='title'>Credits</span>
                                                  <div className='count'>120</div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>)}

                                        </div>
                                      </div>
                                    </div>}
                                    {/* Grid view cards wrapper end */}
                                  </AccordionDetails>
                                  {/* Accordion expanded end */}

                                </Accordion>
                              </div>
                            </TableCell>

                          </TableRow>
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>}
                {/* Table content end */}

              </div>}

            </div>

            <Summary tab={"Space"}></Summary>

          </section>

        </div>
      </div>

      {/* Create project modal start */}
      {CreateProject && <CreateSpaceProjectDialog {...{ CreateProject, CreateProjectDialogClose, AddNewGoalDialogOpen, action: 'Create' }} />}
      {/* Create project modal end */}


      {/* Add new goal modal start */}
      <AddGoalDialog {...{ AddNewGoal, AddNewGoalDialogClose }} />
      {/* Add new goal modal end */}

      {/* Tag result section */}
      {showSearchTags && <TagResultSection {...{showSearchTags, setShowSearchTags}}/>}
      {/* Tag result section end */}
    </> : <UnauthorizedAccess />
  )
}
