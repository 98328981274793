/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint eqeqeq: 0 */
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { Tooltip } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useNavigate } from "react-router-dom";

import images from '../assets/images';
import consumer from '../shared/cable';
import useOutSideClick from '../shared/hooks/UseOutsideClick';
import instance from '../shared/interceptor';
import { updateSpaceList, updateSpaceListState } from '../shared/store/slices/companySlice';
import { addUserData, companyState, permissionState, resetUser, userState } from '../shared/store/slices/userSlice';
import {
  BUSINESS_GOALS,
  COMPANY_CREDITS,
  COMPANY_SETTINGS, COMPANY_SPACE,
  INTEGRATIONS_INDEX,
  MEMBER_INDEX,
  MEMBER_PROFILE, NAV_PANEL, NOTIFICATION_DETAIL, PLAN_PAYMENTS, ROLES_PERMISSION, SESSION_DETAIL, SIGNOUT
} from '../shared/util/constant';
import { clearStorage, deleteCookie, getCookie, getFullname, getInitials, globalError, isAllowedTo, navigateAccordingToRoleAndPaymentStatus, setLoader, setUserToStorage } from '../shared/util/util';
import './sidenav.scss';

moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'Just Now',
    ss: '%s secs',
    m: '1 min',
    mm: '%d mins',
    h: '1 hr',
    hh: '%d hrs',
    d: '1 day',
    dd: '%d days',
    M: '%d Month',
    MM: '%d Months',
    // y:  '1 year',
    // yy: '%d Years'
  }
});

export default function Sidenav() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userSel = useSelector(userState);
  const compSel = useSelector(companyState);
  const permissions = useSelector(permissionState);
  const updateList = useSelector(updateSpaceListState);

  const navList = [
    { label: 'Spaces', icon: 'be-multiple-users', isActive: false, route: COMPANY_SPACE, canActivate: isAllowedTo('update', 'space', permissions), disabled: false },
    { label: 'Credits', icon: 'be-credit-card', isActive: false, route: COMPANY_CREDITS, canActivate: isAllowedTo('read', 'plan_credit_subscription', permissions) && compSel?.active_plan_credit_subscription?.id, disabled: false },
    { label: 'Members', icon: 'be-user-group', isActive: false, route: MEMBER_INDEX, canActivate: isAllowedTo('update', 'user', permissions), disabled: false },
    { label: 'Integrations', icon: 'be-integrations', isActive: false, route: INTEGRATIONS_INDEX, canActivate: isAllowedTo('read', 'integration', permissions), disabled: false },
    { label: 'Automations', icon: 'be-automations', isActive: false, route: '/', canActivate: true, disabled: true },
    { label: 'Roles & Permissions', icon: 'be-roles-permission', isActive: false, route: ROLES_PERMISSION, canActivate: isAllowedTo('update', 'role', permissions), disabled: false },
    { label: 'Billing & Payment', icon: 'be-bill-payments', isActive: true, route: `${PLAN_PAYMENTS}/credits`, canActivate: isAllowedTo('update', 'purchase', permissions), disabled: false },
    { label: 'Account settings', icon: 'be-account-settings', isActive: false, route: COMPANY_SETTINGS, canActivate: isAllowedTo('update', 'company', permissions), disabled: false },
    { label: 'Business goals', icon: 'be-business-goals', isActive: false, route: BUSINESS_GOALS, canActivate: isAllowedTo('update', 'company_goal', permissions), disabled: false },
  ]

  const [avatar, setAvatar] = useState(null);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [accordionExpanded, setAccordionExpanded] = useState();
  const [SpaceClassToggle, setSpaceClassToggle] = useState(false);
  const [CompanyClassToggle, setCompanyClassToggle] = useState(false);
  const [SpaceSearchToggle, setSpaceSearchToggle] = useState(false);
  const [subMenuItems, setSubMenuItems] = useState([]);
  const [filteredSubMenuItems, setFilteredSubMenuItems] = useState([]);
  const [isComponentVisible, setIsComponentVisible] = useState(true);
  const [notificationTabValue, setNotificationTabValue] = useState('new');
  const [companyList, setCompanyList] = useState(navList);
  const [notificationLoading, setNotificationLoading] = useState(false);
  const [notification, setNotification] = useState([]);
  const [notificationMetaData, setNotificationMetaData] = useState({
    nextPage: 1,
    perPage: 0,
    totalCount: 0,
    totalPages: 0
  });
  const [shouldVisible, setShouldVisible] = useState(false);
  const notificationPanel = document.getElementById('notificationsPanel');
  const [notify, setNotify] = useState(false);

  const ref = useOutSideClick(e => {
    setIsComponentVisible(false);
  });
  const spaceRef = useOutSideClick(e => {
    setSpaceClassToggle(false);
  });
  const companyRef = useOutSideClick(e => {
    setCompanyClassToggle(false);
    document.body.classList.remove("sidenav-submenu-fixed");
  });
  const notificationRef = useOutSideClick(e => {
    notificationPanel?.classList?.remove("active");
  });


  useEffect(() => {
    setCompanyList(navList);
  }, [permissions]);

  useEffect(() => {
    if (userSel?.profile_picture?.profile_picture_url) {
      setAvatar(userSel.profile_picture.profile_picture_url);
    } else {
      setAvatar(null);
    }
  }, [userSel?.profile_picture?.profile_picture_url])

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    getSpaceAndProjects(signal);

    return () => {
      controller.abort();
    }
  }, [compSel?.id])

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    getSpaceAndProjects(signal);

    return () => {
      controller.abort();
    }
  }, [])

  useEffect(() => {
    consumer.subscriptions.create({
      channel: `NotificationPublishChannel`, channel_id: `${userSel?.email}`
    }, {
      connected: () => console.log('connected'),
      disconnected: () => console.log('disconnected'),
      received: data => {
        setNotify(true);
      },
    });

    return () => {
      consumer.disconnect();
    }
  }, [])

  const getSpaceAndProjects = async (signal) => {
    try {
      let company = localStorage.getItem('company') ? JSON.parse(localStorage.getItem('company')) : compSel ?? null;
      if (company?.id) {
        const url = `${NAV_PANEL.replace(':id', company?.id)}`;
        let menuRes = await instance.get(url, { signal });
        if (menuRes?.['spaces']) {
          setSubMenuItems(menuRes['spaces']);
          setFilteredSubMenuItems(menuRes['spaces']);
        }
      }
      dispatch(updateSpaceList(false));
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  }

  const searchSpaces = (e) => {
    if (!e?.target?.value) {
      setFilteredSubMenuItems(subMenuItems);
      return;
    }
    let filteredItems = subMenuItems.filter(item => item?.name?.toLowerCase().includes(e?.target?.value?.toLowerCase()));
    setFilteredSubMenuItems(filteredItems);
  }

  const accordionHandleChange = (faqPanel, subMenu) => (event, newExpanded) => {
    if (subMenu?.projects?.length > 0) {
      setAccordionExpanded(newExpanded ? faqPanel : false);
    }
  };

  const mobMenuToggle = () => {
    document.body.classList.toggle("sidenav-visible");
    setUserMenuOpen(false);
  };

  const spaceHover = () => {
    setSpaceClassToggle(prev => !prev);
    setCompanyClassToggle(false);
    document.body.classList.toggle("space-menu-fixed");
    document.body.classList.remove("company-menu-fixed");
    if (SpaceClassToggle) {
      document.body.classList.remove("sidenav-submenu-fixed");
    }
  };

  const companyHover = () => {
    setSpaceClassToggle(false);
    setCompanyClassToggle(prev => !prev);
    document.body.classList.remove("space-menu-fixed");
    document.body.classList.toggle("company-menu-fixed");
    if (CompanyClassToggle) {
      document.body.classList.remove("sidenav-submenu-fixed");
    }
  };

  const logout = async () => {
    try {
      setLoader(dispatch, true);
      await instance.delete(SIGNOUT);
      deleteCookie(process.env.REACT_APP_USER);
      clearStorage();
      dispatch(resetUser());
      navigate('/');
      window.location.reload();
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  }

  const notificationPanelToggle = () => {
    notificationPanel?.classList?.toggle("active");
    setUserMenuOpen(false);

    const controller = new AbortController();
    const signal = controller.signal;

    if (notificationPanel?.classList?.contains('active')) {
      setNotificationTabValue('new');
      getNotification('new', signal, true);
    } else {
      controller.abort();
    }
  };

  const getNotification = async (tab, signal?, newData = false) => {
    try {
      if (notificationMetaData?.nextPage || notificationTabValue != tab || newData) {
        let page = 1;
        if (notificationTabValue == tab && !newData) {
          page = notificationMetaData?.nextPage;
        } else {
          setNotificationLoading(true);
        }
        const url = `${NOTIFICATION_DETAIL.replace(':id', compSel?.id).replace(':user_id', userSel?.id)}/${tab}_notifications?page=${page}`;
        let newNotificationRes = await instance.get(url, { signal });
        if (newNotificationRes?.[`${tab}_notifications`]) {
          setNotificationMetaData({
            nextPage: newNotificationRes?.['next_page'],
            perPage: newNotificationRes?.['per_page'],
            totalCount: newNotificationRes?.['total_count'],
            totalPages: newNotificationRes?.['total_pages']
          })
          if (notificationTabValue == tab && !newData) {
            setNotification(prev => [...prev, ...newNotificationRes[`${tab}_notifications`]]);
          } else {
            setNotification(newNotificationRes[`${tab}_notifications`]);
          }
          if (tab == 'new' && page > 1) {
            setNotify(true);
          }
        }
      }
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setNotificationLoading(false);
      setShouldVisible(true);
    }
  }

  const markAsRead = async (notificationIds) => {
    if (!notificationIds || !notificationIds?.length) return;
    try {
      const url = `${NOTIFICATION_DETAIL.replace(':id', compSel?.id).replace(':user_id', userSel?.id)}/mark_as_read`;
      const payload = {
        notification_ids: [...notificationIds]
      }
      let markReadRes = await instance.patch(url, payload);
      let temp = notification.map(item => {
        if (notificationIds.includes(item?.id)) {
          return { ...item, read: true }
        } else {
          return { ...item }
        }
      });
      setNotification(temp);
      let notifyRead = temp.some(item => !item?.read);
      if (!notifyRead || notificationIds?.length > 1) {
        setNotify(false);
      }
      dispatch(addUserData({ ...userSel, pending_notifications: markReadRes?.['pending_notifications'] }));
      isTokenPresent();
    } catch (e) {
      globalError(dispatch, e);
    }
  }

  const tabHandleChange = (event: React.SyntheticEvent, newValue: string) => {
    setNotificationTabValue(newValue);
  };

  const isNewNotification = useCallback(() => {
    if (notify) return true;
    if (userSel?.pending_notifications) return true;
    return false;
  }, [notify, userSel?.pending_notifications])

  // const joinMeeting = (meet, spaceId) => {
	// 	dispatch(bmeetUrl(meet));
	// 	navigate(`/company/spaces/${spaceId}/meeting-playground/${meet?.id}`, {
  //     state: true
  //   });
	// }

  const routeTo = (notification) => {
    if (notification?.meta_data?.button?.text == 'View Buildcard') {
      window.open(notification?.meta_data?.button?.url, '__blank')
    } 
    // else if (notification?.meta_data?.button?.text == 'Join Now') {
    //   let params = new URLSearchParams(notification?.meta_data?.button?.url);
    //   let data = {id: params?.get('meeting_id'), url: notification?.meta_data?.button?.url, title: params?.get('title')};
    //   joinMeeting(data, params?.get('space_id'));
    // } 
    else {
      navigate(notification?.meta_data?.button?.url.replace(process.env.REACT_APP_DOMAIN, ''))
    }
  }

  const userCookie = getCookie(process.env.REACT_APP_USER);

  const isTokenPresent = async () => {
    try {
      const studioId = userCookie ? JSON.parse(userCookie)?.['id'] : null;
      const res = await instance.get(SESSION_DETAIL, { headers: { 'studio-id': studioId } });
      setUserToStorage(dispatch, addUserData, res, true);
    } catch(e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  }
  
  //==============================================
  return (
    <div ref={companyRef}>
      <div aria-label="menu-toggle" className="mobile-menu-icon" onClick={mobMenuToggle}>
      </div>

      {/* <div aria-label='menu-expand' className="menu-expand-btn" onClick={menuSticky}>
        <img src={images.expandNnavIcon} alt="Expand Icon" />
      </div> */}

      <aside className="be-sidenav">
        <div className="sidenav-outer">
          <div>
          <ul className="top-sidenav">
            <li>
              <a href={void (0)}  onClick={() => {navigateAccordingToRoleAndPaymentStatus(navigate, compSel, userSel); setSpaceClassToggle(false); setCompanyClassToggle(false);}}>
                <em className="site-logo be-builder-logo-b"></em>
                <em className='builder-logo'><img src={images.builderEnterpriseLogo} alt="Logo" /></em>
              </a>
            </li>
            <li>
              <a href={void (0)} className="natasha-link">
                <img src={images.natashaLogoSm} alt="Natasha Logo" />
                <span className='nav-text'>Natasha</span>
              </a>
            </li>
          </ul>

          <ul className="middle-nav">
            <li>
              <a href={void (0)} onClick={() => {navigateAccordingToRoleAndPaymentStatus(navigate, compSel, userSel); setSpaceClassToggle(false); setCompanyClassToggle(false);}} className={`be-house ${window.location.pathname == '/dashboard' ? 'active': ''} ${({ isActive }) => (isActive ? 'active' : '')}`}><span className='nav-text'>Home</span> </a></li>
              <li>
              <a href={void (0)}>
              <em aria-label='companyHover' className="middle-nav-link be-building" onClick={companyHover}></em>
              <span className='nav-text ' onClick={spaceHover}> Company <em className='be-down-arrow'></em></span>
              </a>
              <div className={"submenu-wrapper company-dropdown open-submenu " + (CompanyClassToggle ? 'active' : '')}>
                <div className="submenu-titlebar">
                  <h6>Company</h6>
                </div>
                <div className="submenu-body">
                  <ul className="submenu-list">
                    {companyList.filter(route => route.canActivate).map((company, i) => (
                      <Tooltip key={company.label} placement='right' arrow title={company?.disabled ? 'Coming soon' : ''} classes={{ popper: 'info-tooltip' }}>
                        <li>
                          {company?.disabled ? <div className="trigger-link">
                            <em className={`icon is-black ${company.icon}`}></em>
                            <span>{company.label}</span>
                          </div>
                            :
                            <NavLink to={company.route} className="trigger-link" onClick={companyHover}>
                              <em className={`icon is-black ${company.icon}`}></em>
                              <span>{company.label}</span>
                            </NavLink>
                          }
                        </li>
                      </Tooltip>
                    ))}
                  </ul>
                </div>
              </div>
            </li>
            <li ref={spaceRef}>
            <a href={void (0)}>
              <div className='d-flex justify-content-start'>
              <em className="middle-nav-link be-buildcard" aria-label='spaceHover' onClick={spaceHover}></em>
              <div className="submenu-titlebar build-hub">
                  <span className='nav-text'>Build Hub <span aria-label="open-space-elem" className="be-search search-trigger" onClick={() => setSpaceSearchToggle(true)}></span></span>
                  
                  {SpaceSearchToggle ?
                    <div className="submenu-searchbar" aria-label='side-spacelist'>
                      <span className="be-search search-icon "></span>
                      <input type="text" aria-label='search-space' className="search-input" placeholder='Search' onChange={searchSpaces} />
                      <span className="close-searchbar be-close" aria-label='clear-input' onClick={(e) => { e.preventDefault(); e.stopPropagation(); setFilteredSubMenuItems(subMenuItems); setSpaceSearchToggle(false) }}></span>
                    </div>
                    : ''}
                </div>
                </div>
                  </a>

              <div className={"submenu-wrapper company-dropdown " + (SpaceClassToggle ? 'default-fixed active' : '')}>
                <div className="submenu-titlebar">
                  <h6>Spaces</h6>
                  <span aria-label="open-space-elem" className="be-search search-trigger" onClick={() => setSpaceSearchToggle(true)}></span>
                  {SpaceSearchToggle ?
                    <div className="submenu-searchbar" aria-label='side-spacelist'>
                      <span className="be-search search-icon"></span>
                      <input type="text" aria-label='search-space' className="search-input" placeholder='Search' onChange={searchSpaces} />
                      <span className="close-searchbar be-close" aria-label='clear-input' onClick={(e) => { e.preventDefault(); e.stopPropagation(); setFilteredSubMenuItems(subMenuItems); setSpaceSearchToggle(false) }}></span>
                    </div>
                    : ''}
                </div>
                <div className="submenu-body">
                  <div className='custom-accordion'>
                    {!filteredSubMenuItems?.length &&
                      <div className="no-data-wrapper no-border sm-pad">
                        <em className="thumb"><img src={images.spaceHorizontalColoredIcon} alt="" /></em>
                        <h6 className='sm'>No space found!</h6>
                      </div>
                    }
                    {filteredSubMenuItems.map((subMenu, i) => (
                      <Accordion data-testid="accord" key={`buildcard_${subMenu?.id}`} expanded={accordionExpanded === `buildcard_${subMenu?.id}`} onChange={accordionHandleChange(`buildcard_${subMenu?.id}`, subMenu)}>
                        <AccordionSummary className={subMenu?.projects?.length > 0 ? 'expand-icon' : ''}>
                          <NavLink to={`/company/spaces/${subMenu?.id}/overview`} className={"trigger-link " + (accordionExpanded === `buildcard_${subMenu?.id}` ? 'active' : '')} onClick={(e) => {!subMenu?.private || (subMenu?.private && subMenu?.owner?.id === userSel?.id) ? spaceHover() : e.preventDefault()}}>
                            <em className="icon be-multiple-users" style={{ backgroundColor: `${subMenu?.color}` }}></em>
                            {subMenu?.name?.length > 15 ? <Tooltip placement='right' arrow title={subMenu?.name} classes={{ popper: 'info-tooltip' }}>
                              <span className='truncated-text'>{subMenu?.private && <img src={images.lock} alt="lock icon" />} {subMenu?.name} </span>
                            </Tooltip>
                              : <span> {subMenu?.private && <img src={images.lock} alt="lock icon" />} {subMenu?.name} </span>
                            }
                          </NavLink>
                        </AccordionSummary>
                        <AccordionDetails>
                          {subMenu?.projects?.length > 0 && <ul className="sub-project-list">
                            {subMenu?.projects?.map(item => {
                              return <li onClick={() => { navigate(`/company/spaces/${subMenu?.id}/project/${item?.id}/overview`); spaceHover() }} key={item?.id}>
                                <a href={void (0)}><em className="be-folder"></em>{item?.name}</a>
                              </li>
                            })}
                          </ul>}
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </div>
                </div>
              </div>
            </li>
            
          </ul>
          </div>
          <ul className="bottom-sidenav">
            {/* <li>
              <a href={void (0)} className="be-search"></a>
            </li> */}
            <li ref={notificationRef}>
              <a href={void (0)} aria-label='notification-btn'  onClick={() => notificationPanelToggle()}>
                <span className="be-notification"></span>
                {isNewNotification() && <em className="online-badge"></em>}
                <span className='notifications-info'>Notifications {userSel?.pending_notifications_count && <em className="badge">{userSel?.pending_notifications_count}</em>}</span>
              </a>
              <div className='notifications-panel' id="notificationsPanel">
                <div>
                  <div className='notification-header'>
                    <div className='left'>
                      <TabContext value={notificationTabValue}>
                        <Tabs value={notificationTabValue} onChange={tabHandleChange} aria-label="tabs">
                          <Tab value="new" label="New" onClick={() => getNotification('new')} aria-label="tab-new"/>
                          <Tab value="all" label="All" onClick={() => getNotification('all')} aria-label="tab-all"/>
                        </Tabs>

                      </TabContext>
                    </div>
                    <div className='right'>
                      <a href={void (0)} aria-label="mark-read-btn" className="mark-all-read" onClick={() => markAsRead(notification.map(item => item?.id))}>Mark all as read</a>
                    </div>
                  </div>

                  <div className='notifications-body'>
                    <TabContext value={notificationTabValue}>
                      {!notification.length && !notificationLoading && shouldVisible && <div className="no-data-wrapper notifications">
                        <em className="thumb"><img src={images.iconNoNotifications} alt="Empty" /></em>
                        <h4 aria-label="no-notification">No notification</h4>
                        <p>You don't have any new notification.</p>
                      </div>}
                      {notificationLoading && <div><img src={(images as any).buAnim} alt="B" style={{ position: 'fixed', top: '50%', left: '50%', height: '80px', width: '80px', transform: 'translate(-50%, -50%)' }} /></div>}
                      <TabPanel value="new" id="newNotificationTab">
                        <InfiniteScroll
                          dataLength={notificationMetaData.nextPage * notificationMetaData.perPage}
                          next={() => getNotification('new')}
                          hasMore={notification?.length < notificationMetaData.totalCount}
                          loader={!notificationLoading && <div style={{ 'textAlign': 'center' }}><img src={(images as any).buAnim} alt="B" style={{ height: '60px', width: '60px' }} /></div>}
                          scrollableTarget="newNotificationTab"
                        >
                          {!notificationLoading && notification?.length > 0 && notification?.map(noti => {
                            return <div className='notification-row' key={noti?.id} style={{ 'cursor': 'pointer' }} onClick={() => markAsRead([noti?.id])}>
                              <div className='user-link'>
                                <div className='left-content'>
                                  {!noti?.read && <span className='blue-dot'></span>}
                                  <span className='user-avatar'>
                                    <span className='initials'>{getInitials(noti?.creator_name) ?? 'S'}</span>
                                  </span>
                                  <div className='user-detail'>
                                    <div className='basic-info'>
                                      <div className='description-text'>
                                        <span dangerouslySetInnerHTML={{ __html: noti?.message?.replace(/<i href.*i>/, '') }}></span>
                                        {noti?.message?.includes('<i href') && <div className='btn-wrap'>
                                          <Button onClick={(e) => {
                                            e.stopPropagation();
                                            routeTo(noti);
                                            markAsRead([noti?.id]);
                                            notificationPanel?.classList?.remove("active");
                                          }}>{noti?.meta_data?.button?.text}</Button>
                                        </div>}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='right-content'>
                                  <div className="menu-trigger">
                                    <span className='time'>{moment(noti?.created_at).fromNow(true)}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          })}


                          {/* <div className='notification-row has-greyBG'>
                          <div className='user-link'>
                            <div className='left-content'>
                              <span className='blue-dot'></span>
                              <span className='user-avatar'>
                                <span className='initials'>A</span>
                              </span>
                              <div className='user-detail'>
                                <div className='basic-info'>
                                  <div className='description-text'>
                                    <a href={void (0)}><strong>Astrid Rani</strong></a> has requested your approval for the Buildcard <a href={void (0)}><strong>Warehouse Scanner</strong></a>
                                    <div className='btn-wrap'>
                                      <Button>Review buildcard</Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='right-content'>
                              <div className="menu-trigger">
                                <span className='time'>30 min</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='notification-row'>
                          <div className='user-link'>
                            <div className='left-content'>
                              <span className='blue-dot'></span>
                              <span className='user-avatar'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                                  <g filter="url(#filter0_d_11641_12107)"><circle cx="28" cy="26" r="24" fill="white"/><circle cx="28.5" cy="26.5" r="14.5" fill="#B388FF"/><circle cx="28.5" cy="26.5" r="8.93529" fill="#6200EA" stroke="black" strokeWidth="1.12941"/></g>
                                  <defs><filter id="filter0_d_11641_12107" x="0" y="0" width="56" height="56" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB"><feFlood floodOpacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="2"/><feGaussianBlur stdDeviation="2"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_11641_12107"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_11641_12107" result="shape"/></filter></defs>
                                </svg>
                              </span>
                              <div className='user-detail'>
                                <div className='basic-info'>
                                  <div className='description-text'>
                                    <a href={void (0)}><strong>You</strong></a> have been invited to join space <a href={void (0)}><strong>Logistics</strong></a>

                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='right-content'>
                              <div className="menu-trigger">
                                <span className='time'>30 min</span>
                              </div>
                            </div>
                          </div>
                        </div> */}
                        </InfiniteScroll>
                      </TabPanel>
                    </TabContext>
                    <TabContext value={notificationTabValue}>
                      <TabPanel value="all" id="allNotificationTab">
                        <InfiniteScroll
                          dataLength={notificationMetaData.nextPage * notificationMetaData.perPage}
                          next={() => getNotification('all')}
                          hasMore={notification?.length < notificationMetaData.totalCount}
                          loader={!notificationLoading && <div style={{ 'textAlign': 'center' }}><img src={(images as any).buAnim} alt="B" style={{ height: '60px', width: '60px' }} /></div>}
                          scrollableTarget="allNotificationTab"
                        >
                          {!notificationLoading && notification?.map(noti => {
                            return <div className='notification-row' key={noti?.id} style={{ 'cursor': 'pointer' }} onClick={() => markAsRead([noti?.id])}>
                              <div className='user-link'>
                                <div className='left-content'>
                                  {!noti?.read && <span className='blue-dot'></span>}
                                  <span className='user-avatar'>
                                    {/* <img src={(images as any).supportAvatar} alt="Avatar" /> */}
                                    <span className='initials'>{getInitials(noti?.creator_name) ?? 'S'}</span>
                                  </span>
                                  <div className='user-detail'>
                                    <div className='basic-info'>
                                      <div className='description-text'>
                                        <span dangerouslySetInnerHTML={{ __html: noti?.message?.replace(/<i href.*i>/, '') }}></span>
                                        {noti?.message?.includes('<i href') && <div className='btn-wrap'>
                                          <Button onClick={(e) => {
                                            e.stopPropagation();
                                            routeTo(noti);
                                            notificationPanel?.classList?.remove("active")
                                          }}>{noti?.meta_data?.button?.text}</Button>
                                        </div>}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='right-content'>
                                  <div className="menu-trigger">
                                    <span className='time'>{moment(noti?.created_at).fromNow(true)}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          })}
                        </InfiniteScroll>
                      </TabPanel>
                    </TabContext>
                  </div>
                </div>
              </div>
            </li>
            <li ref={ref}>
              <div aria-label='open-bottom-modal' className='d-flex align-items-center'  onClick={() => { setUserMenuOpen(true); setIsComponentVisible(true); setSpaceClassToggle(false); setCompanyClassToggle(false); }}>
                <a className="user-menu">
                {avatar && <img src={avatar} alt="avatar" />}
                {!avatar && <span>{getInitials(getFullname(userSel?.first_name, userSel?.last_name))}</span>}
                </a>
                <div className='notifications-info'>{getFullname(userSel?.first_name, userSel?.last_name)}
                  <span>{userSel?.email}</span>
                </div>
              </div>
              {isComponentVisible && <div className={"user-menu-outer " + (userMenuOpen ? 'open' : '')}>
                <span className="close-menu be-close" onClick={() => { setUserMenuOpen(false); setIsComponentVisible(false) }}></span>
                <div className="user-info">
                  <em className="dp">
                    {avatar && <img src={avatar} alt="avatar" />}
                    {!avatar && <span>{getInitials(getFullname(userSel?.first_name, userSel?.last_name))}</span>}
                  </em>
                  <span className="name">{getFullname(userSel?.first_name, userSel?.last_name)}</span>
                </div>
                <ul className="links">
                  <li>
                    <Link to={MEMBER_PROFILE} onClick={() => { setUserMenuOpen(false); setIsComponentVisible(false) }}>My profile & settings</Link>
                  </li>
                  <li>
                    <a aria-label="logout-elem" onClick={logout}>Log out</a>
                  </li>
                </ul>
              </div>}
            </li>
          </ul>
        </div>
      </aside >
    </div>
  )
}