/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint eqeqeq: 0 */
import instance from "../interceptor";
import { currentProject, currentSpace } from "../store/slices/companySlice";
import { setSpaceCreditData } from "../store/slices/spaceCreditSlice";
import { setSuccessMsg } from "../store/slices/successSlice";
import { COMPANY, ENT_USERS, PROJECTS, SPACES, USER_SEARCH } from "../util/constant";
import { globalError, setLoader } from "../util/util";


export const getProjectDetail = async (dispatch, compId, paramId, paramProjectId, navigate = null, route = null) => {
    try {
      setLoader(dispatch, true);
      const url = `${PROJECTS.replace(':id', compId).replace(':space_id', paramId)}/${paramProjectId}`;
      let projectRes = await instance.get(url);
      dispatch(currentProject(projectRes['project']));
      if (navigate) {
        navigate(route);
      }
    } catch(e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  }

export const getCurrentSpaceDetail = async (dispatch, compId, paramId, navigate = null, route = null) => {
  try {
      setLoader(dispatch, true);
      const url = `${SPACES.replace(':id', compId)}/${paramId}`;
      const spaceRes = await instance.get(url);
      dispatch(currentSpace(spaceRes['space']));
      if (navigate) {
        navigate(route)
      }
  } catch(e) {
      globalError(dispatch, e);
  } finally {
    setLoader(dispatch, false);
  }
}

export const getAllTags = async (compSel, dispatch, query?) => {
  try {
    let url = `${COMPANY}/${compSel?.id}/tags`;
    if (query) {
      url += `?q=${encodeURIComponent(query?.toLowerCase())}`
    }
    return await instance.get(url);
  } catch (e) {
    globalError(dispatch, e);
  }
}

export const addTag = async (compSel, dispatch, tagName, card) => {
  try {
    tagName = tagName?.trim()?.toLowerCase();
    if (!tagName) return;
    setLoader(dispatch, true);
    let url = `${COMPANY}/${compSel?.id}/entity_tags`;
    let payload = {
      entity_tag: {
        name: encodeURIComponent(tagName),
        taggable_id: card?.enterprise_id,
        taggable_type: 'BuildCard'
      }
    }
    let res =  await instance.post(url, payload);
    dispatch(setSuccessMsg('Tag added successfully.'));
    return res;
  } catch (e) {
    globalError(dispatch, e);
  } finally {
    setLoader(dispatch, false);
  }
}

export const handleDelete = async (dispatch, compSel, tagId) => {
  try {
    setLoader(dispatch, true);
    const url = `${COMPANY}/${compSel?.id}/entity_tags/${tagId}`;
    let res = await instance.delete(url);
    dispatch(setSuccessMsg('Tag deleted successfully.'));
    return res;
  } catch (err) {
    globalError(dispatch, err);
  } finally {
    setLoader(dispatch, false);
  }
}

export const getSpaceMemberList = async (companySel, currentSpace, params, signal, dispatch) => {
  try {
    if (companySel?.id && (params?.id || currentSpace?.id)) {
      setLoader(dispatch, true);
      let url = `${SPACES.replace(':id', companySel?.id)}/${params?.id ?? currentSpace?.id}/all_members`;
      return await instance.get(url, { signal });
    }
  } catch (e) {
    globalError(dispatch, e);
  } finally {
    setLoader(dispatch, false);
  }
}

export const getConfirmedUserList = async (companySel, searchText, dispatch, setLoading) => {
  try {
      setLoading(true);
      let url = `${ENT_USERS.replace(':id', companySel?.id)}?confirmed=true`;
      if (searchText) {
          url += `&q=${searchText}`;
      }
      return await instance.get(url);
  } catch (e) {
      globalError(dispatch, e);
  } finally {
      setLoading(false);
  }
}

export const getUserListWithoutPermission = async(companySel, searchText, dispatch, setLoading) => {
  try {
    setLoading(true);
    let url = `${USER_SEARCH.replace(':id', companySel?.id)}`;
    if (searchText) {
        url += `?q=${searchText}`;
    }
    return await instance.get(url);
  } catch (e) {
    return;
      globalError(dispatch, e);
  } finally {
      setLoading(false);
  }
}

export const getAllSpaceGoals = async (dispatch, compSel, params) => {
  try {
    setLoader(dispatch, true);
    const url = `${SPACES.replace(':id', compSel?.id)}/${params?.id}/space_goals`;
    let res = await instance.get(url);
    return res;
  } catch (e) {
    globalError(dispatch, e);
  } finally {
    setLoader(dispatch, false);
  }
}

const getSpaceCreditDetail = async (compSel, currentSpaceSel) => {
  const url = `${COMPANY}/${compSel?.id}/plan_credit_subscriptions/${compSel?.active_plan_credit_subscription?.id}/space_credits/${currentSpaceSel?.active_space_credit_id}`;
  return instance.get(url);
}

const getBuildcardConsumption = async (compSel, currentSpaceSel) => {
  const url = `${COMPANY}/${compSel?.id}/plan_credit_subscriptions/${compSel?.active_plan_credit_subscription?.id}/space_credits/${currentSpaceSel?.active_space_credit_id}/buildcard_consumption_data`;
  return instance.get(url);
}

const getDraftBuildcardEstimation = async (compSel, currentSpaceSel) => {
  const url = `${COMPANY}/${compSel?.id}/plan_credit_subscriptions/${compSel?.active_plan_credit_subscription?.id}/space_credits/${currentSpaceSel?.active_space_credit_id}/draft_build_cards_estimation`;
  return instance.get(url);
}

export const getSpaceCreditData = async (compSel, currentSpaceSel, dispatch) => {
  try {
    setLoader(dispatch, true);
    let res = await Promise.all([getSpaceCreditDetail(compSel, currentSpaceSel,), getBuildcardConsumption(compSel, currentSpaceSel,), getDraftBuildcardEstimation(compSel, currentSpaceSel,)]);
    dispatch(setSpaceCreditData(res));
  } catch (err) {
    globalError(dispatch, err);
  } finally {
    setLoader(dispatch, false);
  }
}

