/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint eqeqeq: 0 */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import images from '../../../assets/images';
import instance from '../../../shared/interceptor';
import { companyState } from '../../../shared/store/slices/userSlice';
import { COMPANY } from '../../../shared/util/constant';
import { getFullname, getInitials, globalError, setLoader } from '../../../shared/util/util';
import { creditAllocationGraphData, creditConsumptionGraph, creditsGraph } from '../../dashboard/dashboardGraphData';


function CreditOverview({spaceCredits, setSpaceCredits, plan, graphData, searchSpaceCredits, getCreditPlanSub, getSpaceCredit, setValue}) {
    const dispatch = useDispatch();
    const compSel = useSelector(companyState);

    const [sortAsc, setSortAsc] = useState<boolean>(true);
    const [archive, setArchive] = useState(true);
    const [allocationGraphData, setAllocationGraphData] = useState(null);
    const [creditConsumptionGraphData, setCreditConsumptionGraphData] = useState(null);
    
    const handleSortChange = (event: SelectChangeEvent<string>) => {
        const newValue = event.target.value === 'asc';
        setSortAsc(newValue);
        if (newValue) {
            setSpaceCredits(searchSpaceCredits.sort((a, b) => a.allocated_credits - b.allocated_credits));
        } else {
            setSpaceCredits(searchSpaceCredits.sort((a, b) => b.allocated_credits - a.allocated_credits));
        }
    };

    useEffect(() => {
        getCreditPlanSub();
        getSpaceCredit();
        getDistributionGraphData();
        getCreditConsumptionGraphData();
    }, []);

    const toggleArchive = () => {
        setArchive(!archive);
        if(archive) {
            setSpaceCredits(searchSpaceCredits);
        } else {
            setSpaceCredits(searchSpaceCredits.filter(item => item?.space?.archive == false))
        }
    }

    const getDistributionGraphData = async () => {
        try {
            setLoader(dispatch, true);
            const url = `${COMPANY}/${compSel?.id}/plan_credit_subscriptions/${compSel?.active_plan_credit_subscription?.id}/allocation_distribution`;
            let res = await instance.get(url);
            setAllocationGraphData(res?.['plan_credit_subscription']);
        } catch(e) {
            globalError(dispatch, e);
        } finally {
            setLoader(dispatch, false);
        }
    }

    const getCreditConsumptionGraphData = async () => {
        try {
            setLoader(dispatch, true);
            const url = `${COMPANY}/${compSel?.id}/plan_credit_subscriptions/${compSel?.active_plan_credit_subscription?.id}/monthly_credit_consumption_data`;
            let res = await instance.get(url);
            setCreditConsumptionGraphData(res);
        } catch(e) {
            globalError(dispatch, e);
        } finally {
            setLoader(dispatch, false);
        }
    }

    //===================================
  return (
    <>
    <ul className="total-credits-block">
        <li>
            <div className="credits-box be-radius">
                <div className="credits-left">
                    <p>Total credits <em className="icon be-info"></em></p>
                    <h2 className='total'>{plan?.total_credits}</h2>
                </div>
                <div className="credits-right">
                    <em className="thumb"><img src={plan?.plan?.image_url} alt="Gold Plan" /></em>
                </div>
            </div>
        </li>

        <li>
            <div className="credits-box be-radius">
                <div className="credits-left">
                    <p>Credits allocated <em className="icon be-info"></em></p>
                    <h2 className='total'>{plan?.allocated_credits}<sub>{plan?.allocated_credits_percentage}%</sub></h2>
                </div>
                <div className="credits-right">
                    <em className="thumb"><img src={(images as any).iconBuilderCoin} alt="Builder Coin" /></em>
                </div>
            </div>
        </li>

        <li>
            <div className="credits-box be-radius">
                <div className="credits-left">
                    <p>Credit balance <em className="icon be-info"></em></p>
                    <h2 className='total'>{plan?.credit_balance}<sub>{plan?.credit_balance_percentage}%</sub></h2>
                </div>
                <div className="credits-right">
                    <em className="thumb"><img src={images.iconWallet} alt="Wallet" /></em>
                </div>
            </div>
        </li>
    </ul>

    <div className="credit-allocation-row">
        {allocationGraphData?.space_credits_count && <div className="credit-allocation-col">
            <div className="card">
                <div style={{display: 'flex', justifyContent: 'space-between'}}><h4>Credit allocation distribution </h4><h6>{allocationGraphData?.allocated_credits_percentage ?? 0} %</h6></div>
                <span>{allocationGraphData?.space_credits_count} Spaces</span>
                <HighchartsReact highcharts={Highcharts} options={creditAllocationGraphData(allocationGraphData)}/>
            </div>
        </div>}
        {Object.keys(creditConsumptionGraphData ?? {})?.length > 0 && <div className="credit-allocation-col">
            <div className="card">
                <h4>Allocated credits consumption</h4>
                <span>This graph shows the accumulated usage of credits in the current cycle. </span>
                <HighchartsReact highcharts={Highcharts} options={creditConsumptionGraph(creditConsumptionGraphData)}/>
            </div>
        </div>}
    </div>

    {!plan?.allocated_credits && <div className="no-data-wrapper no-allocations">
      <em className="thumb"><img src={images.emptyStateIllustration} alt="Spaces Flag" /></em>
      <h4 aria-label="no-space">No allocations yet</h4>
      <p>You have not allocated credits to your spaces yet.</p>
      <Button variant="contained" className="xs-btn" onClick={() => setValue('2')}>Allocate Credits</Button>
    </div>}
    
    <div className="current-allocations card">
        <div className='d-flex justify-content-between'>
            <h4>Current allocations</h4>
            <h4><span className='total-credits'>Total Credits:</span>{plan?.total_credits}</h4>
        </div>
        <div className=''><em className="thumb">
            <HighchartsReact
                highcharts={Highcharts}
                options={creditsGraph(graphData, {height: '60px', fontStyle: 'normal', fontSize: '12px', pointWidth: 12})}
            />
            </em></div>
            <div className="credit-spaces">
                <h5>Spaces</h5>
                    <div className="right-filter">
                        <Tooltip placement='top' arrow title={!archive ? 'Show archived spaces' : 'Hide archived spaces'} classes={{ popper: 'info-tooltip' }}>
                            <Button onClick={toggleArchive} style={!archive ? {background: '#f3ebff'}: {}} data-testid="wb-btn" variant="outlined" className="xs-btn is-icon-btn be-archive"></Button>
                        </Tooltip>
                        <div className="form-group">
                            <Select className="borderless-dropdown sm-height no-label"
                                value={sortAsc ? 'asc' : 'desc'}
                                onChange={handleSortChange}
                            >
                                <MenuItem  className='sorting-menu-item' value="asc">Low to High</MenuItem>
                                <MenuItem className='sorting-menu-item' value="desc">High to Low</MenuItem>
                            </Select>
                        </div>
                    </div>
                </div>
            <div className="credits-list">
                {spaceCredits?.filter(item => item?.allocated_credits)?.map(credits => <div className='requested-block' key={credits?.id}>
                    <div className="user-link">
                        <div className="user-icon ">
                            <div className='users-box' style={{backgroundColor: credits?.space?.color}}>
                                <span className="icon be-multiple-users"></span>
                            </div>
                            <h5 className='truncated-text'>{credits?.space?.name}</h5> {credits?.space?.archive && <span className='be-archive archive-icon'></span>}
                            {credits?.pending_space_credit_request && <div className="warning" >
                                <Tooltip placement='top' arrow title={`${credits?.pending_space_credit_request?.credit_requested} credits requested by Space Manager`} classes={{ popper: 'info-tooltip' }}>
                                    <img src={images.iconWarning} alt="" />
                                </Tooltip>
                            </div>}
                        </div>
                        {(getFullname(credits?.space?.owner?.first_name, credits?.space?.owner?.last_name) || credits?.space?.owner?.profile_picture?.profile_picture_url) && <div className="user-name">
                            <em className="initials ">
                                {credits?.space?.owner?.profile_picture?.profile_picture_url ? <img src={credits?.space?.owner?.profile_picture?.profile_picture_url} alt="" /> : getInitials(getFullname(credits?.space?.owner?.first_name, credits?.space?.owner?.last_name))}
                            </em> {getFullname(credits?.space?.owner?.first_name, credits?.space?.owner?.last_name)}
                        </div>}
                    </div>
                    <div className="progress-bar">
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress variant="determinate" value={credits?.allocated_credits ? ((credits?.consumed_credits / credits?.allocated_credits) * 100) : 0} />
                        </Box>
                        <span className='progressbar-num'>{credits?.consumed_credits}/{credits?.allocated_credits}</span>
                    </div>
                </div>)}
                {/* <div className='requested-block'>
                    <div className="user-link">
                        <div className="user-icon">
                            <div className='users-box grey'>
                            <span className="icon be-multiple-users"></span>
                            </div>
                            <h5>Equipments</h5> <span className='be-archive archive-icon'></span>
                        </div>
                        <div className="user-name">
                            <em className="">
                            <img src={(images as any).userImage4} alt="" />
                            </em> Annie Cho
                        </div>
                    </div>
                    <div className="progress-bar">
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress variant="determinate" value={30} />
                        </Box>
                        <span className='progressbar-num'>80/120</span>
                    </div>
                </div> */}
            </div>
        </div>
    </>
  )
}

export default CreditOverview;
