/* eslint eqeqeq: 0 */
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import Menu from "@mui/material/Menu";
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

import moment from 'moment';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';

import images from '../../assets/images';
import consumer from '../../shared/cable';
import instance from '../../shared/interceptor';
import { getCurrentSpaceDetail, getProjectDetail, getSpaceCreditData } from '../../shared/services/common.service';
import { currentProjectState, currentSpaceState } from '../../shared/store/slices/companySlice';
import { spaceCreditState } from '../../shared/store/slices/spaceCreditSlice';
import { setSuccessMsg } from '../../shared/store/slices/successSlice';
import { companyState, permissionState, userPermissionState } from '../../shared/store/slices/userSlice';
import { COMPANY, PROJECTS, SPACES } from '../../shared/util/constant';
import { debounce, getFullname, getInitials, globalError, isAllowedTo, navigateToSpaceOrProject, setLoader } from '../../shared/util/util';
import SpaceCreditModals from './SpaceCreditModals';
import TagResultSection from './TagResultSection';
import './spaces.scss';


function Summary({ tab }) {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const compSel = useSelector(companyState);
  const currentSpaceSel = useSelector(currentSpaceState);
  const currentProjectSel = useSelector(currentProjectState);
  const permissions = useSelector(permissionState);
  const userPermissions = useSelector(userPermissionState);
  const spaceCreditSel = useSelector(spaceCreditState);

  const [tagList, setTagList] = useState([]);
  const [activities, setActivities] = useState([]);
  const [showSearchTags, setShowSearchTags] = useState(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [owner, setOwner] = useState(null);
  const [tags, setTags] = useState(null);
  const [openRequestCreditDialogue, setOpenRequestCreditDialogue] = useState(null);
  const [spaceCreditOverview, setSpaceCreditOverview] = useState(null);
  const [openCreditRequestHistoryDialogue, setOpenCreditRequestHistoryDialogue] = useState(false);
  const pendingSpaceCreditRequest = spaceCreditSel?.['spaceCredit']?.[0]?.['space_credit']?.['pending_space_credit_request'];

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    getActivities(signal);

    return () => {
      controller.abort();
    }
  }, [tab]);

  useEffect(() => {
    setOwner(tab == "Project" ? currentProjectSel?.owner : currentSpaceSel?.owner);
    setTags(tab == "Project" ? currentProjectSel?.entity_tags : currentSpaceSel?.entity_tags);
  }, [currentSpaceSel?.owner?.id, currentProjectSel?.owner?.id, currentProjectSel?.entity_tags?.length, currentSpaceSel?.entity_tags?.length])

  useEffect(() => {
    if (tab == 'Space') {
      consumer.subscriptions.create({
        channel: `ActivityChannel`, channel_id: `space_${currentSpaceSel?.id}`
      }, {
        connected: () => null,
        disconnected: () => console.log('disconnected for summary'),
        received: data => {
          activities.unshift({ id: data?.body?.id, value: data?.body?.data?.value, created_date: data?.body?.data?.created_date, created_at: data?.body?.data?.created_at, user: data?.body?.data?.user_details });
        },
      });
    }

    if (tab == 'Project') {
      consumer.subscriptions.create({
        channel: `ActivityChannel`, channel_id: `project_${currentProjectSel?.id}`
      }, {
        connected: () => null,
        disconnected: () => console.log('disconnected for summary'),
        received: data => {
          activities.unshift({ id: data?.body?.id, value: data?.body?.data?.value, created_date: data?.body?.data?.created_date, created_at: data?.body?.data?.created_at, user: data?.body?.data?.user_details });
        },
      });
    }

    return () => {
      consumer.disconnect();
    }
  }, [tab, activities?.length]);

  useEffect(() => {
    if (isAllowedTo('update', 'space_credit', permissions, entity, userPermissions) && currentSpaceSel?.active_space_credit_id) {
      getSpaceCreditData(compSel, currentSpaceSel, dispatch);
    }
  }, [currentSpaceSel?.active_space_credit_id, pendingSpaceCreditRequest?.id, pendingSpaceCreditRequest?.credit_requested]);
  
  useEffect(() => {
    setSpaceCreditOverview(spaceCreditSel?.['spaceCredit']?.[0]?.['space_credit']);
  }, [spaceCreditSel]);

  const getEntityId = (tab) => {
    if (tab === 'Project') {
      return currentProjectSel?.id
    }
    else if (tab === 'Space') {
      return currentSpaceSel?.id
    }
    return null;
  }

  const entity = {
    type: tab,
    id: getEntityId(tab)
  }

  const isAllowedToCreateEntityTag = isAllowedTo('update', 'entity_tag', permissions, entity, userPermissions);
  const isAllowedToDestroyEntityTag = isAllowedTo('destroy', 'entity_tag', permissions, entity, userPermissions)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    getAllTags();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getAllTags = async (query?) => {
    try {
      let url = `${COMPANY}/${compSel?.id}/tags`;
      if (query) {
        url += `?q=${encodeURIComponent(query?.toLowerCase())}`
      }
      let tagsRes = await instance.get(url);
      setTagList(tagsRes['tags']);
    } catch (e) {
      globalError(dispatch, e);
    }
  }

  const addTag = async (tagName) => {
    try {
      tagName = tagName?.trim()?.toLowerCase();
      if (!tagName) return;
      setLoader(dispatch, true);
      let url = `${COMPANY}/${compSel?.id}/entity_tags`;
      let payload = {
        entity_tag: {
          name: encodeURIComponent(tagName),
          taggable_id: tab === 'Space' ? currentSpaceSel?.id : currentProjectSel?.id,
          taggable_type: tab
        }
      }
      await instance.post(url, payload);
      tab === 'Space' ? getCurrentSpaceDetail(dispatch, compSel?.id, currentSpaceSel?.id) : getProjectDetail(dispatch, compSel?.id, currentSpaceSel?.id, currentProjectSel?.id);
      handleClose();
      dispatch(setSuccessMsg('Tag added successfully.'));
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  }

  const tagChangeHandler = debounce((e) => {
    if (e.key === 'Enter') {
      addTag(e.target.value);
      handleClose();
    } else {
      getAllTags(e.target['value']);
    }
  })

  const handleDelete = async (e, tagId) => {
    if (!isAllowedToDestroyEntityTag) return;

    if (!e.detail || e.detail == 1) {
      try {
        const url = `${COMPANY}/${compSel?.id}/entity_tags/${tagId}`;
        await instance.delete(url);
        tab === 'Space' ? getCurrentSpaceDetail(dispatch, compSel?.id, currentSpaceSel?.id) : getProjectDetail(dispatch, compSel?.id, currentSpaceSel?.id, currentProjectSel?.id);
        dispatch(setSuccessMsg('Tag removed successfully.'));
      } catch (e) {
        globalError(dispatch, e);
      }
    }
  }

  const getActivities = async (signal) => {
    try {
      setLoader(dispatch, true);
      let url = '';
      if (tab == 'Space') {
        url = `${SPACES.replace(':id', compSel?.id)}/${params?.id}/activities?page=1`;
      } else if (tab === 'Project') {
        url = `${PROJECTS.replace(':id', compSel?.id).replace(':space_id', params?.id)}/${params?.project_id}/activities?page=1`;
      }
      if (url) {
        const res = await instance.get(url, { signal });
        if (res) {
          const recent = res?.['activities'].filter(item => moment(item?.created_at).isAfter(moment().subtract(7, 'd')));
          if (recent?.length) {
            setActivities(recent.splice(0, 4));
          }
        }
      }
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  }

  const renderMembersList = () => {
    const colorsList = ['#E1C3FF', '#FFC7B8', '#C8F8FF', ''];
    const length = currentSpaceSel?.members ? Math.min(currentSpaceSel?.members.length, 4) : 0
    const res = [];

    currentSpaceSel?.members?.forEach((member, idx) => {
      if (idx < length - 1) {
        res.push(
          member?.profile_picture?.profile_picture_url ? 
            <em key={member?.id} className='initials'><img src={member?.profile_picture?.profile_picture_url} alt="user profile" /></em> : 
            <span key={member?.id} className='initials' style={{ background: colorsList[idx] }}>{getInitials(member?.first_name ?? member?.email)}</span>
          )
      }
      else if (idx === length - 1) {
        let innerText = ''
        if (currentSpaceSel?.members?.length === idx + 1) {
          innerText = getInitials(member.first_name ?? member.email);
        }
        else {
          innerText = `+${currentSpaceSel?.members?.length - 3}`
        }
        res.push(<span key='last' className='initials' style={{ background: colorsList[idx] }}>{innerText}</span>)
      }
    });

    return <div style={{display: 'flex'}}>{res}</div>
  }

  //=========================================================

  return (
    <aside className='summary' aria-label="summary-section">

      {/* <div className='content-row'>
        <div className='title'>Space</div>
        <div className='chips'>
          <Stack direction="row" spacing={1}>
            <Chip label={currentSpaceSel?.name} />
          </Stack>
        </div>
      </div> */}

 
      {false && <div className='content-row base-card-wrap d-none'>
        <div className='base-card'>
          <div className='card-top'>
            <span>Today | 04:30 PM to 05:00 PM</span>
            <span className='three-dots'>
              <em className='icon be-vertical-dots'></em>
            </span>
          </div>
          <div className='card-title'>Warehouse Scanner Ideation</div>
          <div className='sub-heading'>
            <img src={images.iconEnvelope} alt="" /> Sub heading
          </div>
          <div className='button-wrap'>
            <Button className='xs-btn purple-btn btn-join' variant="contained">Join</Button>
            <Button variant="outlined" className="xs-btn is-icon-btn be-link-chain"></Button>
          </div>
        </div>
      </div>}

      {/* <div className='credits'>
        <div className='credit-balance'>
          <span className='title'>Credit Balance</span>
          <div className='count p-0'>405/1000</div>
        </div>
        <div className='request-credits'>
          <Link to="/">Request Credits</Link>
        </div>
      </div> */}

      {currentSpaceSel?.name && tab != 'Space' && <div className='content-row'>
        <div className='title space'>Space</div>
        <div className='chips'>
          <Stack direction="row" className='purple-chips' spacing={1}><Chip label={currentSpaceSel?.name} /></Stack>
        </div>
        {/* <div className='space-name'>{ currentSpaceSel?.name }</div> */}
      </div>}
      {<div className='content-row owner-row'>
        <div>
          <div className='title'>Owner <span></span></div>
          <div className='user-link'>
            <span className='user-avatar'>
              {owner ? 
                (owner?.profile_picture?.profile_picture_url ? 
                  <img src={owner?.profile_picture?.profile_picture_url} alt="Name" /> :
                  getInitials(owner?.first_name ?? owner?.email)
                ) : 
                'N'
              }
            </span>
            <div className='user-name'>
              { owner ? 
                (owner.first_name && owner.last_name ?
                  <>
                    <div className='truncated-text'>{owner.first_name ?? 'No'}</div>
                    <div className='truncated-text'>{owner.last_name ?? 'Owner'}</div>
                  </> :
                  <>{owner?.email}</>) :
                  <>
                    <div className='truncated-text'>No</div>
                    <div className='truncated-text'>Owner</div>
                  </> 
              }

            </div>
          </div>
        </div>
        <div>
          <div className='title text-right'>Members</div>
          {renderMembersList()}
        </div>
      </div>}


      <div className='content-row credit-score'>
        {isAllowedTo('update', 'space_credit', permissions, entity, userPermissions) && <div className='title text-right'>
            Credit consumed
            <Link className='primary-link' aria-label='req-credits-btn' to='#' onClick={() => setOpenRequestCreditDialogue(spaceCreditOverview)}>Request Credits</Link>
          </div>}
        <div className='right'>
          <div className='progress-circle'>
            <Tooltip className='progress-tooltip-popover' placement="bottom" enterTouchDelay={0} title={
              <div className='credits'>
                <span>{Math.ceil(currentSpaceSel?.credit_consumed_percentage)}%</span>
              </div>
            }>
              <span>
                <div className="progress-with-bg">
                  <CircularProgress size={60} variant="determinate" className='bg-progress' value={100} />
                  <CircularProgress size={60} variant="determinate" value={Math.ceil(currentSpaceSel?.credit_consumed_percentage)} />
                  <span className="progress-val">{currentSpaceSel?.credit_consumed_percentage ? Math.ceil(currentSpaceSel?.credit_consumed_percentage) : 0}%</span>
                </div>
              </span>
            </Tooltip>
          </div>
        </div>
      </div>


      <div className='content-row'>
        <div className='title'>Business goals</div>
        <div className="tags">
          <Stack direction="row" className='purple-chips' spacing={1}>
            {currentSpaceSel?.company_goals?.map((goal) => (
              <Chip
                key={goal?.id}
                label={goal?.description}
                deleteIcon={<span data-testid="close-tag-btn" className='icon be-close-dark'></span>}
              />
            ))}
          </Stack>
        </div>
        {/* TODO: to be enabled later - static design 
        <div className="empty-tags-wrapper">
          <em className="empty-icon goal">
            <img src={images.goalColoredIcon} alt="Empty goal" />
          </em>
          <p className="empty-text grey">Add goals to keep your<br/> development on track.</p>
          <div className="button-wrap align-center">
            <Button className='xs-btn purple-btn' variant="contained">Add goal</Button>
          </div>          
        </div>         */}
      </div>

      {isAllowedTo('read', 'tag', permissions) && <div className='content-row'>
        <div className='title-wrap'>
          <div className='title'>Tags</div>
          {(isAllowedToCreateEntityTag) &&
            <Tooltip placement='top-end' arrow title="Add tag" classes={{ popper: 'info-tooltip' }}>
              <Button data-testid="menu-btn" className='xs-btn purple-btn is-icon-btn' variant="contained" onClick={handleClick}>+</Button>
            </Tooltip>
          }
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            disableRestoreFocus
            open={open}
            onClose={handleClose}
            className='search-tag-dropdown-menu'
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {isAllowedToCreateEntityTag && <li>
              <FormControl variant="filled">
                {/* @ts-ignore */}
                <FilledInput autoFocus inputProps={{ 'data-testid': "searchTag" }} type="text" variant="filled" className="xs-input search-tag-input" placeholder='Search or create new'
                  startAdornment={
                    <InputAdornment position="start"></InputAdornment>
                  }
                  onKeyDown={tagChangeHandler}
                />
              </FormControl>
            </li>}
            {tagList.map((tag) => {
              return <li key={tag?.id} onClick={handleClose}>
                <Stack direction="row" className='purple-chips' spacing={1}>
                  <Chip data-testid="add-chip" label={tag?.name} onClick={() => addTag(tag?.name)} />
                </Stack>
              </li>
            })}
          </Menu>
        </div>
        <div className='tags'>
          {!tags?.length && <div className="empty-tags-wrapper">
            <em className="empty-icon">
              <img src={images.emptyTags} alt="Empty tag" />
            </em>
            <p className="empty-text">Your tags will appear here.</p>
          </div>}

          <Stack direction="row" className='purple-chips' spacing={1}>
            {tags?.map(item => (
              <Chip
                onClick={() => setShowSearchTags(item)}
                data-testid="delete-chip" key={item?.id}
                label={item?.name}
                onDelete={(e) => handleDelete(e, item?.id)}
                deleteIcon={isAllowedToDestroyEntityTag ? <span data-testid="close-tag-btn" className='icon be-close-dark'></span> : <></>}
              />
            ))}
          </Stack>
        </div>
      </div>}
      <div className='content-row'>
        <div className='title'>Recent activity</div>
        <div className='recent-activity'>

          {!activities?.length && <div className="empty-tags-wrapper">
            <em className="empty-icon">
              <img src={images.noActivity} alt="Empty Activity" />
            </em>
            <p className="empty-text">No recent activity.</p>
          </div>}

          {activities?.length > 0 && activities?.map(activity => {
            return <div className='user-link' key={activity?.id}>
              <span className='user-avatar'>
                {activity?.user?.profile_picture?.profile_picture_url ?
                  <img src={activity?.user?.profile_picture?.profile_picture_url} alt="user profile" /> :
                  <span className='initials'>{getInitials(getFullname(activity?.user?.first_name, activity?.user?.last_name))}</span>
                }
              </span>
              <div className='user-detail'>
                <div className='basic-info'>
                  <div onClick={(e) => navigateToSpaceOrProject(e, navigate)} className="htmlContent" dangerouslySetInnerHTML={{ __html: activity?.value }}></div>
                </div>
                <span className='time'>{activity?.created_date}</span>
              </div>
              {/* <div className="menu-trigger">
                <em className="trigger-icon be-vertical-dots"></em>
              </div> */}
            </div>
          })}

        </div>
      </div>

      {/* Tag result section */}
      {showSearchTags && <TagResultSection {...{ showSearchTags, setShowSearchTags }} />}
      {/* Tag result section end */}

      <SpaceCreditModals { ...{ 
        openRequestCreditDialogue, setOpenRequestCreditDialogue,
        openCreditRequestHistoryDialogue, setOpenCreditRequestHistoryDialogue
      } } />

    </aside>
  )
}

export default memo(Summary);
