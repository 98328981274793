/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint eqeqeq: 0 */
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import images from '../../../assets/images';
import instance from '../../../shared/interceptor';
import { getUserListWithoutPermission } from '../../../shared/services/common.service';
import { currentProjectState, currentSpaceState } from '../../../shared/store/slices/companySlice';
import { setSuccessMsg } from '../../../shared/store/slices/successSlice';
import { companyState } from '../../../shared/store/slices/userSlice';
import { BULK_CREATE_PROJECT_MEMBER } from '../../../shared/util/constant';
import { debounce, getFullname, getInitials, globalError, setLoader } from '../../../shared/util/util';

function AddMemberToProjectDialog({ AddMembers, AddMembersDialogClose }) {
    const dispatch = useDispatch();
    const companySel = useSelector(companyState);
    const currentSpaceSel = useSelector(currentSpaceState);
    const currentProjectSel = useSelector(currentProjectState);

    const [memberSearchText, setMemberSearchText] = useState('');
    const [selectedMem, setSelectedMem] = useState([]);
    const [memberList, setMemberList] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (AddMembers) {
            getUserList();
        }
    }, [memberSearchText]);

    const getUserList = async () => {
        const userListRes = await getUserListWithoutPermission(companySel, memberSearchText, dispatch, setLoading);
        setMemberList(userListRes?.['users'] ?? []);
    }

    const removeMember = (index) => {
        let tempArr = [...selectedMem];
        tempArr.splice(index, 1);
        setSelectedMem(tempArr);
    }

    const resetForm = () => {
        setMemberList([]);
        setMemberSearchText('');
    }

    const addMembersToProject = async () => {
        try {
            setLoader(dispatch, true);
            const spaceMembers = selectedMem.map(item => ({email: item.email}))
            const payload = {
                project_members: spaceMembers
            }
            const url = `${BULK_CREATE_PROJECT_MEMBER.replace(':id', companySel?.id).replace(':space_id', currentSpaceSel?.id).replace(':project_id', currentProjectSel?.id)}`;
            let res = await instance.post(url, payload);
            if (res?.['project_members']?.['count']) {
                resetForm();
                AddMembersDialogClose(null, true);
                setSelectedMem([]);
                dispatch(setSuccessMsg(`${res?.['project_members']?.count} Member/s added successfully.`));
            } else {
                globalError(dispatch, {message: 'Member already exists, No members added'});
            }
        } catch (e) {
            globalError(dispatch, e);
        } finally {
            setLoader(dispatch, false);
        }
    }

    const handleModalClose = (e, reason) => {
        if (reason && reason == "backdropClick") return;
        setSelectedMem([]); 
        AddMembersDialogClose(e, false);
    }

    const handleChange = debounce((event) => {
        setMemberSearchText(event?.target?.value);
    })

    //==========================================================
    return (
        <Dialog
            open={AddMembers}
            onClose={handleModalClose}
        >
            <DialogContent className='add-members-to-space-dialog' aria-label="add-member-to-project">
                {/* TODO: close button to be enabled later */}
                {/* <span className="modal-close be-close" onClick={() => {setSelectedMem([]); AddMembersDialogClose() }}></span> */}
                <div aria-label="add-project-member" className="modal-body">
                    <div className="modal-title">
                        <h2 aria-label='modal-heading'>Add members to your project</h2>
                        {/* <div className="right-title"></div> */}
                    </div>
                    <div className="modal-form">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <FormControl>
                                        <Autocomplete
                                            loading={loading}
                                            data-testid='autocomplete-search'
                                            disableCloseOnSelect
                                            multiple
                                            freeSolo
                                            disableClearable
                                            id="searchMembersDropdown"
                                            className='search-members-dropdown'
                                            onChange={(event, value) => typeof value?.[value?.length - 1] == 'object' ? setSelectedMem([...new Map(value.map(item => [item['id'], item])).values()]) : null}
                                            options={memberList}
                                            filterOptions={(options) => options}
                                            value={selectedMem}
                                            autoHighlight
                                            renderTags={(value: string[], getTagProps) => ''}
                                            getOptionLabel={(option) => getFullname(option?.first_name, option?.last_name) ?? option?.email}
                                            popupIcon={<InputAdornment position="start"><em className='be-search'></em></InputAdornment>}
                                            renderOption={(props, option, { selected }) => (
                                            <Box component="span" {...props} key={option?.id}>
                                                <div className='search-members-dropdown'>
                                                <div className='left-side'>
                                                    {option?.profile_picture?.profile_picture_url ? <img className='initials' src={option?.profile_picture?.profile_picture_url} /> : <span className='initials'>{getInitials(getFullname(option?.first_name, option?.last_name)) ?? '-'}</span>}
                                                    <span className='user-details'>
                                                        <span className='name'>{getFullname(option?.first_name, option?.last_name) ?? '-'}</span>
                                                        <span className='email'>({option?.email})</span>
                                                    </span>
                                                </div>
                                                <div className='right-side'>
                                                    <Checkbox
                                                        checked={selected || selectedMem.some(item => item?.id == option?.id)}
                                                        icon={<img src={images.checkboxUnchecked} alt='' />}
                                                        checkedIcon={<img src={images.checkboxChecked} alt='' />}
                                                    />
                                                </div>
                                                </div>
                                            </Box>
                                            )}
                                            renderInput={(params) => (
                                            	<TextField {...params} className='search-input-box' aria-label='search-member' label="Search members" variant="outlined"  onChange={(event) => handleChange(event)}/>
                                            )}
                                        />
                                    </FormControl>
                                    <span className="form-error"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='members-to-be-added'>Members to be added <span>({selectedMem.length})</span></div>
                    {!selectedMem.length && <p className='no-members-text'>No members selected yet.</p>}

                    <div className='members-list-container'>
                        <ul>
                            {selectedMem.map((item, idx) => {
                                return (<li key={item.id}>
                                    <div className='user-link' aria-label="member-list">
                                        <span className='initials'>
                                            { getInitials(getFullname(item?.first_name, item?.last_name)) ?? '-' }
                                        </span>
                                        <span className='user-detail'>
                                            <span className="name">{ getFullname(item?.first_name, item?.last_name) }</span>
                                            <span className="email">({item.email})</span>
                                        </span>
                                    </div>
                                    <span aria-label="remove-member" className='be-close' onClick={() => removeMember(idx)}></span>
                                </li>)
                            })}
                        </ul>
                    </div>
                </div>
            </DialogContent>
            <DialogActions className="fixed-footer-wrap">
                <div className="fixed-full-footer">
                    <Button aria-label='close-add-btn' variant="contained" className='grey-btn sm-btn' onClick={e => {setSelectedMem([]); AddMembersDialogClose()}}>Cancel</Button>
                    <Button data-testid="add-btn" variant="contained" className='sm-btn' disabled={!selectedMem.length} onClick={addMembersToProject}>Add members</Button>
                </div>
            </DialogActions>
        </Dialog>
    )
}

export default AddMemberToProjectDialog;
