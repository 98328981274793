/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint eqeqeq: 0 */
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Tab from '@mui/material/Tab';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';

import images from '../../assets/images';
import AddMemberToBmeetDialog from '../../components/dialog/bmeet-member-dialog/AddMemberToBmeetDialog';
import instance from '../../shared/interceptor';
import { getCurrentSpaceDetail } from '../../shared/services/common.service';
import { currentSpaceState } from '../../shared/store/slices/companySlice';
import { companyState, permissionState, userState } from '../../shared/store/slices/userSlice';
import { SPACES, SPACE_LIST } from '../../shared/util/constant';
import { globalError, isAllowedTo, setLoader, sortByDate, sortProject } from '../../shared/util/util';
import './_buildingSpaces.scss';

function SpaceIdeaboard() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const compSel = useSelector(companyState);
  const userData = useSelector(userState);
  const currentSpaceSel = useSelector(currentSpaceState);
  const permissions = useSelector(permissionState);
  
  const [allMeetboard, setAllMeetboard] = useState(null);
  const [searchAllMeetboard, setSearchAllMeetboard] = useState(null);
  const [meetings, setMeetings] = useState(null);
  const [searchMeetings, setSearchMeetings] = useState(null);
  const [whiteBoards, setWhiteBoards] = useState(null);
  const [selectedBoard, setSelectedBoard] = useState(null);
  const [searchedBoard, setSearchedBoard] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [sortAsc, setSortAsc] = useState('created');
  const [loading, setLoading] = useState(false);
  const [ideaboardTabValue, setIdeaboardTabValue] = useState('All');
  const [callMyTeam, setCallMyTeam] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    getCurrentSpaceDetail(dispatch, compSel?.id, params?.id);
    getListOfIdeaboard(signal);
    return () => {
      controller.abort();
    }
  }, [params?.id])

  const closeDeleteWhiteboardDialog = () => {
    setOpenDialog(false);
  };

  const getListOfIdeaboard = async(signal?) => {
    try {
      setLoading(true);
      const url = `${SPACES.replace(':id', compSel?.id)}/${params?.id}/ideaboard_data`;
      let res = await instance.get(url, { signal });
      if (res?.['whiteboards']) {
        setAllMeetboard(sortByDate(res['whiteboards'].concat(res['meetings']), 'created_at', 'Latest'));
        setSearchAllMeetboard(sortByDate(res['whiteboards'].concat(res['meetings']), 'created_at', 'Latest'));
        setWhiteBoards(sortByDate(res['whiteboards'], 'created_at', 'Latest'));
        setSearchedBoard(sortByDate(res['whiteboards'], 'created_at', 'Latest'));
      }
      if (res?.['meetings']) {
        setMeetings(sortByDate(res['meetings'], 'created_at', 'Latest'));
        setSearchMeetings(sortByDate(res['meetings'], 'created_at', 'Latest'));
      }
    } catch(e) {
      globalError(dispatch, e);
    } finally {
      setLoading(false);
    }
  }

  // const deleteBoard = async () => {
  //   try {
  //     setLoader(dispatch, true);
  //     const url = `${SPACES.replace(':id', compSel?.id)}/${params?.id}/whiteboards/${selectedBoard?.id}`;
  //     await instance.delete(url);
  //     dispatch(setSuccessMsg('Whiteboard deleted successfully.'));
  //     getListOfIdeaboard();
  //     closeDeleteWhiteboardDialog();
  //   } catch(e) {
  //     globalError(dispatch, e);
  //   } finally {
  //     setLoader(dispatch, false);
  //   }
  // }

  const searchWhiteBoard = (value) => {
    setSearchText(value);
    if (!value) {
      return resetValues();
    }
    let filteredItems;
    if (ideaboardTabValue == 'All') {
      filteredItems = searchAllMeetboard.filter(item => (item?.name?.toLowerCase().includes(value?.toLowerCase()) || item?.updated_by_name?.toLowerCase().includes(value?.toLowerCase())));
      setAllMeetboard(filteredItems);
    } else if (ideaboardTabValue == 'Meetings') {
      filteredItems = searchMeetings.filter(item => (item?.name?.toLowerCase().includes(value?.toLowerCase()) || item?.updated_by_name?.toLowerCase().includes(value?.toLowerCase())));
      setMeetings(filteredItems);
    } else {
      filteredItems = searchedBoard.filter(item => (item?.name?.toLowerCase().includes(value?.toLowerCase()) || item?.updated_by_name?.toLowerCase().includes(value?.toLowerCase())));
      setWhiteBoards(filteredItems);
    }
  }

  const getTabValToSetData = () => {
    if (ideaboardTabValue == 'All') {
      return [allMeetboard, setAllMeetboard];
    } else if (ideaboardTabValue == 'Meetings') {
      return [meetings, setMeetings];
    } else {
      return [whiteBoards, setWhiteBoards];
    }
  }

  const sortData = (value) => {
    setSortAsc(value);
    let setDataVal = getTabValToSetData();
    if (value == "A-Z" || value == "Z-A") {
      setDataVal[1](sortProject({target: { value }}, setDataVal[0]));
    } else if (value == "created") {
      setDataVal[1](sortByDate(setDataVal[0], 'created_at', 'Latest'));
    } else if (value == "updated") {
      setDataVal[1](sortByDate(setDataVal[0], 'updated_at', 'Latest'));
    }
  }

  const isOwnerOrMemberSpace = () => {
    let member = currentSpaceSel?.members.some(mem => mem?.id == userData?.id);
    return (member || userData?.id == currentSpaceSel?.owner?.id || userData?.role?.name == 'CIO');
  }

  const getWbUrl = async () => {
    try {
      if (!isOwnerOrMemberSpace()) {
        return globalError(dispatch, {message: 'You are not the owner/member of the space'});
      }
      setLoader(dispatch, true);
      const url = `${SPACES.replace(':id', compSel?.id)}/${currentSpaceSel?.id}/whiteboards`;
      let res = await instance.post(url, null);
      navigate(`/company/spaces/${currentSpaceSel?.id}/whiteboard-playground/${res?.['whiteboard']?.id}`);
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  }

  const handleModalClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    closeDeleteWhiteboardDialog();
  }
  
  const ideaboardTabHandleChange = (event: React.SyntheticEvent, newValue: string) => {
    setIdeaboardTabValue(newValue);
    setSortAsc('created');
    resetValues();
    setSearchText('');
  };

  const openMeeting = (item) => {
    if (item?.past) {
      // only allow past meetings to visit summary page
      navigate(`/company/spaces/${currentSpaceSel?.id}/meeting-summary/${item?.id}`);
    }
  }

  const removeSearch = () => {
    setSearchText('');
    resetValues();
  }

  const resetValues = () => {
    if (ideaboardTabValue == 'All') {
      setAllMeetboard(searchAllMeetboard);
    } else if (ideaboardTabValue == 'Meetings') {
      setMeetings(searchMeetings);
    } else {
      setWhiteBoards(searchedBoard);
    }
  }

  const callMyTeamDialogClose = (e, rerender = false) => {
    setCallMyTeam(false);
    if (rerender) {
      getListOfIdeaboard();
    }
  };

  //==============================================================================

  return (
    <>
      <div className="building-spaces idea-board-wrapper" aria-label='space-ideaboard'>
        <div className="page-container top-space">
          <div className="main-page-header">
            <div className="left-page-header">
              <div className="page-breacrumb">
                <div className="breadcrumb-wrapper">
                  <ul className="breadcrumb-links">
                    <li><Link to='/dashboard'>Building</Link></li>
                    <li>{isAllowedTo('update', 'space', permissions) ? <Link to={SPACE_LIST}>Spaces</Link> : 'Spaces'}</li>
                    <li><Link to={`/company/spaces/${params?.id}/overview`}>{currentSpaceSel?.name}</Link></li>
                    <li>Idea board</li>
                  </ul>
                </div>
              </div>
            </div>          
            <div className="right-page-header">            
              <div className="compnay-logo">
                { compSel?.logo?.logo_url && <img src={compSel?.logo?.logo_url} alt="company logo" /> }
              </div>
            </div>
          </div>

          <div className="page-filters-wrapper secondary-header">
            <div className="left-filter">
              <div className="page-title">
                <h2>{currentSpaceSel?.name} idea board</h2>
                <p className='count'>Work on your ideas via whiteboards and meetings.</p>
              </div>
            </div>
            <div className="right-filter">
              <Tooltip placement='top' arrow title={'New whiteboard'} classes={{ popper: 'info-tooltip' }}>
                <Button data-testid="wb-btn" variant="outlined" className="xs-btn is-icon-btn btn-add-whiteboard" onClick={getWbUrl}><img src={images.addWhiteboardIconMd} alt="Icon"/></Button>
              </Tooltip>
              <Tooltip placement='top' arrow title={'New meeting'} classes={{ popper: 'info-tooltip' }}>
                <Button variant="outlined" className="xs-btn is-icon-btn btn-add-whiteboard" onClick={!isOwnerOrMemberSpace() ? () => globalError(dispatch, {message: 'You are not the owner/member of the space'}) : () => setCallMyTeam(true)}><img src={images.addMeetingIconMd} alt="Icon"/></Button>
              </Tooltip>              
            </div>
          </div>

          
          <div className="inner-section-wrap">
            <div className="page-filters-wrapper">
              <div className="left-filter">
                <div className="tab-label-wrap">
                  <TabContext data-testid="tab-list" value={ideaboardTabValue}>
                    <Box>
                      <TabList onChange={ideaboardTabHandleChange} aria-label="">
                        <Tab label="All" value="All" />
                        <Tab label="Meetings" value="Meetings" />
                        <Tab label="Whiteboards" value="Whiteboards" />
                      </TabList>
                    </Box>
                  </TabContext>
                </div>
              </div>
              <div className="right-filter">
                <div className="form-group sort-by">
                  <span className="dropdown-text">Sort by</span>
                  <Select
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "right"
                      }
                    }}
                    className="borderless-dropdown sm-height no-label"
                    displayEmpty
                    value={sortAsc}
                    inputProps={{'data-testid': 'select-box'}}
                    onChange={e => sortData(e?.target?.value)}
                  >
                    <MenuItem className='sorting-menu-item' value="created">Latest</MenuItem>
                    <MenuItem className='sorting-menu-item' value="A-Z">A-Z</MenuItem>
                    <MenuItem className='sorting-menu-item' value="Z-A">Z-A</MenuItem>
                    <MenuItem className='sorting-menu-item' value="updated">Last updated</MenuItem>
                  </Select>
                </div>
                <FormControl variant="filled">
                  {/* @ts-ignore */}
                  <FilledInput type="text" variant="filled" className="xs-input search-input-wrap search-whiteboard" placeholder='Search resources'
                    value={searchText}
                    startAdornment={
                      <InputAdornment position="start"><em className='be-search'></em></InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment position="end" className='search-icon-wrap'>
                        {searchText && <img data-testid="cross-btn" src={images.iconCrossCircle} alt="" onClick={removeSearch}/>}
                      </InputAdornment>
                    }
                    inputProps={{'data-testid': 'search-box'}}
                    onChange={(e) => searchWhiteBoard(e?.target?.value)}
                  />
                </FormControl>
              </div>
            </div>
            <TabContext value={ideaboardTabValue}>              
              <TabPanel value="All">
                {/* No data found - empty state */}
                {!allMeetboard?.length && !loading && <div className="no-data-wrapper no-ideas-yet" aria-label="empty-state">
                  <em className="thumb"><img src={images.emptyStateIllustration} alt="Spaces Flag" /></em>
                  <h4 aria-label="no-space">No data yet</h4>
                  <p>You haven't had any data yet.</p>
                  {/* <Button variant="contained" className="xs-btn grey-btn btn-new-board" onClick={getWbUrl}><img src={images.addWhiteboardIcon} alt="Icon"/> New board</Button> */}
                </div>}
                {allMeetboard?.map((item, index) => <div data-testid="all-cards" key={`${item?.id}_${index}`} className="row-container" onClick={item?.class_name == 'Meeting' ? () => openMeeting(item) : () => navigate(`/company/spaces/${currentSpaceSel?.id}/whiteboard-playground/${item?.id}`)}>
                  <div className="left-content">
                    <span className="icon-wrap">
                      {item?.class_name == 'Meeting' ? <em className="icon be-meetings"></em> : <img src={images.createWhiteboard} alt=""/>}
                    </span>
                    <span className="label">{item?.name}</span>
                  </div>
                  <div className="right-content">
                    <span className="date-time">{moment(item?.created_at).format('DD.MM.YY')} · {moment(item?.created_at).format('HH:mm')}</span>                    
                    <Tooltip placement='top' arrow title={item?.updated_by_name} classes={{ popper: 'info-tooltip' }}>
                      <span className="auth-name">By {item?.updated_by_name}</span>
                    </Tooltip>
                  </div>
                </div>)}
              </TabPanel>

              {/* Tab content for Meetings */}
              <TabPanel value="Meetings">   
                {/* No data found - empty state */}
                {!meetings?.length && !loading && <div className="no-data-wrapper no-ideas-yet" aria-label="empty-state">
                  <em className="thumb"><img src={images.emptyStateIllustration} alt="Spaces Flag" /></em>
                  <h4 aria-label="no-space">No data yet</h4>
                  <p>You haven't had any data yet.</p>
                  {/* <Button variant="contained" className="xs-btn grey-btn btn-new-board" onClick={getWbUrl}><img src={images.addWhiteboardIcon} alt="Icon"/> New board</Button> */}
                </div>}             
                {meetings?.map(meet => <div data-testid="meetings-card" className="row-container" key={meet?.id}>
                  <div className="left-content" onClick={() => openMeeting(meet)} >
                    <span className="icon-wrap">
                      <em className="icon be-meetings"></em>
                    </span>
                    <span className="label">{meet?.name}</span>
                  </div>
                  <div className="right-content">
                    <span className="date-time">{moment(meet?.created_at).format('DD.MM.YY')} · {moment(meet?.created_at).format('HH:mm')}</span>                    
                    <Tooltip placement='top' arrow title={meet?.updated_by_name} classes={{ popper: 'info-tooltip' }}>
                      <span className="auth-name">By {meet?.updated_by_name}</span>
                    </Tooltip>
                  </div>
                </div>)}
              </TabPanel>

              {/* Tab content for Whiteboards */}
              <TabPanel value="Whiteboards">
                {/* No data found - empty state */}
                {!whiteBoards?.length && !loading && <div className="no-data-wrapper no-ideas-yet" aria-label="empty-state">
                  <em className="thumb"><img src={images.emptyStateIllustration} alt="Spaces Flag" /></em>
                  <h4 aria-label="no-space">No data yet</h4>
                  <p>You haven't had any data yet.</p>
                  {/* <Button variant="contained" className="xs-btn grey-btn btn-new-board" onClick={getWbUrl}><img src={images.addWhiteboardIcon} alt="Icon"/> New board</Button> */}
                </div>} 
                {whiteBoards?.map(board => <div data-testid="whiteboard-card" className="row-container" key={board?.id} onClick={() => navigate(`/company/spaces/${currentSpaceSel?.id}/whiteboard-playground/${board?.id}`)}>
                  <div className="left-content">
                    <span className="icon-wrap">
                      <img src={images.createWhiteboard} alt=""/>
                      {/* <em className="icon be-meetings"></em> */}
                    </span>
                    <span className="label">{board?.name}</span>
                  </div>
                  <div className="right-content">
                    <span className="date-time">{moment(board?.created_at).format('DD.MM.YY')} · {moment(board?.created_at).format('HH:mm')}</span>                    
                    <Tooltip placement='top' arrow title={board?.updated_by_name} classes={{ popper: 'info-tooltip' }}>
                      <span className="auth-name">By {board?.updated_by_name}</span>
                    </Tooltip>
                  </div>
                </div>)}
              </TabPanel>
            </TabContext>
          </div>
          {/* ============================================================================================= */}

          {/* {!frameUrl && <div className="inner-section-wrap">
            <div className="page-filters-wrapper">
              <div className="left-filter">
                <span className="count"><img src={images.createWhiteboard} alt="Icon"/> Whiteboards</span>
              </div>
              <div className="right-filter">
                <div className="form-group sort-by">
                  <span className="dropdown-text">Sort by</span>
                  <Select
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "right"
                      }
                    }}
                    className="borderless-dropdown sm-height no-label"
                    displayEmpty
                    value={sortAsc}
                    onChange={e => sortWhiteBoard(e?.target?.value)}
                  >
                    <MenuItem className='sorting-menu-item' value="created">Latest</MenuItem>
                    <MenuItem className='sorting-menu-item' value="A-Z">A-Z</MenuItem>
                    <MenuItem className='sorting-menu-item' value="Z-A">Z-A</MenuItem>
                    <MenuItem className='sorting-menu-item' value="updated">Last updated</MenuItem>
                  </Select>
                </div>
                <FormControl variant="filled">
                  
                  <FilledInput type="text" variant="filled" className="xs-input search-input-wrap search-whiteboard" placeholder='Search '
                    value={searchText}
                    startAdornment={
                      <InputAdornment position="start"><em className='be-search'></em></InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment position="end" className='search-icon-wrap'>
                        {searchText && <img src={images.iconCrossCircle} alt="" onClick={() => {setSearchText(''); setWhiteBoards(searchedBoard)}}/>}
                      </InputAdornment>
                    }
                    onChange={(e) => searchWhiteBoard(e?.target?.value)}
                  />
                </FormControl>
              </div>
            </div>

            
            {!whiteBoards?.length && !loading && <div className="no-data-wrapper no-ideas-yet" aria-label="empty-state">
              <em className="thumb"><img src={images.emptyStateIllustration} alt="Spaces Flag" /></em>
              <h4 aria-label="no-space">No ideas yet</h4>
              <p>You haven't had any idea session yet.</p>
              <Button variant="contained" className="xs-btn grey-btn btn-new-board" onClick={getWbUrl}><img src={images.addWhiteboardIcon} alt="Icon"/> New board</Button>
            </div>}

            <div className="table-wrapper table-responsive space-list-table">
              <TableContainer>
                  <Table>
                    <TableBody>
                      { loading && <tr><td><img src={(images as any).buAnim} alt="B" style={{ position: 'fixed', top: '50%', left: '50%', height: '80px', width:'80px', transform: 'translate(-50%, -50%)' }}/></td></tr> }
                      {whiteBoards?.map((board, idx) => <TableRow key={board?.id}>
                        <TableCell className="cr-pointer" onClick={() => {openIframe(board?.url); setNewWb(board?.name)}}><strong>{board?.name}</strong></TableCell>
                        <TableCell>Updated {moment(board?.updated_at).format('DD.MM.YY')}</TableCell>
                        <TableCell>By {board?.updated_by_name}</TableCell>
                        <TableCell>
                          <div className='three-dots' aria-label="menu">
                            <em className='icon be-vertical-dots' aria-label="open-menu" onClick={(e) => handleMenuClick(idx, e)}></em>
                            <Menu
                              id="basic-menu"
                              anchorEl={anchorEl?.[idx]}
                              open={Boolean(anchorEl?.[idx])}
                              onClose={handleClose}
                              MenuListProps={{
                              'aria-labelledby': 'basic-button',
                              }}
                              anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                              }}
                              transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                              }}
                              className="three-dot-menu"
                            >
                                <MenuItem aria-label="del-btn" className='toggle-menu-item-row' onClick={() => deleteDialog(board)}><img src={images.deleteIcon} alt="" /> Delete</MenuItem>
                            </Menu>
                          </div>
                        </TableCell>
                      </TableRow>)}
                    </TableBody>
                  </Table>
              </TableContainer>
            </div>
          </div>} */}
        </div>
      </div>

      {/* {frameUrl && <iframe aria-label="iframe-label" title="whiteboard application" src={frameUrl} className="wb-integration-iframe" />} */}

      {/* Delete Whiteboard modal start */}
      <Dialog
        open={openDialog}
        onClose={handleModalClose}
      >
        <DialogContent className="delete-whiteboard-modal" aria-label="delete-dialog">
          <div className="modal-body">
            <div className="modal-title">
              <h2>Delete Whiteboard {selectedBoard?.name}</h2>
            </div>
            <p>Are you sure you want to delete this whiteboard? Once deleted it cannot be retrieved.</p>

          </div>
        </DialogContent>
        <DialogActions className="fixed-footer-wrap">
          <div className="fixed-full-footer">
            <Button variant="contained" className='grey-btn sm-btn' onClick={closeDeleteWhiteboardDialog}>Cancel</Button>
            {/* <Button variant="contained" className='sm-btn' aria-label="delete-btn" onClick={deleteBoard}>Confirm delete</Button> */}
          </div>
        </DialogActions>
      </Dialog>
      {/* Delete Whiteboard modal End */}

      { callMyTeam && <AddMemberToBmeetDialog {...{ callMyTeam, callMyTeamDialogClose, selectedMeeting: null, isFromIdeaboard: true }}/> }
    </>
  )
}

export default SpaceIdeaboard;
