import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from 'react-redux';

import { companyState } from '../../shared/store/slices/userSlice';
import { MEMBER_ROLES } from '../../shared/util/constant';
import { getRandom, validateEmail } from '../../shared/util/util';

const ManuallyMemberInviteDialog = (
  { addMembers, AddMembersDialogClose,
    handleAddMemberChange, addedMemberObj,
    error, addMemberToList, addedMemberList,
    sendInviteToManualAddedMember, removeFromAddedMemberList, openBulkMemberDialog }) => {

  const { email_domain } = useSelector(companyState);

  const handleModalClose = (e, reason) => {
    if (reason && reason == "backdropClick") return;
    AddMembersDialogClose(e);
  }

  // ============================================
  return (
    <Dialog
      open={addMembers}
      onClose={handleModalClose}
    >
      <DialogContent>
        {/* <span className="modal-close be-close" onClick={AddMembersDialogClose}></span> */}
        <div className="modal-body">
          <div className="modal-title has-right-data">
            <h2 aria-label='modal-heading'>Add members</h2>
            <div className="right-title">
              <div data-testid="csv-dialog" className="browse-files-link" onClick={() => { AddMembersDialogClose(); openBulkMemberDialog() }}>Upload CSV</div>
            </div>
          </div>

          <div className="modal-form">
          <div className="row">
              <div className="col-sm-12">
                <div className="form-group has-add-email">
                  <FormControl variant="filled">
                    <InputLabel>Email</InputLabel>
                    {/* @ts-ignore */}
                    <FilledInput autoComplete='off' inputProps={{'data-testid': 'mem-email'}} type="email" variant="filled" name="email" value={addedMemberObj?.email} onChange={handleAddMemberChange} />
                  </FormControl>
                  <span className="form-error">{error}</span>
                  {validateEmail(addedMemberObj?.['email']) &&
                    <div className="add-email-wrapper" >
                      <div aria-label='add-mem-wrapper' className="add-email-box" onClick={addMemberToList}>Add <strong>{addedMemberObj?.['email']}
                      </strong>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <FormControl>
                    <Select data-testid="select-val" value={addedMemberObj?.role} className='no-label' name="role" onChange={handleAddMemberChange}>
                      {MEMBER_ROLES.map(role => <MenuItem disabled={role?.value?.toLowerCase() === 'cio'} key={role.key} value={role.value}>{role.label} {role.label === 'Member' ? '(Default)' : ''}</MenuItem>)}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>            

            {/* Member email list on dialog box */}
            <div className="member-list-wrapper">
              <h6 aria-label='list-heading'>{addedMemberList?.length ? `${addedMemberList?.length} member to be added` : 'Members to be added (0)'}</h6>
              {!addedMemberList?.length && <div className="no-member-selected">No members selected yet.</div>}
              <div className="member-list-outer">
                {addedMemberList?.length > 0 &&
                  <ul className="member-list">
                    {addedMemberList.map((member, idx) => (
                      <li key={`members_${idx + getRandom()}`}>
                        <div className="email">
                          <div className="tooltip-wrapper">
                            <div className="tooltip-trigger">
                              <span className="trigger-text">{member.email} {`(${member.role})`}</span>
                              {!member?.email?.endsWith(email_domain) &&
                                <Tooltip enterTouchDelay={0} title="This member does not belong to your organisation. Are you sure you want to invite to your account?">
                                  <em className="be-tooltip tooltip-icon"></em>
                                </Tooltip>
                              }
                            </div>
                          </div>
                        </div>
                        <em aria-label="remove-mem" className="close-icon be-close" onClick={() => removeFromAddedMemberList(idx)}></em>
                      </li>
                    ))}
                  </ul>
                }
              </div>
            </div>

          </div>
        </div>
      </DialogContent>
      <DialogActions className="fixed-footer-wrap">
        <div className="fixed-full-footer">
          <Button variant="contained" className='sm-btn grey-btn' onClick={AddMembersDialogClose}>Cancel</Button>
          <Button variant="contained" className='sm-btn' disabled={!addedMemberList?.length} onClick={sendInviteToManualAddedMember}>Invite members</Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default ManuallyMemberInviteDialog;