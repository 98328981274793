/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint eqeqeq: 0 */
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import instance from '../../../shared/interceptor';
import { setSuccessMsg } from '../../../shared/store/slices/successSlice';
import { companyState } from '../../../shared/store/slices/userSlice';
import { COMPANY } from '../../../shared/util/constant';
import { globalError, setLoader } from '../../../shared/util/util';

function AllocateCreditDialog({companyApproved, handleClose, selectedSpace}) {
    const dispatch = useDispatch();
    const compSel = useSelector(companyState);
    const [credits, setCredits] = useState(null);
    const [error, setError] = useState('');

    const handleModalClose = (e, reason) => {
        if (reason && reason == "backdropClick") return;
        handleClose(null, false);
    }

    const allocateCredit = async () => {
        try {
            if(credits < 0) return setError('Cannot enter negative values');
            if(!credits) return setError('Enter credits');
            setLoader(dispatch, true);
            const url = `${COMPANY}/${compSel?.id}/plan_credit_subscriptions/${compSel?.active_plan_credit_subscription?.id}/space_credits/${companyApproved?.pending?.space_credit_id}/space_credit_requests/${companyApproved?.pending?.id}`;
            let payload = {
                space_credit_request: {
                    "status": "approved",
                    "credit_approved": +credits,
                }
            }
            await instance.patch(url, payload);
            dispatch(setSuccessMsg('credits allocated successfully'));
            handleClose(null, true);
        } catch(e) {
            globalError(dispatch, e);
        } finally {
            setLoader(dispatch, false);
        }
    }

    //========================================================

  return (
    <Dialog className='CompanyApproved'
        open={Boolean(companyApproved)}
        onClose={handleModalClose}
      >
        <DialogContent className="no-btns" data-testid='allocate-credits-modal'>
          <span className="modal-close be-close" onClick={() => handleClose(null, false)}></span>
          <div className="modal-body">
            <div className="connect-with-expert feedback-dialog allocate-dialog">
              <div className="modal-title">
                <h2>You are going to allocate credits to {selectedSpace}  </h2>
              </div>

              <div className="expert-content">

                <div className="form-group">
                  <FormControl variant="filled" className={companyApproved?.total < credits ? 'has-error' : ''}>
                    <InputLabel>Credits</InputLabel>
                    {/* @ts-ignore */}
                    <FilledInput style={companyApproved?.total < credits ? {color: 'red'} : {}} onChange={e => {setCredits(e?.target?.value?.replace(/^0+/, '')); setError('')}} inputProps={{ 'data-testid': 'credits-req' }} type="number" min="0" variant="filled" />
                  </FormControl>
                  { companyApproved?.total < credits && <p aria-label="error" className="form-error">Credit value is more than account credit balance</p> }
                  {credits && companyApproved?.pending?.credit_requested != credits ? <p>You are allocating {companyApproved?.pending?.credit_requested < credits ? 'more' : 'less'} credit to this space then originally requested.</p> : <p></p>}
                  { error && <p aria-label="error" className="form-error">{ error }</p> }
                  
                </div>
              </div>
            </div>
          </div >
        </DialogContent>
        <DialogActions className="fixed-footer-wrap">
          <div className="fixed-full-footer">
            <Button data-testid="close-modal-btn" variant="contained" className='grey-btn sm-btn' onClick={() => handleClose(null, false)}>Cancel</Button>
            <Button data-testid="allocate-credits-btn" className='sm-btn' variant="contained" disabled={!credits || companyApproved?.total < credits} onClick={allocateCredit}>Confirm</Button>
          </div>
        </DialogActions>
      </Dialog >
  )
}

export default AllocateCreditDialog;
