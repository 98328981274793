import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import instance from '../../../shared/interceptor';
import { setSuccessMsg } from '../../../shared/store/slices/successSlice';
import { MEMBER_INDEX, USER_PROFILE } from '../../../shared/util/constant';
import { getFullname, globalError, setLoader } from '../../../shared/util/util';

const RemoveMemberFromEnterpriseDialog = ({RemoveCurrentMember, RemoveCurrentMemberDialogClose, userDetail}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [fullName, setFullName ] = useState('');

  useEffect(() => {
    if (userDetail) {
      setFullName(getFullname(userDetail?.first_name, userDetail?.last_name));
    }
  }, [userDetail])

  const removeUser = async () => {
    try {
      setLoader(dispatch, true);
      let url = `${USER_PROFILE.replace(':id', userDetail?.company?.id).replace(':user_id', userDetail?.id)}`;
      await instance.delete(url);
      navigate(MEMBER_INDEX);
      dispatch(setSuccessMsg('Member removed successfully.'));
    } catch(err) {
      globalError(dispatch, err);
    } finally {
      setLoader(dispatch, false);
    }
  }

  const handleModalClose = (e, reason) => {
    if (reason && reason == "backdropClick") return;
    RemoveCurrentMemberDialogClose(e)
  }

  //=========================================

  return (
      <Dialog
      open={RemoveCurrentMember}
      onClose={handleModalClose}
    >
      <DialogContent className='normal-hori-padding' aria-label="remove-enter-dialog">
        {/* <span className="modal-close be-close" onClick={RemoveCurrentMemberDialogClose}></span> */}
        <div className="modal-body">
          <div className="modal-title">
            <h2>Remove { fullName }</h2>
            <p>You are about to remove { fullName } from your Builder Enterprise!</p>
            <p className='note'>Note: This will only remove the access of { fullName } to Builder Enterprise, all the related artefacts created by { fullName } will be available to be viewed by other members.</p>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="fixed-footer-wrap">
        <div className="fixed-full-footer">
          <Button variant="contained" className='sm-btn grey-btn' onClick={RemoveCurrentMemberDialogClose}>Cancel</Button>
          <Button aria-label="remove-enter-btn" className="sm-btn red-btn" variant="contained" onClick={removeUser}>Confirm remove</Button>          
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default RemoveMemberFromEnterpriseDialog;
