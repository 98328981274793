/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint eqeqeq: 0 */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import LinearProgress from '@mui/material/LinearProgress';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import images from '../../../assets/images';
import UnauthorizedAccess from '../../../components/UnauthorizedAccess';
import CreateSpaceGoalDialog from '../../../components/dialog/space-goal/CreateSpaceGoalDialog';
import instance from '../../../shared/interceptor';
import { currentSpaceState } from '../../../shared/store/slices/companySlice';
import { setSuccessMsg } from '../../../shared/store/slices/successSlice';
import { companyState, permissionState, userPermissionState } from '../../../shared/store/slices/userSlice';
import { SPACES, goalStatusList } from '../../../shared/util/constant';
import { getColorCodeAndClassForGoals, globalError, isAllowedTo, setLoader, sortByDate } from '../../../shared/util/util';
import Summary from '../../building/summary';
import SpaceTopNavigation from '../SupplyChainDistributionNavigation';
import '../spaces-overview.scss';
import './space-goals.scss';

function SpaceGoals() {
  const params = useParams();
  const currentSpaceSel = useSelector(currentSpaceState);
  const dispatch = useDispatch();
  const compSel = useSelector(companyState);
  const navigate = useNavigate();
  const permissions = useSelector(permissionState);
  const userPermissions = useSelector(userPermissionState);

  const [anchorEl, setAnchorEl] = useState(null);
  const [spaceGoals, setSpaceGoals] = useState([]);
  const [searchSpaceGoals, setSearchSpaceGoals] = useState([]);
  const [searchVal, setSearchVal] = useState('');
  const [archive, setArchive] = useState(false);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [sortStatus, setSortStatus] = useState('all');

  const openThreeDotMenu = (event, idx) => {
    setAnchorEl({[idx]: event.currentTarget});
  };

  const [openDialogue, setOpen] = useState(false);
  const handleClickOpen = (goal?) => {
    setOpen(true);
    if (goal) {
      setSelectedGoal(goal);
    }
  };

  useEffect(() => {
    getAllSpaceGoals();
  }, [])
  
  const handleClose = (e, render = null) => {
    setAnchorEl(null);
    setOpen(false);
    setSelectedGoal(null);
    if(render) {
      getAllSpaceGoals();
    }
  };

  const getAllSpaceGoals = async () => {
    try {
      setLoader(dispatch, true);
      const url = `${SPACES.replace(':id', compSel?.id)}/${params?.id}/space_goals`;
      let res = await instance.get(url);
      setSpaceGoals(sortByDate(res?.['space_goals'].filter(item => item?.archive == false), 'created_at', 'Latest'));
      setSearchSpaceGoals(sortByDate(res?.['space_goals'], 'created_at', 'Latest'));
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  }

  const searchGoals = (event) => {
    let val = event?.target?.value;
    setSearchVal(val);
    console.log(archive)
    if (archive) {
      if(val) {
        setSpaceGoals(searchSpaceGoals.filter(item => item?.name?.toLowerCase()?.includes(val?.toLowerCase())));
      } else {
        setSpaceGoals(searchSpaceGoals);
      }
    } else {
      if(val) {
        setSpaceGoals(searchSpaceGoals.filter(item => item?.name?.toLowerCase()?.includes(val?.toLowerCase()) && item?.archive == archive));
      } else {
        setSpaceGoals(searchSpaceGoals.filter(item => item?.archive == archive));
      }
    }
  }

  const toggleArchive = () => {
    setArchive(prev => !prev)
    if (archive) {
      setSpaceGoals(searchSpaceGoals.filter(item => item?.archive == false));
    } else {
      setSpaceGoals(searchSpaceGoals);
    }
  }

  const sortSpaceGoals = (e) => {
    let val = e?.target?.value;
    setSpaceGoals(sortByDate(spaceGoals, 'created_at', val));
  }

  const changeStatus = async (e, goal) => {
    let statusVal = e?.target?.value;
    try {
      setLoader(dispatch, true);
      const url = `${SPACES.replace(':id', compSel?.id)}/${params?.id}/space_goals/${goal?.id}`;
      let payload = {
        name: goal?.name,
        description: goal?.description,
        status: statusVal,
        due_date: goal?.due_date,
        owner_id: goal?.owner_id,
        company_goal_ids: goal?.company_goal_ids,
        archive: goal?.archive
      }
      await instance.patch(url, payload);
      handleClose(null, true);
      setArchive(true);
      dispatch(setSuccessMsg('Status updated successfully.'));
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  }

  const archiveGoal = async (goal) => {
    try {
      setLoader(dispatch, true);
      const url = `${SPACES.replace(':id', compSel?.id)}/${params?.id}/space_goals/${goal?.id}`;
      let payload = {
        name: goal?.name,
        description: goal?.description,
        status: goal?.status,
        due_date: goal?.due_date,
        owner_id: goal?.owner_id,
        company_goal_ids: goal?.company_goal_ids,
        archive: !goal?.archive
      }
      await instance.patch(url, payload);
      handleClose(null, true);
      setArchive(false);
      dispatch(setSuccessMsg(`Status ${goal?.archive ? 'unarchived': 'archived'} successfully.`));
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  }

  const sortByStatus = (e) => {
    let val = e?.target?.value;
    setSortStatus(val);
    if (val == 'all') {
      setSpaceGoals(searchSpaceGoals);
    } else {
      setSpaceGoals(searchSpaceGoals.filter(goals => goals.status == val));
    }
  }


  //=======================================
  return (
    <>
      {isAllowedTo('read', 'space_goal', permissions, {id: currentSpaceSel?.id, type: 'Space'}, userPermissions) ? <div className="space-overview-page space-goals-wrapper">
        <div className="page-container top-space pb-0">
          <SpaceTopNavigation />

          <section className='overview-inner-section'>
            <div className='page-content pt-0'>

              <div className="goals-container space">
                <div className="goals-left-col w-100">
                  <div className="secondary-header">
                    {/* Dropdowns and Search wrapper */}
                    <div className="page-filters-wrapper">                
                      <div className="left-filter">
                        <div className="form-group sort-by">
                          <div className="dropdown-wrapper">
                            <span className="dropdown-text">Sort by &nbsp;&nbsp;&nbsp;</span>
                            <Select 
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "right"
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "right"
                                },                                
                                classes: { list: 'p-0' }
                              }} 
                              className="borderless-dropdown sm-height no-label" 
                              defaultValue={'Latest'}
                              onChange={sortSpaceGoals}
                            >
                              <MenuItem className='sorting-menu-item' value="Latest">Latest created</MenuItem>
                              <MenuItem className='sorting-menu-item' value="Oldest">Oldest created</MenuItem>
                            </Select>
                          </div>
                          <div className="dropdown-wrapper">
                            <span className="dropdown-text">Show &nbsp;&nbsp;&nbsp;</span>
                            <Select 
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "right"
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "right"
                                },
                                classes: { list: 'p-0' }
                              }} 
                              className="borderless-dropdown sm-height no-label" 
                              value={sortStatus}
                              onChange={sortByStatus}
                            >
                              <MenuItem className='sorting-menu-item' value="all">All</MenuItem>
                              <MenuItem className='sorting-menu-item' value="on_track">On Track</MenuItem>
                              <MenuItem className='sorting-menu-item' value="off_track">Off Track</MenuItem>
                              <MenuItem className='sorting-menu-item' value="accomplished">Accomplished</MenuItem>
                              <MenuItem className='sorting-menu-item' value="progressing">Progressing</MenuItem>
                              <MenuItem className='sorting-menu-item' value="not_started">Not Started</MenuItem>
                            </Select>
                          </div>                                        
                        </div>
                      </div>

                      <div className="right-filter">                  
                        <FormControl variant="filled" className='search-input'>
                          {/* @ts-ignore */}
                          <FilledInput inputProps={{ 'data-testid': "searchGoals" }} type="text" variant="filled" className="xs-input search-input-wrap" placeholder='Search goals'
                            startAdornment={
                              <InputAdornment position="start"><em className='be-search'></em></InputAdornment>
                            }
                            value={searchVal}
                            endAdornment={
                              <InputAdornment position="end" className='search-icon-wrap'>
                                {searchVal && <img src={images.iconCrossCircle} alt="" onClick={e => {setSearchVal(''); setSpaceGoals(searchSpaceGoals.filter(item => item?.archive == archive));}}/>}
                              </InputAdornment>                        
                            }
                            onChange={searchGoals}
                          />
                        </FormControl>
                        
                        <Tooltip placement='top' title={archive ? 'Hide archive' : 'Show archive'} style={{'width':'auto'}}>
                          <Button style={archive ? {background: '#f3ebff'}: {}} variant="contained" className="xs-btn is-icon-btn btn-archive" onClick={toggleArchive}><em className="icon be-archive"></em></Button>
                        </Tooltip>
                        {isAllowedTo('update', 'space_goal', permissions, { id: currentSpaceSel?.id, type: 'Space' }, userPermissions) && <Button variant="contained" className="xs-btn purple-btn" onClick={handleClickOpen}>Add goal</Button>}
                        
                      </div>
                    </div>
                  </div>
                  
                  {/* No data wrapper */}
                  {!spaceGoals?.length && <div className="no-data-wrapper">
                    <em className="thumb"><img src={images.emptyStateIllustration} alt="Spaces Flag" /></em>
                    <h4 aria-label="no-space">No goals yet</h4>
                    <p>Add goals to keep your development on track.</p>
                    {isAllowedTo('update', 'space_goal', permissions, { id: currentSpaceSel?.id, type: 'Space' }, userPermissions) && <Button variant="contained" className="sm-btn" onClick={handleClickOpen}>Add goals</Button>}
                  </div>}
                  
                  {/* Goal card wrapper */}
                  {spaceGoals?.map((item, idx) => <div className="incread-by-box" key={item?.id}> 
                    <div className="title-head">
                      {/* <h4><span className="cr-pointer">Convert 10 lead weekly to increase sales</span> <span className="private">Private</span> <em className="icon be-archive"></em></h4> */}                      
                      <h4><span className="cr-pointer" onClick={() => navigate(`/company/spaces/${params?.id}/space-goals/${item?.id}`)}>{item?.name}</span> </h4>
                      <Button className="three-dot-btn xs-btn" onClick={(event) => openThreeDotMenu(event, idx)}><em className='icon be-vertical-dots'></em></Button>
                      <Menu
                        id="three-dot-menu"
                        anchorEl={anchorEl?.[idx]}
                        open={Boolean(anchorEl?.[idx])}
                        onClose={(e) => handleClose(e, null)}
                        anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                        }}
                        transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                        }}
                        className={'three-dot-menu-paper'}
                      >
                        <MenuItem className='toggle-menu-item-row' onClick={(e) => handleClickOpen(item)}><img src={images.editIcon} alt="" /> Edit</MenuItem>
                        <MenuItem className='toggle-menu-item-row' onClick={() => archiveGoal(item)}><em className='icon be-archive'></em> {item?.archive ? 'Unarchive' : 'Archive'}</MenuItem>
                      </Menu>
                    </div>
                    <div className="progress-wrap">
                      <div className="text-content">{item?.description}</div>
                      <div className="progress-bar">                    
                        <Box sx={{ width: '100%' }}>
                          <LinearProgress variant="determinate" value={item?.build_cards_count ? (item?.build_cards_accomplished / item?.build_cards_count) * 100 : 0} />
                        </Box>
                        <div className="progress-text">
                          <strong>{item?.build_cards_accomplished}/{item?.build_cards_count}</strong><span> Builcards accomplished</span>
                        </div>
                      </div>
                    </div>
                    <div className="tags-wrapper">
                      <Stack direction="row" spacing={1}>
                        {item?.company_goals?.slice(0, 2)?.map(goal => <Chip label={goal?.name} className="purple-tag" key={goal?.id} />)}
                        {item?.company_goals?.length > 2 && <Chip label={`+${item?.company_goals?.length - 2}`} className="purple-tag" />}
                      </Stack>
                    </div>
                    <div className="box-footer">
                      <span className="date-box">Target {moment(item?.due_date).format('DD.MM.YYYY')}</span>
                      <span className="status">
                        {/* { onTrack: backgroundColor:'#69F0AE', border:'1px solid #00E676' },{ Not started: backgroundColor:'#DFE0E6', border:'1px solid #9B9FB1' },{ progressing: backgroundColor:'#FFD740', border:'1px solid #FFC400' },{ offTrack: backgroundColor:'#FF5546', border:'1px solid #FFB3AC' },{ accomplished: backgroundColor:'#00E676', border:'1px solid #00C853' } */}
                        <em className="color-dot left" style={{'background': getColorCodeAndClassForGoals(item?.status, item?.archive).color}}></em> 
                        <Select
                          inputProps={{}}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "right"
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "right"
                            }, 
                            classes: { list: 'p-0' }
                          }}                          
                          className="select-status borderless-dropdown"
                          value={item?.status}
                          displayEmpty
                          label="Status"
                          onChange={(e) => changeStatus(e, item)}
                        >
                          {goalStatusList?.map(status => <MenuItem key={status?.id} value={status?.value} className="status-menu-item"><em className="dot-sm" style={{backgroundColor: status?.color, border: status?.border}}></em> {status?.text}</MenuItem>)}
                        </Select>
                      </span>
                    </div>
                  </div>)}
                  {/* <div className="incread-by-box disabled"> 
                    <div className="title-head">
                      <h4><span className="cr-pointer">Convert 10 lead weekly to increase sales</span> <em className="icon be-archive"></em></h4>                       
                      <Button className="three-dot-btn xs-btn" onClick={openThreeDotMenu}><em className='icon be-vertical-dots'></em></Button>
                      <Menu
                        id="three-dot-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                        }}
                        transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                        }}
                        className={'three-dot-menu-paper'}
                      >
                        <MenuItem className='toggle-menu-item-row' ><img src={images.editIcon} alt=""/> Edit</MenuItem>
                        <MenuItem className='toggle-menu-item-row'><em className='icon be-archive'></em> Archive</MenuItem>
                      </Menu>
                    </div>
                    <div className="progress-wrap">
                      <div className="text-content">Increase number of employee worldwide by 60%, this includes online and in store.</div>
                      <div className="progress-bar">                    
                        <Box sx={{ width: '100%' }}>
                          <LinearProgress variant="determinate" value={0} />
                        </Box>  
                        progressbar here!                     
                        <div className="progress-text">
                          <strong>0/0</strong><span> Builcards accomplished</span>
                        </div>
                      </div>
                    </div>
                    <div className="tags-wrapper">
                      <Stack direction="row" spacing={1}>
                        <Chip label='Reduce delivery time by 2 days' className="purple-tag" />
                        <Chip label='Reduce delivery time by 2 days' className="purple-tag" />
                        <Chip label='+1' className="purple-tag" />
                      </Stack>
                    </div>
                    <div className="box-footer">
                      <span className="date-box">Target 20.10.2024</span>
                      <span className="status">
                        {/* { onTrack: backgroundColor:'#69F0AE', border:'1px solid #00E676' },{ Not started: backgroundColor:'#DFE0E6', border:'1px solid #9B9FB1' },{ progressing: backgroundColor:'#FFD740', border:'1px solid #FFC400' },{ offTrack: backgroundColor:'#FF5546', border:'1px solid #FFB3AC' },{ accomplished: backgroundColor:'#00E676', border:'1px solid #00C853' } */}
                        {/* Accomplished <img src={images.checkAccomplishedRounded} alt="icon"/>                         
                      </span>
                    </div>
                  </div> */}

                </div>
              </div>             

            </div>

            <Summary tab={"Space"}></Summary>
          </section>

        </div>
      </div> : <UnauthorizedAccess/>}

      {/* Create Space goal dialogue start */}
      {openDialogue && <CreateSpaceGoalDialog { ...{openDialogue, handleClose, selectedGoal} }/>}
      {/* Create Space goal dialogue end */}

    </>    
  )
}

export default SpaceGoals;
