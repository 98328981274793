/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint eqeqeq: 0 */
import { Paper } from '@mui/material';
import Button from '@mui/material/Button';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import Pagination from '@mui/material/Pagination';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import images from '../../../assets/images';
import instance from '../../../shared/interceptor';
import { companyState } from '../../../shared/store/slices/userSlice';
import { COMPANY } from '../../../shared/util/constant';
import { debounce, getFullname, getInitials, globalError, setLoader } from '../../../shared/util/util';


function CreditHistory({ setValue }) {
    const dispatch = useDispatch();
    const compSel = useSelector(companyState);

    const [page, setPage] = useState(1);
    const [allocation, setAllocation] = useState(null);
    const [query, setQuery] = useState('');
    const [random, setRandom] = useState(1);

    useEffect(() => {
        getCreditHistory();
    }, [query, page]);

    const getCreditHistory = async () => {
        try {
            setLoader(dispatch, true);
            let url = `${COMPANY}/${compSel?.id}/plan_credit_subscriptions/${compSel?.active_plan_credit_subscription?.id}/credit_allocations?q=${query}&page=${page}`;
            let res = await instance.get(url);
            setAllocation(res);
        } catch(e) {
            globalError(dispatch, e);
        } finally {
            setLoader(dispatch, false);
        }
    }

    const handlePaginationChange = (e, val) => {
        setPage(val);
    }

    const searchSpace = debounce((e) => {
        setQuery(e?.target?.value);
        setPage(1);
    })

    //===============================


  return (
    <>
        <div className='allocation-header'>
            <h4>Credit allocation/deallocation history</h4>
            <div className="right-filter">
                <div className="form-group">
                    <FormControl variant="filled">
                    {/* @ts-ignore */}
                    <FilledInput inputProps={{ 'data-testid': "spaceSearch" }} type="text" variant="filled" className="xs-input search-input-wrap" placeholder='Search spaces'
                        startAdornment={
                        <InputAdornment position="start"><em className='be-search'></em></InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment position="end" className='search-icon-wrap'>
                              {query && <img src={images.iconCrossCircle} alt="" onClick={() => {setQuery(''); setRandom(prev => prev + 1)}}/>}
                            </InputAdornment>                        
                        }
                        key={random}
                        onChange={searchSpace}
                    />
                    </FormControl>
                </div>

            </div>
        </div>
        <div className="table-wrapper table-responsive history-list-table">
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell>DATE</TableCell>
                        <TableCell align="right">CREDIT ALLOCATED/DEALLOACTED</TableCell>
                        <TableCell >SPACE</TableCell>
                        <TableCell >Executed by</TableCell>
                        <TableCell align="right">Credit balance</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {!allocation?.credit_allocations?.length && <TableRow>
                            <TableCell colSpan={5}>
                                {!query && <div className="no-data-wrapper no-ideas-yet" aria-label="empty-state">
                                    <em className="thumb"><img style={{'borderRadius': 0}} src={images.emptyStateIllustration} alt="Spaces Flag" /></em>
                                    <h4 aria-label="no-space">No data yet</h4>
                                    <p>You have not allocated credits to your spaces yet.</p>
                                    <Button variant="contained" className="xs-btn purple-btn btn-new-board" onClick={() => setValue('2')}>Allocate Credits</Button>
                                </div>}
                                {query && <div className="card manage-card no-search-results">
                                    <em className="icon sm"><img src={images.emptyBox} alt="" /></em>
                                    <h6>No results found</h6>
                                    <p>{query} Space does not exist</p>
                                </div>}
                            </TableCell>
                        </TableRow>}
                        {allocation?.credit_allocations?.map((row) => (
                            <TableRow
                            key={row?.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell >{moment(row?.created_at).format('DD.MM.YYYY')}</TableCell>
                                <TableCell align="right"><span className={row?.allocation_type == 'deallocated' ? 'minus' : 'plus'}>{row?.allocation_type == 'deallocated' ? `-${row?.credit}` : `+${row?.credit}`}</span></TableCell>
                                <TableCell ><span className='icon be-multiple-users' style={{backgroundColor: row?.space_color}}></span> {row?.space_name}</TableCell>
                                <TableCell ><span className='img initials'>
                                    {row?.actor?.profile_picture?.profile_picture_url ? <img src={row?.actor?.profile_picture?.profile_picture_url} alt="" /> : getInitials(getFullname(row?.actor?.first_name, row?.actor?.last_name))}
                                </span>{getFullname(row?.actor?.first_name, row?.actor?.last_name)}</TableCell>
                                <TableCell align="right">{row?.credit_balance}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
        <div className="custom-pagination-wrapper justify-content-between">
            {allocation?.total_count > 0 && <p>Showing {allocation?.credit_allocations?.length} out of {allocation?.total_count} results</p>}
            {allocation?.total_pages > 1 && <Pagination count={allocation?.total_pages} color="secondary" onChange={handlePaginationChange}/>}
        </div>
    </>
  )
}

export default CreditHistory;
