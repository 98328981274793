/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint eqeqeq: 0 */
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import images from '../../assets/images';
import RemoveMemberFromEnterpriseDialog from '../../components/dialog/member-detail-dialog/RemoveMemberFromEnterprise';
import RemoveMemberFromTheSpaceDialog from '../../components/dialog/member-detail-dialog/RemoveMemberFromSpace';
import instance from '../../shared/interceptor';
import { setSuccessMsg } from '../../shared/store/slices/successSlice';
import { companyState, permissionState, userState } from '../../shared/store/slices/userSlice';
import { ENT_USERS, ROLES, UPDATE_ROLE } from '../../shared/util/constant';
import { getFullname, getInitials, getRandom, globalError, isAllowedTo, setLoader } from '../../shared/util/util';
import './members.scss';


let colorList = ['rgba(236, 13, 255, 0.5)', 'rgba(13, 168, 255, 0.5)', 'rgba(36, 0, 255, 0.5)'];

export default function MemberDetail() {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const userSel = useSelector(userState);
  const compSel = useSelector(companyState);
  const permissions = useSelector(permissionState);

  const [userDetail, setUserDetail] = useState(null);
  const [RemoveCurrentMember, setRemoveCurrentMember] = useState(false);
  const [RemoveMemberFromSpace, setRemoveMemberFromSpace] = useState(false);
  const [roles, setRoles] = useState([]);
  const [filteredSpaces, setFilteredSpaces] = useState([]);
  const [currentSpace, setCurrentSpace] = useState(null);

  useEffect(() => {
    (async () => await getUserDetails())();
  }, []);

  const getUserDetails = async () => {
    try {
      setLoader(dispatch, true);
      let url = `${ENT_USERS.replace(':id', state.company.id)}/${state.id}`;
      let rolesUrl = `${ROLES.replace(':id', state.company.id)}`;
      let response = await Promise.all([instance.get(url), instance.get(rolesUrl)]);

      setUserDetail(response[0]['user']);
      if (compSel?.owner?.id == userSel?.id && userSel?.role?.name == 'CIO') {
        setRoles(response[1]['roles']);
      } else {
        let filteredItem = [...response[1]['roles']].filter(item => item.name != 'CIO');
        setRoles(filteredItem);
      }
      let filteredListOfSpaces = response[0]['user']['all_spaces']
        // .filter(space => (space.private && userSel?.id === space.owner_id));
      setFilteredSpaces(filteredListOfSpaces);
    } catch (err) {
      globalError(dispatch, err);
    } finally {
      setLoader(dispatch, false);
    }
  }

  const RemoveCurrentMemberDialogClose = () => {
    setRemoveCurrentMember(false);
  };

  const RemoveMemberFromSpaceDialogClose = async (e, spaceRemoved = false) => {
    if(spaceRemoved) {
      await getUserDetails();
    }
    setRemoveMemberFromSpace(false);
  };

  const updateRole = async (role) => {
    if (userSel?.role?.name != 'CIO' && role == 'CIO' && userSel?.owner?.id != compSel?.owner?.id) {
      globalError(dispatch, {message: 'You are not authorized to update the role of this user to CIO.'});
      return;
    }
    try {
      setLoader(dispatch, true);
      let url = `${UPDATE_ROLE.replace(':id', state.company.id).replace(':userId', state.id)}`;
      let payload = {
        user: {
          role_name: role
        }
      }
      await instance.patch(url, payload);
      await getUserDetails();
      dispatch(setSuccessMsg('Role updated successfully'));
    } catch (err) {
      globalError(dispatch, err);
    } finally {
      setLoader(dispatch, false);
    }
  }

  const removeMember = (space) => {
    setCurrentSpace(space);
    setRemoveMemberFromSpace(true);
  }

  const removeUser = () => {
    setRemoveCurrentMember(true);
  }

  // ============================================

  return (
    <>
      <div className="member-detail-page" aria-label="member-detail-page">
        <div className="page-container top-space">
          <div className="breadcrumb-wrapper">
            <ul className="breadcrumb-links">
              <li><Link to='/company'>Company</Link></li>
              <li><Link to='/company/members'>Members</Link></li>
              <li>Details</li>
            </ul>
          </div>

          <div className="member-info-wrapper">
            <div className="info-outer">
              <div className="name-wrapper">
                <div className="initials">
                  { userDetail?.profile_picture?.profile_picture_url ? 
                  <img src={userDetail?.profile_picture?.profile_picture_url}  style={{borderRadius: '50%'}}/>
                  :
                  getInitials(userDetail?.first_name + ' ' + userDetail?.last_name)}
                </div>
                <div className="name-info">
                  <span className="h2 name">{getFullname(userDetail?.first_name, userDetail?.last_name)}</span>
                  <span className="date">Member since {userDetail?.confirmed_at ? moment(userDetail?.confirmed_at).format('MMM DD, YYYY'): 'N/A'}</span>
                </div>
              </div>

              <div className="member-info-row">
                <div className="left-col">
                  <div className="detailed-info-row">
                    <div className="inner-col">
                      <span className="info-label">Email</span>
                      <span className="value">{userDetail?.email}</span>
                    </div>
                    <div className="inner-col">
                      <span className="info-label">Job Title</span>
                      <span className="value">{userDetail?.job_title ?? '-'}</span>
                    </div>
                    {/* <div className="inner-col">
                      <span className="info-label">User Role</span>
                      <span className="value">{userDetail?.role?.name ?? '-'}</span>
                    </div> */}
                  </div>
                </div>
                {userSel?.id !== userDetail?.id && isAllowedTo('destroy', 'user', permissions) && <div className="right-col">
                  <Button aria-label="remove-user" variant="outlined" className="xs-btn" onClick={() => removeUser()}>Remove member</Button>
                </div>}
              </div>
            </div>

            <div className="info-outer">
              <div className="info-title">
                <h6>Spaces</h6>
              </div>
              <div className="space-row">
                {filteredSpaces.length > 0 && filteredSpaces.map((space, i) => (
                  <div className="space-col" key={`space_${i + getRandom()}`}>
                    <div className="space-card">
                      <div className="card-left">
                        <em className="be-multiple-users space-icon" style={{ backgroundColor: colorList[Math.floor(Math.random() * colorList.length)] }}></em>
                        <span className="name h6">{space.name}</span>
                      </div>
                      <div className="card-right">
                        <Button aria-label="remove-mem-btn" variant="contained" className="xs-btn purple-btn" onClick={() => removeMember(space)}>Remove member</Button>
                      </div>
                    </div>
                  </div>
                ))}
                {!filteredSpaces.length && <div className="space-col w-100">
                  <div className="space-card has-no-space">
                    <em className="icon">
                      <img src={images.spacesFlagPurple} alt="flag" />
                    </em>
                    <div className="no-space-content">
                      {getFullname(userDetail?.first_name, userDetail?.last_name)} has not been added to any space yet.
                    </div>
                  </div>
                </div>}
              </div>
            </div>

            <div className="info-outer">
              <div className="info-title sm-space">
                <h6>User role</h6>
                <a href={void (0)} className='primary-link'>More about user roles</a>
              </div>
              <div className="roles-wrapper">
                <div className="radio-wrapper">
                  <RadioGroup name='user-role' value={userDetail?.role?.name ?? 'Member'}>
                    {roles.length > 0 && roles.map(item => <FormControlLabel key={item.id} className="radio-control box-radio no-border top-align" value={item.name} control={<Radio
                      onChange={() => updateRole(item.name)}
                      icon={<img src={images.radioUnchecked} alt='unchecked-radio' />}
                      checkedIcon={<img src={images.radioTickChecked} alt='checked-radio' />}
                      aria-label="role-radio"
                    />} label={
                      <div className='radio-content'>
                        <strong>{item.name}</strong>
                        <div className="sub-content">
                          <p>{item.description}</p>
                        </div>
                      </div>
                    } />)}
                    {/* <FormControlLabel className="radio-control box-radio no-border top-align" value="IT Admin" control={<Radio
                      icon={<img src={images.radioUnchecked} alt='' />}
                      checkedIcon={<img src={images.radioTickChecked} alt='' />}
                    />} label={
                      <div className='radio-content'>
                        <strong>IT Admin</strong>
                        <div className="sub-content">
                          <p>Access to the organisation, its data, and settings. Can create scope definitions and add members to the organisation. Can assign managers to scopes. Can manage Builder Cloud service.</p>
                        </div>
                      </div>
                    } />
                    <FormControlLabel className="radio-control box-radio no-border top-align" value="Finance Admin" control={<Radio
                      icon={<img src={images.radioUnchecked} alt='' />}
                      checkedIcon={<img src={images.radioTickChecked} alt='' />}
                    />} label={
                      <div className='radio-content'>
                        <strong>Finance</strong>
                        <div className="sub-content">
                          <p>Can manage subscription and billing details.</p>
                        </div>
                      </div>
                    } />
                    <FormControlLabel className="radio-control box-radio no-border top-align" value="Manager" control={<Radio
                      icon={<img src={images.radioUnchecked} alt='' />}
                      checkedIcon={<img src={images.radioTickChecked} alt='' />}
                    />} label={
                      <div className='radio-content'>
                        <strong>Manager</strong>
                        <div className="sub-content">
                          <p>Admin access for their scope. Can create new projects and Buildcards for their scope, as well as remove projects and Buildcards they’re a member of. Can assign leads to projects. Can invite, add, delete members in their scope. Can create business goals and assign them to projects and Buildcards in their scope. Approve Buildcards for their scope. </p>
                        </div>
                      </div>
                    } />
                    <FormControlLabel className="radio-control box-radio no-border top-align" value="Member" control={<Radio
                      icon={<img src={images.radioUnchecked} alt='' />}
                      checkedIcon={<img src={images.radioTickChecked} alt='' />}
                    />} label={
                      <div className='radio-content'>
                        <strong>Member</strong>
                        <div className="sub-content">
                          <p>Can create projects and Buildcards. Manage Buildcards and releases.</p>
                        </div>
                      </div>
                    } /> */}
                  </RadioGroup>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      {/* Remove member from enterprise starts */}
      <RemoveMemberFromEnterpriseDialog {...{ RemoveCurrentMember, RemoveCurrentMemberDialogClose, userDetail }} />
      {/* Remove member from enterprise ends */}


      {/* Remove member from particular space modal starts */}
      <RemoveMemberFromTheSpaceDialog {...{ RemoveMemberFromSpace, RemoveMemberFromSpaceDialogClose, currentSpace, userDetail }} />
      {/* Remove member from particular space modal ends */}
    </>
  )
}
