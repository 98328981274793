import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import instance from '../../shared/interceptor';
import { setSuccessMsg } from '../../shared/store/slices/successSlice';
import { RESEND_INVITE } from '../../shared/util/constant';
import { globalError, setLoader } from '../../shared/util/util';

const RenderMenuList = ({row, openRevokeDialog}) => {
  const dispatch = useDispatch();

  const [openElem, setOpenElem] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (row) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpenElem(row);
  };

  const resendInvite = async () => {
    try {
      setLoader(dispatch, true);
      let url = RESEND_INVITE.replace(':id', row.company.id).replace(':userId', row.id);
      await instance.post(url, null);
      dispatch(setSuccessMsg('Invite sent successfully'));
    } catch(err) {
      globalError(dispatch, err);
    } finally {
      setLoader(dispatch, false);
    }
  }

  // ============================================

  return (<><Button data-testid="menu-item" variant="contained" className='xxs-btn purple-btn' onClick={handleClick(row)}>Invited<em className="be-chevron-down"></em></Button>
      <Menu
        anchorEl={anchorEl}
        open={openElem === row}
        onClose={() => { setAnchorEl(null);setOpenElem(null) }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className='custom-dropmenu'
      >
        <MenuItem className='is-primary' onClick={resendInvite}><em className="be-send-email"></em>Resend Invite
        </MenuItem>
        <MenuItem data-testid="revoke-dialog" onClick={() => openRevokeDialog(row)}><em className="be-close-circle"></em>Revoke Invite</MenuItem>
      </Menu>
    </>)
  }

  export default RenderMenuList;