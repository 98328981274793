/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint eqeqeq: 0 */
import Button from '@mui/material/Button';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import images from '../../assets/images';
import AddSpaceMemberCsvDialog from '../../components/dialog/company-space-dialog/AddSpaceMemberCsvDialog';
import AddSpaceMemberDialog from '../../components/dialog/company-space-dialog/AddSpaceMemberDialog';
import instance from '../../shared/interceptor';
import { currentSpaceState } from '../../shared/store/slices/companySlice';
import { companyState, permissionState, userPermissionState } from '../../shared/store/slices/userSlice';
import { SPACES } from '../../shared/util/constant';
import { debounce, globalError, isAllowedTo, setLoader } from '../../shared/util/util';
import SpaceTeamListTable from './SpaceTeamListTable';
import SpaceTopNavigation from './SupplyChainDistributionNavigation';
import './spaces.scss';
import Summary from './summary';

export default function SpaceTeam() {
  const dispatch = useDispatch();
  const currentSpaceSel = useSelector(currentSpaceState);
  const compSel = useSelector(companyState);
  const permissions = useSelector(permissionState);
  const userPermissions = useSelector(userPermissionState);

  const [addSpaceMember, setAddSpaceMember] = useState(false);
  const [AddSpaceMemberBulk, addSpaceMembersBulk] = useState(false);
  const [teamList, setTeamList] = useState([]);
  const [spaceOwner, setSpaceOwner] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [random, setRandom] = useState(1);
  const [loading, setLoading] = useState(false);
  const [shouldVisible, setShouldVisible] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (currentSpaceSel) {
      getTeamList(signal);
    }

    return () => {
      controller.abort();
      setShouldVisible(false);
    }
  }, [currentSpaceSel?.id, searchText]);

  const AddSpaceMemberDialogOpen = () => {
    setAddSpaceMember(true);
  };

  const AddSpaceMemberDialogClose = async (e, render = false) => {
    if(render) {
      await getTeamList();
    }
    setAddSpaceMember(false);
  };

  const AddSpaceMemberBulkDialogOpen = () => {
    setAddSpaceMember(false);
    addSpaceMembersBulk(true);
  };

  const openManual = () => {
    addSpaceMembersBulk(false);
    setAddSpaceMember(true);
  }

  const AddSpaceMemberBulkDialogClose = async (e, render = false) => {
    if(render) {
      await getTeamList();
    }
    addSpaceMembersBulk(false);
  };

  const getTeamList = useCallback(async (signal?) => {
    try {
      if (compSel?.id && currentSpaceSel?.id) {
        if (!searchText){
          setLoader(dispatch, true);
        }
        setLoading(true);
        let url = `${SPACES.replace(':id', compSel?.id)}/${currentSpaceSel?.id}/all_members`;
        if (searchText) {
          url += `?q=${searchText}`;
        }
        let teamListRes = await instance.get(url, { signal });
        if (teamListRes) {
          setTeamList(teamListRes['space']['members']);
          setSpaceOwner(teamListRes['space']['owner']);
        }
      }
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoading(false);
      setLoader(dispatch, false);
      setShouldVisible(true);
    }
  }, [currentSpaceSel?.id, searchText])

  const searchMember = debounce((e) => {
    setSearchText(e.target.value);
  })

  const clearInput = () => {
    setSearchText('');
    setRandom(prev => prev + 1);
  }

  //=========================================================

  return (
    <>
      <div className="supply-chain-distribution spaces-team-page tab-page-section" aria-label="space-team">
        <div className="page-container top-space pb-0">
          <SpaceTopNavigation />

          <section>
            <div className='page-content'>
              <div className="page-filters-wrapper">
                <div className="left-filter">
                  <span aria-label="count" className="count">{(Object.keys(spaceOwner ?? {}).length) ? teamList?.length + 1 : teamList?.length} members</span>
                </div>
                <div className="right-filter">
                  <div className="form-group">
                    <FormControl variant="filled">
                      {/* @ts-ignore */}
                      <FilledInput key={random} onChange={searchMember} inputProps={{ 'data-testid': "searchMember" }} type="text" variant="filled" className="xs-input search-input-wrap" placeholder='Search member'
                        startAdornment={
                          <InputAdornment position="start"><em className='be-search'></em></InputAdornment>
                        }
                        endAdornment={
                          <InputAdornment position="end" className='search-icon-wrap'>
                            {searchText && <img src={images.iconCrossCircle} alt="" onClick={clearInput}/>}
                          </InputAdornment>                        
                        }
                      />
                    </FormControl>
                  </div>
                  {(isAllowedTo('update', 'space_member', permissions, { id: currentSpaceSel?.id, type: 'Space' }, userPermissions)) && <Button data-testid="add-space-to-list" variant="contained" className="xs-btn purple-btn" onClick={AddSpaceMemberDialogOpen}>Add members</Button>}
                </div>
              </div>

              <div className="table-wrapper table-responsive">
                { loading && <div><img src={(images as any).buAnim} alt="B" style={{ position: 'fixed', top: '50%', left: '50%', height: '80px', width:'80px', transform: 'translate(-50%, -50%)' }}/></div> }
                {!loading && (!teamList?.length && spaceOwner && !Object.keys(spaceOwner ?? {})?.length) && <div className="p-4">No member found.</div>}
                {shouldVisible && (teamList?.length > 0 || Object.keys(spaceOwner ?? {})?.length > 0) && <SpaceTeamListTable {...{teamList, spaceOwner, loading}}/>}
              </div>

            </div>
            <Summary tab={"Space"}></Summary>
          </section>

        </div>
      </div>

      { addSpaceMember && <AddSpaceMemberDialog {...{addSpaceMember, AddSpaceMemberDialogClose, AddSpaceMemberBulkDialogOpen}}/>}

      { AddSpaceMemberBulk && <AddSpaceMemberCsvDialog {...{ AddSpaceMemberBulk, AddSpaceMemberBulkDialogClose, openManual }}/>}
    </>
  )
}