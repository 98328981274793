/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint eqeqeq: 0 */
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import images from '../../assets/images';
import instance from '../../shared/interceptor';
import { companyState, userState } from '../../shared/store/slices/userSlice';
import { LIST_ALL_PERMISSIONS, RESTORE_DEFAULT_PERM, ROLES, ROLES_TABLE, UPDATE_PERMISSIONS } from '../../shared/util/constant';
import { globalError, groupBy, setLoader, toSnakeCase } from '../../shared/util/util';
import RolesPermissionsTopNavigation from './RolesPermissionsTopNavigation';
import './company.scss';
import { setSuccessMsg } from '../../shared/store/slices/successSlice';

let rolesAccordion = [
  { accordTitle: 'CIO', alias: 'CIO' },
  { accordTitle: 'Space Admin', alias: 'Space Admin' },
  // { accordTitle: 'Project Lead' },
  { accordTitle: 'Member', alias: 'Member' },
  { accordTitle: 'Finance', alias: 'Finance Admin' },
  { accordTitle: 'IT', alias: 'IT Admin' },
  // { accordTitle: 'External' },
]

export default function CompanyDefaultRolesPermissions() {
  const dispatch = useDispatch();
  const companySel = useSelector(companyState);
  const userSel = useSelector(userState);

  const [noSearchResults, setNoSearchResults] = useState(false);
  const [rolePermAccordion, setRolePermAccordion] = useState([]);
  const [rolesAndPerm, setRolesAndPerm] = useState([]);
  const [roleAcc, setRoleAcc] = useState(rolesAccordion);
  const [btnEnable, setBtnEnable] = useState('');

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    
    getListOfPermissions(signal);

    return () => {
      controller.abort();
    }
  }, []);


  const getListOfPermissions = async (signal?) => {
    try {
      setLoader(dispatch, true);
      let permUrl = `${ROLES.replace(':id', companySel?.id)}`;
      let allPermUrl = `${LIST_ALL_PERMISSIONS.replace(':id', companySel?.id)}`;
      let res = await Promise.all([instance.get(permUrl, {signal}), instance.get(allPermUrl, {signal})]);

      if (res.length && res?.[0]?.['roles'] && res?.[1]?.['permissions']) {
        let roleBasedPerm = [...res[0]['roles']];
        let allPermissions = res[1]['permissions'];
        setRolesAndPerm(res[0]['roles']);
  
        roleBasedPerm.forEach(it => {
          it.permissions = [...it.permissions].map(it => ({...it, checked: true}));
          it.permissions = it.permissions.concat(allPermissions.filter(a => !it.permissions.find(b => b.id === a.id)));
        })
        let groupedArr = roleBasedPerm.map(item => {
          return { ...item, permissions: groupBy(item.permissions, 'module')}
        });
        setRolePermAccordion(groupedArr);
      }
    } catch(e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  }

  const checkHandler = (row, item, some) => {
    Object.keys(item.permissions).forEach(x => {
      item.permissions[x].forEach((y, idx) => {
        if (y.title === some && y.title !== 'can-read-company') {
          item.permissions[x][idx] = { ...item.permissions[x][idx], checked: !item.permissions[x][idx]['checked'] };
        }
      })
    });
    let temp = [...rolePermAccordion];
    let index = temp.map(a => a.name).indexOf(item.name);
    temp[index] = item;
    setRolePermAccordion(temp);
    setBtnEnable(item?.name);
  }
  
  const saveChanges = async (title) => {
    if(title === 'CIO') {
      return globalError(dispatch, {message: 'Cannot update CIO permissions'});
    }
    try {
      setLoader(dispatch, true);
      let changed = rolePermAccordion.map(item => {
        return {...item, permissions: Object.keys(item.permissions).flatMap(key => item.permissions[key])}
      })
      let accordObj = rolesAndPerm.find(item => item.name === title);
      let changedObj = changed.find(item => item.name === title);
      let changedPermissionsIds = [];
      accordObj.permissions.forEach(x => {
        changedObj.permissions.forEach(y => {
          if (x.id === y.id && y.checked) {
            changedPermissionsIds.push({id: y.id });
          }
        })
      })
      let roleId = changedObj?.id;
      let companyId = userSel?.company?.id;
      let payload = {
        permissions: changedPermissionsIds
      }
      let url = `${UPDATE_PERMISSIONS.replace(':id', companyId).replace(':role_id', roleId)}`;
      await instance.patch(url, payload);
      await getListOfPermissions();
      dispatch(setSuccessMsg('Permissions updated successfully.'));
    } catch(e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  }

  const restoreDefaultPermissions = async (title) => {
    if(title === 'CIO') {
      return globalError(dispatch, {message: 'Cannot update CIO permissions'});
    }
    try {
      setLoader(dispatch, true);
      let changed = rolePermAccordion.map(item => {
        return {...item, permissions: Object.keys(item.permissions).flatMap(key => item.permissions[key])}
      })
      let changedObj = changed.find(item => item.name === title);
      let roleId = changedObj?.id;
      let companyId = userSel?.company?.id;
      let url = `${RESTORE_DEFAULT_PERM.replace(':id', companyId).replace(':role_id', roleId)}`;
      await instance.patch(url, null);
      await getListOfPermissions();
      dispatch(setSuccessMsg('Permissions restored successfully.'));
    } catch(e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  }

  const roleData = (data) => {
    if (!data) {
      setRoleAcc(rolesAccordion);
      setNoSearchResults(false);
    } else {
      let filteredData =  rolesAccordion.filter(item => item.accordTitle.toLowerCase().includes(data?.toLowerCase()));
      if (filteredData?.length > 0) {
        setRoleAcc(filteredData);
        setNoSearchResults(false);
      } else {
        setNoSearchResults(true);
      }
    }
  }

  //=====================================================================

  return (
    <div className="company-roles-permissions-page" aria-label="roles-permission-page">
      <div className="page-container top-space">

        <RolesPermissionsTopNavigation roleData={roleData}/>

        <div className="page-content">
          { noSearchResults ?
            <div className="search-result-wrapper">
              <h5 className='mt-4'>Search results</h5>
              <div className="no-search-results">
                <em className="icon"><img src={images.emptyBox} alt="" /></em>
                <h4>No role found</h4>
              </div>
            </div>

            :

            <div className='secondary-accordion'>
              {roleAcc.map((accord, i) => (
                // expanded={accordionExpanded === `${accord.accordTitle}`} onChange={accordionHandleChange(`${accord.accordTitle}`)}
                <Accordion key={`accord_${accord.accordTitle}`}>
                  <AccordionSummary>
                    <div className="accordion-heading">
                      <div className="left-heading">
                        <em className="icon be-right-arrow"></em>
                        <h5>{accord.accordTitle}</h5>
                      </div>
                      <div className="right-btns" onClick={(e) => e.stopPropagation()}>
                        <Button data-testid="restore-default" variant="outlined" className="xs-btn" onClick={() => restoreDefaultPermissions(accord.alias)}>Restore defaults</Button>
                        <Button data-testid="save-changes-btn" disabled={ btnEnable != accord.alias } variant="contained" className="xs-btn" onClick={() => saveChanges(accord.alias)}>Save changes</Button>
                      </div>
                    </div>
                    <div className="roles-desc">
                      <p>{rolesAndPerm?.map(item => {
                        if (item?.name == accord.alias) {
                          return <span key={item?.id}>{item?.description}</span>;
                        }
                      })}</p>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    {ROLES_TABLE.map((table, i) => (
                      <div className="roles-table" key={`rolestables_${table.title}`}>
                        <h6>{table.title}</h6>
                        <div className="table-responsive">
                          <table className="custom-table secondary">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th className='checkbox-col'><span className='sm-font'>View</span></th>
                                <th className='checkbox-col'><span className='sm-font'>Add/Edit</span></th>
                                <th className='checkbox-col'><span className='sm-font'>Remove</span></th>
                              </tr>
                            </thead>
                            <tbody>
                              {table.innerTable.map((row, i) => {
                                return rolePermAccordion.map(item => {
                                  if(accord.alias === item.name) {
                                    // (item.permissions[row.module] ?? []).some(item => (item.title === `can-read-${row?.module?.toLowerCase()}` && item.checked)) ?? false
                                    return (
                                      <tr key={`settings_row_${i}`}>
                                        <td className='role-title'>{row.label}</td>
                                        <td className='text-center'>
                                          <FormControlLabel
                                            className='checkbox-control'
                                            control={<Checkbox
                                              aria-label='checkbox'
                                              data-testid='checkbox1'
                                              checked={(item.permissions[row.module] ?? []).some(item => (item.title === `can-read-${toSnakeCase(row?.module)}` && item.checked)) ?? false}
                                              onChange={() => checkHandler(row, item, `can-read-${toSnakeCase(row?.module)}`)}
                                              icon={<img src={images.checkboxUnchecked} alt='' />}
                                              checkedIcon={<img src={images.checkboxChecked} alt='' />}
                                            />}
                                            label=''
                                          />
                                        </td>
                                        <td className='text-center'>
                                          <FormControlLabel
                                            className='checkbox-control'
                                            control={<Checkbox
                                              aria-label='checkbox'
                                              data-testid='checkbox2'
                                              checked={(item.permissions[row.module] ?? []).some(item => (item.title === `can-update-${toSnakeCase(row?.module)}` && item.checked)) ?? false}
                                              onChange={() => checkHandler(row, item, `can-update-${toSnakeCase(row?.module)}`)}
                                              icon={<img src={images.checkboxUnchecked} alt='' />}
                                              checkedIcon={<img src={images.checkboxChecked} alt='' />}
                                            />}
                                            label=''
                                          />
                                        </td>
                                        <td className='text-center'>
                                          <FormControlLabel
                                            className='checkbox-control'
                                            control={<Checkbox
                                              aria-label='checkbox'
                                              data-testid='checkbox3'
                                              checked={(item.permissions[row.module] ?? []).some(item => (item.title === `can-destroy-${toSnakeCase(row?.module)}` && item.checked)) ?? false}
                                              onChange={() => checkHandler(row, item, `can-destroy-${toSnakeCase(row?.module)}`)}
                                              icon={<img src={images.checkboxUnchecked} alt='' />}
                                              checkedIcon={<img src={images.checkboxChecked} alt='' />}
                                            />}
                                            label=''
                                          />
                                        </td>
                                      </tr>
                                    )
                                  }
                                })
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          }

        </div>
      </div>
    </div>
  )
}
