/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint eqeqeq: 0 */
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';
import instance from '../../../shared/interceptor';
import { setSuccessMsg } from '../../../shared/store/slices/successSlice';
import { companyState } from '../../../shared/store/slices/userSlice';
import { COMPANY, SPACES } from '../../../shared/util/constant';
import { globalError, setLoader } from '../../../shared/util/util';

export const ArchiveGoalDialog = ({archive, handleClose, goal = null}) => {
  const dispatch = useDispatch();
  const compSel = useSelector(companyState);
  const params = useParams();

  const handleModalClose = (e, reason) => {
    if (reason && reason == "backdropClick") return;
    handleClose(e, false)
  }

  const confirmArchive = async () => {
    try {
      setLoader(dispatch, true);
      let payload;
      let url;
      if (goal == 'space') {
        url = `${SPACES.replace(':id', compSel?.id)}/${params?.id}/space_goals/${archive?.id}`;
        payload = {
          name: archive?.name,
          description: archive?.description,
          status: archive?.status,
          due_date: archive?.due_date,
          owner_id: archive?.owner_id,
          company_goal_ids: archive?.company_goal_ids,
          archive: !archive?.archive
        }
      } else {
        payload = {company_goal: {archive: !archive?.archive, name: archive?.name, description: archive?.description, private: archive?.private, due_date: moment(archive?.due_date).format('YYYY-MM-DD'), status: archive?.status }}
        url = `${COMPANY}/${compSel?.id}/company_goals/${archive?.id}`;
      }
      await instance.patch(url, payload);
      dispatch(setSuccessMsg(`Goal ${archive?.archive ? 'unarchived' : 'archived'} successfully.`));
      handleClose(null, true);
    } catch(e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  }

  //======================================================
  return (<Dialog
    open={Boolean(archive)}
    onClose={handleModalClose}
  >
    <DialogContent className='normal-hori-padding' aria-label="archive-goal-modal">
      <div className="modal-body">
        <div className="modal-title">
          <h2 aria-label="archive-heading">{archive?.archive ? 'Unarchive' : 'Archive'} Goal</h2>
          <p>Are you sure you want to {archive?.archive ? 'unarchive' : 'archive'} {archive?.name} goal?</p>
        </div>
      </div>
    </DialogContent>
    <DialogActions className="fixed-footer-wrap">
      <div className="fixed-full-footer">
        <Button data-testid="close-archive-goal" variant="contained" className='sm-btn grey-btn' onClick={handleClose}>Cancel</Button>
        <Button data-testid="confirm-archive-goal" className='sm-btn' variant="contained" aria-label="archive-confirm" onClick={confirmArchive}>Yes</Button>
      </div>
    </DialogActions>
  </Dialog >)
}

export default ArchiveGoalDialog;
