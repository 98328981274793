/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint eqeqeq: 0 */
import Button from '@mui/material/Button';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from 'highcharts/highcharts-more';
import Highcharts from "highcharts/highstock";
import currentDateIndicator from "highcharts/modules/current-date-indicator";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import images from '../../../assets/images';
import ArchiveGoalDialog from '../../../components/dialog/business-goals-dialogue/ArchiveGoalDialog';
import CreateBusinessGoalDialogue from '../../../components/dialog/business-goals-dialogue/CreateBusinessGoalDialog';
import WelcomeBusinessGoalsPopup from '../../../components/dialog/business-goals-dialogue/WelcomeBusinessGoalsPopup';
import instance from '../../../shared/interceptor';
import GoalsCard from '../../../shared/shared-comp/GoalsCard';
import { loadingState } from '../../../shared/store/slices/loadingSlice';
import { companyState, userState } from '../../../shared/store/slices/userSlice';
import { COMPANY, goalStatusList } from '../../../shared/util/constant';
import customPlugin from '../../../shared/util/customPlugin';
import { getColorCodeAndClassForGoals, globalError, setLoader, sortByDate } from '../../../shared/util/util';
import { goalsStatusData, goalsTimeLine } from '../../dashboard/dashboardGraphData';
import '../company.scss';
import './business-goals.scss';

currentDateIndicator(Highcharts);
customPlugin(Highcharts);
HighchartsMore(Highcharts);


export default function BusinessGoals() {
  const dispatch = useDispatch();
  const compSel = useSelector(companyState);
  const loader = useSelector(loadingState);
  const userSel = useSelector(userState);

  const [companyGoals, setCompanyGoals] = useState(null);
  const [allGoalsArePrivate, setAllGoalsArePrivate] = useState(null);
  const [filterCompanyGoals, setFilterCompanyGoals] = useState(null);
  const [statusData, setStatusData] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [openCreateDialogue, setOpenCreateDialogue] = useState(false);
  const [openEditDialogue, setOpenEditDialogue] = useState(false);
  const [sortBy, setSortBy] = useState('target');
  const [sortStatus, setSortStatus] = useState('all');
  const [welcomeGoalPopup, setWelcomeGoalPopup] = useState(false);
  const [timelineData, setTimelineData] = useState([]);
  const [goalNameList, setGoalNameList] = useState([]);
  const [archiveModal, setArchiveModal] = useState(null);
  const [goalDetail, setGoalDetail] = useState(null);

  useEffect(() => {
    getAllData();
  }, []);

  const getAllGoals = () => {
    const url = `${COMPANY}/${compSel?.id}/company_goals`;
    return instance.get(url);
  }

  const getStatusData = () => {
    const url = `${COMPANY}/${compSel?.id}/company_goals/status_data`;
    return instance.get(url);
  }

  const getTimelineData = () => {
    const url = `${COMPANY}/${compSel?.id}/company_goals/timeline_data`;
    return instance.get(url);
  }

  const getAllData = async () => {
    try {
      setLoader(dispatch, true);
      let res = await Promise.all([getAllGoals(), getStatusData(), getTimelineData()]);
      setCompanyGoals(sortByDate(res?.[0]?.['company_goals'], 'due_date', 'Oldest'));
      setFilterCompanyGoals(sortByDate(res?.[0]?.['company_goals'], 'due_date', 'Oldest'));
      setStatusData(res?.[1]);
      setGoalNameList(res?.[2]?.['company_goals'].map(item => ({id: item?.id, name: item?.name, status: item?.status, archive: null, kpis: item?.kpis})))
      setTimelineData(res?.[2]?.['company_goals'].map(item => item?.kpis)?.flat()?.map((kpi, idx) => (
        {
          name: getColorCodeAndClassForGoals(kpi?.status, kpi?.archive ?? null)?.class, 
          color: getColorCodeAndClassForGoals(kpi?.status, kpi?.archive ?? null)?.color, 
          low: new Date(kpi?.created_at).getTime(), 
          high: new Date(kpi?.due_date).getTime(), 
          kpi_name: kpi?.name
        }
      )))
      // sortByStatus({target: { value: sortStatus }})
      setSortStatus('all');
    } catch(e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  }

  const sortGoals = (e) => {
    setSortBy(e.target.value as any);
    if (e.target.value == 'target') {
      setCompanyGoals(sortByDate(filterCompanyGoals, 'due_date', 'Oldest'));
    } else {
      setCompanyGoals(sortByDate(filterCompanyGoals, 'created_at', 'Latest'));
    }
  }

  const sortByStatus = (e) => {
    let val = e?.target?.value;
    let search = searchText?.toLowerCase();
    setSortStatus(val);
    if (val == 'all') {
      setCompanyGoals(filterCompanyGoals.filter(item => item?.name?.toLowerCase().includes(search)));
    } else {
      setCompanyGoals(filterCompanyGoals.filter(goals => goals.status == val && goals?.name?.toLowerCase().includes(search)));
    }
  }

  const filterGoals = (e) => {
    let value = e?.target?.value;
    setSearchText(value);
    if (!value) {
      return setCompanyGoals(filterCompanyGoals.filter(item => {
        if (sortStatus == 'all') {
          return item;
        } else {
          return item?.status == sortStatus;
        }
      }));
    }
    let filteredItems = filterCompanyGoals.filter(item => {
      let nameVal = item?.name?.toLowerCase();
      if (sortStatus == 'all') {
        return nameVal.includes(value?.toLowerCase());
      } else {
        return nameVal.includes(value?.toLowerCase()) && item?.status == sortStatus
      }
    });

    let allFilteredGoalsPrivate = true;
    filteredItems.map((item) => {
      allFilteredGoalsPrivate &&= item?.private
    });
    setAllGoalsArePrivate(allFilteredGoalsPrivate);

    setCompanyGoals(filteredItems);
  }

  const handleClose = (e, render = null) => {
    setOpenCreateDialogue(false);
    setArchiveModal(null);
    setOpenEditDialogue(false);
    if (render) {
      getAllData();
    }
  };

  const editClickHandler = (goal) => {
    setOpenEditDialogue(true);
    setGoalDetail(goal);
  }

  const archiveClickHandler = (goal) => {
    setArchiveModal(goal);
  }


  //=======================================================================

  return (
    <div className="goals-overview-page" aria-label="business-goals-overview-page">
      <div className="page-container top-space">
        <div className="main-page-header">
          <div className="page-breacrumb">
            <div className="breadcrumb-wrapper">
              <ul className="breadcrumb-links">
                <li><Link to='/company/company-settings'>Company</Link></li>
                <li>Business goals</li>
              </ul>
            </div>
            <div className="compnay-logo">
              { compSel?.logo?.logo_url && <img src={compSel?.logo?.logo_url} alt="company logo" /> }
            </div>
          </div>
          <div className="page-title">
            <h2>Business goals</h2>
          </div>
        </div>

        {timelineData?.length > 0 && <div className="timeline-graph-wrap">
          <h4>Timeline</h4>
          <div className="graph-container custom-scrollbar">
            <div style={{width: '18%', marginTop: '3.5rem'}}>
              <span>{goalNameList?.filter(item => item?.kpis?.length)?.map(item => <div className="label-box" style={{ height: (item?.kpis?.length * 22.6) + 'px', lineHeight: item?.kpis?.length * 22.6 + 'px'}} key={item?.id}><em className="color-dot" style={{backgroundColor: getColorCodeAndClassForGoals(item?.status, item?.archive)?.color}}></em>{item?.name}</div>)}</span>
            </div>
            <div style={{width: '82%'}}>
              <HighchartsReact
                highcharts={Highcharts}
                options={goalsTimeLine(timelineData, timelineData?.length * 26 + 'px')}
              />
            </div>
          </div>
        </div>  }        
        {!timelineData?.length && <div className="no-data-wrapper">
            <em className="thumb"><img src={images.emptyStateIllustration} alt="Empty" /></em>
            <h4 aria-label="no-space">No KPIs yet</h4>
            <p>Add KPIs to measure your business goals</p>         
          </div>}

        <h2 className="section-title mb-0">All goals</h2>

        <div className="goals-container">            
          <div className="goals-left-col">
            <div className="secondary-header">
              <div className="page-filters-wrapper">
                <div className="left-filter">
                  <div className="form-group sort-by">
                    <div className="dropdown-wrapper">
                      <span className="dropdown-text">Sort by &nbsp;&nbsp;&nbsp;<strong></strong></span>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                          },
                          classes: { list: 'p-0' }
                        }}
                        className="borderless-dropdown sm-height no-label"
                        displayEmpty
                        value={sortBy}
                        onChange={sortGoals}
                      >
                        <MenuItem className='sorting-menu-item' value="target">Target date</MenuItem>
                        <MenuItem className='sorting-menu-item' value="created">Created on</MenuItem>
                      </Select>  
                    </div>                    
                  </div>
                  <div className="form-group sort-by">
                    <div className="dropdown-wrapper">
                      <span className="dropdown-text">Show &nbsp;&nbsp;&nbsp;<strong></strong></span>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                          },
                          classes: { list: 'p-0' }
                        }}
                        className="borderless-dropdown sm-height no-label"
                        displayEmpty
                        value={sortStatus}
                        onChange={sortByStatus}
                      >
                        <MenuItem className='sorting-menu-item' value="all">All</MenuItem>
                        { goalStatusList.map(item => <MenuItem key={item?.id} className='sorting-menu-item' value={item?.value}>{item?.text}</MenuItem>)}
                      </Select>
                    </div>                    
                  </div>
                </div>
                <div className="right-filter">
                  <Button onClick={() => setOpenCreateDialogue(true)} variant="contained" className="xs-btn purple-btn btn-add-goal">Add goal</Button>
                </div>
              </div>
            </div>

            {/* TODO : add status class  on-track : #69F0AE, progressing : #FFD740, off-track : #FF8A80, accomplished : #00C853, accomplished2 : #83889E */}              
            {companyGoals?.length > 0 && companyGoals?.map(goal => {
              if (goal?.private && goal?.owner?.id != userSel?.id) {
                return null;
              } else {
                return <GoalsCard key={goal?.id} {...{goal, editClickHandler, archiveClickHandler}}  />
              }
            })}

            {((!companyGoals?.length || allGoalsArePrivate) && !loader?.loading) && <div className="no-data-wrapper">
              <em className="thumb"><img src={images.emptyStateIllustration} alt="Empty" /></em>
              <h4 aria-label="no-space">No goals yet</h4>
              <p>Add goals to keep your development on track.</p>  
              <Button onClick={() => setOpenCreateDialogue(true)} variant="contained" className="xs-btn grey-btn btn-add-goals">Add goal</Button>          
            </div>}

          </div>
          <div className="goals-right-col">
            <div className="search-wrap">
              <FormControl variant="filled">
                <FilledInput type="text" className="xs-input search-input-wrap search-goals" placeholder='Search goals' value={searchText}
                  startAdornment={
                    <InputAdornment position="start"><em className='be-search'></em></InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end" className='search-icon-wrap'>
                      {searchText && <img data-testid="cross-btn" className="cr-pointer" onClick={() => filterGoals({target: { value: ''}})} src={images.iconCrossCircle} alt="" /> }
                    </InputAdornment>
                  }
                  inputProps={{'data-testid' : "filter-goals-input"}}
                  onChange={filterGoals}
                />
              </FormControl>
            </div>
            <div className="right-col-section">                
              <div className="goal-count">
                <span>{statusData?.total_count}</span> Goals
              </div>
              <div className="chart-wrapper">                  
                <HighchartsReact highcharts={Highcharts} options={goalsStatusData(statusData, '210px', false, false)}/>
              </div>
              <div className="goals-status-wrap">
                <div className="content-row title">
                  <div className="content-left">Status</div>
                  <div className="content-right">Goals</div>
                </div>
                {/* { onTrack: '#69F0AE' },{ progressing: '#FFD740' },{ offTrack: '#FF8A80' },{ accomplished: '#00C853' } */}
                {goalStatusList.map(item => (
                  <div className="content-row" key={item?.id}>
                    <div className="content-left"><em className="color-dot" style={{backgroundColor: item?.color, border: item?.border}}></em> {item?.text}</div>
                    <div className="content-right">{statusData?.[item?.value] ?? 0}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Welcome business goal popup */}
      {welcomeGoalPopup && <WelcomeBusinessGoalsPopup {...{setWelcomeGoalPopup}}/>}

      {/* Create business goal dialogue start */}
      {openCreateDialogue && <CreateBusinessGoalDialogue {...{openCreateDialogue, handleClose}}/>}
      {/* Create business goal dialogue end */}

      {openEditDialogue && <CreateBusinessGoalDialogue {...{openCreateDialogue: openEditDialogue, handleClose, goalDetail}}/>}
      
      {archiveModal && <ArchiveGoalDialog {...{archive: archiveModal, handleClose}}/>}
    </div>
  )
}
