import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useDispatch } from 'react-redux';

import instance from '../../shared/interceptor';
import { setSuccessMsg } from '../../shared/store/slices/successSlice';
import { REVOKE_INVITE } from '../../shared/util/constant';
import { globalError, setLoader } from '../../shared/util/util';

const RevokeInviteDialog = ({RevokeInvite, closeRevokeDialog, selectedUser}) => {
  const dispatch = useDispatch();

  const revokeInvitation = async (e) => {
    try {
      setLoader(dispatch, true);
      let url = `${REVOKE_INVITE.replace(':id', selectedUser?.company?.id).replace(':userId', selectedUser.id)}`;
      await instance.delete(url);
      closeRevokeDialog(e, true);
      dispatch(setSuccessMsg('Invite Revoke successfully'));
    } catch (err) {
      globalError(dispatch, err);
    } finally {
      setLoader(dispatch, false);
    }
  }

  const handleModalClose = (e, reason) => {
    if (reason && reason == "backdropClick") return;
    closeRevokeDialog(e);
  }

  // ============================================

  return (<Dialog
      open={RevokeInvite}
      onClose={handleModalClose}
    >
      <DialogContent className='normal-hori-padding'>
        {/* <span className="modal-close be-close" onClick={closeRevokeDialog}></span> */}
        <div className="modal-body">
          <div className="modal-title">
            <h2 aria-label="revoke-heading">Revoke invitation</h2>
            <p>Are you sure you want to remove the invitation for <span className="d-block">{selectedUser?.email} ?</span></p>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="fixed-footer-wrap">
        <div className="fixed-full-footer">
          <Button data-testid="close-revoke" variant="contained" className='sm-btn grey-btn' onClick={closeRevokeDialog}>Cancel</Button>
          <Button data-testid="revoke-btn" className='sm-btn' variant="contained" onClick={(e) => revokeInvitation(e)}>Confirm remove</Button>
        </div>
      </DialogActions>
    </Dialog>)
}

export default RevokeInviteDialog;
