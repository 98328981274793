/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint eqeqeq: 0 */
import Button from '@mui/material/Button';
import Menu from "@mui/material/Menu";
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import instance from '../../../shared/interceptor';
import { getCurrentSpaceDetail } from '../../../shared/services/common.service';
import { currentSpaceState } from '../../../shared/store/slices/companySlice';
import { companyState, permissionState } from '../../../shared/store/slices/userSlice';
import { SPACES, SPACE_LIST } from '../../../shared/util/constant';
import { globalError, isAllowedTo, setLoader } from '../../../shared/util/util';
import '../_buildingSpaces.scss';


const PlaygroundWhiteboard = () => {
  const [frameUrl, setFrameUrl] = useState(null);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const currentSpaceSel = useSelector(currentSpaceState);
  const compSel = useSelector(companyState);
  const permissions = useSelector(permissionState);
  
  const [wb, setWb] = useState(null);

  useEffect(() => {
    blockInspect(true);

    return () => {
      blockInspect(false);
    }
  }, []);

  useEffect(() => {
    if (!currentSpaceSel?.id) {
      getCurrentSpaceDetail(dispatch, compSel?.id, params?.id);
    }
    if (!params?.whiteboard_id) {
      navigate(-1);
    } else {
      getWbUrl();
    }
  }, [params?.id, params?.whiteboard_id])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  }

  const getWbUrl = async () => {
    try {
      setLoader(dispatch, true);
      const url = `${SPACES.replace(':id', compSel?.id)}/${params?.id}/whiteboards/${params?.whiteboard_id}`;
      let wbRes = await instance.get(url);
      console.log(wbRes)
      setWb(wbRes?.['whiteboard']);
      setFrameUrl(wbRes?.['whiteboard']?.['url']);
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  }

  const blockInspect = (def) => {
    document.oncontextmenu = document.body.oncontextmenu = function() {
      return def ? false : true;
    }
    document.onkeydown = (e) => {
      if (e.key as any == 123) {
          return def ? e.preventDefault() : true;
      }
      if (e.ctrlKey && e.shiftKey && e.key == 'I') {
          return def ? e.preventDefault() : true;
      }
      if (e.ctrlKey && e.shiftKey && e.key == 'C') {
          return def ? e.preventDefault() : true;
      }
      if (e.ctrlKey && e.shiftKey && e.key == 'J') {
          return def ? e.preventDefault() : true;
      }
      if (e.ctrlKey && e.key == 'U') {
          return def ? e.preventDefault() : true;
      }
    };
  }


  //==================================================================
  return (
    <div className="building-spaces idea-board-wrapper" aria-label='playground-whiteboard'>
      <div className="iframe-page-head">
        <div className="main-page-header">
          <div className="left-page-header">
            <Button aria-label="ideaboard-btn" variant="contained" className="sm-btn purple-btn btn-back" onClick={() => {setFrameUrl(''); navigate(`/company/spaces/${params?.id}/ideaboard`)}}> 
              <em className="btn-icon be-left-arrow"></em>
            </Button>
            <div className="page-breacrumb">
              <div className="breadcrumb-wrapper">
                <ul className="breadcrumb-links">
                  <li><Link to='/dashboard'>Building</Link></li>
                  <li>{isAllowedTo('update', 'space', permissions) ? <Link to={SPACE_LIST}>Spaces</Link> : 'Spaces'}</li>
                  <li><Link to={`/company/spaces/${params?.id}/overview`}>{currentSpaceSel?.name}</Link></li>
                  <li aria-label="link-ideaboard" className="cr-pointer" onClick={() => {navigate(`/company/spaces/${params?.id}/ideaboard`); setFrameUrl(''); setWb(null)}}>Idea board</li>
                  <li>{wb?.name}</li>
                </ul>
              </div>
            </div>
            <div className='dropdown-wrap'>
              <Button data-testid="menu-item" variant="contained" className='xxs-btn purple-btn' onClick={handleClick}>Invite <em className="be-chevron-down"></em></Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                className='custom-dropmenu action-menu-dropdown invite'
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem className='menu-item'>Option 1</MenuItem>
              </Menu>
            </div>

          </div>          
          <div className="right-page-header">            
            <div className="compnay-logo">
              { compSel?.logo?.logo_url && <img src={compSel?.logo?.logo_url} alt="company logo" /> }
            </div>
          </div>
        </div>

        {frameUrl && <iframe aria-label="iframe-label" title="whiteboard application" src={frameUrl} className="wb-integration-iframe" />}
      </div>
    </div>
  )
}

export default PlaygroundWhiteboard;
