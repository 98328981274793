import Button from '@mui/material/Button';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import images from '../../assets/images';
import instance from '../../shared/interceptor';
import { FORGOT_PASSWORD } from '../../shared/util/constant';
import { getCookie, setLoader, validateEmail } from '../../shared/util/util';

export default function ForgotPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [instructionsSent, setInstructionsSent] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const userCookie = getCookie(process.env.REACT_APP_USER);
    const token =  userCookie ? JSON.parse(userCookie)?.['authtoken'] : null;
    if (token) {
      navigate('/dashboard');
    }
  }, []);

  const resetEmailHandler = (e) => {
    setResetEmail(e.target.value);
  }

  const sendResetEmail = async () => {
    try {
      if(validateEmail(resetEmail)) {
        setLoader(dispatch, true);
        setError('');
        let payload = {
          user: { email: resetEmail }
        }
        await instance.post(FORGOT_PASSWORD, payload);
        setInstructionsSent(true);
      } else {
        setError('Provide a valid email address.');
      }
    } catch(e) {
      setInstructionsSent(true);
    } finally {
      setLoader(dispatch, false);
    }
  }

  const submitForm = (e) => {
    setError('');
    if (e?.key === 'Enter') {
      e?.preventDefault();
      sendResetEmail();
    }
  }


  //================================================

  return (
    <section className="login-page">
      <div className={"login-wrapper " + (instructionsSent ? 'lg' : '')}>
        <div className="login-outer">   
          <em className="logo secondary">
            <img src={images.builderEnterpriseLogo} alt="Builder Enterprise" />
          </em>       
          {!instructionsSent ?
            <form onKeyDown={submitForm}>
              <div className="signup-title secondary">                
                <h1 aria-label='forgot-heading'>Forgot password?</h1>
                <p>Enter your registered email address for reset instructions</p>
              </div>

              <div className="form-group">
                <FormControl variant="filled" className={ error ? 'has-error' : '' }>
                  <InputLabel>Email</InputLabel>
                  {/* @ts-ignore */}
                  <FilledInput inputProps={{ 'data-testid': 'reset-email' }} type="email" variant="filled" onChange={resetEmailHandler}/>
                </FormControl>
                <span className="form-error" aria-label="error-msg">{ error }</span>
              </div>

              <div className="btn-block lg-space">
                <Button disabled={!resetEmail || !validateEmail(resetEmail)} data-testid='forgot-signin' variant="contained" onClick={sendResetEmail}>Send instructions</Button>
              </div>

              <div className="forgot-link secondary">
                <Link to='/signin' className="primary-link">Back to Sign in</Link >
              </div>
            </form>
            :
            <>
              <div className='success-generic-box'>
                <em className="icon"><img src={images.roundedTickGreen} alt="" /></em>
                <h2 className="h1 lg">Instructions sent!</h2>
                <p>If Builder.ai account exists for the email <strong>{ resetEmail }</strong>, instructions for resetting your password has been sent to it. Check your mailbox.</p>
                <Link to='/signin' className="primary-link">Back to Sign in</Link>
              </div>
            </>
          }
        </div>
      </div>
    </section >
  )
}
