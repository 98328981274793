/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint eqeqeq: 0 */
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { companyState } from '../../../shared/store/slices/userSlice';
import { COMPANY } from '../../../shared/util/constant';
import { globalError, setLoader } from '../../../shared/util/util';
import instance from '../../../shared/interceptor';
import { setSuccessMsg } from '../../../shared/store/slices/successSlice';


function FeedbackCreditDialog({connectWithExpert, handleClose}) {
    const dispatch = useDispatch();
    const compSel = useSelector(companyState);

    const [feedbackVal, setFeedbackVal] = useState('');
    const [error, setError] = useState('');

    const handleModalClose = (e, reason) => {
        if (reason && reason == "backdropClick") return;
        handleClose(null, false);
    }

    const submitFeedback = async () => {
        try {
            if(!feedbackVal) return setError('Enter the feedback');
            setLoader(dispatch, true);
            const url = `${COMPANY}/${compSel?.id}/plan_credit_subscriptions/${compSel?.active_plan_credit_subscription?.id}/space_credits/${connectWithExpert?.space_credit_id}/space_credit_requests/${connectWithExpert?.id}`;
            let payload = {
                space_credit_request: {
                    "status": "rejected",
                    "feedback": feedbackVal
                }
            }
            await instance.patch(url, payload);
            dispatch(setSuccessMsg('Feedback sent successfully'));
            handleClose(null, true);
        } catch(e) {
            globalError(dispatch, e);
        } finally {
            setLoader(dispatch, false);
        }
    }

  return (
    <Dialog
        open={Boolean(connectWithExpert)}
        onClose={handleModalClose}
      >
        <DialogContent className="no-btns">
          <span className="modal-close be-close" onClick={() => handleClose(null, false)}></span>
          <div className="modal-body">
            <div className="connect-with-expert feedback-dialog">
              <div className="modal-title">
                <h2>Feedback</h2>
              </div>

              <div className="expert-content">
                <div className="form-group">
                  <textarea onChange={e => {setFeedbackVal(e?.target?.value); setError('')}} className={`${error ? 'input-error' : ''} form-control`} placeholder='Please describe the reason for rejection'></textarea>
                  { error && <span aria-label="error" className="form-error">{ error }</span> }
                </div>
                <Button variant="contained" className="" disabled={!feedbackVal} onClick={submitFeedback}>Submit Feedback</Button>
              </div>
            </div>
          </div >
        </DialogContent>
      </Dialog>
  )
}

export default FeedbackCreditDialog;
