/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import moment from "moment";
import { getBcState, getCardLable } from "../../shared/util/util";

/* eslint eqeqeq: 0 */
export const todoAndReleaseOption = (stats) => ({
  chart: {
    spacing: [-15, 0, 10, 0],
    height: '290px',
    },
    legend: {
      verticalAlign: 'bottom',
      itemStyle: {
        fontSize: '11px'
      }
    },
    title: {
      text: ''
    },
    credits: false,
    colors: ["#6200EA", "#B388FF"],
    tooltip: {
      followPointer: false,
      outside: true,
      useHTML: true,
      formatter: function(tooltip) {
        return `<strong>${this?.point?.y} ${this.point.name} Overdue</strong>`;
      }
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
        borderWidth: 0,
        fillColor: '#ECEDF0',
        borderRadius: 0
      }
    },
    series: [
      {
        type: "pie",
        innerSize: "65%",
        data: [{
          name: "Releases Review",
          y: stats?.todosReleases?.total_pending_release ?? 0
        }, {
          name: "Todo's",
          y: stats?.todosReleases?.total_pending_todos ?? 0
        }],
        states: {
          inactive: {
            enabled: false
          }
        },
      }
    ],
    accessibility: {
      enabled: false
    }
});

export const buildCardDistributionOption = (stats) => ({
  chart: {
    height: '240px',
    spacing: [0, 0, 0, 0],
    },
    legend: {
      itemStyle: {
        fontSize: '11px'
      }
    },
    title: {
      text: ''
    },
    credits: false,
    colors: [ '#F3EBFF', '#B388FF', '#7C4DFF', '#651FFF', '#6200EA', '#69F0AE' ],
    tooltip: {
      followPointer: false,
      outside: true,
      useHTML: true,
      formatter: function(tooltip) {
        return `<strong>${this.point.name}: ${this?.point?.y}</strong>`;
      }
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
        borderWidth: 0,
        fillColor: '#ECEDF0',
        borderRadius: 0,
        states: {
          hover: {
            enabled: false,
          }
        }
      }
    },
    series: [
      {
        type: "pie",
        innerSize: "60%",
        data: [{
          name: "Draft",
          y: stats?.buildcardDistribution?.draft ?? 0
        }, {
          name: "Pending approval",
          y: stats?.buildcardDistribution?.pending_approval ?? 0
        }, {
          name: "Ready for kick off",
          y: stats?.buildcardDistribution?.ready_for_kickoff ?? 0
        }, {
          name: "Starting soon",
          y: stats?.buildcardDistribution?.starting_soon ?? 0
        }, {
          name: "Running",
          y: stats?.buildcardDistribution?.running ?? 0
        }, {
          name: "Delivered",
          y: stats?.buildcardDistribution?.completed ?? 0
        }],
        states: {
          inactive: {
            enabled: false
          }
        },
      }
    ],
    accessibility: {
      enabled: false
    }
});

export const trendingReleaseOption = (stats) => ({
  chart: {
    zoomType: 'x',
    height: '280px',
  },
  title: {
      text: '',
  },
  subtitle: {
      text: ''
  },
  tooltip: {
    outside: true,
    shared: true,
    fontSize: 12,
    useHTML: true,
    formatter: function(tooltip) {
      return `<div class='graph-tooltip-wrap'>
        <div class="graph-tooltip-content">
          <div class="date-text">
            ${this.points[0]?.key}
          </div>
          <div class="tickets-row">
            <span class="title">Tickets</span>
            ${this.points?.[0]?.series?.name == "Raised" ? `<span class="tickets-count" style="font-weight: 500;">${this.points?.[0]?.y}</span>` : ''}
            ${this.points?.[0]?.series?.name == "Resolved" || this.points?.[1]?.series?.name == "Resolved" ? `<span class="tickets-resolved" style="color:#6200EA; font-weight: 500;">&#129053; ${this.points?.[1]?.y ?? this.points?.[0]?.y} Resolved</span>` : ''}
          </div>
        </div>
      </div>`;
    }
  },
  xAxis: {
    lineColor: '#333333',
    lineWidth: 1,
    type: 'datetime',
    categories: stats?.ticketTrends?.map(item => Object.keys(item)),
    crosshair: {
      color: '#C0C3CE',
      width: '1px',
      interpolate: true,  
      snap: true,
    },
    labels: {
      style: { fontSize: '10px'}
    }
  },
  yAxis: {
    title: {
        text: ''
    },
    offset: 10
  },
  legend: {
    symbolRadius: 2,
    squareSymbol: true,
    padding: 5,
    enabled: true,
    align: 'right',
    verticalAlign: 'top',
    itemStyle: {
      color: 'black',
      fontSize: '12px'
    }
  },
  credits: {
    enabled: false
  },
  plotOptions: {
    series: {
      pointPlacement: 'on',
      fillOpacity: 0.1
    },
      area: {
        marker: {
            radius: 2
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 1
          }
        },
      threshold: null
    }
  },
  series: [{
    type: 'area',
    name: 'Raised',
    data: stats?.ticketTrends?.map(item => Object.keys(item).map(val => item[val]['completed_todos_count'])),
    color: '#EF7C8B',
  }, {
    type: 'area',
    name: 'Resolved',
    data: stats?.ticketTrends?.map(item => Object.keys(item).map(val => item[val]['created_todos_count'])),
    color: '#6200EA',
  }
  ],
  accessibility: {
    enabled: false
  }
});

export const goalsStatusData = (stats, height, showInLegend, showTitle) => ({
  chart: {
    height: height,
    spacing: [0, 0, 0, 0],
    },
    legend: {
      itemStyle: {
        fontSize: '11px'
      },
      verticalAlign: 'top',
    },
    title: {
      text: `${showTitle ? (stats?.total_count ?? 0) + ' KPIs' : ''} `,
      verticalAlign: 'bottom',
    },
    credits: false,
    tooltip: {
      followPointer: false,
      outside: true,
      useHTML: true,
      formatter: function(tooltip) {
        return `<strong>${this.point.name}: ${this?.point?.y}</strong>`;
      }
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: false,
        },
        showInLegend: showInLegend,
        borderWidth: 0,
        fillColor: '#ECEDF0',
        borderRadius: 0,
        states: {
          hover: {
            enabled: false,
          }
        }
      }
    },
    series: [
      {
        type: "pie",
        innerSize: "65%",
        data: [{
          name: "Not Started",
          y: stats?.not_started ?? 0,
          color: '#DFE0E6'
        }, {
          name: "On Track",
          y: stats?.on_track ?? 0,
          color: '#69F0AE'
        }, {
          name: "Progressing",
          y: stats?.progressing ?? 0,
          color: '#FFD740'
        }, {
          name: "Off Track",
          y: stats?.off_track ?? 0,
          color: '#FF8A80'
        }, {
          name: "Accomplished",
          y: stats?.accomplished ?? 0,
          color: '#00C853'
        }],
        states: {
          inactive: {
            enabled: false
          }
        },
      }
    ],
    accessibility: {
      enabled: false
    }
});

const calculateMaxTimeDifference = (data) => {
  if (!data?.length) {
    return 0;
  }

  let oldestStartDate = data[0]?.low;
  let farthestEndDate = data[0]?.high;

  data.forEach(obj => {
    if (obj.low < oldestStartDate) {
      oldestStartDate = obj.low;
    }
    if (obj.high > farthestEndDate) {
      farthestEndDate = obj.high;
    }
  });

  const monthsInBetween = moment(farthestEndDate).diff(moment(oldestStartDate), 'months');

  return monthsInBetween ?? 1; // return 1 as minimum month
}

export const goalsTimeLine = (data, height) => {
  const maxTimeDiff = calculateMaxTimeDifference(data);
  const CHARACTERS_IN_MAX_TIME_DIFF = 200;
  return {
    chart: {
      type: 'columnrange',
      inverted: true,
      height: height,
      spacing: [-7, 0, 0, 5],
      events: {
        load: function() {
          setTimeout(() => {
            let yAxis = this.yAxis?.[0];
            let coords = yAxis?.toPixels(new Date());
            this.renderer?.path(['M', coords, 0, 'L', coords, height]).attr({
              'stroke-width': 2.5,
              stroke: '#B388FF',
            }).add();
      
            this.renderer?.symbol('circle', (coords - 5.5), 0, 12, 12).attr({
              fill: '#B388FF'
            }).add();
          }, 2000);
        }
      }
    },
    accessibility: {
      enabled: false
    },
    title: {
      text: ''
    },
    xAxis: {
      // categories: uniqueColors,
      labels: {
        enabled: false,
      },
      lineWidth: 0,
      tickWidth: 0,
      gridLineWidth: 0,
    },
    yAxis: {
      type: "datetime",
      tickInterval: 30 * 24 * 3600 * 1000,
      title: {
          text: ''
      },
      opposite: true,
      gridLineDashStyle: 'ShortDash',
      grid: {
        enabled: true,
        borderWidth: 2,
      },
      gridLineWidth: 2,
    },
    credits: false,
    tooltip: {
      enabled: true,
      formatter: function(val) {
        let diff = moment(this.point.high).diff(moment(this.point.low), 'months');
        let charLen = this.point?.kpi_name?.length;
        if (charLen / diff <= CHARACTERS_IN_MAX_TIME_DIFF / maxTimeDiff) {
          return false;
        } else {
          return `<span>${this.point?.kpi_name}</span>`
        }
      },
      positioner: function(labelWidth, _, point) {
        return {
          x: point.plotX - 50,
          y: point.plotY - 10
        };
      },
      borderWidth: 1,
      backgroundColor: 'rgba(255,255,255,0.8)',
      style: {
        color: '#3C3E49',
        fontSize: '10px',
      },
    },
    plotOptions: {
      columnrange: {
        borderRadius: '50%',
        pointWidth: 20,
        dataLabels: {
          inside: true,
          enabled: true,
          useHTML: true,
          verticalAlign: 'middle',
          align: 'center',
          formatter: function(val) {
            let diff = moment(this.point.high).diff(moment(this.point.low), 'months');
            let charLen = this.point?.kpi_name?.length;
            if (charLen / diff <=  CHARACTERS_IN_MAX_TIME_DIFF / maxTimeDiff) {
              return `<span>${this.point?.kpi_name}</span>`
            } else {
              return false;
            }
          },
        }
      },
    },
  
    legend: {
      enabled: false
    },
    series: [{
      name: '',
      data: data,
    }]
  }
};

export const creditsGraph = (data, props) => {
  const sum = data.reduce((sum, data) =>  sum + data.data[0], 0);

  return {
    chart: {
      type: 'bar',
      height: props.height,
      minWidth: '100%',
      spacing: [0, 0, 0, 0],
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        grouping: false,
        pointWidth: props.pointWidth
      }
    },
    accessibility: {
      enabled: false
    },
    title: {
      text: ''
    },
    legend: {
      symbolRadius:0,
      align: 'left',
      margin: 0,
      reversed: true,
      itemStyle: {
        fontSize: props.fontSize,
        fontFamily: 'Rubik, sans-serif',
        fontWeight: '500',
     },
    },
    tooltip: {
      enabled: false
    },
    xAxis: {
      labels: {
        enabled: false,
      },
      lineWidth: 0,
      tickWidth: 0,
      gridLineWidth: 0,
    },
    yAxis: {
      max: sum,
      labels: {
        enabled: false,
      },
      title: {
        text: null
      },
      lineWidth: 0,
      tickWidth: 0,
      gridLineWidth: 0,
    },
    credits: false,
    series: data
  }
}

export const creditAllocationGraphData = (stats) => {
  return {
    chart: {
      height: '210px',
      spacing: [0, 0, 0, 0],
      },
      legend: {
        itemStyle: {
          fontSize: '12px'
        },
        layout: stats?.allocated_space_credits?.length < 5 ? 'vertical' : 'horizontal',
        align: 'left',
        verticalAlign: 'middle',
        borderWidth: 0,
        width: 280,
        itemWidth: 140,
        itemMarginBottom: 13
      },
      title: {
        text: ''
      },
      credits: false,
      colors: stats?.allocated_space_credits?.map(item => item?.space?.color),
      tooltip: {
        padding: 0,
        borderRadius: 4,
        followPointer: false,
        outside: true,
        useHTML: true,
        formatter: function(tooltip) {
          let goal = stats?.allocated_space_credits.find(item => item?.space?.name == this?.point?.name);
          return `<div class="chart-tooltip">
            <div class="head">${this.point.name}</div>
            <div class="body">
              <div class="content-row">
                <div class="title">Credit allocated: </div>
                <div class="count">${this.point.y}</div>
              </div>
              ${ goal?.space?.space_goals?.length ? `<div class="content-row">
                  <div class="title">Goal: </div>
                  <div class="tag-wrapper">
                    ${goal?.space?.space_goals?.map((item, idx) => `<span class="custom-tag">${item?.['name']}</span>`)}                        
                  </div>
              </div>` : '' }
            </div>
          </div>
          `        
        }
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
          borderWidth: 2,
          fillColor: '#ECEDF0',
          borderRadius: 1,
          states: {
            hover: {
              enabled: false,
            }
          }
        }
      },
      series: [
        {
          type: "pie",
          innerSize: "62%",
          data: stats?.allocated_space_credits?.map(item => ({ name: item?.space?.name, y: item?.allocated_credits})),
          states: {
            inactive: {
              enabled: false
            }
          },
        }
      ],
      accessibility: {
        enabled: false
      }
    }
  };

export const creditConsumptionGraph = (data) => {
  return {
    chart: {
        type: 'column',
        height: '210px',
        spacing: [10, 0, 5, 0],
    },
    xAxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    },
    yAxis: {
      title: {
        text: null
      },
      // tickInterval: 250
    },
    title: {
      text: ''
    },
    legend: {
      enabled: false
    },
    tooltip: {
      useHTML: true,
      outside: true,
      formatter: function(tooltip) {
        console.log(this.point)
        return `<strong>${this?.point?.category}: ${this?.point?.y}</strong>`;
      }
    },
    credits: false,
    plotOptions: {
      column: {
        borderRadius: {
          radius: 10
        },
        color: '#7C4DFF',
        grouping: false,
        borderRadiusTopLeft: 10,
        borderRadiusTopRight: 10
      }
    },
    series: [{
      data: [
        data?.January ?? 0,
        data?.February ?? 0,
        data?.March ?? 0,
        data?.April ?? 0,
        data?.May ?? 0,
        data?.June ?? 0,
        data?.July ?? 0,
        data?.August ?? 0,
        data?.September ?? 0,
        data?.October ?? 0,
        data?.November ?? 0,
        data?.December ?? 0,
      ]
    }],
    accessibility: {
      enabled: false
    }
  }
}

export const buildcardConsumptionGraphData = (stats) => {
  return {
    chart: {
      height: '280px',
      spacing: [0, -200, 0, 0],
      events: {
        render: function() {
          setTimeout(() => {
            const chart = this;
            const textX = chart.plotLeft + (chart.series?.[0]?.center?.[0]);
            const textY = chart.plotTop + (chart.series?.[0]?.center?.[1]);
            if (stats?.total_consumed_credits) {
              chart.centerText = chart.renderer?.text(`<p>${stats?.total_consumed_credits}<p>`, textX - 7, textY)
              .css({
                fontSize: 14,
                fontWeight: 'bold',
              })
              .add()
              chart.centerText = chart.renderer?.text(`<p>Credits<p>`, textX, textY + 17)
              .css({
                fontSize: 14,
              })
              .add()
    
              chart.centerText?.attr({
                x: textX - chart.centerText?.getBBox()?.width / 2,
              })
            }
          }, 3000)
        }
      }
    },
    legend: {
      itemStyle: {
        fontSize: '12px'
      },
      layout: stats?.build_cards?.length < 8 ? 'vertical' : 'horizontal',
      align: 'left',
      verticalAlign: 'middle',
      borderWidth: 0,
      width: 340,
      itemWidth: 170,
      itemMarginBottom: 17
    },
    title: {
      text: ''
    },
    credits: false,
    colors: ['#E1C3FF', '#FEFFB6', '#EFFBEC', '#C8F8FF', '#BDDEFF', '#E5DEFD', '#D9C4FF', '#FFCEFC', '#FFC9C9', '#FFC7B8'],
    tooltip: {
      padding: 0,
      borderRadius: 4,
      followPointer: false,
      outside: true,
      useHTML: true,
      formatter: function(tooltip) {
        let goal = stats?.build_cards.find(item => item?.name == this?.point?.name);
        return `<div class="chart-tooltip">
            <div class="head">${this.point.name}</div>
            <div class="body">
              <div class="content-row">
                <div class="title">Total Credit consumed: </div>
                <div class="count">${this.point.y}</div>
              </div>
              <div class="content-row">
                <div class="title">Status: </div>
                <div class="tag-wrapper">
                  <span class="custom-tag ${getBcState(goal?.state, null)}" style="color: white;">${getCardLable(goal?.state, null)}</span>
                </div>
              </div>
              ${ goal?.space_goals?.length ? `<div class="content-row">
                  <div class="title">Goal: </div>
                  <div class="tag-wrapper">
                    ${goal?.space_goals?.map((item, idx) => `<span class="custom-tag">${item?.['name']}</span>`)}                        
                  </div>
              </div>` : '' }
            </div>
          </div>
        `        
      }
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
        borderWidth: 2,
        fillColor: '#ECEDF0',
        borderRadius: 1,
        states: {
          hover: {
            enabled: false,
          }
        },
        shadow: true
      }
    },
    series: [
      {
        type: "pie",
        innerSize: "70%",
        data: stats?.build_cards?.map(item => ({ name: item?.name, y: item?.consumed_credits})),
        states: {
          inactive: {
            enabled: false
          }
        },
      }
    ],
    accessibility: {
      enabled: false
    }
  }
};
