import CircularProgress from '@mui/material/CircularProgress';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import images from '../../assets/images';
import { getCurrentSpaceDetail } from '../../shared/services/common.service';
import { companyState, userState } from '../../shared/store/slices/userSlice';
import { getFullname, getInitials } from '../../shared/util/util';

function SpaceListTable({ editSpaceModal, archiveSpaceModal, space, unarchiveSpace }) {
    const companySel = useSelector(companyState);
    const userSel = useSelector(userState);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [selectedSpace, setSelectedSpace] = useState(null);
    const [ iconBg ] = useState(['#B388FF', '#FFD740', '#69F0AE', '#FF5546', '#6D4EA4', '#88B8FF']);

    const handleClick = (event, row) => {
        setAnchorEl(event.currentTarget);
        setSelectedSpace(row);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const fetchDetailsAndRoute = (id) => {
        let navigateTo = `/company/spaces/${id}/overview`;
        getCurrentSpaceDetail(dispatch, companySel.id, id, navigate, navigateTo);
    }

    //===========================================================

    return (<TableRow key={space?.id} className={space?.archive ? 'disabled' : ''}>
            <TableCell className='text-nowrap'>
                <div className='user-link'>
                <span className='multiple-user-icon' style={{ backgroundColor: `${space?.color}` }}>
                    <em className='icon be-multiple-users'></em>
                </span>
                {space?.archive && <span className='archive-icon'><em className='icon be-archive'></em></span>}
                <span aria-label="user-name-navigate" className="user-name prime-color" onClick={
                    !space?.private || (space?.private && space?.owner?.email === userSel?.email) ? () => fetchDetailsAndRoute(space?.id) : null}>{space?.name}</span>
                { space?.private && <span className='lock-icon'>                            
                    <img src={images.lock} alt="lock icon" />
                </span> }
                </div>
            </TableCell>
            <TableCell className='text-nowrap'>
                { getFullname(space?.owner?.first_name, space?.owner?.last_name) ?? space?.owner?.email ?? '-'}
            </TableCell>
            <TableCell className='text-nowrap'>
                { space?.members?.slice(0, 3).map((member, idx) => (
                <Fragment key={`member_${member?.id}`}>
                    <span className="initials" style={{ backgroundColor: `${iconBg[idx]}` }}>{getInitials(getFullname(member?.first_name, member?.last_name)) ?? '-'}</span>
                </Fragment>
                ))}
                { space?.members?.length - 3 > 0 ? 
                    <span className="initials" style={{ backgroundColor: `#DFE0E6` }}>+{space?.members?.length - (space?.owner?.id ? 2 : 3)}</span> :
                    (space?.members?.length == 2 && space?.owner?.id) ? 
                   <span className="initials" style={{ backgroundColor: `${iconBg[2]}` }}>{getInitials(getFullname(space?.owner?.first_name, space?.owner?.last_name)) ?? '-'}</span> :
                   (space?.owner?.id) ? <span className="initials" style={{ backgroundColor: `${iconBg[2]}` }}>{getInitials(getFullname(space?.owner?.first_name, space?.owner?.last_name)) ?? '-'}</span> :
                   !space?.members?.length && '-'
                }
            </TableCell>
            <TableCell className='text-nowrap text-center'>
              <span>{ space?.project_count }</span>
            </TableCell>
            <TableCell className='text-nowrap text-center'>
              <span>{space?.build_cards_count}</span>
            </TableCell>
            <TableCell className='text-nowrap text-center'>
                <div className='progress-circle'>
                <Stack spacing={2} direction="row" ></Stack>                          
                <Tooltip placement="bottom-end" enterTouchDelay={0} title={
                    <div className='credits'>
                    <div className='date-row'>
                        <span>
                        <em className='icon icon-blue'></em> Consumed credits
                        </span>
                        <span className='price ms-3'>{space?.consumed_credits}</span>
                    </div>
                    <div className='date-row'>
                        <span>
                        <em className='icon icon-grey'></em> Allocated credits
                        </span>
                        <span className='price ms-3'>{space?.allocated_credits}</span>
                    </div>
                    </div>
                }>
                    {/* <CircularProgress size={24} variant="determinate" value={75} /> */}
                    <div className="progress-with-bg circle-open-down" style={{ opacity : space?.archive ? '0.4' : '1' }}>
                      <CircularProgress size={24} variant="determinate" thickness={4} className='bg-progress' value={100} />
                      <CircularProgress size={24} variant="determinate" thickness={4} value={space?.credit_consumed_percentage} />
                    </div>
                </Tooltip>
                </div>
            </TableCell>
            { space?.can_update ? <TableCell className='text-nowrap text-center'>
             {space?.archive ? 
             <Tooltip placement="bottom-end" title="Unarchive" arrow classes={{ popper: 'info-tooltip archive' }}>
                <em className='icon be-archive icon-archive' onClick={() => unarchiveSpace(space)}></em>
             </Tooltip>
             : 
             <div className='three-dots'>
                <em className='icon be-vertical-dots' aria-label="open-menu" onClick={(e) => handleClick(e, space)}></em>                          
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    }}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                    }}
                    transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                    }}
                >
                    <MenuItem className='toggle-menu-item-row' onClick={() => {editSpaceModal(selectedSpace); handleClose()}}><img src={images.editIcon} alt="" /> Edit</MenuItem>
                    {!space?.archive && <MenuItem className='toggle-menu-item-row' aria-label="archive-btn" onClick={() => {archiveSpaceModal(selectedSpace); handleClose()}}><em className='icon be-archive'></em> Archive</MenuItem>}
                </Menu>
                </div>}
                {/* if disabled row */}
                <span className='archive-icon' style={{display: 'none'}}><em className='icon be-archive-disabled'></em></span>
            </TableCell> : <TableCell className='text-nowrap text-center'></TableCell>}
        </TableRow>)
}

export default SpaceListTable;
