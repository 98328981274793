import { InputLabel } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { TransitionProps } from '@mui/material/transitions';
import React, { forwardRef } from 'react';

import images from '../../../assets/images';

// add new goal dialog transition
const dialogTransition = forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="down" ref={ref} {...props} />;
});

function AddGoalDialog({AddNewGoal, AddNewGoalDialogClose}) {
    let dropDownoptions = [
        { value: 'Increase sales to 30%' },
        // { value: 'Organizational unit capacity to 10%' },
        // { value: 'Functional unit Setup' },
        // { value: 'Sustainability' },
    ]

    const handleModalClose = (e, reason) => {
        if (reason && reason == "backdropClick") return;
        AddNewGoalDialogClose(e);
    }

    //=====================================================
    return (
        <Dialog
            open={AddNewGoal}
            onClose={handleModalClose} 
            className='add-new-goal-dialog'
            TransitionComponent={dialogTransition}
        >
            <DialogContent>
                {/* <span className="modal-close be-close" onClick={AddNewGoalDialogClose}></span> */}
                <div className="modal-body add-new-goal-modal">
                    <div className="modal-title has-right-data">
                        <h2>Add new space goal</h2>
                    </div>

                    <div className="modal-form">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">                         
                                    <FormLabel className='label'>Goal Name</FormLabel>             
                                    <FormControl>
                                        <InputLabel>Type your Goal here...</InputLabel>
                                        {/* @ts-ignore */}
                                        <FilledInput inputProps={{'data-testid': 'project-name'}} type="text" variant="filled" name="project-name"   />
                                    </FormControl>
                                    <span className="form-error"></span>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <FormLabel className='label'>Align with a business goal</FormLabel>
                                    <FormControl>
                                        <Autocomplete
                                            id="searchMembersDropdown"
                                            options={dropDownoptions}
                                            autoHighlight
                                            //getOptionLabel={}                        
                                            renderOption={(props, option) => (
                                            <Box component="span" {...props}>
                                                <div className='select-goal-dropdown'>
                                                    <div className='left-side'>                              
                                                        <Stack direction="row" spacing={1}>
                                                        <Chip label='Increase sales to 30%' />                                
                                                        </Stack>                             
                                                    </div>
                                                    <div className='right-side'>
                                                        <Checkbox defaultChecked
                                                        icon={<img src={images.checkboxUnchecked} alt='' />}
                                                        checkedIcon={<img src={images.checkboxChecked} alt='' />}
                                                        />
                                                    </div>
                                                </div> 
                                                <div className='select-goal-dropdown'>
                                                    <div className='left-side'>                              
                                                        <Stack direction="row" spacing={1}>
                                                        <Chip label='Organizational unit capacity to 10%' />                                
                                                        </Stack>                             
                                                    </div>
                                                    <div className='right-side'>
                                                        <Checkbox 
                                                        icon={<img src={images.checkboxUnchecked} alt='' />}
                                                        checkedIcon={<img src={images.checkboxChecked} alt='' />}
                                                        />
                                                    </div>
                                                </div>                           
                                                <div className='select-goal-dropdown'>
                                                    <div className='left-side'>                              
                                                        <Stack direction="row" spacing={1}>
                                                        <Chip label='Functional unit Setup' />                                
                                                        </Stack>                             
                                                    </div>
                                                    <div className='right-side'>
                                                        <Checkbox defaultChecked
                                                        icon={<img src={images.checkboxUnchecked} alt='' />}
                                                        checkedIcon={<img src={images.checkboxChecked} alt='' />}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='select-goal-dropdown'>
                                                    <div className='left-side'>                              
                                                        <Stack direction="row" spacing={1}>
                                                        <Chip label='Sustainability' />                                
                                                        </Stack>                             
                                                    </div>
                                                    <div className='right-side'>
                                                        <Checkbox 
                                                        icon={<img src={images.checkboxUnchecked} alt='' />}
                                                        checkedIcon={<img src={images.checkboxChecked} alt='' />}
                                                        />
                                                    </div>
                                                </div>
                                            </Box>
                                            
                                            )}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Select" variant="outlined" />
                                            )}
                                        />
                                    </FormControl>
                                    <span className="form-error"></span>
                                </div>
                                <div className="form-group tag-wrapper">                  
                                    <Stack direction="row" spacing={1}>
                                    <Chip label='Increase sales to 30%' />
                                    <Chip label='Organizational unit capacity to 10%' />
                                    <Chip label='Functional unit Setup' />                                
                                    </Stack>                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions className="fixed-footer-wrap">
                <div className="fixed-full-footer">
                    <Button variant="contained" className='grey-btn sm-btn' onClick={AddNewGoalDialogClose}>Cancel</Button>
                    <Button variant="contained" className='grey-btn sm-btn' disabled>Create goal</Button>
                </div>
            </DialogActions>
        </Dialog>
    )
}

export default AddGoalDialog;
