import { useSelector } from 'react-redux'
import { permissionState, userPermissionState } from './store/slices/userSlice'
import { isAllowedTo } from './util/util';
import UnauthorizedAccess from '../components/UnauthorizedAccess';

function CanActivateChild({access, mod, children, entity}) {
    const permissions = useSelector(permissionState);
    const userPermissions = useSelector(userPermissionState);
    const isHavingAccess = isAllowedTo(access, mod, permissions, entity, userPermissions);
    
    if (isHavingAccess) {
        return (<>{children}</>)
    }

    return <UnauthorizedAccess />
}

export default CanActivateChild;
