import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import moment from 'moment';
import { useEffect, useState } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import images from '../assets/images';
import SpaceTopNavigation from '../pages/building/SupplyChainDistributionNavigation';
import ProjectTopNavigation from '../pages/building/projects/ProjectNavigation';
import Summary from '../pages/building/summary';
import instance from '../shared/interceptor';
import { companyState } from '../shared/store/slices/userSlice';
import { PROJECTS, SPACES } from '../shared/util/constant';
import { debounce, getFullname, getInitials, globalError, navigateToSpaceOrProject } from '../shared/util/util';

function Activity ({ tab }) {
  const params = useParams();
  const dispatch = useDispatch();
  const compSel = useSelector(companyState);
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [recentActivities, setRecentActivities] = useState([]);
  const [earlierActivities, setEarlierActivities] = useState([]);
  const [random, setRandom] = useState(1);
  const [shouldVisible, setShouldVisible] = useState(false);
  const [pageMetadata, setPageMetadata] = useState({
    totalCount: 0,
    totalPage: 0,
    nextPage: 1,
    perPage: 0
  });

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    getActivities(signal);

    return () => {
      controller.abort();
      setShouldVisible(false);
    }
  }, [tab, searchTerm])

  const getActivities = async (signal?) => {
    try {
      if (pageMetadata.nextPage) {
        setLoading(true);
        let url = '';

        if (tab == 'Space') {
          url = `${SPACES.replace(':id', compSel?.id)}/${params?.id}/activities?page=${pageMetadata.nextPage}`;
        } else if (tab == 'Project') {
          url = `${PROJECTS.replace(':id', compSel?.id).replace(':space_id', params?.id)}/${params?.project_id}/activities?page=${pageMetadata.nextPage}`;
        }
        if (searchTerm) {
          url += `&q=${searchTerm}`
        }
        const resp = await instance.get(url, { signal });
        if (resp) {
          const recent = resp?.['activities'].filter(item => moment(item?.created_at).isAfter(moment().subtract(7,'d')));
          const earlier = resp?.['activities'].filter(item => moment(item?.created_at).isBefore(moment().subtract(7,'d')));
          setRecentActivities(prevItems => [...prevItems, ...recent]);
          setEarlierActivities(prevItems => [...prevItems, ...earlier]);
          setPageMetadata({
            totalCount: resp?.['total_count'],
            totalPage: resp?.['total_pages'],
            nextPage: resp?.['next_page'],
            perPage: resp?.['per_page']
          });
        }
      }
    } catch(e) {
      globalError(dispatch, e);
    } finally {
      setLoading(false);
      setShouldVisible(true);
    }
  }

  const searchActivity = debounce((e) => {
    setPageMetadata({
      totalCount: 0,
      totalPage: 0,
      nextPage: 1,
      perPage: 0
    });
    setSearchTerm(e?.target?.value);
    setRecentActivities([]);
    setEarlierActivities([]);
  });

  const clearInput = () => {
    setPageMetadata({
      totalCount: 0,
      totalPage: 0,
      nextPage: 1,
      perPage: 0
    });
    setSearchTerm('');
    setRecentActivities([]);
    setEarlierActivities([]);
    setRandom(prev => prev + 1)
  }

  //================================================
  return (
    <div className="project-overview tab-page-section spaces-team-page" aria-label='project-activity-page'>
      <div className="page-container pb-0 top-space">
        {tab === 'Project' ? <ProjectTopNavigation /> : <SpaceTopNavigation />}
        
        <section className='timeline-wrapper'>
          <div className='page-content'>
            <div className="page-filters-wrapper">
              <div className="left-filter">
                <div className="form-group search-container">
                  <FormControl variant="filled">
                    {/* @ts-ignore */}
                    <FilledInput  inputProps={{ "data-testid": "input-project" }} type="text" variant="filled" className="xs-input search-input-wrap" placeholder='Search by member...'
                      startAdornment={
                        <InputAdornment position="start"><em className='be-search'></em></InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment position="end" className='search-icon-wrap'>
                          { searchTerm && <img data-testid="cross-icon" src={images.iconCrossCircle} alt="" onClick={clearInput}/> }
                        </InputAdornment>                        
                      }
                      key={random}
                      onChange={searchActivity}
                    />
                  </FormControl>
                </div>
              </div>
            </div>

            <div className='activities-wrapper'>
              {!loading && !recentActivities?.length && !earlierActivities?.length && <div className="no-data-wrapper secondary fixed-height">
                <em className="thumb"><img src={images.emptyStateIllustration} alt="Empty" /></em>
                <h4 aria-label="no-space">No activities yet</h4>
                <p>Currently you don't have any activities.</p>            
              </div>}
              {shouldVisible &&  (recentActivities?.length > 0 || earlierActivities?.length > 0 ) &&
              <div className='activity-list-box'>
                <InfiniteScroll
                  dataLength={pageMetadata.nextPage * pageMetadata.perPage}
                  next={() => getActivities()}
                  hasMore={!!pageMetadata.nextPage}
                  loader={loading && <div><img src={(images as any).buAnim} alt="B" style={{ marginLeft: '50%', height: '80px', width:'80px', transform: 'translate(-50%, -50%)' }}/></div>}
                >
                  { recentActivities?.length > 0 && <div className="activity-group">
                      <h6>Recent</h6>
                      <div className='recent-activity no-scroll'>
                        { recentActivities?.map(activity => {
                          return <div className='user-link' key={activity?.id}>
                            <span className='user-avatar'>
                              { activity?.user?.profile_picture?.profile_picture_url ?
                              <img src={activity?.user?.profile_picture?.profile_picture_url} alt="user profile" /> :
                                <span className='initials'>{getInitials(getFullname(activity?.user?.first_name, activity?.user?.last_name))}</span>
                              }
                            </span>
                            <div className='user-detail' aria-label="user-detail">
                              <div className='basic-info'>
                                <div onClick={(e) => navigateToSpaceOrProject(e, navigate)} className="htmlContent" dangerouslySetInnerHTML={{__html: activity?.value}}></div>
                                {/* <span >Annie Cho </span>
                                <span className='description-text'>kicked off the Buildcard <a href={void (0)}>Warehouse Scanner 2</a></span> */}
                              </div>
                              <span className='time'>{activity?.created_date}</span>
                            </div>
                            {/* TODO: to be enable later
                            <div className="menu-trigger">
                              <em className="trigger-icon be-vertical-dots"></em>
                            </div> */}
                          </div>
                        })}
                      </div>
                    </div>}

                  { earlierActivities?.length > 0 && <div className="activity-group">
                      <h6>Earlier</h6>
                      <div className='recent-activity no-scroll'>
                      
                        { earlierActivities?.map(activity => {
                          return <div className='user-link' key={activity?.id}>
                            <span className='user-avatar'>
                              { activity?.user?.profile_picture?.profile_picture_url ?
                              <img src={activity?.user?.profile_picture?.profile_picture_url} alt="user profile" /> :
                                <span className='initials'>{getInitials(getFullname(activity?.user?.first_name, activity?.user?.last_name))}</span>
                              }
                            </span>
                            <div className='user-detail'>
                              <div className='basic-info'>
                                <div onClick={(e) => navigateToSpaceOrProject(e, navigate)} className="htmlContent" dangerouslySetInnerHTML={{__html: activity?.value}}></div>
                                {/* <span className="user-name">Annie Cho </span>
                                <span className='description-text'>kicked off the Buildcard <a href={void (0)}>Warehouse Scanner 2</a></span> */}
                              </div>
                              <span className='time'>{activity?.created_date}</span>
                            </div>
                            {/* TODO: to be enable later
                            <div className="menu-trigger">
                              <em className="trigger-icon be-vertical-dots"></em>
                            </div> */}
                          </div>
                        })}
                      </div>
                    </div>}
                </InfiniteScroll>
              </div>
              }
            </div>

          </div>

          <Summary tab={tab}></Summary>

        </section>
      </div>
    </div>
  )
}

export default Activity;
