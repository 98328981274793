import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import instance from '../../../shared/interceptor';
import { currentSpaceState } from '../../../shared/store/slices/companySlice';
import { setSuccessMsg } from '../../../shared/store/slices/successSlice';
import { companyState } from '../../../shared/store/slices/userSlice';
import { COMPANY } from '../../../shared/util/constant';
import { globalError, setLoader } from '../../../shared/util/util';


function RequestCreditDialog({ openRequestCreditDialogue, closeRequestCreditDialogue, handleClickOpen }) {
  const dispatch = useDispatch();
  const compSel = useSelector(companyState);
  const currentSpaceSel = useSelector(currentSpaceState);

  const [formVal, setFormVal] = useState({
    credit_requested: '',
    request_note: ''
  });

  const createSpaceCreditRequest = async () => {
    try {
      let note = formVal?.request_note?.trim();
      if (!note) {
        return globalError(dispatch, { message: 'Note cannot be blank.'});
      }
      if (note?.length > 100) {
        return globalError(dispatch, { message: 'Note length should not be greater than 100 character.'});
      }
      setLoader(dispatch, true);
      const url = `${COMPANY}/${compSel?.id}/plan_credit_subscriptions/${compSel?.active_plan_credit_subscription?.id}/space_credits/${currentSpaceSel?.active_space_credit_id}/space_credit_requests`;
      let payload = {
          "space_credit_request": formVal
      }
      await instance.post(url, payload);
      closeRequestCreditDialogue(true);
      dispatch(setSuccessMsg('Request submitted successfully.'));
    } catch (err) {
      globalError(dispatch, err);
    } finally {
      setLoader(dispatch, false);
    }
  }

  const handleModalClose = (e, reason) => {
    if (reason && reason == "backdropClick") return;
    closeRequestCreditDialogue(null);
  }

  //========================================================
  return (
    <Dialog
      open={Boolean(openRequestCreditDialogue)}
      onClose={handleModalClose}
      className="request-credit-modal"
      data-testid='request-credit-modal'
    >
      <DialogContent
      aria-label='req-credit-modal'
      className="modal-body-wrap">
        <span className="modal-close be-close" onClick={() => closeRequestCreditDialogue(null)}></span>
        <div className="modal-body">
          <div className="modal-title">
            <h2>Request Credits</h2>
            <p></p>
          </div>
          <div className="modal-form">
            {openRequestCreditDialogue?.pending_space_credit_request?.credit_requested > 0 && <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <div className="credit-note" data-testid='pending-req-note'>
                    <p>You already have a credit request of <strong>{openRequestCreditDialogue?.pending_space_credit_request?.credit_requested}</strong> pending from <strong>{moment(openRequestCreditDialogue?.pending_space_credit_request?.created_at).format('DD MMM, YYYY')}</strong>.</p>
                    <p>Do you want to request more credit? If you request more credits, it will added as a top up on your previous pending request and the latest credit request note will be sent to the approver.</p>
                  </div>
                </div>
              </div>
            </div>}
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <FormLabel>Credits</FormLabel>
                  <FormControl>
                    <InputLabel>Enter Credits...</InputLabel>
                    <FilledInput onKeyDown={(evt) => ["e", "E"].includes(evt.key) && evt.preventDefault()} value={formVal?.credit_requested} type="number" placeholder="" onChange={(e) => setFormVal({...formVal, credit_requested: e?.target?.value})}/>
                  </FormControl>
                  {formVal?.credit_requested > openRequestCreditDialogue?.credit_balance && <span className="form-error">Cannot allocate more than the credit balance</span>}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <FormLabel>Request note</FormLabel>
                  <FormControl>
                    <textarea value={formVal?.request_note} onChange={(e) => setFormVal({...formVal, request_note: e?.target?.value})} className='custom-textarea2' rows={4} placeholder='Give a quick note why are you requesting this credit. This will help the approver in credit allocation.'></textarea>
                  </FormControl>
                  <span className="form-error"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="fixed-footer-wrap">
        <div className="fixed-full-footer">
          <Button variant="contained" className='grey-btn sm-btn' onClick={() => { closeRequestCreditDialogue(); handleClickOpen() }}>Credit request history</Button>
          <Button data-testid="req-credit-btn" variant="contained" className='sm-btn' disabled={ !formVal?.credit_requested || !formVal?.request_note || formVal?.credit_requested > openRequestCreditDialogue?.credit_balance} onClick={createSpaceCreditRequest}>Request credits</Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default RequestCreditDialog;
