import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useParams } from 'react-router-dom';
import instance from '../interceptor';
import { setSuccessMsg } from '../store/slices/successSlice';
import { companyState } from '../store/slices/userSlice';
import { COMPANY, SPACES, goalStatusList } from '../util/constant';
import { getColorCodeAndClassForGoals, getFullname, globalError, setLoader } from '../util/util';


const GoalDetailCard = ({ goalDetail, getGoalDetail, update = null }) => {
  const dispatch = useDispatch();
  const compSel = useSelector(companyState);
  const params = useParams();

  const changeStatus = async (e) => {
    try {
      const val = e?.target?.value;
      setLoader(dispatch, true);
      let url;
      let payload;
      if (update == 'kpi') {
        url = `${COMPANY}/${compSel?.id}/company_goals/${params?.goal_id}/kpis/${goalDetail?.id}`;
        payload = {kpi: {
          name: goalDetail?.name,
          description: goalDetail?.description,
          due_date: moment(goalDetail?.due_date).format('YYYY-MM-DD'), 
          space_ids: goalDetail?.spaces?.map(space => space?.id),
          status: val
        }}
      } else if (update == 'space_goal') {
        url = `${SPACES.replace(':id', compSel?.id)}/${params?.id}/space_goals/${goalDetail?.id}`;
        payload = {
          name: goalDetail?.name,
          description: goalDetail?.description,
          status: val,
          due_date: goalDetail?.due_date,
          owner_id: goalDetail?.owner_id,
          company_goal_ids: goalDetail?.company_goal_ids,
          archive: goalDetail?.archive
        }
      } else {
        url = `${COMPANY}/${compSel?.id}/company_goals/${goalDetail?.id}`;
        payload = {
          company_goal: {
            name: goalDetail?.name,
            description: goalDetail?.description,
            private: goalDetail?.private,
            archive: goalDetail?.archive,
            status: val,
            due_date: goalDetail?.due_date
          }
        }
      }
      await instance.patch(url, payload);
      getGoalDetail();
      dispatch(setSuccessMsg('Status updated successfully.'));
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  }    

  //========================================================
  return (
    <div className="status-content-wrap" aria-label='goal-detail-card'>
      <div className="col-wrap">
        <div className="col">                      
          <label>Status</label>
          <div className="content-wrap">
            {/* { onTrack: '#69F0AE' },{ progressing: '#FFD740' },{ offTrack: '#FF8A80' },{ accomplished: '#00C853' } */}
            <em className="dot" style={{backgroundColor: getColorCodeAndClassForGoals(goalDetail?.status, goalDetail?.archive)?.color}}></em>            
            <Select
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "right"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "right"
                },                                
                classes: { list: 'p-0' }
              }} 
              inputProps={{'data-testid':"card-select"}}
              className="select-status borderless-dropdown"
              value={goalDetail?.status || ''}
              defaultValue='on_track'
              label="Status"
              onChange={changeStatus}
            >
              {goalStatusList.map(status => <MenuItem className="status-menu-item" key={status?.id} value={status?.value}><em className="dot-sm" style={{backgroundColor: status?.color, border: status?.border}}></em> {status?.text}</MenuItem>)}
            </Select>                
          </div>          
        </div>
        <div className="col">
          <label>Target date</label>
          <div className="content-wrap">
            <div className="context-label">{moment(goalDetail?.due_date).format('DD MMM YY')}</div>            
          </div>
        </div>
        <div className="col">
          <label>Kicked off</label>
          <div className="content-wrap">
            <div className="context-label">{moment(goalDetail?.created_at).format('DD MMM YY')}</div>
          </div>
        </div>
        <div className="col no-border">
          <label>Credits spent</label>                      
          <div className="content-wrap">
            <div className="context-label">234</div>
          </div>
        </div>
      </div>
      <div className="col-wrap">
        <div className="col">
          <label>Owner</label>                      
          <div className="owner-name">
            {getFullname(goalDetail?.owner?.first_name, goalDetail?.owner?.last_name)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default GoalDetailCard;
