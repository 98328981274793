/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint eqeqeq: 0 */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import images from '../../assets/images';
import { getColorCodeAndClassForGoals } from '../util/util';

const GoalsCard = ({goal, editClickHandler, archiveClickHandler}) => {
  const navigate = useNavigate();

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
  }));

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  //========================================================

  return (
    <div aria-label="goal-card" className={`incread-by-box ${getColorCodeAndClassForGoals(goal?.status, goal?.archive)?.class}`}> 
      <div className="title-head">
        <h4><span data-testid="navigation-btn" className="cr-pointer" onClick={() => navigate(`/company/business-goals/${goal?.id}`)}>{goal?.name}</span> {goal?.private && <span className="private">Private</span>} {goal?.archive && <em className="icon be-archive"></em>}</h4>
        <Button className="three-dot-btn xs-btn" onClick={(e) => setAnchorEl(e.currentTarget)}><em className='icon be-vertical-dots'></em></Button>
        <Menu
          id="basic-goal-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{
          'aria-labelledby': 'basic-button',
          }}
          anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
          }}
          transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
          }}
          className={'three-dot-menu-paper'}
        >
          <MenuItem data-testid="edit-dialog" className='toggle-menu-item-row' onClick={(e) => {setAnchorEl(null); editClickHandler(goal)}} ><img src={images.editIcon} alt=""/> Edit</MenuItem>
          <MenuItem data-testid="archive-dialog" className='toggle-menu-item-row' aria-label="archive-btn" onClick={(e) => {setAnchorEl(null); archiveClickHandler(goal)}}><em className='icon be-archive'></em> {goal?.archive ? 'Unarchive' : 'Archive'}</MenuItem>
        </Menu>
      </div>
      <div className="progress-wrap">
        <div className="text-content">{goal?.description}</div>
        <div className="progress-bar">                    
          <Box sx={{ flexGrow: 1 }}>
            <BorderLinearProgress variant="determinate" value={goal?.kpis_count ? (goal?.kpis_accomplished / goal?.kpis_count) * 100 : 0} />
          </Box>
          
          <div className="progress-text">
            <strong>{goal?.kpis_accomplished}/{goal?.kpis_count}</strong><span> KPIs accomplished</span>
          </div>
        </div>
      </div>
      <div className="box-footer">
        <span className="date-box">Target {moment(goal?.due_date).format('DD.MM.YY')}</span>
        <span className="status">
          {/* { onTrack: '#69F0AE' },{ progressing: '#FFD740' },{ offTrack: '#FF8A80' },{ accomplished: '#00C853' } */}
          {goal?.status?.replace('_', ' ')} <em className="color-dot" style={{backgroundColor: getColorCodeAndClassForGoals(goal?.status, goal?.archive)?.color}}></em>
        </span>
      </div>
    </div>
  )
}

export default GoalsCard;
