/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint eqeqeq: 0 */
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import LinearProgress from '@mui/material/LinearProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useEffect, useState } from 'react';
// import CSVReader from "react-csv-reader";
import Papa from 'papaparse';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import images from '../../assets/images';
import ManuallyMemberInviteDialog from '../../components/dialog/ManuallyMemberInviteDialog';
import RevokeInviteDialog from '../../components/dialog/RevokeInviteDialog';
import instance from '../../shared/interceptor';
import { setSuccessMsg } from '../../shared/store/slices/successSlice';
import { companyState, permissionState } from '../../shared/store/slices/userSlice';
import { BULK_UPLOAD, ENT_USERS, UPDATE_ROLE } from '../../shared/util/constant';
import { debounce, getRandom, globalError, isAllowedTo, setLoader, validateEmail } from '../../shared/util/util';
import InvitedMemberTable from './InvitedMemberTable';
import CsvTable from './UploadedMemberCsvTable';
import './members.scss';
import { loadingState } from '../../shared/store/slices/loadingSlice';

const idb = window.indexedDB;

export default function MembersDashboard() {
  const companySel = useSelector(companyState);
  const permissions = useSelector(permissionState);
  const loadingSel = useSelector(loadingState);
  const dispatch = useDispatch();

  const [error, setError] = useState('');
  const [RevokeInvite, setRevokeInvite] = useState(false);
  const [addMembers, setAddMembers] = useState(false);
  const [BulkUploadMembers, setBulkUploadMembers] = useState(false);
  const [userObj, setUserObj] = useState(null);
  const [memberSearchText, setMemberSearchText] = useState('');
  const [addedMemberObj, setAddedMemberObj] = useState({ email: '', role: 'Member' });
  const [addedMemberList, setAddedMemberList] = useState([]);
  const [uploadedCSV, setUploadedCSV] = useState([]);
  const [isDragOver, setIsDragOver] = useState(false);
  const [isCorrectFormat, setIsCorrectFormat] = useState(false);
  const [isSizeExceeded, setIsSizeExceeded] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedUser, setSelectedUser] = useState(null);
  const [encodeData, setEncodeData] = useState(null);
  const [random, setRandom] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => await getUserList())();
  }, [page, memberSearchText]);

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: header => header.toLowerCase(),
  };

  const getUserList = async () => {
    try {
      setLoading(true);
      if (!memberSearchText){
        setLoader(dispatch, true);
      }
      let url = `${ENT_USERS.replace(':id', companySel?.id)}?page=${page}`;
      if (memberSearchText) {
        url += `&q=${memberSearchText}`;
      }
      let userListRes = await instance.get(url);
      setUserObj(userListRes);
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoading(false);
      setLoader(dispatch, false);
    }
  }

  const openRevokeDialog = (user) => {
    setSelectedUser(user);
    setRevokeInvite(true);
  }

  const searchMember = debounce((e) => {
    setPage(1);
    setMemberSearchText(e.target.value);
  })

  const handleAddMemberChange = (e) => {
    setError('');
    setAddedMemberObj({ ...addedMemberObj, [e.target.name]: e.target.value });
  }

  const addMemberToList = () => {
    setError('');
    let isEmailPresent = addedMemberList.some(member => (
      member.email === addedMemberObj.email && member.role === addedMemberObj.role
    ));
    if (isEmailPresent) {
      setError('Email already present in the list.');
    } else {
      setAddedMemberList([...addedMemberList, addedMemberObj]);
    }
    setAddedMemberObj({ email: '', role: 'Member' });
  }

  const removeFromAddedMemberList = (idx) => {
    setError('');
    let tempArr = [...addedMemberList];
    tempArr.splice(idx, 1);
    setAddedMemberList([...tempArr]);
  }

  const AddMembersDialogClose = () => {
    setError('');
    setAddMembers(false);
    setAddedMemberObj({ email: '', role: 'Member' });
    setAddedMemberList([]);
  };

  const closeRevokeDialog = async (e: Event, success = false) => {
    setSelectedUser(null);
    setRevokeInvite(false);
    if(success) {
      await getUserList();
    }
  }

  const openBulkMemberDialog = () => {
    setBulkUploadMembers(true);
  }

  // For reference if required CSVReader in future
  // const handleForce = (data, fileInfo) => {
  //   console.log(fileInfo);
  //   if(fileInfo.type === 'text/csv' || fileInfo.type === 'application/vnd.ms-excel') {
  //     setUploadedCSV(data);
  //     return;
  //   }
  //   setError('Your file is in a wrong format. Convert the file to .csv or .xls and try again.');
  // };

  const handleForce = (files) => {
    if (idb) {
      idb.deleteDatabase('csvData');
    }
    setUploadedCSV([]);
    let filteredList = Array.from(files)
      .filter((file: any) => (file.type === 'text/csv' ||
        file.type === 'application/vnd.ms-excel' ||
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'))

    if (filteredList.length) {
      setIsCorrectFormat(false);
      filteredList.forEach(async (file: any) => {
        let sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
        console.log(sizeInMB, 'sizeInMB')
        if (+sizeInMB > 5) {
          setIsSizeExceeded(true);
          setIsDragOver(false);
        } else {
          parseCsv(file);
        }
      });
    } else {
      setIsCorrectFormat(true);
      setIsDragOver(false);
    }
  }

  const parseCsv = async (file) => {
    setIsSizeExceeded(false);
    const text = await file.text();
    const data = Papa.parse(text, papaparseOptions);
    if (data?.data?.length > 10000) {
      return setError('You can only upload upto 10k members');
    }
    let keys = Object.keys(data.data[0]);
    let emailKey = keys.find(key => key.match(/email/i));
    let roleKey = keys.find(key => key.match(/role/i));
    if (emailKey) {
      setError('');
      let allowed = ['member', 'it admin', 'finance admin'];
      let filteredDataWithRole = data.data.filter(member => member[emailKey] && validateEmail(member[emailKey])).map(
        ({ email, ...prop }: any) => ({ email, role: prop[roleKey] && allowed.includes(prop[roleKey].toLowerCase()) ? prop[roleKey] : 'Member', checked: true, id: getRandom() }));
      setUploadedCSV(filteredDataWithRole);
    } else {
      setError('Email is required in CSV');
    }
  }

  const addManually = (openManual = false) => {
    idb?.deleteDatabase('csvData');
    setError('');
    setUploadedCSV([]);
    setBulkUploadMembers(false);
    setIsDragOver(false);
    setIsCorrectFormat(false);
    setIsSizeExceeded(false);
    if (openManual) {
      setAddMembers(true);
    }
  }

  const sendInviteToManualAddedMember = async () => {
    let membersToAdd = addedMemberList.map(({ role, ...prop }) => ({ ...prop, role_name: role }));
    await postInvitedMemberList(membersToAdd, true);
    await getUserList();
    setAddedMemberList([]);
  }

  const sendInviteToCsvAddedMember = () => {
    if (uploadedCSV?.length) {
      const dbPromise = idb.open('csvData', 1);
      dbPromise.onsuccess = () => {
        const db = dbPromise.result;
        const tx = db.transaction('memberCsv', 'readonly');
        const csvData = tx.objectStore('memberCsv');
        const members = csvData.getAll();
        members.onsuccess = async (query) => {
          tx.oncomplete = () => {
            db.close();
          }
          const arr = query?.srcElement['result'][0]?.uploadedCSV;
          let dataToSend = arr.filter(item => item['checked']).map(({ checked, id, role, ...prop }) => ({ ...prop, role_name: role }));
          await postInvitedMemberList(dataToSend);
          await getUserList();
        }
        members.onerror = (err) => {
          console.log(err)
        }
      };
    }
  }

  const postInvitedMemberList = async (memberList, manual = false) => {
    try {
      setLoader(dispatch, true);
      let payload = {
        users: memberList
      }
      let url = BULK_UPLOAD.replace(':id', companySel?.id);
      let res = await instance.post(url, payload);
      if (res?.['users']?.['count']) {
        addManually();
        if (manual) {
          setAddMembers(false);
        }
        dispatch(setSuccessMsg(`${res?.['users']?.count} Member/s invited successfully.`));
      } else {
        globalError(dispatch, {message: 'Member already exists, No members added'});
      }
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  }

  const paginateUsers = (val) => {
    setPage(val);
  }

  const memberRoleChangeHandler = async (e, member) => {
    try {
      setLoader(dispatch, true);
      let url = `${UPDATE_ROLE.replace(':id', member.company.id).replace(':userId', member.id)}`;
      let payload = {
        user: {
          role_name: e.target.value
        }
      }
      let updatedUserRes = await instance.patch(url, payload);
      let updatedList = userObj?.['users']?.map(item => item?.id == updatedUserRes?.['user']?.id ? updatedUserRes?.['user'] : item);
      dispatch(setSuccessMsg('Role updated Successfully'));
      setUserObj(prev => ({...prev, users: updatedList}));
    } catch (err) {
      globalError(dispatch, err);
    } finally {
      setLoader(dispatch, false);
    }
  }


  const createBUlkMemberData = (
    email: string,
    role: string,
  ) => {
    return { email, role };
  }

  const bulkMemberDataRows = [
    createBUlkMemberData('christina.walsh@mail.com', 'Member'),
    createBUlkMemberData('christina.walsh@mail.com', 'IT Admin'),
  ];

  const downloadCsv = () => {
    let csvFile = convertToCSV(bulkMemberDataRows);
    const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
    const encodedUri = URL.createObjectURL(blob);
    setEncodeData(encodedUri);
  }

  const convertToCSV = (arr) => {
    const array = [Object.keys(arr[0])].concat(arr)

    return array.map(it => {
      return Object.values(it).toString()
    }).join('\n')
  }

  const handleModalClose = (e, reason) => {
    if (reason && reason == "backdropClick") return;
    addManually(e);
  }

  const clearInput = () => {
    setMemberSearchText('');
    setRandom(prev => prev + 1);
  }

  // ============================================

  return (
    <>
      <div className="members-page">
        <div className="page-container top-space">
          <div className="main-page-header">
            <div className="page-breacrumb">
              <div className="breadcrumb-wrapper">
                <ul className="breadcrumb-links">
                  <li><Link to="/company">Company</Link></li>
                  <li>Members</li>
                </ul>
              </div>
              <div className="compnay-logo">
              { companySel?.logo?.logo_url && <img src={companySel?.logo?.logo_url} alt="" /> }
              </div>
            </div>

            <div className="page-title">
              <h2 aria-label="member-heading">Members</h2>
            </div>
          </div>

          {!userObj?.users?.length && !memberSearchText && !loadingSel?.loading ?
            <div className="no-data-wrapper">
              <em className="thumb"><img src={images.emptyStateIllustration} alt="" /></em>
              <h4 aria-label="no-member">No members yet</h4>
              <p>Add new members to join your team and collaborate on projects.</p>
              { isAllowedTo('update', 'user', permissions) && <Button data-testid='addmember' variant="contained" className="sm-btn purple-btn" onClick={() => setAddMembers(true)}>Add members</Button>}
            </div>
            : <>
              <div className="page-filters-wrapper">
                <div className="left-filter">
                  <span aria-label="count" className="count">{userObj?.total_count} members</span>
                </div>
                <div className="right-filter">
                  <div className="form-group">
                    <FormControl variant="filled">
                      {/* @ts-ignore */}
                      <FilledInput inputProps={{ 'data-testid': "memberSearch" }} type="text" variant="filled" className="xs-input search-input-wrap" placeholder='Search members'
                        onChange={searchMember}
                        key={random}
                        startAdornment={
                          <InputAdornment position="start"><em className='be-search'></em></InputAdornment>
                        }
                        endAdornment={
                          <InputAdornment position="end" className='search-icon-wrap'>
                            {memberSearchText && <img src={images.iconCrossCircle} alt="" onClick={clearInput}/>}
                          </InputAdornment>                        
                        }
                      />
                    </FormControl>
                  </div>
                  { isAllowedTo('update', 'user', permissions) && <Button data-testid="add-member-to-list" variant="contained" className="xs-btn purple-btn" onClick={() => setAddMembers(true)}>Add members</Button>}
                </div>
              </div>

              {memberSearchText && !userObj?.users?.length && !loading &&
                <div className="no-search-results is-flexbox">
                  <em className="icon"><img src={images.emptyBox} alt="" /></em>
                  <h4>No member found</h4>
                </div>
              }

              {[].length > 0 && <div className="upload-progress">
                <LinearProgress variant="determinate" value={80} />
                <p>This could take a while, so feel free to continue using Builder Enterprise and we’ll let you know when it’s done.</p>
              </div>}

              {/* Invited member table */}
              {userObj?.users?.length > 0 && <InvitedMemberTable {...{ userObj, openRevokeDialog, paginateUsers, memberRoleChangeHandler, loading }} />}
            </>
          }


        </div>
      </div>


      {/* Revoke invite modal starts */}
      <RevokeInviteDialog {...{ RevokeInvite, closeRevokeDialog, selectedUser }} />
      {/* Revoke invite modal ends */}


      {/* Add members modal starts */}
      <ManuallyMemberInviteDialog {...{
        addMembers, AddMembersDialogClose,
        handleAddMemberChange, addedMemberObj,
        error, addMemberToList, addedMemberList,
        sendInviteToManualAddedMember, removeFromAddedMemberList, openBulkMemberDialog
      }} />
      {/* Add members modal ends */}


      {/* Upload bulk members modal starts */}
      <Dialog
        className='lg-dialog'
        open={BulkUploadMembers}
        onClose={handleModalClose}
      >
        <DialogContent className='hori-sm-padding'>
          {/* <span className="modal-close be-close" aria-label="add-manually-close" onClick={() => addManually()}></span> */}
          <div className="modal-body">
            <div className="modal-title has-right-data">
              <h2>Add members</h2>
              <div className="right-title">
                <div aria-label="add-manually" className="browse-files-link" onClick={() => addManually(true)}>Add members manually</div>
              </div>
            </div>

            <div className="bulk-member-upload-wrapper">
              <div className="member-upload-info">
                <p className="info">Upload a CSV or Excel file with the email addresses of the people you would like to invite, you
                  can also include other information such as first and last name, job title and role. We will help you map the
                  file to our data structure in the next step. </p>

                {!uploadedCSV.length ?
                  <>
                    <div className="table-wrapper table-responsive">
                      <TableContainer>
                        <Table className='auto-width has-padding'>
                          <TableHead>
                            <TableRow>
                              <TableCell>EMAIL (REQUIRED)</TableCell>
                              <TableCell>Role</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {bulkMemberDataRows.map((row, i) => (
                              <TableRow key={`table2_${i + getRandom()}`}>
                                <TableCell>{row.email}</TableCell>
                                <TableCell>{row.role}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>

                    <div className="member-links">
                      <a href={encodeData} download="example.csv" className="browse-files-link example-csv" onClick={downloadCsv}>
                        <em className="icon be-download"></em>Example CSV file
                      </a>
                    </div>

                    <div className="system-msg-wrapper">
                      {error && <div className="system-msg">{error}</div>}
                      {isSizeExceeded && <div className="system-msg">Your file is too large to handle. Reduce the size of the file below [5MB] and try
                        again.</div>}
                      {isCorrectFormat && <div aria-label="invalid-format" className="system-msg">Your file is in a wrong format. Convert the file to .csv or .xls and try again.
                      </div>}
                    </div>
                  </>
                  : ''}
              </div>

              {!uploadedCSV.length ?
                <div aria-label="drag-drop" className={`photo-upload-box lg-height secondary grey-bg ${isDragOver ? 'file-uploading' : ''}`}
                  onDragLeave={() => setIsDragOver(false)}
                  onDragOver={(e) => {
                    e.preventDefault();
                    setIsDragOver(true);
                  }}
                  onDrop={(e) => { e.preventDefault(); handleForce(e.dataTransfer.files) }}
                >
                  {/* Upload member csv */}
                  <div className="upload-info">
                    <div className="upload-icon no-bg">
                      <img className="file-icon" src={images.fileUpload} alt="" />
                    </div>
                    <h6>Upload member list</h6>
                    <p className="instructions">Drag & drop a .csv file to upload</p>
                    <div className="browse-files-link">
                      {/* For reference ==> <CSVReader
                        onFileLoaded={handleForce}
                        parserOptions={papaparseOptions}
                        cssInputClass='hidden-input'
                      />  */}
                      <input aria-label="browse-file" type="file" className="hidden-input" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={(e) => { e.preventDefault(); handleForce(e.target.files); setError('') }} /> Browse files
                    </div>
                  </div>
                </div>
                : <>
                  <div className="member-links">
                    <div className="imported-numbers">Imported {uploadedCSV.length} Members</div>
                    <div className="links">
                      <a href={void (0)} className="browse-files-link example-csv">
                        <input type="file" className="hidden-input" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          onChange={(e) => { e.preventDefault(); handleForce(e.target.files) }} />
                        <em className="icon be-upload"></em>Upload new CSV
                      </a>
                      <div className="browse-files-link">
                        <em className="icon be-info"></em>Preview invitation email
                        <div className="email-box">
                          <img src={(images as any).invitationEmail} className="email-thumb" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <CsvTable images={images} uploadedCSV={uploadedCSV} />
                </>}

            </div>
          </div>

        </DialogContent>
        <DialogActions className="fixed-footer-wrap">
          <div className="fixed-full-footer">
            <Button variant="contained" aria-label="add-manually-close" className='grey-btn sm-btn' onClick={() => addManually()}>Cancel</Button>
            <Button variant="contained" className='sm-btn' onClick={sendInviteToCsvAddedMember}>Invite members</Button>
          </div>
        </DialogActions>
      </Dialog>
      {/* Upload bulk members modal ends */}
    </>
  )
}
