/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint eqeqeq: 0 */
import Button from '@mui/material/Button';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import images from '../../assets/images';
import ArchiveSpaceDialog from '../../components/dialog/company-space-dialog/ArchiveSpaceDialog';
import CreateSpaceDialog from '../../components/dialog/company-space-dialog/CreateSpaceDialog';
import EditSpaceDialog from '../../components/dialog/company-space-dialog/EditSpaceDialog';
import instance from '../../shared/interceptor';
import { addSpaces, spaceState } from '../../shared/store/slices/companySlice';
import { companyState, permissionState, userState } from '../../shared/store/slices/userSlice';
import { ON_BOARDING_WAITING_PAYMENT, SPACES } from '../../shared/util/constant';
import { debounce, getRandom, globalError, isAllowedTo, setLoader, sortListAcc } from '../../shared/util/util';
import './_buildingSpaces.scss';
import SpaceListTable from './SpaceListTable';


export default function BuildingSpaces() {
  const dispatch = useDispatch();
  const userSel = useSelector(userState);
  const permissions = useSelector(permissionState);
  const compSel = useSelector(companyState);
  const spaceSel = useSelector(spaceState);

  const [CreateSpace, setCreateSpaces] = useState(false);
  const [ArchiveSpace, setArchiveSpaces] = useState(false);
  const [EditSpace, setEditSpaces] = useState(false);
  const [spaceList, setSpaceList] = useState([]);
  const [searchSpaceList, setSearchSpaceList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedSpace, setSelectedSpace] = useState(null);
  const [sortAsc, setSortAsc] = useState(false);
  const [archive, setArchive] = useState(true);
  const [unarchive, setUnArchive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [random, setRandom] = useState(getRandom());

  useEffect(() => {
    getListOfSpaces();
  }, []);

  const CreateSpaceDialogClose = async (e, render = false) => {
    setCreateSpaces(false);
    if(render) {
      await getListOfSpaces();
    }
  };

  const archiveSpaceDialogClose = async (e, render = false) => {
    setArchiveSpaces(false);
    setUnArchive(false);
    if(render) {
      await getListOfSpaces();
    }
  };

  const EditSpaceDialogClose = async (e, render = false) => {
    if (render) {
      await getListOfSpaces();
    }
    setEditSpaces(false);
    setArchive(true);
  };

  const editSpaceModal = (space) => {
    setSelectedSpace(space);
    setEditSpaces(true);
  }

  const archiveSpaceModal = (space) => {
    setSelectedSpace(space);
    setArchiveSpaces(true)
    setUnArchive(false);
  }

  const getListOfSpaces = async () => {
    try {
      // if (!searchQuery){
      //   setLoader(dispatch, true);
      // }
      setLoading(true);
      let url = `${SPACES.replace(':id', userSel?.company?.id)}`
      if (searchQuery) {
        url += `?q=${searchQuery}`;
      }
      let spaceListRes = await instance.get(url);
      let filteredValue = spaceListRes?.['spaces'].filter(item => item.archive === false) ?? [];
      setSpaceList(filteredValue);
      setSearchSpaceList(filteredValue);
      dispatch(addSpaces(spaceListRes?.['spaces'] ?? []));
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoading(false);
      // setLoader(dispatch, false);
    }
  }

  const searchSpace = debounce((val) => {
    setSearchQuery(val);
    if (val) {
      setSpaceList(spaceSel.filter(item => (item.archive == !archive || item.archive == false) && (item?.name?.toLowerCase()?.includes(val?.toLowerCase()) || item?.email?.toLowerCase()?.includes(val?.toLowerCase()))) ?? []);
    } else {
      setSpaceList(searchSpaceList ?? []);
    }
  });

  const sortList = () => {
    setSortAsc(!sortAsc);
    let sortedList = sortListAcc(spaceList, 'name', sortAsc);
    setSpaceList(sortedList);
  }

  const toggleArchiveSpaces = () => {
    setArchive(prev => !prev);
    let filteredList
    if (archive) {
      setSearchQuery('');
      setRandom(getRandom());
      filteredList = spaceSel;
    } else {
      filteredList = spaceSel.filter(item => (item.archive === archive || item.archive === false) && (item?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase()) || item?.email?.toLowerCase()?.includes(searchQuery?.toLowerCase()))); 
    }
    setSpaceList(filteredList);
  }

  const clearInput = () => {
    setSearchQuery('');
    if(archive) {
      setSpaceList(searchSpaceList);
    } else {
      setSpaceList(spaceSel ?? []);
    }
    setRandom(getRandom());
  }

  const unarchiveSpace = (space) => {
    setSelectedSpace(space);
    setUnArchive(true);
    setArchiveSpaces(true);
  }


  //==============================================
  return (
    <>
      <div className="building-spaces" data-testid="building-spaces">
        <div className="page-container top-space">
          <div className="main-page-header">
            <div className="page-breacrumb">
              <div className="breadcrumb-wrapper">
                <ul className="breadcrumb-links">
                  <li><Link to={ON_BOARDING_WAITING_PAYMENT}>Building</Link></li>
                  <li>Spaces</li>
                </ul>
              </div>
              <div className="compnay-logo">
                { compSel?.logo?.logo_url && <img src={compSel?.logo?.logo_url} alt="company logo" /> }
              </div>
            </div>

            <div className="page-title">
              <h2>Spaces</h2>
            </div>
          </div>

          <div className="page-filters-wrapper">
            <div className="left-filter">
              <span aria-label="count" className="count">{spaceList?.length} spaces</span>
            </div>
            <div className="right-filter">
              <div className="form-group">
                <FormControl variant="filled">
                  {/* @ts-ignore */}
                  <FilledInput inputProps={{ 'data-testid': "spaceSearch" }} type="text" variant="filled" className="xs-input search-input-wrap" placeholder='Search spaces'
                    key={random}
                    startAdornment={
                      <InputAdornment position="start"><em className='be-search'></em></InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment position="end" className='search-icon-wrap'>
                        {searchQuery && <img src={images.iconCrossCircle} alt="" onClick={clearInput}/>}
                      </InputAdornment>                        
                    }
                  onChange={(e) => searchSpace(e.target.value)}/>
                </FormControl>
              </div>
              <Tooltip placement='bottom' arrow title={archive ? 'Show archive' : 'Hide archive'} classes={{ popper: 'info-tooltip' }}>
                <Button variant="outlined" className="xs-btn is-icon-btn be-archive" style={!archive ? {background: '#f3ebff'}: {}} aria-label="toggle" onClick={toggleArchiveSpaces}></Button>
              </Tooltip>
              { isAllowedTo('update', 'space', permissions) && <Button data-testid="add-space-to-list" variant="contained" className="xs-btn purple-btn" onClick={() => setCreateSpaces(true)}>Create spaces</Button>}
            </div>
          </div>

          { loading && <img src={(images as any).buAnim} alt="B" style={{ position: 'fixed', top: '50%', left: '50%', height: '80px', width:'80px', transform: 'translate(-50%, -50%)' }}/> }

          { !loading && spaceList?.length > 0 && <div className='page-content'>
              <div className="table-wrapper table-responsive space-list-table">
                  <TableContainer>
                      <Table>
                          <TableHead>
                          <TableRow>
                              <TableCell>Name <em className='icon' aria-label="sort-btn" onClick={sortList} style={{'cursor': 'pointer'}}>{sortAsc ? <span className='icon be-left-arrow move-up'></span> : <span className='icon be-left-arrow move-down'></span>}</em></TableCell>
                              <TableCell>Owner</TableCell>
                              <TableCell>Members</TableCell>
                              <TableCell className='text-center'>Projects</TableCell>
                              <TableCell className='text-center'>Buildcards</TableCell>
                              <TableCell className='text-center'>Consumed/allocated Credits</TableCell>
                              <TableCell align='right'>&nbsp;</TableCell>
                          </TableRow>
                          </TableHead>
                          <TableBody>
                          {!loading && spaceList.map((space, idx) => (
                              <SpaceListTable key={space?.id} {...{editSpaceModal, archiveSpaceModal, space, unarchiveSpace}}/>
                          ))}

                          </TableBody>
                      </Table>
                  </TableContainer>
              </div>
          </div>}

          {/* No data section */}
          { !loading && !spaceList?.length && <div className="no-data-wrapper secondary">
            <em className="thumb"><img src={images.spacesFlag} alt="Spaces Flag" /></em>
            <h4 aria-label="no-space">No spaces yet</h4>
            <p>Create spaces to organise your company and group your projects. </p>
            <Button variant="contained" className="sm-btn" aria-label="create-modal" onClick={() => setCreateSpaces(true)}>Create spaces</Button>
          </div>}

        </div>
      </div>

      {/* Create space modal start */}
      { CreateSpace && <CreateSpaceDialog {...{CreateSpace, CreateSpaceDialogClose}}/>}
      {/* Create space modal end */}

      {/* Edit space modal start */}
      { EditSpace && <EditSpaceDialog {...{EditSpace, EditSpaceDialogClose, selectedSpace }}/>}
      {/* Edit space modal end */}

      {/* Archive space modal start */}
      { ArchiveSpace && <ArchiveSpaceDialog {...{ArchiveSpace, unarchive, archiveSpaceDialogClose, selectedSpace }}/>}
      {/* Archive space modal end */}

    </>
  )
}
