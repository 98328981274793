import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import images from "../assets/images";
import "../assets/scss/main.scss";

function UnauthorizedAccess() {
  const navigate = useNavigate();

  return (
    <section className="page-container unauthorised-access-wrap" aria-label="not-authorized">
      <div className="inner-container">
        <div className="icon-wrap">
          <img src={images.iconLock} alt="lock" />
        </div>
        <h3>You're not authorized to access this page.</h3>
        <Button onClick={() => navigate('/dashboard')}>Go to Home</Button>
      </div>
    </section>
  )
}

export default UnauthorizedAccess;
