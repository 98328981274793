/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint eqeqeq: 0 */
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { companyState, permissionState, userState } from '../../shared/store/slices/userSlice';
import { MEMBER_DETAIL, MEMBER_ROLES } from '../../shared/util/constant';
import { getFullname, getInitials, getRandom, isAllowedTo } from '../../shared/util/util';
import RenderMenuList from './RenderMenuList';
import images from '../../assets/images';

const InvitedMemberTable = ({userObj, openRevokeDialog, paginateUsers, memberRoleChangeHandler, loading}) => {
    const navigate = useNavigate();
    const permissions = useSelector(permissionState);
    const userSel = useSelector(userState);
    const compSel = useSelector(companyState);

    const navigateToMemberDetail = (member) => {
        if(getFullname(member.first_name, member.last_name)) {
            navigate(MEMBER_DETAIL.replace(':id', member.id), {state: member});
        }
    }

    const handlePaginationChange = (e, val) => {
        paginateUsers(val);
    }

    // ============================================

    return (
        <div className="page-content">
            <div className="table-wrapper table-responsive">
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Job title</TableCell>
                                <TableCell>Location</TableCell>
                                <TableCell align='right'>
                                    <div className="tooltip-wrapper">
                                        <div className="tooltip-trigger align-items-center justify-content-end">User Role<em
                                        className="be-info tooltip-icon"></em>
                                        </div>
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        { loading && <tr><td><img src={(images as any).buAnim} alt="B" style={{ position: 'fixed', top: '50%', left: '50%', height: '80px', width:'80px', transform: 'translate(-50%, -50%)' }}/></td></tr> }
                        { !loading && userObj.users.map((row, i) => (
                            <TableRow
                            key={`table1_${i + getRandom()}`}
                            >
                                <TableCell className='text-nowrap'>
                                    <div className={`user-link ${!getFullname(row.first_name, row.last_name) ? 'is-grey' : ''}`}>
                                    {row?.profile_picture?.profile_picture_url ? 
                                    <span className="user-avatar"><img src={row?.profile_picture?.profile_picture_url} /></span>
                                    : 
                                    <span className={`initials ${!getFullname(row.first_name, row.last_name) ? 'be-user' : ''}`}>
                                        {getFullname(row.first_name, row.last_name) ? getInitials(row.first_name + ' ' + row.last_name) : ''}
                                    </span>}
                                    <span aria-label="user-name-navigate" className="user-name" onClick={() => navigateToMemberDetail(row)}>{getFullname(row.first_name, row.last_name) ?? '-'}</span>
                                    </div>

                                </TableCell>
                                <TableCell className='text-nowrap'>
                                    <div className="email-invitation-dropdown">
                                    {!getFullname(row.first_name, row.last_name) ?
                                        <>
                                        {row.email}
                                        {false && <Button variant="contained" className='xxs-btn lightred-btn'>
                                            <div className="tooltip-wrapper">
                                            <div className="tooltip-trigger">
                                                <em className="be-tooltip tooltip-icon">
                                                <div className="tooltip-content sm-width reverse">
                                                    <div className="content-inner">Unable to send invitation. Check email address</div>
                                                </div>
                                                </em>
                                            </div>
                                            </div>
                                            Failed<em className="be-close-dark"></em>
                                        </Button>}
                                        <div>
                                            <RenderMenuList {...{row, openRevokeDialog}} />
                                        </div>
                                        </>
                                        :
                                        row.email
                                    }
                                    </div>
                                </TableCell>
                                <TableCell className='text-nowrap'>{row?.job_title ?? '-'}</TableCell>
                                <TableCell className='text-nowrap'>{row?.location ?? '-'}</TableCell>
                                <TableCell align="right" className='select-data-wrapper'>
                                    { isAllowedTo('update', 'user', permissions) && <FormControl>
                                        <Select value={row?.role?.name} className="borderless-dropdown sm-height no-label" onChange={(e) => memberRoleChangeHandler(e, row)}>
                                            { MEMBER_ROLES.map(role => (
                                            <MenuItem disabled={userSel?.role?.name != 'CIO' && role?.value == 'CIO' && userSel?.owner?.id != compSel?.owner?.id} key={role.key} value={role.value}>{role.label}</MenuItem>
                                            )) }
                                        </Select>
                                    </FormControl> }
                                    {!isAllowedTo('update', 'user', permissions) && <span>{row?.role?.name}</span>}
                                </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>

            {userObj?.total_pages > 1 &&
                <div className="custom-pagination-wrapper">
                    <Pagination count={userObj?.total_pages} shape="rounded" size="large" siblingCount={2} onChange={handlePaginationChange} />
                </div>
            }
        </div>
    )
}

export default InvitedMemberTable;