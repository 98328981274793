/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint eqeqeq: 0 */
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import LinearProgress from '@mui/material/LinearProgress';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import images from '../../assets/images';
import { companyState } from '../../shared/store/slices/userSlice';
import { VAT_AMOUNT, countryList } from '../../shared/util/constant';
import './PlanCheckout.scss';
import { formatCurrency } from '../../shared/util/util';


const transferDetails = [
  { title: 'Company name', value: 'Engineer.ai Corp' },
  { title: 'Account number', value: '3777644882' },
  { title: 'Swift code', value: 'CHASUK334' },
  { title: 'Bank', value: 'JPMorgan Chase Bank UK' },
  { title: 'Bank address', value: '1 Chaseside, Bournemouth BH7 7DA, United Kingdom' },
];

const domesticPayments = [
  { title: 'Account number', value: '00000038711222777333', hasTooltip: false },
  { title: 'Wire routing number', value: '00213399922', hasTooltip: false },
  { title: 'ACH routing number', value: '3333772229', hasTooltip: false },
  { title: 'Reference', value: '2999812-4477733991', hasTooltip: true },
];

export default function PlanPayment({ selectedPlan }) {
  const [PaymentType, setPaymentType] = useState('transfer');
  const compSel = useSelector(companyState);
  const [PaymentStatus, setPaymentStatus] = useState('');
  const [BaiscCcView, setBaiscCcView] = useState(true);
  const [AddNewCC, setAddNewCC] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const paymentTypeHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentType(event.target.value);
  };

  const PaymentStatusHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentStatus(event.target.value);
  };

  const toggleBaiscCcView = () => {
    setBaiscCcView(BaiscCcView => !BaiscCcView);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleModalClose = (e, reason) => {
    if (reason && reason == "backdropClick") return;
    setAddNewCC(false);
  }

  //============================================================
  return (
    <>
      <section className="plan-checkout-wrapper" aria-label="payment-page">

        <div className="checkout-content">
          <div className="page-container">
            <h2 className="checkout-page-title h1 lg">Payment</h2>

            <div className="payment-container">
              <div className="payment-method">
                <h4>How do you want to pay?</h4>
                <div className="radio-wrapper">
                  <RadioGroup onChange={paymentTypeHandleChange}>
                    <div className="row">
                      <div className="col-md-6">
                        <FormControlLabel className={'radio-control box-radio ' + (PaymentType === 'transfer' ? 'checked' : '')} value="transfer" control={<Radio
                          icon={<img src={images.radioUnchecked} alt='' />}
                          checkedIcon={<img src={images.radioChecked} alt='' />}
                          checked={PaymentType === 'transfer'}
                        />} label={
                          <strong>Transfer</strong>
                        } />
                      </div>
                      <div className="col-md-6">
                        <FormControlLabel className={'radio-control box-radio ' + (PaymentType === 'credit-card' ? 'checked' : '')} value="credit-card" control={<Radio
                          icon={<img src={images.radioUnchecked} alt='' />}
                          checkedIcon={<img src={images.radioChecked} alt='' />}
                          checked={PaymentType === 'credit-card'}
                        />} label={
                          <strong>Credit card</strong>
                        } />
                      </div>
                    </div>
                  </RadioGroup>
                </div>
              </div>

              {/* if Payment method is TRANSFER */}
              {PaymentType === 'transfer' ?
                <div className="payment-method-details">
                  <div className="internal-section-title">
                    <h4>Transfer</h4>
                    <p>Make your payment now and we’ll contact you as soon as we receive it. </p>
                  </div>

                  <div className="transfer-detail-wrapper">
                    <div className="form-group country-field">
                      <Autocomplete
                        autoHighlight
                        options={countryList}
                        renderInput={(params) => <TextField {...params} label="Country" />}
                      />
                    </div>

                    <div className="transfer-detail-outer">
                      <div className="transfer-detail-block">
                        <div className="transfer-title">
                          <h6>Transfer details</h6>
                          <span className="be-copy"></span>
                        </div>
                        <div className="transfer-outer">
                          {transferDetails.map((transfer, i) => (
                            <div className="transfer-row" key={transfer.title}>
                              <div className="title">{transfer.title}</div>
                              <div className="value">{transfer.value}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="transfer-detail-block">
                        <div className="transfer-title">
                          <h6>Domestic payments</h6>
                          <span className="be-copy"></span>
                        </div>
                        <div className="transfer-outer">
                          {domesticPayments.map((dp, i) => (
                            <div className="transfer-row" key={dp.title}>
                              <div className="title">{dp.title}
                                {dp.hasTooltip ?
                                  <em className="be-tooltip"></em>
                                  : ''
                                }
                              </div>
                              <div className="value">{dp.value}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="payment-status-wrapper">
                    <div className="radio-wrapper">
                      <RadioGroup onChange={PaymentStatusHandleChange}>
                        <div className="row">
                          <div className="col-md-6">
                            <FormControlLabel className={'radio-control box-radio top-align ' + (PaymentStatus === 'payment-done' ? 'checked' : '')} value="payment-done" control={<Radio
                              icon={<img src={images.radioUnchecked} alt='' />}
                              checkedIcon={<img src={images.radioChecked} alt='' />}
                              checked={PaymentStatus === 'payment-done'}
                            />} label={
                              <div className='radio-content'>
                                <strong>I’ve made the transfer</strong>
                                <div className="sub-content">
                                  <p>Paying immediately speeds up the process. We’ll need proof of payment.</p>
                                </div>
                              </div>
                            } />
                          </div>
                          <div className="col-md-6">
                            <FormControlLabel className={'radio-control box-radio top-align ' + (PaymentStatus === 'payment-later' ? 'checked' : '')} value="payment-later" control={<Radio
                              icon={<img src={images.radioUnchecked} alt='' />}
                              checkedIcon={<img src={images.radioChecked} alt='' />}
                              checked={PaymentStatus === 'payment-later'}
                            />} label={
                              <div className='radio-content'>
                                <strong>I’ll do it later</strong>
                                <div className="sub-content">
                                  <p>We’ll email the transfer details. Your plan is activated once we get proof of payment.</p>
                                </div>
                              </div>
                            } />
                          </div>
                        </div>
                      </RadioGroup>
                    </div>

                    {PaymentStatus === 'payment-done' ?
                      <div className="upload-proof-wrapper">
                        <div className="internal-section-title">
                          <h6>Upload your proof of payment </h6>
                          <p>For example, this could be a screenshot of your transfer.</p>
                        </div>

                        <div className="file-upload-wrapper">
                          <em className="upload-icon"><img src={images.fileUpload} alt="" /></em>
                          <h6>Upload proof of payment</h6>
                          <p>Drag and drop a .jpg, .png or pdf to upload</p>
                          <a href={void (0)} className="browse-files-link primary-link">Browse files
                            <input type="file" className="hidden-input" />
                          </a>
                        </div>

                        <div className="file-uploading-wrapper">
                          <LinearProgress variant="determinate" value={76} className='sm transparent' />
                          <em className="file-icon be-pdf-file"></em>
                          {/* Remove class "uploaded" to below div class-"file-name" when file is being uploading */}
                          <div className="file-name uploaded">ExportBank.pdf <span className="status">(uploading)</span></div>
                          <em className="be-close"></em>
                        </div>
                      </div>
                      : ''}

                  </div>
                </div>
                : ''}
              {/* if Payment method is TRANSFER */}


              {/* if Payment method is CREDIT CARD */}
              {PaymentType === 'credit-card' ?
                <>
                  <div className="payment-method-details">
                    <div className="internal-section-title">
                      <h4 onClick={toggleBaiscCcView}>Credit card details</h4>
                      {BaiscCcView ?
                        <p>This will be your default payment method.</p>
                        : ''
                      }
                    </div>

                    {BaiscCcView ?
                      <div className="credit-card-details">
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group">
                              <FormControl variant="filled">
                                <InputLabel>Name on card</InputLabel>
                                {/* @ts-ignore */}
                                <FilledInput type="text" variant="filled" />
                              </FormControl>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group">
                              <FormControl variant="filled">
                                <InputLabel>Card number</InputLabel>
                                {/* @ts-ignore */}
                                <FilledInput type="text" variant="filled" />
                              </FormControl>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <div className="form-group">
                              <FormControl variant="filled">
                                <InputLabel>Expiration</InputLabel>
                                {/* @ts-ignore */}
                                <FilledInput type="text" variant="filled" />
                              </FormControl>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group">
                              <FormControl variant="filled">
                                <InputLabel>CVV</InputLabel>
                                {/* @ts-ignore */}
                                <FilledInput type="text" variant="filled" />
                              </FormControl>
                            </div>
                          </div>
                        </div>
                      </div>
                      : ''
                    }

                    {!BaiscCcView ?
                      <>
                        <div className="credit-card-listing">
                          <div className="inner-listing">
                            <RadioGroup>
                              <div className="inner-row">
                                <FormControlLabel className='radio-control' value="cc_1" control={<Radio
                                  icon={<img src={images.radioUnchecked} alt='' />}
                                  checkedIcon={<img src={images.radioChecked} alt='' />}
                                />} label={
                                  <div className="radio-row">
                                    <div className="card-type-no">
                                      <em className="card-type"><img src={images.visaLogo} alt="" /></em>xxx 252
                                    </div>
                                    <div className="card-holder-name">David Cleaves</div>
                                    <div className="card-expiry">05/23</div>
                                  </div>
                                } />
                              </div>
                              <div className="inner-row">
                                <FormControlLabel className='radio-control' value="cc_2" control={<Radio
                                  icon={<img src={images.radioUnchecked} alt='' />}
                                  checkedIcon={<img src={images.radioChecked} alt='' />}
                                />} label={
                                  <div className="radio-row">
                                    <div className="card-type-no">
                                      <em className="card-type"><img src={images.visaLogo} alt="" /></em>xxx 252
                                    </div>
                                    <div className="card-holder-name">David Cleaves</div>
                                    <div className="card-expiry">05/23</div>
                                  </div>
                                } />
                              </div>
                              <div className="inner-row">
                                <FormControlLabel className='radio-control' value="cc_3" control={<Radio
                                  icon={<img src={images.radioUnchecked} alt='' />}
                                  checkedIcon={<img src={images.radioChecked} alt='' />}
                                />} label={
                                  <div className="radio-row">
                                    <div className="card-type-no">
                                      <em className="card-type"><img src={images.visaLogo} alt="" /></em>xxx 252
                                    </div>
                                    <div className="card-holder-name">David Cleaves</div>
                                    <div className="card-expiry">05/23</div>
                                  </div>
                                } />
                              </div>
                            </RadioGroup>
                          </div>
                        </div>
                        <a href={void (0)} className="add-cc-link" onClick={() => setAddNewCC(true)}>
                          <em className="be-add-circle"></em>Add new credit card
                        </a>
                      </>
                      : ''
                    }

                  </div>
                </>
                : ''}
              {/* if Payment method is CREDIT CARD */}

            </div>

          </div>
        </div>

        <div className="checkout-summary">
          <div className="checkout-content-outer">
            <div className="checkout-plan-title">
              <h4>Billing summary</h4>
            </div>
            <div className="checkout-costing">
              <div className="cost-row">
                <span className="cost-label">Yearly fixed cost</span>
                <span className="cost">{formatCurrency(selectedPlan?.starting_price)}</span>
              </div>
              <div className="cost-row">
                <span className="cost-label">VAT</span>
                <span className="cost">$15,000</span>
              </div>
              <div className="cost-row total-row">
                <span className="cost-label">Total</span>
                <span className="cost">{formatCurrency(parseInt(selectedPlan?.starting_price) + VAT_AMOUNT)}</span>
              </div>
            </div>
          </div>
          <div className="checkout-buttons has-checkbox">
            <FormControlLabel
              className='checkbox-control top-align'
              control={<Checkbox
                aria-label='checkbox'
                icon={<img src={images.checkboxUnchecked} alt='' />}
                checkedIcon={<img src={images.checkboxChecked} alt='' />}
              />}
              label={
                <span>I’ve read and agreed to the terms of the&nbsp;
                  <a href={void (0)} className="primary-link" target="_blank" rel="noreferrer">Master service agreement</a>
                </span>
              }
            />
            <Button href='/payment-success' variant="contained">Confirm & finish</Button>
          </div>
        </div>
      </section>

      {/* Invite IT admin dialog starts */}
      <Dialog
        open={AddNewCC}
        onClose={handleModalClose}
      >
        <DialogContent>
          {/* <span className="modal-close be-close" onClick={() => setAddNewCC(false)}></span> */}
          <div className="modal-body">
            <div className="modal-title">
              <h2>Add new credit card</h2>
            </div>
            <div className="modal-form">
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <FormControl variant="filled">
                      <InputLabel>Name on card</InputLabel>
                      {/* @ts-ignore */}
                      <FilledInput type="text" variant="filled" />
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <FormControl variant="filled">
                      <InputLabel>Card number</InputLabel>
                      {/* @ts-ignore */}
                      <FilledInput type="text" variant="filled" />
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <FormControl variant="filled">
                      <InputLabel>Expiration</InputLabel>
                      {/* @ts-ignore */}
                      <FilledInput type="text" variant="filled" />
                    </FormControl>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <FormControl variant="filled">
                      <InputLabel>CVV</InputLabel>
                      {/* @ts-ignore */}
                      <FilledInput type="text" variant="filled" />
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <FormControlLabel
                      className='checkbox-control'
                      control={<Checkbox
                        icon={<img src={images.checkboxUnchecked} alt='' />}
                        checkedIcon={<img src={images.checkboxChecked} alt='' />}
                      />}
                      label={"Make this the default payment method."}
                    />
                  </div>
                </div>
              </div>

              <div className="member-permission-wrapper">
                <h6>Who should have access to this credit card?</h6>
                <div className="permission-form-group form-group">
                  <div className="member-input">
                    <FormControl variant="filled">
                      {/* @ts-ignore */}
                      <FilledInput type="text" className="sm-input" variant="filled" placeholder='Find member' />
                    </FormControl>

                    <div className="menu-with-arrow">
                      <Button variant="contained" className='sm-btn white-btn black-text' onClick={handleClick}>Can view</Button>
                      <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        className='custom-dropmenu'
                      >
                        <MenuItem>Can view</MenuItem>
                        <MenuItem>Can edit</MenuItem>
                      </Menu>
                    </div>
                  </div>
                  <Button variant="contained" className='sm-btn purple-btn'>Add</Button>
                </div>
                <div className="users-list">
                  <div className="user-row">
                    <div className="user-info">
                      <em className="dp"><img src={(images as any).memberAvatar} alt="" /></em>
                      <span className="name">David Cleaves (you)</span>
                    </div>
                    <div className="menu-with-arrow">
                      <Button variant="contained" className='sm-btn white-btn black-text' onClick={handleClick}>Can view</Button>
                      <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        className='custom-dropmenu'
                      >
                        <MenuItem>Can view</MenuItem>
                        <MenuItem>Can edit</MenuItem>
                      </Menu>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="fixed-footer-wrap">
          <div className="fixed-full-footer">
            <Button variant="contained" className='sm-btn grey-btn' onClick={() => setAddNewCC(false)}>Cancel</Button>
            <Button variant="contained" className="sm-btn">Save</Button>            
          </div>
        </DialogActions>
      </Dialog>
      {/* Invite IT admin dialog ends */}
    </>
  )
}
