/* eslint-disable jsx-a11y/anchor-is-valid */
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';

import images from '../../assets/images';
import { getCurrentSpaceDetail } from '../../shared/services/common.service';
import { currentSpaceState } from '../../shared/store/slices/companySlice';
import { companyState, permissionState, userPermissionState, userState } from '../../shared/store/slices/userSlice';
import { ON_BOARDING_WAITING_PAYMENT, SPACE_LIST } from '../../shared/util/constant';
import { isAllowedTo } from '../../shared/util/util';
import './spaces.scss';

export default function SpaceTopNavigation() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = location;
  const { id } = useParams() ?? {id: null};
  const splitLocation = pathname.split("/");
  
  const currentSpaceSel = useSelector(currentSpaceState);
  const companySel = useSelector(companyState);
  const userSel = useSelector(userState);
  const permissions = useSelector(permissionState);
  const userPermissions = useSelector(userPermissionState);

  const entity =  { id: currentSpaceSel?.id, type: 'Space' }

  useEffect(() => {
    fetchDetails();
  }, [id]);

  const fetchDetails = () => {
    getCurrentSpaceDetail(dispatch, companySel?.id, id);
  }

  const isPrivateSpaceOwner = () => {
    return (!currentSpaceSel?.private || (currentSpaceSel?.private && currentSpaceSel?.owner?.email === userSel?.email));
  }
  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const open = Boolean(anchorEl);
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  //===========================================
  return (
    <>
      <div className="main-page-header">
        <div className="page-breacrumb">
          <div className="breadcrumb-wrapper">
            <ul className="breadcrumb-links">
              <li><Link to={ON_BOARDING_WAITING_PAYMENT}>Building</Link></li>
              <li>{isAllowedTo('update', 'space', permissions) ? <Link to={SPACE_LIST}>Spaces</Link> : 'Spaces'}</li>
              <li>{ isPrivateSpaceOwner() && currentSpaceSel?.name }</li>
            </ul>
          </div> 
          <div className="compnay-logo">
            { companySel?.logo?.logo_url && <img src={companySel?.logo?.logo_url} alt="company logo" /> }
          </div>         
        </div>

        {isPrivateSpaceOwner() && <div className="page-title">
          <h2 aria-label="member-heading">{ currentSpaceSel?.name }</h2>
          <p>Creating stellar apps for our warehouses, distribution centres and retailers.</p>
        </div>}
      

      {(isPrivateSpaceOwner() && currentSpaceSel?.description) && <div className="page-filters-wrapper align-items-start">
        <div className="left-filter">
          <span aria-label="count" className="count">{ currentSpaceSel?.description }</span>
        </div>       
        {/* <div className="right-filter full-width justify-right">
          <Button variant="outlined" className="xs-btn is-icon-btn icon be-vertical-dots"></Button>              
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            className='custom-dropmenu action-menu-dropdown'
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem className='menu-item'><em className='icon be-edit'></em> Edit</MenuItem>
          </Menu>
        </div>*/}
      </div>}
      </div>

      <div className="projects-top-nav-wrapper">
        <div className="page-top-navigation">
          <ul>
            <li><NavLink className={splitLocation[1] === "spaces/overview" ? "active" : ""} to={`/company/spaces/${currentSpaceSel?.id}/overview`}>Overview</NavLink></li>
            {isAllowedTo('read', 'space_goal', permissions, entity, userPermissions) && <li><NavLink className={splitLocation[1] === "spaces/goals" ? "active" : ""} to={`/company/spaces/${currentSpaceSel?.id}/space-goals`}>Goals</NavLink></li>}
            {isAllowedTo('read', 'space_member', permissions, entity, userPermissions) && <li><NavLink className={splitLocation[1] === "spaces/team" ? "active" : ""} to={`/company/spaces/${currentSpaceSel?.id}/team`}>Members</NavLink></li>}
            {isAllowedTo('read', 'space_credit', permissions, entity, userPermissions) && <li><NavLink className={splitLocation[1] === "spaces/credits" ? "active" : ""} to={`/company/spaces/${currentSpaceSel?.id}/credits`}>Credits</NavLink></li>}
            <li><NavLink className={splitLocation[1] === "spaces/activity" ? "active" : ""} to={`/company/spaces/${currentSpaceSel?.id}/activity`}>Activity</NavLink></li>            
          </ul>
        </div>
        <div className="page-filters-wrapper m-0">
          <div className="right-filter full-width justify-right">
            {isAllowedTo('update', 'buildcard', permissions, entity, userPermissions) && <Button variant="contained" className="sm-btn purple-btn btn-start-working-idea" onClick={() => navigate(`/company/spaces/${id}/space-playground`)}>Start working on an idea</Button>}
            <Tooltip arrow placement='top' title="Idea board" classes={{ popper: 'info-tooltip' }}>
              <Button variant="outlined" className="xs-btn is-icon-btn icon btn-idea-board" onClick={() => navigate(`/company/spaces/${id}/ideaboard`)}><img src={images.iconIdeaBoard} alt="Idea board" /></Button>
            </Tooltip>            
          </div>
        </div>
      </div>      
    </>
  )
}
