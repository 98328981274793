import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Papa from 'papaparse';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import images from '../../../assets/images';
import instance from '../../../shared/interceptor';
import { currentSpaceState } from '../../../shared/store/slices/companySlice';
import { companyState } from '../../../shared/store/slices/userSlice';
import { BULK_CREATE_SPACE_MEMBER, FETCH_USERS_DETAILS } from '../../../shared/util/constant';
import { getFullname, globalError, setLoader } from '../../../shared/util/util';
import { setSuccessMsg } from '../../../shared/store/slices/successSlice';


function AddSpaceMemberCsvDialog({ AddSpaceMemberBulk, AddSpaceMemberBulkDialogClose, openManual }) {
	const dispatch = useDispatch();
	const companySel = useSelector(companyState);
	const currentSpaceSel = useSelector(currentSpaceState);

	const [isDragOver, setIsDragOver] = useState(false);
	const [error, setError] = useState('');
	const [fileName, setFileName] = useState('');
	const [matchedList, setMatchedList] = useState([]);

	const handleForce = (files) => {
		let filteredList = Array.from(files)
			.filter((file: any) => (file.type === 'text/csv' ||
				file.type === 'application/vnd.ms-excel' ||
				file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'))

		if (filteredList.length) {
			filteredList.forEach(async (file: any) => {
				let sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
				if (+sizeInMB > 5) {
					setError('File size should be smaller than 5 MB');
					setIsDragOver(false);
				} else {
					parseCsv(file);
				}
			});
		} else {
			setError('Uploaded file should be in CSV format');
			setIsDragOver(false);
		}
	}

	const papaparseOptions = {
		header: true,
		dynamicTyping: true,
		skipEmptyLines: true,
		transformHeader: header => header.toLowerCase(),
	};

	const parseCsv = async (file) => {
		const text = await file?.text();
		const data = Papa.parse(text, papaparseOptions);
		if (data?.data?.length > 10000) {
			return setError('You can only upload upto 10k members');
		}
		let keys = Object.keys(data?.data[0]);
		let emailKey = keys.find(key => key.match(/email/i));
		if (emailKey) {
			setError('');
			setFileName(file.name);
			let filteredList = [...new Set(data.data.map(item => item[emailKey].trim()))];
			let payload = {
				users: filteredList.map(item => ({ email: item }))
			}
			fetchDetails(payload);
		} else {
			setError('Email is required in CSV');
		}
	}

	const fetchDetails = async (payload) => {
		try {
			setLoader(dispatch, true);
			const url = `${FETCH_USERS_DETAILS.replace(':id', companySel.id)}`;
			const fetchedRes = await instance.post(url, payload);
			setMatchedList(fetchedRes['users']);
		} catch (e) {
			globalError(dispatch, e);
		} finally {
			setLoader(dispatch, false);
		}
	}

	const removeFile = () => {
		setFileName('');
	}

	const removeUserFromList = (idx) => {
		let tempArr = [...matchedList];
		tempArr.splice(idx, 1);
		setMatchedList(tempArr);
	}

	const sendBulkMembers = async () => {
		try {
			setLoader(dispatch, true);
			const spaceMembers = matchedList.map(item => ({ email: item.email }))
			const payload = {
				space_members: spaceMembers
			}
			const url = `${BULK_CREATE_SPACE_MEMBER.replace(':id', companySel.id).replace(':space_id', currentSpaceSel.id)}`;
			let res = await instance.post(url, payload);
			if (res?.['space_members']?.['count']) {
				removeFile();
				AddSpaceMemberBulkDialogClose(null, true);
				dispatch(setSuccessMsg(`${res?.['space_members']?.count} Member/s added successfully.`));
			} else {
				globalError(dispatch, { message: 'Member already exists, No members added' });
			}
		} catch (e) {
			globalError(dispatch, e);
		} finally {
			setLoader(dispatch, false);
		}
	}

	const handleModalClose = (event, reason) => {
		if (reason && reason == "backdropClick") return;
		AddSpaceMemberBulkDialogClose(event, false)
	}

	//=====================================================
	return (
		<Dialog
			open={AddSpaceMemberBulk}
			onClose={handleModalClose}
		>
			<DialogContent className='add-members-to-space-dialog bulk'>
				{/* TODO: close button to be enabled later */}
				{/* <span className="modal-close be-close" onClick={AddSpaceMemberBulkDialogClose}></span> */}
				<div className="modal-body" aria-label='space-csv'>
					<div className="modal-title">
						<h2 aria-label='modal-heading'>Add members to your space</h2>
					</div>
					<div className="bulk-member-upload-wrapper">
						<div className="member-upload-info">
							<div className='member-links'>
								<div className='imported-numbers'>Upload a list of email addresses</div>
								<div className='links'>
									<a href={void (0)} className="browse-files-link example-csv" onClick={openManual}>
										{/* <input type="file" className="hidden-input" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" /> */}
										Add members manually
									</a>
								</div>
							</div>
							<p className="info">
								The list should be a .csv with only email addresses of the people you want to invite to tis space. We will match the list with members inside {companySel?.name ? companySel.name : 'Company'} and invite the ones missing via email.
							</p>
							<div data-testid="drop-area" className={`upload-email-list-wrap ${isDragOver ? 'file-uploading' : ''}`} onDragLeave={() => setIsDragOver(false)}
								onDragOver={(e) => {
									e.preventDefault();
									setIsDragOver(true);
								}}
								onDrop={(e) => { e.preventDefault(); handleForce(e.dataTransfer.files) }}>
								<em className="thumb"><img src={images.uploadCloudIcon} alt="upload" /></em>
								<h4 aria-label="no-space">Upload email list</h4>
								<p>Drag & drop a .csv to upload</p>
								<a href={void (0)} className="browse-files-link example-csv">
									<input type="file" className="hidden-input" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
										onChange={(e) => { e.preventDefault(); handleForce(e.target.files); }} />
									Browse file
								</a>
							</div>
							<span aria-label="error" className="form-error">{error}</span>
							{fileName && <div className='uploaded-files-wrapper'>
								<div className='file-description'>
									<img src={(images as any).fileCsv} alt="" />
									<span className='name'>{fileName}</span>
									<span className='icon be-close' onClick={removeFile}></span>
								</div>
							</div>}
							{matchedList?.length > 0 && <>
								<div className='member-links to-be-added-title'>
									<div className='imported-numbers'>Members to be added ({matchedList.length})</div>
								</div>
								<div className="table-wrapper table-responsive table-bulk-members-added">
									<TableContainer>
										<Table>
											<TableHead>
												<TableRow>
													<TableCell>Email <em className='icon be-down-arrow-1'></em></TableCell>
													<TableCell>Match</TableCell>
													<TableCell>&nbsp;</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{matchedList.map((user, idx) => {
													return (
														<TableRow key={user.id}>
															<TableCell className='text-nowrap'>
																<span>{user?.email}</span>
															</TableCell>
															<TableCell className='text-nowrap'>
																<span>{getFullname(user?.first_name, user?.last_name) ?? '-'}</span>
															</TableCell>
															<TableCell className='text-nowrap'>
																<span data-testid="cross-btn" className='icon be-close' onClick={() => removeUserFromList(idx)}></span>
															</TableCell>
														</TableRow>
													)
												})}
											</TableBody>
										</Table>
									</TableContainer>
								</div> 
							</>}
						</div>

					</div>

				</div>
			</DialogContent>
			<DialogActions className="fixed-footer-wrap">
				<div className="fixed-full-footer">
					<Button variant="contained" className='grey-btn sm-btn' onClick={AddSpaceMemberBulkDialogClose}>Cancel</Button>
					<Button data-testid="add-member-btn" className='sm-btn' variant="contained" disabled={!matchedList?.length} onClick={sendBulkMembers}>Add members</Button>
				</div>
			</DialogActions>
		</Dialog>
	)
}

export default AddSpaceMemberCsvDialog;
