/* eslint eqeqeq: 0 */
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import Menu from "@mui/material/Menu";
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { permissionState, userPermissionState } from '../store/slices/userSlice';
import { getWidth, isAllowedTo, isEllipsisActive } from '../util/util';
import { currentSpaceState } from '../store/slices/companySlice';


function CardEntityTags({ card, setShowSearchTags, deleteTag, tagList, addTags, tagChangeHandler }) {
  const permissions = useSelector(permissionState);
  const userPermissions = useSelector(userPermissionState);
  const currentSpaceSel = useSelector(currentSpaceState);
  const entity = { id: currentSpaceSel?.id, type: 'Space' }

  const [showTagName, setShowTagName] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [width, setWidth] = useState(null);

  const div = useCallback(node => {
    if (node !== null) {
      setWidth(node.getBoundingClientRect().width);
    }
  }, [width]);

  const handleClose = () => {
    setAnchorEl2(null);
  }

  const moreTagsDropdownClick = (id, event, elem) => {
    event.preventDefault();
    event.stopPropagation();
    elem({ [id]: event.currentTarget });
  };

  const tagInput = (card) => {
    return <div>
      <FormControl variant="filled" onKeyDown={e => e.stopPropagation()}>                                                        
        <FilledInput type="text" className="xs-input search-tag-input" placeholder='Search or create new'
          autoFocus
          startAdornment={
            <InputAdornment position="start"></InputAdornment>
          }
          onKeyDown={(e) => tagChangeHandler(e, card)}
          inputProps={{ 'data-testid': 'tag-input'}}
        />
      </FormControl>
    </div>
  }

  const canDestroyTag = () => isAllowedTo('destroy', 'entity_tag', permissions, entity, userPermissions);
  const canAddTag = () => isAllowedTo('update', 'entity_tag', permissions, entity, userPermissions);


  //=============================================================
  return (
    <div className="tag-wrapper" ref={div}> 
      <div className="tags-container">                                                                                                       
        {card?.entity_tags?.slice(0, 3).map(tag => <Tooltip placement='top-start' arrow title={showTagName} key={tag?.id} classes={{ popper: 'info-tooltip' }}>
          <Stack direction="row" spacing={1}>
            <Chip 
              data-testid="delete-tag-grid-chip" 
              onMouseEnter={(e) => isEllipsisActive(e?.target) && setShowTagName(prev => tag?.name)} 
              onMouseLeave={e => setShowTagName(prev => null)} 
              onClick={() => setShowSearchTags(tag)} 
              style={{maxWidth: getWidth(card?.entity_tags?.length, width)}} 
              label={tag?.name} 
              onDelete={canDestroyTag() ? () => deleteTag(tag?.id) : null} 
              deleteIcon={canDestroyTag() ? <span data-testid="delete-tag-grid" 
              className='icon be-close-dark'></span> : <></>} />
        </Stack></Tooltip>)}
        {card?.entity_tags?.length > 3 && <Stack direction="row" spacing={1}>
          <Chip className="overflow-visible" label={`+${card?.entity_tags?.length - 3}`} onClick={(e) => moreTagsDropdownClick(card?.id, e, setAnchorEl2)} />
        </Stack>}
        
        <Menu
          className='taglist-menu cards'
          anchorEl={anchorEl2?.[card?.id]}
          open={ Boolean(anchorEl2?.[card?.id]) }
          disableRestoreFocus
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {card?.entity_tags?.length > 0 ? <div className='selected-tags'>                                                        
            {card?.entity_tags?.map(tag => <Stack key={tag?.id} direction="row" className='purple-chips' spacing={1}>
              <Chip 
                label={tag?.name} 
                onClick={() => {setShowSearchTags(tag); handleClose()}} 
                onDelete={canDestroyTag() ? () => {deleteTag(tag?.id); handleClose()} : null} 
                deleteIcon={canDestroyTag() ? <span className='icon be-close-dark sm-close'></span> : <></>} />
            </Stack>)}
          </div> : 
          <div className="empty-tags-wrapper">
            <p className="empty-text">No tag added yet</p>
          </div>}
          {(canAddTag() && anchorEl2) && tagInput(card)}
          <div style={{maxHeight: '260px'}}>
            {tagList?.map(item => <div key={item?.id} className='tag-row'>
              <Stack direction="row" className='purple-chips' spacing={1}>
                <Chip label={item?.name} onClick={() => addTags(item?.name, card)}/>
              </Stack>
            </div>)}
          </div>
        </Menu>
      </div>
      {canAddTag() && <div className="icon-btn-wrap">
        <Tooltip placement='top-end' arrow title="Add tag" classes={{ popper: 'info-tooltip' }}>
          <Button
            variant="contained"
            className="xs-btn is-icon-btn purple-btn has-border"
            onClick={(e) => moreTagsDropdownClick(card?.id, e, setAnchorEl2)}><em className='icon be-tag-pin'></em>
          </Button>
        </Tooltip>
      </div>}
    </div>
  )
}

export default CardEntityTags;
