/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint eqeqeq: 0 */
import { InputLabel } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import instance from '../../../shared/interceptor';
import { getUserListWithoutPermission } from '../../../shared/services/common.service';
import { currentProject, currentProjectState, currentSpaceState } from '../../../shared/store/slices/companySlice';
import { setSuccessMsg } from '../../../shared/store/slices/successSlice';
import { companyState, userState } from '../../../shared/store/slices/userSlice';
import { COMPANY, PROJECTS } from '../../../shared/util/constant';
import { debounce, getFullname, getRandom, globalError, setLoader } from '../../../shared/util/util';
import images from '../../../assets/images';


function CreateSpaceProjectDialog({ CreateProject, CreateProjectDialogClose, AddNewGoalDialogOpen, action }) {
  const dispatch = useDispatch();
  const companySel = useSelector(companyState);
  const currentSpace = useSelector(currentSpaceState);
  const userSel = useSelector(userState);
  const currentProjectSel = useSelector(currentProjectState);

  const [textFeildValues, setTextFieldValues] = useState({});
  const [spaceOwnerList, setSpaceOwnerList] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [projectOwner, setProjectOwner] = useState(null);
  const [renderKey, setRenderKey] = useState(getRandom());
  const [tagList, setTagList] = useState([]);
  const [selectedTag, setSelectedTags] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (CreateProject) {
      getMembersList();
      if (action == 'Edit') {
        setTextFieldValues({ ...textFeildValues, 'project-name': currentProjectSel?.name, 'description': currentProjectSel?.description })
      }
    }
  }, [searchQuery]);

  const getMembersList = async () => {
    try {
      let userRes = await getUserListWithoutPermission(companySel, searchQuery, dispatch, setLoading);
      setSpaceOwnerList(userRes['users']);
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoading(false);
    }
  }

  const handleDelete = (memberId) => {
    let idx = memberList.findIndex(member => member?.id === memberId);
    let tempArr = [...memberList];
    tempArr.splice(idx, 1);
    setMemberList(tempArr);
  };

  const handleInputChange = (e) => {
    setTextFieldValues({ ...textFeildValues, [e.target.name]: e.target.value });
  }

  const searchMembers = debounce((value) => {
    setSearchQuery(value);
  });

  const handleMemberChange = (value) => {
    let exists = memberList.findIndex(member => member?.id == value?.id);
    if (exists < 0) {
      setMemberList([...memberList, value]);
    }
    setRenderKey(getRandom());
  }

  const addProject = async () => {
    try {
      setLoader(dispatch, true);
      const payload = {
        project: {
          name: textFeildValues?.['project-name'] ?? currentProjectSel?.name,
          description: textFeildValues?.['description'] ?? currentProjectSel?.description,
          email: projectOwner?.email
        }
      }
      if (userSel?.email === currentSpace?.owner?.email || userSel?.role?.name === 'CIO') {
        payload['project']['tags'] = selectedTag.map(item => item?.name);
        payload['project']['project_members'] = memberList.map(member => ({ email: member?.email }));
      }

      let url = `${PROJECTS.replace(':id', companySel?.id).replace(':space_id', currentSpace?.id)}`;
      if (action === 'Edit') {
        url += `/${currentProjectSel?.id}`;
        let editedRes = await instance.patch(url, payload);
        dispatch(currentProject(editedRes['project']));
        dispatch(setSuccessMsg('Project updated successfully.'));
      } else {
        await instance.post(url, payload);
        dispatch(setSuccessMsg('Project created successfully.'));
      }
      closeDialog(null, true);
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  }

  const checkMandatory = () => {
    if (action == 'Edit') {
      return currentProjectSel?.name == textFeildValues?.['project-name'] &&
        currentProjectSel?.description == textFeildValues?.['description'];
    } else {
      return !textFeildValues?.['project-name'] || !textFeildValues?.['description'];
    }
  }

  const closeDialog = (e, render?) => {
    setTextFieldValues({});
    CreateProjectDialogClose(e, render);
  }

  const getAllTags = debounce(async (e?) => {
    let query = e.target.value;
    try {
      setLoading(true);
      let url = `${COMPANY}/${companySel?.id}/tags`;
      if (query) {
        url += `?q=${query}`
      }
      let tagsRes = await instance.get(url);
      setTagList(tagsRes['tags']);
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoading(false);
    }
  });

  const removeSelectedTag = (idx) => {
    let tempArr = [...selectedTag];
    tempArr.splice(idx, 1);
    setSelectedTags(tempArr);
  }

  const setTagsValue = (event, value) => {
    if (event.target.value) {
      setSelectedTags([...selectedTag, { id: getRandom(), name: event.target.value }])
      return;
    };
    setSelectedTags(value);
  }

  const handleModalClose = (e, reason) => {
    if (reason && reason == "backdropClick") return;
    closeDialog(e, false);
  }


  //===============================================================
  return (
    <Dialog
      open={CreateProject}
      onClose={handleModalClose}
    >
      <DialogContent>
        {/* <span className="modal-close be-close" onClick={CreateProjectDialogClose}></span> */}
        <div className="modal-body create-project-modal" aria-label="create-edit-project-dialog">
          <div className="modal-title has-right-data">
            <h2>{action} project</h2>
          </div>

          <div className="modal-form">
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <FormControl>
                    <InputLabel>Project name</InputLabel>
                    {/* @ts-ignore */}
                    <FilledInput inputProps={{ 'data-testid': 'project-name' }} type="text" variant="filled" name="project-name" onChange={handleInputChange} defaultValue={action === 'Edit' ? currentProjectSel?.name : ''} />
                  </FormControl>
                  <span className="form-error"></span>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className="col-sm-12">
                <div className="form-group">
                  <TextField
                    inputProps={{ 'data-testid': 'project-description' }}
                    id="outlined-multiline-static"
                    label="Project description"
                    multiline
                    rows={4}
                    defaultValue={action === 'Edit' ? currentProjectSel?.description : ''}
                    variant="outlined"
                    name="description"
                    className='custom-textarea top-spacing'
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* TODO: align goals to be enabled later */}
          {/* <div className="modal-title has-right-data align-space-goal">
            <h6 aria-label='modal-heading'>Align Space goals</h6>
            <div className="right-title">
              <div className="browse-files-link" onClick={AddNewGoalDialogOpen}><img src={images.addPlusSquare} alt="+" /> Add new goal</div>
            </div>
          </div> */}
          {/* <div className="modal-form">
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <FormControl>
                    <Select defaultValue="" className='no-label' name="goals" onChange={handleInputChange}>
                      <MenuItem value="value 1">Value 1</MenuItem>
                      <MenuItem value="value 2">Value 2</MenuItem>
                      <MenuItem value="value 3">Value 3</MenuItem>
                    </Select>
                  </FormControl>
                  <span className="form-error"></span>
                </div>
              </div>
            </div>
          </div> */}

          {(userSel?.email === currentSpace?.owner?.email || userSel?.role?.name === 'CIO') && action !== 'Edit' && <div className="modal-title advanced-settings">
            <Accordion>
              <AccordionSummary
                expandIcon={<em className='icon be-chevron-down'></em>}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h4 aria-label='modal-heading'>Advanced settings</h4>
              </AccordionSummary>
              <AccordionDetails>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <FormLabel>Add project owner</FormLabel>
                      <FormControl>
                        <Autocomplete
                          loading={loading}
                          className='autocomplete-search'
                          data-testid="autoSearch"
                          freeSolo
                          defaultValue={action === 'Edit' ? { name: '', email: currentProjectSel?.owner?.email ?? '' } : null}
                          onChange={(event, value) => setProjectOwner(value)}
                          options={spaceOwnerList}
                          filterOptions={(options) => options}
                          autoHighlight
                          getOptionLabel={(option) => getFullname(option?.first_name, option?.last_name) ?? option?.email ?? ''}
                          renderOption={(props, option) => (
                            <Box component="span" {...props} key={props?.id} className='owner-dropbox'>
                              {option?.profile_picture?.profile_picture_url ? <img className='initials' src={option?.profile_picture?.profile_picture_url} /> : <span className='initials'>{option?.first_name?.[0] ?? '-'}</span>}
                              <span className='right-content'>
                                <span className='owner-name'>{getFullname(option?.first_name, option?.last_name) ?? ''}</span>
                                &nbsp;
                                <span className='email'>({option.email})</span>
                              </span>
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField {...params}
                              className='search-input-box'
                              data-testid="search-space-owner"
                              label="Select"
                              variant="outlined"
                              onChange={(event) => searchMembers(event.currentTarget.value)}
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <FormLabel>Add tag</FormLabel>
                      <FormControl>
                        <Autocomplete
                          loading={loading}
                          className='autocomplete-search'
                          freeSolo
                          multiple
                          options={tagList}
                          filterOptions={(options) => options}
                          onChange={(event, value) => setTagsValue(event, value)}
                          autoHighlight
                          value={selectedTag}
                          getOptionLabel={(option) => option?.['name']}
                          renderTags={(value: string[], getTagProps) => ''}
                          renderOption={(props, option) => (
                            <Box component="span" {...props} key={ option?.id } className='owner-dropbox'>
                              <Stack direction="row" spacing={1}>
                                <Chip className='custom-tag' label={option?.name} />
                              </Stack>
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              className='search-input-box' {...params}
                              label="Select"
                              variant="outlined"
                              onChange={getAllTags}
                            />
                          )}
                        />
                      </FormControl>
                      <div className="form-group tag-wrapper">
                        <Stack direction="row" spacing={1}>
                          {selectedTag?.map((item, idx) => <Chip key={item?.id} label={item?.name} onDelete={() => removeSelectedTag(idx)} deleteIcon={<span className='icon be-close-dark'></span>} />)}
                        </Stack>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <FormLabel>Add members</FormLabel>
                      <FormControl>
                        <Autocomplete
                          loading={loading}
                          className='autocomplete-search'
                          key={renderKey}
                          freeSolo
                          data-testid="member-autosearch"
                          onChange={(event, value) => handleMemberChange(value)}
                          options={spaceOwnerList}
                          filterOptions={(options) => options}
                          autoHighlight
                          getOptionLabel={(option) => getFullname(option?.first_name, option?.last_name) ?? option?.email}
                          renderOption={(props, option) => (
                            <Box component="span" {...props} key={option?.id} className='owner-dropbox'>
                              {option?.profile_picture?.profile_picture_url ? <img className='initials' src={option?.profile_picture?.profile_picture_url} /> : <span className='initials'>{option?.first_name?.[0] ?? '-'}</span>}
                              <span className='right-content'>
                                <span className='owner-name'>{getFullname(option?.first_name, option?.last_name) ?? ''}</span>
                                &nbsp;
                                <span className='email'>({option.email})</span>
                              </span>
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              className='search-input-box' {...params}
                              label="Select"
                              variant="outlined"
                              onChange={(event) => searchMembers(event.currentTarget.value)}
                            />
                          )}
                        />
                      </FormControl>
                      <div className="form-group tag-wrapper">
                        <Stack direction="row" spacing={1}>
                          {memberList?.length > 0 && memberList.map(member => {
                            return <Chip aria-label="member-item"
                              key={member?.id}
                              onDelete={() => handleDelete(member?.id)}
                              clickable
                              variant="outlined"
                              label={`${getFullname(member?.first_name, member?.last_name) ?? member?.email}`}
                              deleteIcon={<span className='icon be-close-dark'></span>}
                            />
                          })}
                        </Stack>
                      </div>
                    </div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>}

        </div>
      </DialogContent>
      <DialogActions className="fixed-footer-wrap">
        <div className="fixed-full-footer">
          <Button variant="contained" className='sm-btn grey-btn' onClick={closeDialog}>Cancel</Button>
          <Button aria-label="add-project-btn" className='sm-btn' disabled={checkMandatory()} variant="contained" onClick={addProject}>{action === 'Edit' ? 'Update' : 'Add'} project</Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default CreateSpaceProjectDialog;
