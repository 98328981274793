import Button from '@mui/material/Button';

import images from '../../../assets/images';
import { useNavigate } from 'react-router-dom';

function WelcomeBusinessGoalsPopup({setWelcomeGoalPopup}) {
  const navigate = useNavigate();

  //================================
  return (
    <div className="welcome-to-business-goal-popup open" aria-label='welcome-goal-popup'>
      <img src={(images as any).cornerShapeNatashaIcon} alt="icon"/>
      <span aria-label='close-btn' className="close-menu be-close" onClick={() => setWelcomeGoalPopup(false)}></span> 
      <div className="inner-container">
        <h4 className="title">Welcome to your business goals</h4>
        <p>Create business goals and assign them to spaces so everyone knows what they're working towards.</p>
        <Button variant="contained" className="xs-btn" onClick={() => navigate('/company/spaces/all-spaces')}>Set up my space</Button>    
      </div>      
    </div>
  )
}

export default WelcomeBusinessGoalsPopup;
