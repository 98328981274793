/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint eqeqeq: 0 */
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useDispatch, useSelector } from 'react-redux';
import { globalError, setLoader } from '../../../shared/util/util';

import instance from '../../../shared/interceptor';
import { setSuccessMsg } from '../../../shared/store/slices/successSlice';
import { companyState } from '../../../shared/store/slices/userSlice';
import { SPACES } from '../../../shared/util/constant';


function ArchiveSpaceDialog({ArchiveSpace, unarchive, archiveSpaceDialogClose, selectedSpace}) {
  const dispatch = useDispatch();
  const companySel = useSelector(companyState);

  const archiveSpace = async () => {
    try {
      setLoader(dispatch, true);
      let payload = {
        name: selectedSpace?.name,
        email: selectedSpace?.owner?.email,
        description: selectedSpace?.description,
        archive: unarchive ? false : true
      }
      let url = `${SPACES.replace(':id', companySel.id)}/${selectedSpace.id}`;
      await instance.patch(url, payload);
      archiveSpaceDialogClose({}, true);
      dispatch(setSuccessMsg(`Space ${unarchive ? 'Unarchived': 'Archived'} Successfully.`));
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  }

  const handleModalClose = (e, reason) => {
    if (reason && reason == "backdropClick") return;
    archiveSpaceDialogClose(e, false)
  }

  //===============================================================

  return (<Dialog
    open={ArchiveSpace}
    onClose={handleModalClose}
  >
    <DialogContent className='normal-hori-padding' aria-label="archive-modal">
      {/* <span className="modal-close be-close" onClick={archiveSpaceDialogClose}></span> */}
      <div className="modal-body">
        <div className="modal-title">
          <h2 aria-label="revoke-heading">Archive {selectedSpace?.name}</h2>
          <p>Are you sure you want to {unarchive ? 'Unarchive': 'archive'} this space?</p>
        </div>
      </div>
    </DialogContent>
    <DialogActions className="fixed-footer-wrap">
      <div className="fixed-full-footer">
        <Button data-testid="close-revoke" variant="contained" className='sm-btn grey-btn' onClick={archiveSpaceDialogClose}>Cancel</Button>
        <Button variant="contained" className='sm-btn' aria-label="archive-confirm" onClick={archiveSpace}>Confirm {unarchive ? 'unarchive': 'archive'}</Button>
      </div>
    </DialogActions>
  </Dialog >)
}

export default ArchiveSpaceDialog;
