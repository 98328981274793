/* eslint eqeqeq: 0 */
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import HighchartsReact from "highcharts-react-official";
import HC_rounded from "highcharts-rounded-corners";
import Highcharts from "highcharts/highcharts-gantt";
import currentDateIndicator from "highcharts/modules/current-date-indicator";
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import images from '../../../assets/images';
import instance from '../../../shared/interceptor';
import { addTag, getAllSpaceGoals, getAllTags, getSpaceMemberList, handleDelete } from '../../../shared/services/common.service';
import CardEntityTags from '../../../shared/shared-comp/CardEntityTags';
import { currentProjectState, currentSpaceState } from '../../../shared/store/slices/companySlice';
import { companyState, permissionState, userPermissionState, userState } from '../../../shared/store/slices/userSlice';
import { PROJECTS } from '../../../shared/util/constant';
import customPlugin from '../../../shared/util/customPlugin';
import { debounce, getBcState, getCardLable, globalError, isAllowedTo, setLoader } from '../../../shared/util/util';
import TagResultSection from '../TagResultSection';
import '../project.scss';
import Summary from '../summary';
import ProjectTopNavigation from './ProjectNavigation';
import GoalsTags from '../../../shared/shared-comp/GoalsTags';
HC_rounded(Highcharts);
currentDateIndicator(Highcharts);
customPlugin(Highcharts);

export default function ProjectOverview() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userSel = useSelector(userState);
  const compSel = useSelector(companyState);
  const currentProjectSel = useSelector(currentProjectState);
  const currentSpaceSel = useSelector(currentSpaceState);
  const permissions = useSelector(permissionState);
  const userPermissions = useSelector(userPermissionState);
  const params = useParams();

  const [buildcards, setBuildcards] = useState(null);
  const [canUpdate, setCanUpdate] = useState(false);
  const [runningBCWithGraphData, setRunningBCWithGraphData] = useState([]);
  const [tagList, setTagList] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [teamList, setTeamList] = useState([]);
  const [width, setWidth] = useState(null);
  const [showSearchTags, setShowSearchTags] = useState(null);
  const [spaceGoals, setSpaceGoals] = useState([]);
  const [searchSpaceGoals, setSearchSpaceGoals] = useState([]);

  const entity = { id: currentSpaceSel?.id, type: 'Space' }

  const div = useCallback(node => {
    if (node !== null) {
      setWidth(node.getBoundingClientRect().width);
    }
  }, [width]);

  const [options] = useState({
    chart: {
      spacing: [20, 5, 10, 5],
    },
    accessibility: {
      enabled: false
    },
    credits: false,
    tooltip: false,
    width: '100%',
    height: '100%',
    yAxis: {
      min: 0,
      max: 2,
      visible: false,
    },
    xAxis: [{
      endOnTick: true,
      opposite: false,
      tickInterval: 7 * 1000 * 60 * 60 * 24,
      // maxPadding: 1,
      gridLineDashStyle: 'ShortDash',
      grid: {
        enabled: true,
        borderWidth: 0,
      },
      gridLineWidth: 2,
      labels: {
        // align: 'right',
        format: "{value:WEEK%W}"
      },
      currentDateIndicator: {
        id: 'green-marker',
        marker: 'circle',
        color: '#00C853',
        enabled: true,
        width: 2.5,
        label: {
          format: ''
        },
      },
    }],
    plotOptions: {
      gantt: {
        borderRadius: 17,
        // groupSpacing: 0.2,
        dataLabels: {
          enabled: true,
          allowOverlap: true,
          style: {
            fontSize: 12
          }
        }
      },
    },
    series: [{
      groupPadding: 0.09,
      pointPadding: 0.09,
      dataLabels: [{
        color: '#3C3E49',
        align: 'center',
        style: {
          textOutline: 0
        },
        enabled: true,
        inside: true,
        formatter: function() {
          return this.point?.name?.toUpperCase()
        },
      }],
      grid: {
        enabled: true
      },
      gridLineWidth: 2,
      enableMouseTracking: false,
      data: []
    }]
  });

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    getAllBuildcards(signal);

    return () => {
      controller.abort();
    }
  }, [params?.id, params?.project_id]);

  const navigateToPlayWelcome = () => {
    const routeTo = `/company/spaces/${currentSpaceSel?.id}/project/${currentProjectSel?.id}/playground-welcome`;
    navigate(routeTo);
  }

  const getAllBuildcards = async (signal?) => {
    try {
      setLoader(dispatch, true);
      const url = `${PROJECTS.replace(':id', compSel?.id).replace(':space_id', params?.id)}/${params?.project_id}/fetch_all_build_cards`;
      let buildcardRes = await instance.get(url, { signal });
      setCanUpdate(buildcardRes?.['can_update'] ?? false);
      if (buildcardRes?.['build_cards']) {
        setBuildcards(buildcardRes['build_cards']);
        let runningBC = buildcardRes['build_cards'].filter(cards => cards?.['status'] == 'ongoing');
        if (runningBC?.length) {
          let runningWithGraphOptions = runningBC.map(item => {
            return { ...item, builder_card_phases: {
              ...options,
              series: [...options.series.map(s => ({...s,
                  data: item?.builder_card_phases?.map((phase, index) => {
                    if(!phase?.start_date || !phase?.end_date) return null;
                    let startDateArr = phase?.start_date?.split('-');
                    let endDateArr = phase?.end_date?.split('-');
                    let option = {
                      name: phase?.name,
                      start: Date.UTC(startDateArr[0], startDateArr[1] - 1, startDateArr[2]), //2023, 9, 3
                      end: Date.UTC(endDateArr[0], endDateArr[1] - 1, endDateArr[2]),
                      y: index,
                      color: phase.name == 'Design' ? '#FEE5E2' : phase.name == 'MVP' ? 'lightgreen' : phase.name == 'Full Build' ? '#F3EBFF' : '#ECEDF0',
                      completed: {
                        amount: phase?.completion_percentage / 100,
                        fill: phase.name == 'Design' ? 'pink' : phase.name == 'MVP' ? 'green' : phase.name == 'Full Build' ? '#7C4DFF' : '#ECEDF0'
                      }
                    };
                    return option;
              })}))]
            }}
          });
          setRunningBCWithGraphData(runningWithGraphOptions);
        }
        getTeamList(signal);
        if (isAllowedTo('read', 'space_goal', permissions, entity, userPermissions)) {
          let res = await getAllSpaceGoals(dispatch, compSel, params);
          setSpaceGoals(res?.['space_goals']);
          setSearchSpaceGoals(res?.['space_goals']);
        }
      }
    } catch(e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  }

  const redirect = (card) => {
    if ((card?.status == 'ongoing' || card?.status == 'completed' || card?.state == 'ready_for_kickoff') && canUpdate) {
      window.open(card?.home_url, '_blank');
    } else if ((!card?.status || (card?.status != 'ongoing'  && card?.status != 'completed')) && 
      (card?.state == 'draft' || card?.state == 'pending_approval')) {
      navigate(`/company/spaces/${params?.id}/project/${params?.project_id}/playground/${card.enterprise_id}`);
    }
  }

  const searchSpaceGoalList = (event) => {
    let val = event?.target?.value;
    if(val) {
      setSpaceGoals(searchSpaceGoals.filter(item => item?.name?.toLowerCase()?.includes(val?.toLowerCase())));
    } else {
      setSpaceGoals(searchSpaceGoals);
    }
  }

  const handleClose = () => {
    setAnchorEl2(null);
    setTagList(null);
  }

  const mutateBcList = (response, toAdd) => {
    return buildcards.map(bc => {
      if (bc?.enterprise_id == response?.['entity_tag']?.taggable_id) {
        if (toAdd) {
          return {...bc, entity_tags: [...bc?.entity_tags, {id: response?.['entity_tag']?.['id'], name: response?.['entity_tag']?.['name']}]};
        } else {
          return {...bc, entity_tags: bc?.entity_tags?.filter(item => item?.id !== response?.['entity_tag']?.id)};
        }
      } else {
        return bc;
      }
    });
  }

  const mutateRunningBc = (filtered, running) => {
    let updated = running?.map(item => ({...item, entity_tags: filtered?.find(fi => fi?.enterprise_id === item?.enterprise_id)?.['entity_tags']}));
    setRunningBCWithGraphData(updated);
  }

  const tagChangeHandler = debounce((e, card) => {
    if (e.key === 'Enter'){
      addTags(e.target.value, card);
      handleClose();
    } else {
      getTagList(e.target['value']);
    }
  })

  const getTagList = async (query?) => {
    let tagsRes = await getAllTags(compSel, dispatch, query);
    setTagList(tagsRes['tags']);
  }

  const addTags = async (tagName, card) => {
    let response = await addTag(compSel, dispatch, tagName, card);
    let filteredList = mutateBcList(response, true);
    setBuildcards(filteredList);
    if (runningBCWithGraphData?.length) {
      mutateRunningBc(filteredList, runningBCWithGraphData);
    }
    // getAllBuildcards();
    handleClose();
  }

  const deleteTags = async (tagId) => {
    let response = await handleDelete(dispatch, compSel, tagId);
    let filteredList = mutateBcList(response, false);
    setBuildcards(filteredList);
    if (runningBCWithGraphData?.length) {
      mutateRunningBc(filteredList, runningBCWithGraphData);
    }
    // getAllBuildcards();
  }

  const getTeamList = async (signal?) => {
    let teamListRes = await getSpaceMemberList(compSel, currentSpaceSel, params, signal, dispatch);
    if (teamListRes) {
      setTeamList(teamListRes?.['space']?.['members']);
    }
  }

  const updateGoals = (goal, card) => {
    let mappedList =  buildcards?.map(item => {
      if (item?.enterprise_id == card?.enterprise_id) {
        return {...item, space_goals: [...item?.space_goals, {id: goal?.id, name: goal?.['name']}]};
      } else {
        return item;
      }
    });
    setBuildcards(mappedList);
  }

  const isAllowedToAddEntityTag = () => isAllowedTo('update', 'entity_tag', permissions, { id: currentSpaceSel?.id, type: 'Space'}, userPermissions);

  //=======================================================
  return (
    <>
      <div className="project-page-wrap project-overview" aria-label='project-overview-page'>
        <div className="page-container top-space pb-0">
          <ProjectTopNavigation />

          <section>
            <div className='page-content'>
            
              {/* No data wrapper */}
              {!buildcards?.length && <div className="no-data-wrapper next-idea">
                <em className="thumb"><img src={images.iconNextIdea} alt="Empty" /></em>
                <h4 aria-label="no-space">What's your next idea?</h4>
                <p>Collaborate with your team to develop the next big thing.<br/> We'll capture your idea and translate it into an app.</p>                
                {isAllowedTo('update', 'buildcard', permissions, { id: currentSpaceSel?.id, type: 'Space' }, userPermissions) && <Button data-testid='play-welcome' variant="contained" className="sm-btn purple-btn" onClick={navigateToPlayWelcome}>Start working on an idea</Button>}
              </div> }
              {/* No data wrapper end */}

              {/* Project overview section */}
              { buildcards?.length > 0 && <div className='overview-inner-section'>
                {false && <div className='apps-list'>
                  <div className='section-title'>
                    App (12)
                  </div>
                  <div className='data-container'>
                    <div className='row'>
                      <div className="col-xs-12 col-sm-4">
                        <div className='app-card'>
                          <div className='data-content'>
                            <div className='thumbnail'>
                              <span>
                                <em className='icon be-multiple-users'></em>
                              </span>
                            </div>
                            <div className='description'>
                              <div className='sub-title'>IOS</div>
                              <div className='title'>Makro NextGen CRM</div>
                            </div>
                          </div>
                          <div className='right-arrow rotate-down'>
                            <em className='icon be-down-arrow'></em>
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-4">
                        <div className='app-card'>
                          <div className='data-content'>
                            <div className='thumbnail'>
                              <span>
                                <em className='icon be-multiple-users'></em>
                              </span>
                            </div>
                            <div className='description'>
                              <div className='sub-title'>Android</div>
                              <div className='title'>Makro NextGen CRM</div>
                            </div>
                          </div>
                          <div className='right-arrow rotate-down'>
                            <em className='icon be-down-arrow'></em>
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-4">
                        <div className='app-card'>
                          <div className='data-content'>
                            <div className='thumbnail'>
                              <span>
                                <em className='icon be-multiple-users'></em>
                              </span>
                            </div>
                            <div className='description'>
                              <div className='sub-title'>IOS</div>
                              <div className='title'>Makro NextGen CRM</div>
                            </div>
                          </div>
                          <div className='right-arrow rotate-down'>
                            <em className='icon be-down-arrow'></em>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>}

                {/* Running Buildcards : no accordion */}
                {runningBCWithGraphData.length > 0 && <div className='apps-list project-overview-app-list'>
                  <div className='section-title'>
                    Running Buildcards
                  </div>
                  {runningBCWithGraphData.map((item, idx) => <div key={item?.id} className='data-container cards-with-graph-wrapper'>
                    <div className='row'>
                      <div className="col-xs-12 col-sm-4">                        
                        <div className={`card-template eq-height ${item?.builder_card_phases?.series?.[0]?.data?.every(item => item) ? '' : 'full-height'}` }>
                          <div className='card-body'>                                
                            <div className='tag-container has-right-content'>
                              <Stack direction="row" spacing={1}>              
                                <Chip label="Running" className='green' />
                              </Stack>
                              {isAllowedTo('read', 'space_goal', permissions, entity, userPermissions) && <GoalsTags {...{card: item, idx, spaceGoals, searchSpaceGoalList, updateGoals}} />}
                            </div> 
                            
                            <div className='inner-container'>
                              <div className='left'>
                                <div className='date-container'>
                                  <span className='title'>DELIVERY </span><span>{item?.delivery_date ? moment(item?.delivery_date).format('DD.MM.YYYY') : '-'}</span>
                                </div>
                                <div className='card-title-container'>
                                  <span aria-label='redirect-play' className='card-name' onClick={() => redirect(item)}>{item?.name}</span>
                                  {/* <div className='sub-heading'>
                                    <img src={images.iconEnvelope} alt=""/> Sub heading
                                  </div> */}
                                </div>
                              </div>  
                              <div className='right'>
                                <div className='progress-circle'>                                  
                                  <Tooltip className='progress-tooltip-popover' placement="bottom" classes={{ popper: 'info-tooltip' }} enterTouchDelay={0} title={
                                    <div className='credits'>
                                      <span>{item?.completion ?? 0}%</span>
                                    </div>
                                  }>
                                    <div className="progress-with-bg">
                                      <CircularProgress size={40} variant="determinate" className='bg-progress' value={100} />
                                      <CircularProgress size={40} variant="determinate" value={item?.completion ?? 0} />
                                      <span className="progress-val">{Math.ceil(item?.completion ?? 0)}%</span>
                                    </div>
                                  </Tooltip>
                                </div>
                              </div>                                
                            </div>

                            {isAllowedTo('read', 'tag', permissions) && <div className='card-tags-wrap'>
                              <CardEntityTags {...{card: item, setShowSearchTags, deleteTag: deleteTags, tagList, addTags, tagChangeHandler}}/>
                            </div>}
                          </div>
                          <div className='card-footer'>
                            <div className='content-box'>
                              <span className='title'>Features</span>
                              <div className='count'>{item?.number_of_features}</div>
                            </div>
                            <div className='content-box'>
                              <span className='title'>Platforms</span>
                              <div className='count'>{item?.number_of_platforms}</div>
                            </div>
                            <div className='content-box'>
                              <span className='title'>Credits</span>
                              <div className='count'>120</div>
                            </div>
                          </div>
                        </div>                        
                      </div>                      
                      <div className="col-xs-12 col-sm-8">
                        <div className='graph-container'>
                          {item?.builder_card_phases?.series?.[0]?.data?.every(item => item) ? <HighchartsReact
                            constructorType={"ganttChart"}
                            highcharts={Highcharts}
                            options={item?.builder_card_phases}
                          /> 
                          : 
                          <div className="no-graph-available">
                            <div className="top-content">
                              <div><img src={images.noGraphIcon} alt="No graph" /></div>
                              <h5 className="info-title">Information not available.</h5>
                              <p className="info-text">You will find Buildcard stages here once it begin.</p>
                            </div>                            
                          </div>}
                        </div>
                      </div>
                    </div>
                  </div>)}
                </div>}

                {/* Accordion */}
                <div className='apps-list project-overview-app-list'> 
                  <div className='accordion-wrapper buildcards'>                  
                    <Accordion defaultExpanded={true}>
                      <AccordionSummary                              
                        aria-controls="active-bc-accordion"
                        id="active-bc-accordion"
                      >
                        {buildcards.filter(cards => ['draft', 'pending_approval', 'ready_for_kickoff'].includes(cards?.state) && (!cards?.status || (cards?.status != 'ongoing' && cards?.status != 'completed'))).length > 0 && <div className='section-title'>
                          <span className='clickable'>Active Buildcards <em className='icon be-chevron-down'></em></span>
                        </div>}
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className='data-container'>
                          <div className='row'>
                            { buildcards.filter(cards => ['draft', 'pending_approval', 'ready_for_kickoff'].includes(cards?.state) && (!cards?.status || (cards?.status != 'ongoing' && cards?.status != 'completed')))
                            .map((item, idx) => <div key={item?.id} className="col-xs-12 col-sm-4">                        
                              <div className='card-template eq-height'>
                                <div className='card-body'>                                
                                  <div className='tag-container has-right-content'>
                                    <Stack direction="row" spacing={1}>              
                                      <Chip label={getCardLable(item?.state, item?.status)} className={getBcState(item?.state, item?.status)} />
                                    </Stack>
                                    {isAllowedTo('read', 'space_goal', permissions, entity, userPermissions) && <GoalsTags {...{card: item, idx, spaceGoals, searchSpaceGoalList, updateGoals}} />}
                                  </div> 
                                  
                                  <div className='inner-container'>
                                    <div className='left'>
                                      {item?.state == 'draft' && <div className='date-container'>
                                        <span className='title'> </span><span>{item?.weeks ? `${item?.weeks} weeks` : ''}</span>
                                      </div>}
                                      <div className='date-container'>
                                        <span className='title'>DELIVERY </span><span>{ item?.delivery_date ? moment(item?.delivery_date).format('DD.MM.YYYY') : '-' }</span>
                                      </div>
                                      <div className='card-title-container'>
                                        <span aria-label='navigate-btn' className='card-name' onClick={() => redirect(item)}>{item?.name}</span>
                                        {/* <div className='sub-heading'>
                                          <img src={images.iconEnvelope} alt=""/> Sub heading
                                        </div> */}
                                      </div>
                                    </div>  
                                    <div className='right'>
                                      <div className='progress-circle'>                                  
                                        <Tooltip className='progress-tooltip-popover' placement="bottom" classes={{ popper: 'info-tooltip' }} enterTouchDelay={0} title={
                                          <div className='credits'>
                                            <span>{item?.completion ?? 0}%</span>
                                          </div>
                                        }>
                                          <span>{!['draft', 'pending_approval', 'ready_for_kickoff'].includes(item?.state) && 
                                            <div className="progress-with-bg">
                                              <CircularProgress size={40} variant="determinate" className='bg-progress' value={100} />
                                              <CircularProgress size={40} variant="determinate" value={item?.completion ?? 0} />
                                              <span className="progress-val">{Math.ceil(item?.completion ?? 0)}%</span>
                                            </div>
                                          }</span>
                                        </Tooltip>
                                      </div>
                                    </div>                                
                                  </div>

                                  {isAllowedTo('read', 'tag', permissions) && <div className='card-tags-wrap'>
                                    <CardEntityTags {...{card: item, setShowSearchTags, deleteTag: deleteTags, tagList, addTags, tagChangeHandler}}/>
                                  </div>}
                                  
                                </div>
                                <div className='card-footer'>
                                  <div className='content-box'>
                                    <span className='title'>Features</span>
                                    <div className='count'>{item?.number_of_features}</div>
                                  </div>
                                  <div className='content-box'>
                                    <span className='title'>Platforms</span>
                                    <div className='count'>{item?.number_of_platforms}</div>
                                  </div>
                                  <div className='content-box'>
                                    <span className='title'>Credits</span>
                                    <div className='count'>120</div>
                                  </div>
                                </div>
                              </div>                        
                            </div> )}
                          </div>
                        </div>                              
                      </AccordionDetails>
                    </Accordion>                      
                  </div>                    
                </div>
                <div className='apps-list project-overview-app-list'>                                  
                  <div className='accordion-wrapper buildcards'>                                       
                    <Accordion defaultExpanded={true}>
                      <AccordionSummary                              
                        aria-controls="shipped-bc-accordion"
                        id="shipped-bc-accordion"
                      >
                        {buildcards?.filter(cards => cards?.status == 'completed').length > 0 && <div className='section-title'>
                          <span className='clickable'>Shipped ({buildcards?.filter(cards => cards?.status == 'completed').length}) <em className='icon be-chevron-down'></em></span>
                        </div>}
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className='data-container'>
                          <div className='row'>
                            {buildcards?.filter(cards => cards?.status == 'completed').map((item, idx) => <div key={item?.id} className="col-xs-12 col-sm-4">                        
                              <div className='card-template eq-height'>
                                <div className='card-body'>                                
                                  <div className='tag-container has-right-content'>
                                    <Stack direction="row" spacing={1}>              
                                      <Chip label="Delivered" style={{ 'background' : '#00C853' }} />
                                    </Stack>
                                    {isAllowedTo('read', 'space_goal', permissions, entity, userPermissions) && <GoalsTags {...{card: item, idx, spaceGoals, searchSpaceGoalList, updateGoals}} />}
                                  </div> 
                                  
                                  <div className='inner-container'>
                                    <div className='left'>
                                      <div className='date-container'>
                                        <span className='title'>DELIVERY </span><span>{item?.delivery_date ? moment(item?.delivery_date).format('DD.MM.YYYY') : '-'}</span>
                                      </div>
                                      <div className='card-title-container'>
                                        <span className='card-name' onClick={() => redirect(item)}>{item?.name}</span>
                                        {/* <div className='sub-heading'>
                                          <img src={images.iconEnvelope} alt=""/> Sub heading
                                        </div> */}
                                      </div>
                                    </div>  
                                    <div className='right'>
                                      <div className='progress-circle'>                                  
                                        <Tooltip className='progress-tooltip-popover' placement="bottom" classes={{ popper: 'info-tooltip' }} enterTouchDelay={0} title={
                                          <div className='credits'>
                                            <span>{item?.completion ?? 0}%</span>
                                          </div>
                                        }>
                                          <span>{!['draft', 'pending_approval', 'ready_for_kickoff'].includes(item?.state) && 
                                            <div className="progress-with-bg">
                                              <CircularProgress size={40} variant="determinate" className='bg-progress' value={100} />
                                              <CircularProgress size={40} variant="determinate" value={item?.completion ?? 0} />
                                              <span className="progress-val">{Math.ceil(item?.completion ?? 0)}%</span>
                                            </div>
                                          }</span>
                                        </Tooltip>
                                      </div>
                                    </div>                                
                                  </div>

                                  {isAllowedTo('read', 'tag', permissions) && <div className='card-tags-wrap'>
                                    <CardEntityTags {...{card: item, setShowSearchTags, deleteTag: deleteTags, tagList, addTags, tagChangeHandler}}/>
                                  </div>}
                                </div>
                                <div className='card-footer'>
                                  <div className='content-box'>
                                    <span className='title'>Features</span>
                                    <div className='count'>{item?.number_of_features}</div>
                                  </div>
                                  <div className='content-box'>
                                    <span className='title'>Platforms</span>
                                    <div className='count'>{item?.number_of_platforms}</div>
                                  </div>
                                  <div className='content-box'>
                                    <span className='title'>Credits</span>
                                    <div className='count'>120</div>
                                  </div>
                                </div>
                              </div>                        
                            </div>)}
                          </div>
                        </div>                              
                      </AccordionDetails>
                    </Accordion>                      
                  </div>                    
                </div>
                {/* Accordion end */}
                
              </div>}
              {/* Project overview section end */}
            </div>
            
            <Summary tab={"Project"}></Summary>
            
          </section>

          <section className='project-overview-tab-page'>
            
          </section>

        </div>
      </div>

      {/* Tag result section */}
      {showSearchTags && <TagResultSection {...{showSearchTags, setShowSearchTags}}/>}
      {/* Tag result section end */}
    </>
  )
}
